import React, { useState } from 'react'
import Theme6navbar from '../../../layouts/Theme6navbar'
import { Container } from 'react-bootstrap'
import { FormHelperText, Grid, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import PersonIcon from '@mui/icons-material/Person';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DescriptionIcon from '@mui/icons-material/Description';
const Theme6contactus = () => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    const onSubmit = (data) => {
        console.log(data);
    };
    return (
        <div>
            <div className='bg-t6-contact'>
                <Theme6navbar />
                <div className='Contact-content'>
                    <h1>Contact</h1>
                    <p>{`Home >> Contact`}</p>
                </div>
            </div>
            <Container className='mt-5'>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
                        <div className='contact-info'>
                            <div className='contact-icon'>
                                <i class="fas fa-mobile"></i>
                            </div>
                            <p className='mt-4'>Call Us +777 (100) 1234</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
                        <div className='contact-info'>
                            <div className='contact-icon'>
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <p className='mt-4'>No: 58 A, East Madison St
                                Baltimore, MD, USA</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
                        <div className='contact-info'>
                            <div className='contact-icon'>
                                <i class="fas fa-envelope"></i>
                            </div>
                            <p className='mt-4'>yourname@somemail.com</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className='write-us'>
                <h2>FEEL FREE TO WRITE US</h2>
                <p>Feel free to write us We will back to you as soon as possible.</p>
            </div>
            <div>
                <Container className='mt-5'>
                    <form onSubmit={handleSubmit(onSubmit)} className='form-padding'>
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: " Name is required",

                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            // label="Enter Name *"
                                            label={
                                                <span>
                                                    <PersonIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                    Enter Name *
                                                </span>
                                            }
                                            placeholder="Enter Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.name && errors.name.message}
                                </FormHelperText>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Mail ID is required",
                                        validate: validateEmail,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            // label="Mail ID *"
                                            label={
                                                <span>
                                                    <MarkunreadIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                    Enter Email *
                                                </span>
                                            }
                                            placeholder="Mail ID"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.email && errors.email.message}
                                </FormHelperText>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                <div>
                                    <Controller
                                        name="about"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: `Message is required` }}
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                rows={5}
                                                fullWidth
                                                // label="Enter Message *"
                                                label={
                                                    <span>
                                                        <DescriptionIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                        Enter Message *
                                                    </span>
                                                }
                                                placeholder="Enter Message *"
                                                type="text"
                                                {...field}
                                                className="invest-area"
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.about && errors.about.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='text-center'>
                            <button className="contact-btn" type="submit">
                                Send
                            </button>
                        </div>
                    </form>
                </Container>
            </div>
            <div className='mt-5'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <iframe
                            style={{ borderRadius: '15px', border: 0, width: '100%' }}
                            title="google-map"
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3730.9500954897703!2d78.13120084502243!3d9.930841232529497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwNTUnNTEuNSJOIDc4wrAwOCcwMS4xIkU!5e0!3m2!1sen!2sin!4v1722334469432!5m2!1sen!2sin"
                            width="500"
                            height="350"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Theme6contactus
