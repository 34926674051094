import React from 'react'

const Theme2footer = () => {
  document.getElementById('theme').setAttribute('href', '/css/theme2.css');
  return (
    <>
      <section className='theme2-footer'>
        {/* <div className='text-center'> <img src='/images/theme2/theme2logo.png' className='theme2-logo mt-5 mb-3 img-fluid' /> </div> */}
        {/* <div className='foot-nav py-3'>
          <span className='foot-nav-li' style={{color: 'white' }}><b>Services.</b></span>
          <span className='foot-nav-li' style={{color: 'white' }}><b>Work.</b></span>
          <span className='foot-nav-li' style={{color: 'white' }}><b>Skills.</b></span>
          <span className='foot-nav-li' style={{color: 'white' }}><b>Experience.</b></span>
          <span className='foot-nav-li' style={{color: 'white' }}><b>Blog.</b></span>
        </div> */}
        <p className='text-center pt-2 pb-4 m-0' style={{color: '#8750f7'}}>Powered by<b>&nbsp;&nbsp;E-Mugavari.</b></p>
      </section>
    </>
  )
}

export default Theme2footer