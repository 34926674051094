import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Pagenotfound from './components/common-page/page-not-found/Pagenotfound';
import BaseRoute from './components/BaseRoute';
import Sesstiontimeout from './components/common-page/sesstion-time-out-page/Sesstiontimeout';

function App() {

  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <BaseRoute />
      </DataProvider>
      <Routes>
        <Route path='404' element={<Pagenotfound />} />
        <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
      </Routes>
    </div >
  );
}

export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",