import React from 'react'
import Theme5navbar from '../layouts/Theme5navbar'
import { Grid } from '@mui/material'

const Theme5blogdetails = () => {
    return (
        <div className='banner-t5-back'>
            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Exploring The Latest Innovations In Video Games</h1>
                        <p className='p-t5-bpdet'>Home &nbsp;/ &nbsp;Power Accessories &nbsp;/ &nbsp;10 Devices To Make Your Everyday Life Better</p>
                    </div>
                </div>
            </div>
            <div className='blog-det-t5'>
                <div className='image-detailsblog'>
                    <Grid container >
                        <Grid item sm={12} md={12} lg={12}>
                            <img src={'/images/theme5/blognew-3.jpg'} alt="blog-img" className='big-det-img mt-5' data-aos="fade-right"  data-aos-duration="1000"/>
                            <h3 className='mt-4' data-aos="fade-right"  data-aos-duration="1000">Tellus elementum sagittis vitae et leo duis ut. Pretium lectus quam.Sit amet justo donec enim diam vulputate ut pharetra sit.</h3>
                            <p className='p-t5-bpdet mt-3' data-aos="fade-right"  data-aos-duration="1000">Commodo nulla facilisi nullam vehicula. Malesuada bibendum arcu vitae elementum curabitur vitae. Nullam sit amet vehicula ex. Vivamus ac enim eu lacus ornare sollicitudin. Integer lacus dui, gravida sit amet pellentesque sed, Nullam ante sem, eleifend vitae pellentesque sed, tristique rutrum lacus. Pellentesque mattis viverra erat, at lacinia elit. Quisque convallis pharetra metus finibus volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam semper libero ut lectus vehicula, quis sagittis magna dapibus. Suspendisse sodales lorem tincidunt sagittis fermentum. Curabitur placerat ultrices dolor eu sagittis. Donec non sodales lacus. Nullam ut felis vitae justo tristique consectetur id vitae quam. Nam posuere eros ut urna viverra rutrum. Nam nulla tellus, viverra mollis lectus nec, hendrerit luctus libero.</p>
                            <p className='p-t5-bpdet' data-aos="fade-right"  data-aos-duration="1000">Integer quis nulla eu erat eleifend laoreet pretium et erat. Aenean libero elit, congue sed faucibus quis, pretium eu urna. Sed eu iaculis justo. Praesent feugiat massa non arcu pellentesque dictum. In ac arcu aliquet, rhoncus massa vel, facilisis mi. Nunc eget est ex. Nullam ante sem, eleifend vitae pellentesque sed, tristique rutrum lacus. Proin eu metus hendrerit, euismod justo et, lacinia libero. Nullam imperdiet ornare imperdiet. Maecenas eleifend, metus eu scelerisque elementum, massa felis varius dolor, nec consequat urna diam et tortor.</p>
                            <div className='mt-3' data-aos="fade-left"  data-aos-duration="1000">
                                <h4>Verci varius natoque penatibus et magnis dis parturient montes</h4>
                                <p className='p-t5-bpdet mt-3'>Vivamus ac enim eu lacus ornare sollicitudin. Integer lacus dui, gravida sit amet pellentesque sed, posuere nec eros. Pellentesque mattis viverra erat, at lacinia elit. Quisque convallis pharetra metus finibus volutpat. Aliquam suscipit, erat vel pharetra tempus, enim sem pellentesque est, dapibus efficitur lacus ipsum porttitor augue. Nullam sit amet vehicula ex.</p>
                                <Grid container direction="row" alignItems="center" justifyContent="space-evenly" spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                        <img src={'/images/theme5/Blog-Detail-Img-1.jpg'} alt="blg-det-t5-img" className='list-det-img' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                        <div data-aos="fade-left"  data-aos-duration="1000">
                                            <ul className='blog-details-lists-t5'>
                                                <li>Cras adipiscing enim eu turpis egestas pretium aenean Fonsectetur adipiscing sit.</li>
                                                <li>Egestas egestas fringilla phasellus faucibus scelerisque Zamper auctor neque.</li>
                                                <li>Morbi enim nunc faucibus a pellentesque sit amet porttios Id volutpat lacus Iaculis.</li>
                                                <li>Elementum pulvinar etiam non quam lacusets suspendisse Semper auctor.</li>
                                                <li>Blandit libero volutpat sed egestas arcu dui Jirna condimentum mattis pellentesque id.</li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='mt-4'>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-evenly" spacing={3}>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                            <div className='mt-3' data-aos="fade-left"  data-aos-duration="1000">
                                                <h4>Natoque penatibus orci varius et magnis dis parturient montes</h4>
                                                <p className='p-t5-bpdet mt-4'> Aenean vehicula ex accumsan lacus suscipit, a auctor dolor aliquet. Pellentesque vitae eleifend erat, non euismod risus. Vestibulum dapibus magna velit, hendrerit placerat mi ullamcorper id. Fusce quis neque tempor neque porttitor pellentesque ac eu velit. Sed enim ligula, facilisis sed lacinia sed, volutpat scelerisque nisl. Aenean posuere nec orci at imperdiet. Donec vitae elit sed sem pretium efficitur at sed dolor. Suspendisse sed aliquet ex, ut iaculis nunc. Pellentesque convallis metus justo, eget varius erat scelerisque ut. Sed facilisis fringilla orci eget maximus.Integer quis nulla eu erat eleifend laoreet pretium et erat. Aenean libero elit, congue sed faucibus quis, pretium eu urna. Sed eu iaculis justo. Praesent feugiat massa non arcu pellentesque dictum. In ac arcu aliquet, rhoncus massa vel, facilisis mi. Nunc eget est ex. Nullam ante sem, eleifend vitae pellentesque sed, tristique rutrum lacus. Proin eu metus hendrerit, euismod justo et, lacinia libero. Nullam imperdiet ornare imperdiet. Aliquam suscipit, erat vel pharetra tempus, enim sem pellentesque est, dapibus efficitur lacus ipsum porttitor augue. Nunc orci mauris, venenatis id nulla id, hendrerit vulputate nunc.</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                            <img src={'/images/theme5/Blog-Detail-Img-2.jpg'} alt="blg-det-t5-img" className='list-det-img1' />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='mt-3' data-aos="fade-left"  data-aos-duration="1000">
                    <h4>Magnis dis erci varius natoque penatibus et parturient montes</h4>
                    <p className='p-t5-bpdet mt-2'>Bliquam suscipit, erat vel pharetra tempus, enim sem pellentesque est, dapibus efficitur lacus ipsum porttitor augue. Nullam sit amet vehicula ex. Vivamus ac enim eu lacus ornare sollicitudin. Integer lacus dui, gravida sit amet pellentesque sed, posuere nec eros. Pellentesque mattis viverra erat, at lacinia elit. Quisque convallis pharetra metus finibus volutpat</p>
                </div>
                <div className='features-right-t5-box1-mail-t5-roc1'>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <p className='comments-blg-det'>Sovamus luctus, erat nec aliquet vehicula, urna nisl consequat justo, vitae lacinia mauris est viverra nisi. Fusce commodo odio vel sollicitudin laoreet. In nisi urna, semper eget viverra at, placerat sed justo. Vivamus luctus, erat nec aliquet vehicula, urna nisl consequat justo, vitae lacinia mauris est viverra nisi.</p>

                    </Grid>
                    <div className="text-right">
                        <p className='comments-blg-det'>
                            - Hendry Erick, Los Angles, CA</p>
                    </div>
                </div>
                <div className='my-5'>
                    <p>Sivamus mollis gravida consectetur. Vestibulum vel nibh pretium, fringilla quam ac Maecenas eleifend metus eu scelerisque elementum, Vestibulum suscipit blandit pharetra. Aliquam suscipit Integer quis nulla eu erat eleifend laoreet pretium et erat urna sit amet iaculis dictum, ligula erat ornare enim, et imperdiet massa sem vitae tortor.</p>
                </div>
                <div className='blog-right-t5-box1-mail-t5-roc1' data-aos="fade-right"  data-aos-duration="1000">
                    <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                        <h3>Comments ( 1 )</h3>
                        <div className='comm-t5-blgdet mt-3'>
                            <div>
                                <img src={'/images/theme5/testi-3.jpg'} alt="Products" className='t5-testi-caros mb-2' />
                            </div>
                            <div>
                                <h4>developer</h4>
                                <span className='mt-2'>June 1, 2024 at 10:51 am</span>
                                <p className='comments-blg-det1 mt-2'>Vitae semper quis lectus nulla at volutpat diam ut venenatis. Duis tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis.</p>
                            </div>
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Theme5blogdetails