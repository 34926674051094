import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Card, Container } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, CardContent, CardMedia, Dialog, DialogContent, Divider, FormControl, FormHelperText, FormLabel, Grid, List, ListItem, ListItemIcon, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useForm } from 'react-hook-form';
import { IoArrowDownCircle } from "react-icons/io5";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Aos from 'aos';
import 'aos/dist/aos.css'
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { Link, NavLink } from "react-router-dom";
import { Autoplay } from 'swiper/modules';
import axios from 'axios';

const options = ['Option 1', 'Option 2'];


const Welcome = () => {
  document.getElementById('theme').setAttribute('href', './css/landing.css');


  const { handleSubmit, control, reset, formState: { errors } } = useForm({});
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');
  const [expanded, setExpanded] = useState("panel1");
  const [visibleItems, setVisibleItems] = useState(10);

  const [open, setOpen] = useState(true)

  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
  };
  const validateEmail = (value) => {
    if (value !== '') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const isValid = emailRegex.test(value);
      return isValid || 'Invalid email address';
    }
  };
  const onSubmit = (data) => {
    debugger
    const config = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    var req = {
      Name: data.name,
      Email: data.email,
      MobileNumber: data.phoneNumber,
    }
    return axios.post("https://formspree.io/f/mzzpnrja", req, config)
    .then((result) => {
      if (result.data) {
        handleClose()
        reset()
        availsmartopen()
      }
    }).catch((err) => {
      console.log(err);
      
    });
  }
  useDidMountEffect(() => {
    Aos.init()
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [isScrolled, setIsScrolled] = useState(false);

  // useDidMountEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const [isFirstImage, setIsFirstImage] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIsFirstImage((prevImage) => !prevImage);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  const [isActive, setIsActive] = useState(false);
  const [anchorElStartupMenu, setAnchorElStartupMenu] = useState(false);
  const handleOpenStartupMenu = () => {
    setAnchorElStartupMenu(!anchorElStartupMenu);
    setIsActive(!isActive);
  };
  const handleCloseStartupMenu = () => {
    setAnchorElStartupMenu(null);
    setIsActive(false);
  };

  const [screenSize, setScreenSize] = useState(1);
  useEffect(() => {
    size();
  }, []);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 8);
  };

  const [activeButton, setActiveButton] = useState('monthly');

  const handleToggle = (button) => {
    setActiveButton(button);
  };
  const validatePhoneNumber = (value) => {
    // const isValid = value.length === 10;
    if (value.length !== 10) {
        return 'Phone number must be 10 digits';
    }
    const matches = value.match(
        /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    if (matches === null) {
        return "Only numbers allowed";
    }
};
const [smartOpen, setSmartOpen] = useState(false)
  const availsmartopen = () => {
    setSmartOpen(true)

     
        setTimeout(() => {
          availsmartClose()
        }, 1500);
    
  }
  const availsmartClose = () => {
    setSmartOpen(false)
  }
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');


  const faqs = [
    { question: 'What is the no-code website builder?', answer: 'E-Mugavari is a no-code website builder that allows users to create websites easily without any coding knowledge.' },
    { question: 'Do I need design skills to use this builder?', answer: 'No design skills are required! Our user-friendly templates and tools make it easy for anyone to create a professional-looking website.' },
    { question: 'Can I customize my website design?', answer: 'Absolutely! You can customize layouts, colors, fonts, and images to match your personal or business branding.' },
    { question: 'Is the website mobile-friendly?', answer: 'Yes, all websites created with our builder are automatically optimized for mobile devices, ensuring a great user experience.' },
    { question: 'Do you offer templates for specific industries?', answer: 'Yes, we provide a wide range of templates tailored for various industries, including personal branding, e-commerce, portfolios, and more.' },
    { question: 'Can I connect my own domain name?', answer: 'Yes, you can easily connect your own domain name to your website for a professional touch.' },
    { question: 'How do I optimize my site for search engines?', answer: 'Our built-in SEO tools guide you in optimizing your website’s content, meta tags, and images for better visibility on search engines.' },
    { question: 'Can I integrate social media with my website?', answer: 'Yes, you can easily link your social media accounts and add social sharing buttons to enhance engagement.' },
    { question: 'What if I need help while building my website?', answer: 'We offer dedicated customer support through tutorials, guides, and a community forum to assist you at any stage.' },
    { question: 'Is there a limit on the number of pages I can create?', answer: 'There are no limits! You can create as many pages as you need for your website.' },
    { question: 'Can I add custom forms to my website?', answer: 'Yes, you can easily create and customize forms to collect user information, feedback, or inquiries.' },
    { question: 'How do I track website performance?', answer: 'Our integrated analytics tools provide insights into website performance, user behavior, and engagement metrics.' },
    { question: 'Is my website secure?', answer: 'Yes, we implement SSL and other security measures to ensure your website and user data are protected.' },
    { question: 'Can I create a blog with this website builder?', answer: 'Yes, our platform includes blogging functionality, allowing you to easily create and manage blog posts to share your insights and updates.' },
    { question: 'Is there a free trial available?', answer: 'Yes, we provide a free trial period so you can explore our features and see if our builder meets your needs' },
    { question: 'How often are new templates added?', answer: 'We regularly update our template library with new designs to keep up with current trends and user needs.' },
    { question: 'Are there any hidden fees?', answer: 'No, we believe in transparency. Our pricing plans are clear, with no hidden fees.' },
    { question: ' What kind of customer support do you offer?', answer: 'We provide 24/7 customer support through live chat, email, and a comprehensive knowledge base.' },
  ];

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(1);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(1);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(1);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(1);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(1);
    } else {
      setScreenSize(1);
    }
  };

  return (
    <div >
      <div className={`${isScrolled ? 'scrolled fixed-tops' : 'fixed-top'}`} >
        <div className='container'>
          <nav className="navbar navbar-expand-lg navbar-light bg-light nav-pads" style={{ padding: '20px 30px', borderRadius: '50px' }}>
            <Link className="navbar-brand nav-log-res1 mt-0" to="/" >
              <img src="/images/welcome/e-mugavari-logo-main.png" alt="logo" className='img-fluid' />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav nav-items m-auto" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <li className="nav-item mr-3">
                  <NavLink className="nav-link menues" style={{ fontWeight: 'bold', color: 'black' }}>Home</NavLink>
                </li>
                <li className="nav-item mr-3">
                  <NavLink className="nav-link menues" style={{ fontWeight: 'bold', color: 'black' }}>Themes</NavLink>
                </li>
                <li className="nav-item mr-3">
                  <NavLink className="nav-link menues" style={{ fontWeight: 'bold', color: 'black' }}>Pricing</NavLink>
                </li>
              </ul>
              <ul className="navbar-nav">
                <div className="nav-align">
                  <Link to={'/login'} className="nav-buttons" style={{ border: '2px solid #007bff', padding: '12px 35px', borderRadius: '30px', fontWeight: 'bold' }}>
                    Login
                  </Link>
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className='banner-images'>
        <div className='banner-content'>
          <h1 className='foot mb-5'>
            <div className='emuga-land'>
              <div>
                <span className='e-mug-gra'>e-Mugavari:</span>
              </div>
              <div>
                <article className='font-art'>
                  <span class='foot-ani'>Empower Your Identity</span>
                  <br />
                  <span class='foot-ani'>Elevate Your Business.</span>
                </article>
              </div>
            </div>
          </h1>
          <div>
            <article class="appeared">
              <span class="appear-disappear frame-1">Global Brands Start Here – No Code, No Limits!</span>
              <span class="appear-disappear frame-2">Your Brand, Your Website, Your Identity – In Minutes!</span>
              <span class="appear-disappear frame-3">Seamless Websites for You and Your Business.</span>
              <span class="appear-disappear frame-4">Build Your Brand, Your Way – No Code Needed!</span>
            </article>
          </div>
          <div className='starting'>
            <button className='start'>Lets Start</button>
          </div>
        </div>
        <div>
          <img className='astrounat img-fluid' src="/images/welcome/Astrounat.png" alt="" />
        </div>
      </div>
      <div className='modern'>
        <div className='container'>
          <div className='pt-5'>
            <h3 data-aos="fade-up" data-aos-duration="1000" className='era'>Why website is more important for this <span className='erass'>“Modern Era” ?</span></h3>
          </div>
          <p data-aos="fade-up" data-aos-duration="1000" className='ofer'>In the modern era, a website is an essential tool for businesses and individuals alike. It offers numerous benefits, including increased visibility, credibility, and engagement.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className='card-section'>
          <Grid container spacing={3} className="cardss">
            <Grid item xs={12} sm={6} md={4}>
              <Card className='carded1'>
                <div className='toped1'>
                  <CardMedia
                    component="img"
                    className='announce  img-fluid'
                    image="/images/welcome/announce.png"
                    alt="Announce"
                  />
                  <h6 className='bramd'>
                    Brand Identity Creation
                  </h6>
                </div>
                <div className='spans1'>
                  <p className='card-para'>
                    A website provides a platform to showcase a business's brand identity, including its values, mission, and unique selling points.
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className='carded2'>
                <div className='toped2'>
                  <CardMedia
                    component="img"
                    className='announce  img-fluid'
                    image="/images/welcome/people.png"
                    alt="People"
                  />
                  <h6 className='bramd'>
                    Building Audience Engagement
                  </h6>
                </div>
                <div className='spans2'>
                  <p className='card-para'>
                    A website provides a cohesive platform for visuals and storytelling to showcase your unique style.
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className='carded3'>
                <div className='toped3'>
                  <CardMedia
                    component="img"
                    className='announce  img-fluid'
                    image="/images/welcome/market.png"
                    alt="Market"
                  />
                  <h6 variant="h6" className='bramd'>
                    Digital Footprint Growth
                  </h6>
                </div>
                <div className='spans3'>
                  <p className='card-para'>
                    A website expands your digital footprint by increasing visibility and attracting a wider audience online.
                  </p>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='discover'>
        <Container className='container'>
          <Grid container spacing={3} className='row' alignItems="center" justifyContent="center">
            <Grid item xs={12} lg={6}></Grid>
            <Grid item xs={12} lg={6} >
              <div className='xplore'>
                <p data-aos="fade-up" data-aos-duration="1000" className='cover'>
                  Discover Over <span className='hund'>100+</span> Design Theme Templates to Create Your Digital Universe.
                </p>
                <button data-aos="fade-up" data-aos-duration="1000" className='start-let'>
                  Let's Explore
                </button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <div className='love'>
          <h4 data-aos="fade-up" data-aos-duration="1000" className='oures'> why do users <span className='loe'>LOVE</span> Our e-Mugavari?</h4>
        </div>
        <div className='users'>
          <Grid container spacing={3} className='row' alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6} lg={4} >
              <img
                data-aos="fade-up" data-aos-duration="1000"
                src="/images/welcome/mugavari-girl.png"
                alt="E-Mugavari"
                className='mugavari-girl img-fluid'
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={8} className='users-muga'>
                  <div data-aos="fade-up" data-aos-duration="1000" className='user1'>
                    <h6 className='friendly1'>Pre-designed Templates:</h6>
                    <p className='easy1'>
                      E-Mugavari's pre-designed templates makes website creation effortless. Tailored for both personal branding and business needs, these templates provide a professional foundation.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='users-muga'>
                  <div data-aos="fade-up" data-aos-duration="1000" className='user2'>
                    <h6 className='friendly1'>Custom Domain Support:</h6>
                    <p className='easy2'>
                      Custom domain allows to use your own domain for a truly professional online presence.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} className='users-muga'>
                  <div data-aos="fade-up" data-aos-duration="1000" className='user3'>
                    <h6 className='friendly1'>Social Media Integration:</h6>
                    <p className='easy3'>
                      E-Mugavari's social media integration, connects the website with all your social platforms which allows visitors to engage with your content across multiple channels.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='users-muga'>
                  <div data-aos="fade-up" data-aos-duration="1000" className='user4'>
                    <h6 className='friendly1'>SEO Optimization Tools:</h6>
                    <p className='easy4'>
                      E-Mugavari's built-in SEO optimization tools, are designed to boost website’s visibility on search engines and improve site's ranking.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={8} className='users-muga'>
                  <div data-aos="fade-up" data-aos-duration="1000" className='user5'>
                    <h6 className='friendly1'>Responsive Design:</h6>
                    <p className='easy5'>
                      E-Mugavari ensures responsive design, meaning your website will look perfect on any device, from desktops to smartphones by adapting automatically to provide an optimal user experience. No need for separate mobile versions.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='swiper-section'>
        <Container className='mt-4'>
          <Grid container spacing={3} className='row' alignItems="center" justifyContent="center">
            <Grid item xs={12} lg={6} style={{ padding: '0px' }}>
              <div className='clients'>
                <p data-aos="fade-up" data-aos-duration="1000" className='cover'>
                  "Our clients are the heartbeat of E-Mugavari, <span className='succ'>and their success stories inspire us every day."</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} style={{ padding: '0px' }}>
              <div className="testimonial-slider-section">
                <div className="container-fluid">
                  <div>
                    <Swiper
                      slidesPerView={screenSize}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                    >
                      <SwiperSlide>
                        <div className="testimonial-client">
                          <div className="client_pics_titled">
                            <div>
                              <img src="/images/welcome/user-avatar-male-5.png" alt="" className="reviews " />
                            </div>
                            <div className="client_pic_title">
                              <h4>Dr. Ajeet</h4>
                              {/* <p>Product Designer</p> */}
                            </div>
                          </div>
                          <div className="client_content">
                            <p className='client-feeds'>"I built the website for my company using e-mugavari. The templates are sleek, and I love how easy it is to customize every detail. My online presence has never looked better!"</p>
                            <span className='client-rate'>Ratings:</span>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial-client">
                          <di className="client_pics_titled">
                            <div>
                              <img src="/images/welcome/user-avatar-male-5.png" alt="" className="reviews " />
                            </div>
                            <div className="client_pic_title">
                              <h4>Alagu Jegan</h4>
                              {/* <p>Product Designer</p> */}
                            </div>
                          </di>
                          <div className="client_content">
                            <p className='client-feeds'>"I needed a simple yet effective website for our non-profit, and e-mugavari delivered it.  We were able to launch our site in just a day!"</p>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial-client">
                          <div className="client_pics_titled">
                            <div>
                              <img src="/images/welcome/user-avatar-male-5.png" alt="" className="reviews " />
                            </div>
                            <div className="client_pic_title">
                              <h4>Micheal Joshuva</h4>
                              {/* <p>Product Designer</p> */}
                            </div>
                          </div>
                          <div className="client_content">
                            <p className='client-feeds'>"I needed a professional website to showcase my business and I did it through e-mugavari.  The process was straightforward, and the analytics help me track engagement. Highly recommend!"</p>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </SwiperSlide>
                 
                    </Swiper>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className='choose-section'>
        <div className='plan'>
          <h2 data-aos="fade-up" data-aos-duration="1000" className='yours'>Choose your <span className='plans'>Plan</span></h2>
        </div>
        <Container className='mt-5'>
          <Grid container spacing={2} className='row' alignItems="flex-start" justifyContent="center">
            <Grid item xs={12} sm={6} md={5} lg={5}>
              <div data-aos="fade-up" data-aos-duration="1000" className='input_background mb-3'>
                <button
                  className="ind"
                  style={{
                    backgroundColor: activeButton === 'monthly' ? '#7F148C' : '#F0ECEC',
                    color: activeButton === 'monthly' ? 'white' : 'black',
                  }}
                  onClick={() => handleToggle('monthly')}
                >
                  Monthly
                </button>
                <button
                  className="buss"
                  style={{
                    backgroundColor: activeButton === 'annual' ? '#7F148C' : '#F0ECEC',
                    color: activeButton === 'annual' ? 'white' : 'black',
                  }}
                  onClick={() => handleToggle('annual')}
                >
                  Annual
                </button>
              </div>
              <Card data-aos="fade-up" data-aos-duration="1000" className='premiumed'>
                <CardContent>
                  <div className='ratess'>
                    <div>
                      <h5 className='price-line'>
                        Agam (Individual)
                      </h5>
                    </div>
                    <div>
                      <h6 className='priced'>
                        {activeButton === 'monthly' ? 'Rs. 499' : 'Rs. 4999'}
                      </h6>
                    </div>
                  </div>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" component="div" fontWeight="bold">
                    Features
                  </Typography>
                  <List sx={{ mt: 1 }}>
                    {/* <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Unlimited Products & Categories</Typography>
                    </ListItem> */}
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>1000+ Design Templates</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Galleries & Blog</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Email Newsletter</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Custom Pages</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Business Analytics</Typography>
                    </ListItem>
                    <ListItem sx={{ visibility: 'hidden' }}>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Unlimited Products & Categories</Typography>
                    </ListItem>
                  </List>
                </CardContent>
                <div className='sub-div1'>
                  <button className='subs'>Subscribe</button>
                </div>
              </Card>
              <div data-aos="fade-up" data-aos-duration="1000" className='cans'>
                <ul className='trial'>
                  <li>1 Month free trial</li>
                  <li>Switch or cancel anytime</li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5}>
              <Card data-aos="fade-up" data-aos-duration="1000" className='premium'>
                <CardContent>
                  <div className='ratess'>
                    <div>
                      <h5 className='price-line'>
                        Puram (Business)
                      </h5>
                    </div>
                    <div>
                      <h6 className='priced'>
                        {activeButton === 'monthly' ? 'Rs. 999' : 'Rs. 9999'}
                      </h6>
                    </div>
                  </div>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" component="div" fontWeight="bold">
                    Features
                  </Typography>
                  <List sx={{ mt: 1 }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Unlimited Products & Categories</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>1000+ Design Templates</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Galleries & Blog</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Email Newsletter</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Custom Pages</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <Typography>Business Analytics</Typography>
                    </ListItem>
                  </List>
                </CardContent>
                <div className='sub-div1'>
                  <button className='subs'>Subscribe</button>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className='subscribe'>
        <div className='joined'>
          <div data-aos="fade-up" data-aos-duration="1000" >
            <p className='joins'> Subscribe Now</p>
            <span className='stay'>Stay in the loop with everyone you need to know</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className='emailed'>
            <input type="email" placeholder='Sign up for our mail list' className='mail-list' />
            <button className='scibe'>Subscribe</button>
            <p className='care'>We care about your data in our <span style={{ color: 'white' }}>Privacy Policy</span></p>
          </div>
        </div>

      </div>
      <div className='accord'>
        <div data-aos="fade-up" data-aos-duration="1000" className='faq'>
          <h4 className='faq-tite'>FAQs</h4>
          <p className='help'>Have Questions? We’re here to Help.</p>
        </div>
        <Container>
          <div data-aos="fade-up" data-aos-duration="1000" className="accordion">
            {faqs.slice(-visibleItems).map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${faqs.length - visibleItems + index + 1}`}
                onChange={handleChange(`panel${faqs.length - visibleItems + index + 1}`)}
                className="sm-accordion-divs"
              >
                <AccordionSummary
                  aria-controls={`panel${faqs.length - visibleItems + index + 1}-content`}
                  id={`panel${faqs.length - visibleItems + index + 1}-header`}
                >
                  <div className="d-flex w-100">
                    <p style={{ marginBottom: '0px' }}>
                      {index + 1}. {faq.question}
                    </p>
                    <div className="ml-auto">
                      {expanded === `panel${faqs.length - visibleItems + index + 1}` ? (
                        <span className="round-black">-</span>
                      ) : (
                        <span className="round-black">+</span>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  Answer: {faq.answer}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className='text-center'>
            <button className='conatct-let' onClick={loadMore}>
              Load More <IoArrowDownCircle />
            </button>
          </div>
        </Container>
      </div>
      <div className='contacts'>
        <div data-aos="fade-up" data-aos-duration="1000" className='container e-muga-cont'>
          <div className='guide'>
            <img src="/images/welcome/logo-e 1.png" alt="" className='logos' />
            <h2>We're here to help 24/7</h2>
            <p >Every guide is trained and excited to work with you, whether you need help with a password reset or you're looking for a team to build your complete web presence.</p>
            <button className='started-let'>
              Let's Start
            </button>
          </div>
          <div>
            {isFirstImage ? (
              <img src="/images/welcome/expect.png" alt="First" className='expected' />
            ) : (
              <img src="/images/welcome/expect-2.png" alt="Second" className='expected' />
            )}
          </div>
        </div>
        <Container>
          <div data-aos="fade-up" data-aos-duration="1000" className="contact-container">
            <Grid container spacing={3} className="contact-grid" alignItems="center" justifyContent="center">
              <Grid item xs={12} md={6}>
                <div className="contact-card">
                  <Typography variant="h6" className="contact-title">
                    Reach Out
                  </Typography>
                  <div className="contact-info">
                    {/* <div className="contact-item">
                      <LocationOnIcon className="contact-icon" />
                      <Typography variant="body1" className="contact-text">
                        2/397 Perumbakkam Main road,<br />
                        Perumbakkam, Chennai, Tamil Nadu 600100
                      </Typography>
                    </div> */}
                    <div className="contact-item">
                      <PhoneIcon className="contact-icon" />
                      <Typography variant="body1" className="contact-text">
                        +91 81243 34423 &emsp; +91 98941 34423
                      </Typography>
                    </div>
                    <div className="contact-item">
                      <EmailIcon className="contact-icon" />
                      <Typography variant="body1" className="contact-text">
                        vanakkam@e-mugavari.com
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={1}>
                <Divider orientation="vertical" flexItem className="vertical-divider" />
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="contact-card1">
                  <Typography variant="h6" className="contact-title">
                    Chat Now
                  </Typography>
                  <Typography variant="body2" className="contact-description">
                    Chat for quick help on product issues,<br />
                    your account, and more.
                  </Typography>
                  <form className="contact-form">
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Sign up for our mail list"
                      className="contact-input"
                      InputProps={{ style: { borderRadius: '5px' } }}
                    />
                    <button className="signup-button">
                      Sign Up
                    </button>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className='last line'>
          <span className='regis'>©2024 All rights reserved by e-Mugavari | Powered by <a href='https://www.amizhth.com/' style={{ textDecoration: 'underline' }}>Amizhth Techno Solutions PVT LTD</a></span>
        </div>
      </div>
      <Dialog fullWidth={true} maxWidth={"xs"} open={open} className='dg-form' style={{borderRadius:'20px '}}>
        <div className='dg-cont'>
        <h3 className='mb-3'>Let's Start</h3>
          <form className="signin-form mt-2" onSubmit={handleSubmit(onSubmit)}>

            <div className="form-group mt-2">
              {/* <FormLabel shrink="true" className='input_Lable'>Name <span className="text-danger">*</span></FormLabel> */}
              <Controller name="name" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                render={({ field }) =>
                  <TextField
                    {...field}
                    variant='outlined'
                    label="Name *"
                    placeholder='Enter Your Name'
                    fullWidth
                    sx={{ background: 'white' }}
                  />} />
              <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
            </div>

            <div className="form-group mt-2">
              {/* <FormLabel shrink="true" className='input_Lable'>Email <span className="text-danger">*</span></FormLabel> */}
              <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                render={({ field }) =>
                  <TextField
                    {...field}
                    variant='outlined'
                    label="Email *"
                    placeholder='Enter Your Email'
                    fullWidth
                    sx={{ background: 'white' }}
                  />} />
              <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
            </div>


            <div className="form-group mt-2">
              {/* <FormLabel shrink="true" className='input_Lable'>Phone Number <span className="text-danger">*</span></FormLabel> */}
              <Controller name="phoneNumber" control={control} defaultValue="" rules={{ required: 'Phone Number is required', validate: validatePhoneNumber, }}
                render={({ field }) =>
                  <TextField
                    {...field}
                    variant='outlined'
                    label="Phone Number *"
                    placeholder='Enter Your Phone Number'
                    fullWidth
                    sx={{ background: 'white' }}
                    inputProps={{ maxLength: 10 }}
                    type="number"
                    onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
                  />} />
              <FormHelperText className='text-danger'>{errors.phoneNumber && errors.phoneNumber.message}</FormHelperText>
            </div>

            <div className="btn-div text-center">
            <button className='scibe2 mr-3' onClick={()=>handleClose()}>Close</button>
              <button className='scibe1'>Submit</button>
            </div>
          </form>
        </div>
      </Dialog>
      <div>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={smartOpen}
          onClose={availsmartClose}
        >
          <DialogContent>
            <div className="text-center">
              <img src={'/images/Welcomepage/sucess.png'} alt="" className="sucess-image" />
              <h4>Thank you for Submitting...</h4>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div >
  )
}



export default Welcome