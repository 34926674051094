import React from 'react'
import Theme4navbar from '../../layouts/Theme4navbar'
import { CircularProgress, Divider, Grid, Stack } from '@mui/material'
import { useEffect } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ModeIcon from '@mui/icons-material/Mode';
import SchoolIcon from '@mui/icons-material/School';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { Swiper, SwiperSlide } from 'swiper/react';
import Theme4footer from '../../layouts/Theme4footer';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useRef } from 'react';

const Theme4homepage = () => {
    // const [screenSize, setScreenSize] = useState(3)

    // const size = () => {
    //     if (window.innerWidth > 1440) {
    //         setScreenSize(3)
    //     } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
    //         setScreenSize(3)
    //     } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
    //         setScreenSize(2)
    //     } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
    //         setScreenSize(2)
    //     } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
    //         setScreenSize(2)
    //     } else {
    //         setScreenSize(1)
    //     }
    // }

    // useDidMountEffect(() => {
    //     size();
    // }, [])

    const designPercentage = 60;

    const skills = [
        { skill: 'Photoshop', percentage: 90 },
        { skill: 'Illustrator', percentage: 65 },
        { skill: '3D MAX', percentage: 75 },
    ];

    const designPercentage1 = 90;

    const skills1 = [
        { skill: 'Java', percentage: 35 },
        { skill: 'C++', percentage: 95 },
        { skill: 'HTML5', percentage: 80 },
    ];

    const designPercentage2 = 85;

    const skills2 = [
        { skill: 'Dutch', percentage: 55 },
        { skill: 'French', percentage: 95 },
        { skill: 'Portuguese', percentage: 37 },
    ];

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [activeSection, setActiveSection] = useState('about');

    const aboutRef = useRef(null);
    const resumeRef = useRef(null);
    const servicesRef = useRef(null);
    const skillsRef = useRef(null);
    const clientsRef = useRef(null);

    const sectionImages = {
        about: '/images/theme4/fig4.png',
        resume: '/images/theme4/fig5.png',
        services: '/images/theme4/fig9.png',
        skills: '/images/theme4/fig7.png',
        clients: '/images/theme4/fig8.png',
    };

    useEffect(() => {
        const sections = [
            { ref: aboutRef, id: 'about' },
            { ref: resumeRef, id: 'resume' },
            { ref: servicesRef, id: 'services' },
            { ref: skillsRef, id: 'skills' },
            { ref: clientsRef, id: 'clients' },
        ];

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const section = sections.find(sec => sec.ref.current === entry.target);
                    if (section) {
                        setActiveSection(section.id);
                    }
                }
            });
        }, options);

        sections.forEach(section => {
            if (section.ref.current) {
                observer.observe(section.ref.current);
            }
        });

        return () => {
            sections.forEach(section => {
                if (section.ref.current) {
                    observer.unobserve(section.ref.current);
                }
            });
        };
    }, []);


    return (
        <>
            <Theme4navbar />
            <section>
                <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
                    <Grid item xs={12} sm={12} md={5} lg={5} className="fixed-image">
                        {/* <img src='/images/theme4/fig4.png' className='img-fluid' /> */}
                        <img src={sectionImages[activeSection]} className='left-img-zindex img-fluid' alt="Section Illustration" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                        <section className={`fixed-nav ${activeSection ? 'active' : ''}`}>
                            {/* <div className='nav-head'>
                                <div className='nav-box'>
                                    <span className='nav-opt'>About</span>
                                    <span className='nav-opt'>Resume</span>
                                    <span className='nav-opt'>Services</span>
                                    <span className='nav-opt'>Skills</span>
                                    <span className='nav-opt'>Clients</span>
                                </div>
                            </div> */}
                            <div className='nav-head'>
                                <div className='nav-box'>
                                    <a href="#about-section" className={`nav-opt ${activeSection === 'about' ? 'active' : ''}`}>About</a>
                                    <a href="#resume-section" className={`nav-opt ${activeSection === 'resume' ? 'active' : ''}`}>Resume</a>
                                    <a href="#services-section" className={`nav-opt ${activeSection === 'services' ? 'active' : ''}`}>Services</a>
                                    <a href="#skills-section" className={`nav-opt ${activeSection === 'skills' ? 'active' : ''}`}>Skills</a>
                                    <a href="#clients-section" className={`nav-opt ${activeSection === 'clients' ? 'active' : ''}`}>Clients</a>
                                </div>
                            </div>
                        </section>

                        <section id='about-section' className='about-section' ref={aboutRef}>
                            <section>
                                <div className="speech-bubble1">
                                    <span>Who i am</span>
                                </div>

                                <h1 className='portfolio1'>ABOUT</h1>

                                <div className='yel-line1'></div>
                                <div className='yel-line2'></div>

                                <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }} spacing={2}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <div className='about-hum-img'>
                                            <img src='/images/theme4/theme4-abouthum.jpg' className='img-fluid' />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7} lg={7}>
                                        <div className='responsive-screen'>
                                            <p style={{ fontSize: '14px', color: '#4d4d4d' }}><b>GWENN STERN</b></p>

                                            <h3 className='mt-3' style={{ color: 'black' }}>
                                                <b>WEB DESIGNER & WEB <br /> DEVELOPER FROM USA</b>
                                            </h3>

                                            <p className='mt-3 mr-4' style={{ fontSize: '14px' }}>Asunt in anim uis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum. Allamco laboris nisi ut aliquip ex ea commodo consequat. Aser velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum.</p>

                                            <p className='mt-3 mr-4' style={{ fontSize: '14px' }}>Aser velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum.</p>

                                            <button className='about-btn'><b>VIEW WORK</b></button>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className='mt-3'>
                                    <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }} spacing={2}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} className='pt-0'>
                                            <div className="speech-bubble2 text-center">
                                                <span>SOME <br /> FACTS</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <div>
                                                <div className='text-center' style={{ fontSize: '80px' }}><b>461</b></div>
                                                <p className='text-center' style={{ fontSize: '14px' }}><b>Finished projects</b></p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <div>
                                                <div className='text-center' style={{ fontSize: '80px' }}><b>168</b></div>
                                                <p className='text-center' style={{ fontSize: '14px' }}><b>Happy customers</b></p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <div>
                                                <div className='text-center' style={{ fontSize: '80px' }}><b>222</b></div>
                                                <p className='text-center' style={{ fontSize: '14px' }}><b>Working hours</b></p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        </section>

                        <div className='end-line1'>
                            <Divider />
                        </div>

                        <section id='resume-section' className='resume-section' ref={resumeRef}>
                            <section>
                                <div className="speech-bubble1">
                                    <span>MY BIO</span>
                                </div>
                                <h1 className='portfolio1'>Resume</h1>
                                <div className='yel-line1'></div>
                                <div className='yel-line2'></div>
                            </section>

                            <section className='accor'>
                                <div>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ boxShadow: 'none' }}>
                                        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                                            <div className='accor-1'>
                                                <BusinessCenterIcon style={{ color: '#ffc815' }} className='mr-3' />
                                                <b>2012-2015 <span style={{ color: '#666666' }}> - Work in company "Dolore"</span></b>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p style={{ color: '#666666' }}>Web designer</p>
                                            <ul className='d-flex align-items-center'>
                                                <span className='ml-4'><li>Development</li></span>
                                                <span className='ml-4'><li>Seo optimization</li></span>
                                                <span className='ml-4'><li>Web design</li></span>
                                            </ul>
                                            <b style={{ fontSize: '13px' }}>Asunt in anim uis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum. Allamco laboris nisi ut aliquip ex ea commodo consequat. Aser velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,</b>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ boxShadow: 'none' }}>
                                        <AccordionSummary aria-controls="panel2-content" id="panel2-header">
                                            <div className='accor-1'>
                                                <BusinessCenterIcon style={{ color: '#ffc815' }} className='mr-3' />
                                                <b>2010-2012 <span style={{ color: '#666666' }}> - Work in company "Excepteur"</span></b>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p style={{ color: '#666666' }}>Web designer</p>
                                            <ul className='d-flex align-items-center'>
                                                <span className='ml-4'><li>Development</li></span>
                                                <span className='ml-4'><li>Seo optimization</li></span>
                                                <span className='ml-4'><li>Web design</li></span>
                                            </ul>
                                            <b style={{ fontSize: '13px' }}>Nullam vitae tortor sit amet lectus lacinia placerat. Aliquam vel venenatis urna, egetIn metus quam.Mauris in erat justo. Nullam ac urna eu felis dapibus condimentum sit amet a augue.</b>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ boxShadow: 'none' }}>
                                        <AccordionSummary aria-controls="panel3-content" id="panel3-header">
                                            <div className='accor-1'>
                                                <ModeIcon style={{ color: '#ffc815' }} className='mr-3' />
                                                <b>2010-2015 <span style={{ color: '#666666' }}> - Course designer</span></b>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p style={{ color: '#666666' }}>Student</p>
                                            <ul className='d-flex align-items-center'>
                                                <span className='ml-4'><li>Photoshop</li></span>
                                                <span className='ml-4'><li>Illustrator</li></span>
                                                <span className='ml-4'><li>html markup</li></span>
                                            </ul>
                                            <b style={{ fontSize: '13px' }}>Sed non neque elit. Sed ut imperdiet nisi. Proin condimentum fermentum nunc. Etiam pharetra, erat sed fermentum feugiat, velit mauris egestas quam, ut aliquam massa nisl quis neque. Suspendisse in orci enim.</b>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ boxShadow: 'none' }}>
                                        <AccordionSummary aria-controls="panel4-content" id="panel4-header">
                                            <div className='accor-1'>
                                                <ModeIcon style={{ color: '#ffc815' }} className='mr-3' />
                                                <b>2006-2010 <span style={{ color: '#666666' }}> - University studies</span></b>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p style={{ color: '#666666' }}>Student</p>
                                            <ul className='d-flex align-items-center'>
                                                <span className='ml-4'><li>Dancing</li></span>
                                                <span className='ml-4'><li>Drinking</li></span>
                                                <span className='ml-4'><li>and some studies</li></span>
                                            </ul>
                                            <b style={{ fontSize: '13px' }}>Hllentesque nec turpis in orci consequat sagittis et eget massa. Ut commodo consectetur lorem ultricies rutrum. Nulla vitae semper nulla. Ut bibendum tortor ac diam tincidunt, vitae malesuada augue convallis.</b>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </section>
                        </section>

                        <div className='end-line1'>
                            <Divider />
                        </div>

                        <section id='services-section' className='services-section' ref={servicesRef}>
                            <section>
                                <div className="speech-bubble1">
                                    <span>What i do</span>
                                </div>

                                <h1 className='portfolio1'>Services</h1>

                                <div className='yel-line1'></div>
                                <div className='yel-line2'></div>

                                <Swiper className="mySwiper" slidesPerView={3} spaceBetween={30} autoplay="true">
                                    <SwiperSlide>
                                        <div>
                                            <div className='swiper_details'>
                                                <div className='o-con'>
                                                    <div className="card_data" >
                                                        <img src="/images/theme4/theme4-ser-1.jpg" className=" img-fluid" alt="" />
                                                        <div className="card-body">
                                                            <h6 className="text-center">DESIGN</h6>

                                                            <p style={{ fontSize: '13px' }}><b>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</b></p>

                                                            <div className='end-line2'>
                                                                <Divider />
                                                            </div>

                                                            <div className='text-center'>
                                                                <p>Concept</p>
                                                                <p>Design</p>
                                                                <p>3D Modeling</p>
                                                                <p>Research</p>

                                                                <button className='swip-btn'>20$-30$</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='swiper_details'>
                                                <div className='o-con'>
                                                    <div className="card_data" >
                                                        <img src="/images/theme4/theme4-ser-2.jpg" className=" img-fluid" alt="" />
                                                        <div className="card-body">
                                                            <h6 className="text-center">BRANDING</h6>

                                                            <p style={{ fontSize: '13px' }}><b>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</b></p>

                                                            <div className='end-line2'>
                                                                <Divider />
                                                            </div>

                                                            <div className='text-center'>
                                                                <p>Concept</p>
                                                                <p>Design</p>
                                                                <p>Flayers</p>
                                                                <p>Research</p>

                                                                <button className='swip-btn'>20$-300$</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='swiper_details'>
                                                <div className='o-con'>
                                                    <div className="card_data" >
                                                        <img src="/images/theme4/theme4-ser-3.jpg" className=" img-fluid" alt="" />
                                                        <div className="card-body">
                                                            <h6 className="text-center">DEVELOPMENT</h6>

                                                            <p style={{ fontSize: '13px' }}><b>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</b></p>

                                                            <div className='end-line2'>
                                                                <Divider />
                                                            </div>

                                                            <div className='text-center'>
                                                                <p>Develop</p>
                                                                <p>Design</p>
                                                                <p>3D Modeling</p>
                                                                <p>User Interface</p>

                                                                <button className='swip-btn'>350$-1600$</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='swiper_details'>
                                                <div className='o-con'>
                                                    <div className="card_data" >
                                                        <img src="/images/theme4/port8.jpg" className=" img-fluid" alt="" />
                                                        <div className="card-body">
                                                            <h6 className="text-center">WEB DESIGN</h6>

                                                            <p style={{ fontSize: '13px' }}><b>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</b></p>

                                                            <div className='end-line2'>
                                                                <Divider />
                                                            </div>

                                                            <div className='text-center'>
                                                                <p>Concept</p>
                                                                <p>Design</p>
                                                                <p>Flayers</p>
                                                                <p>Research</p>
                                                                <button className='swip-btn'>350$-1600$</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                            </section>
                        </section>

                        <div className='end-line1'>
                            <Divider />
                        </div>

                        <section id='skills-section' className='skills-section' ref={skillsRef}>
                            <section>
                                <div className="speech-bubble1">
                                    <span>ATTAINMENTS</span>
                                </div>

                                <h1 className='portfolio1'>Skills</h1>

                                <div className='yel-line1'></div>
                                <div className='yel-line2'></div>

                                <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center", }}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='circular-progress'>
                                            <CircularProgressbar
                                                value={designPercentage}
                                                text={`${designPercentage}%`}
                                                styles={buildStyles({
                                                    pathColor: '#8f8f8f',
                                                    textColor: '#8f8f8f',
                                                    trailColor: '#ddd',
                                                    textSize: '10px',
                                                    fontWeight: 'bold',
                                                })}
                                            />
                                            <h6 style={{ marginTop: '25px', textAlign: 'center', color: '#f8c329' }}>DESIGN</h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <div style={{ width: '80%', marginLeft: '40px' }}>
                                            {skills.map(({ skill, percentage }, idx) => (
                                                <div key={idx} style={{ margin: '10px 0' }}>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='m-0'>{skill}</p>
                                                        <p className='m-0'>{percentage}%</p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '10px',
                                                            backgroundColor: '#ddd',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: `${percentage}%`,
                                                                height: '100%',
                                                                backgroundColor: '#f8c329',
                                                                borderRadius: '5px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center", }}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='circular-progress'>
                                            <CircularProgressbar
                                                value={designPercentage1}
                                                text={`${designPercentage1}%`}
                                                styles={buildStyles({
                                                    pathColor: '#8f8f8f',
                                                    textColor: '#8f8f8f',
                                                    trailColor: '#ddd',
                                                    textSize: '10px',
                                                    fontWeight: 'bold',
                                                })}
                                            />
                                            <h6 style={{ marginTop: '25px', textAlign: 'center', color: '#f8c329' }}>DEVELOPMENT</h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <div style={{ width: '80%', marginLeft: '40px' }}>
                                            {skills1.map(({ skill, percentage }, idx) => (
                                                <div key={idx} style={{ margin: '10px 0' }}>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='m-0'>{skill}</p>
                                                        <p className='m-0'>{percentage}%</p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '10px',
                                                            backgroundColor: '#ddd',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: `${percentage}%`,
                                                                height: '100%',
                                                                backgroundColor: '#f8c329',
                                                                borderRadius: '5px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center", }}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='circular-progress'>
                                            <CircularProgressbar
                                                value={designPercentage2}
                                                text={`${designPercentage2}%`}
                                                styles={buildStyles({
                                                    pathColor: '#8f8f8f',
                                                    textColor: '#8f8f8f',
                                                    trailColor: '#ddd',
                                                    textSize: '10px',
                                                    fontWeight: 'bold',
                                                })}
                                            />
                                            <h6 style={{ marginTop: '25px', textAlign: 'center', color: '#f8c329' }}>Languages</h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <div style={{ width: '80%', marginLeft: '40px' }}>
                                            {skills2.map(({ skill, percentage }, idx) => (
                                                <div key={idx} style={{ margin: '10px 0' }}>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='m-0'>{skill}</p>
                                                        <p className='m-0'>{percentage}%</p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '10px',
                                                            backgroundColor: '#ddd',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: `${percentage}%`,
                                                                height: '100%',
                                                                backgroundColor: '#f8c329',
                                                                borderRadius: '5px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </section>
                        </section>

                        <div className='end-line1'>
                            <Divider />
                        </div>

                        <section id='clients-section' className='clients-section' ref={clientsRef}>
                            <section>
                                <div className="speech-bubble1">
                                    <span>TESTIMONIALS</span>
                                </div>

                                <h1 className='portfolio1'>Clients</h1>

                                <div className='yel-line1'></div>
                                <div className='yel-line2'></div>
                            </section>

                            <Swiper className="mySwiper" slidesPerView={1} spaceBetween={30} autoplay="true">
                                <SwiperSlide>
                                    <div className='swipe-1'>
                                        <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }} >
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <img src='/images/theme4/theme4-client1.jpg' className='' />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                                <h6><b>Mike Jones</b></h6>
                                                <p><b> Asunt in anim uis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum. Allamco laboris nisi ut aliquip ex ea commodo consequat. Aser velit esse cillum dolore eu fugiat nulla pariatur. </b></p>
                                                <p className='border-corner mb-1' style={{ fontSize: '12px', color: '#ffc815' }}>Via Twitter</p>
                                                <div className='black-line'></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='swipe-1'>
                                        <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }} >
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <img src='/images/theme4/theme4-client2.jpg' className='' />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                                <h6><b>Mery Lynn</b></h6>
                                                <p><b>  Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in anim id est laborum. Allamco laboris nisi ut aliquip ex ea commodo consequat. Aser velit esse cillum dolore eu fugiat nulla pariatur.  </b></p>
                                                <p className='border-corner mb-1' style={{ fontSize: '12px', color: '#ffc815' }}>Via Facebook</p>
                                                <div className='black-line'></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </section>

                        <div className='mt-5'>
                            <Theme4footer />
                        </div>

                    </Grid>
                </Grid>
            </section>
        </>
    )
}

export default Theme4homepage
