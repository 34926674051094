import React from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import { CardActions, CardContent, Container, Grid } from '@mui/material'
import Theme3logobanner from '../logos-banner/Theme3logobanner'

const Theme3news = () => {
  const crdDatas = [
    {
      id: 1,
      title: "Teams Up With Vilangu Director Prasanth Pandiyaraj For His Next",
      date: "August 23, 2024"  ,
      comments: "No Comments",
      desc: "Soori, known for his comedic roles, has transitioned to a successful lead actor with films like Viduthalai, Garudan, and the upcoming Kottukkaali.He is also producing Viduthalai Part-2 and has signed a new project directed by Prasanth Pandiyaraj, known for the acclaimed web series Vilangu.Soori expressed gratitude for the support he has received on social media."
    },
    {
      id: 2,
      title: "Actor Soori To Adopt His Village Saligramam? Here’s The Truth",
      date: "July 24, 2024", 
      comments: "No Comments",
      desc: "Actor Soori's career has dramatically evolved from comedian to leading man, particularly in Vetrimaaran’s Viduthalai Part 1. Parthiban praised Soori's journey, noting his rise from Saligramam to commanding an ₹8 crore fee.After moving to Chennai in 1996, Soori initially struggled and worked as a cleaner while taking small roles in films. He gained recognition as Parotta Soori in Vennila Kabadi Kuzhu (2009) due to a memorable scene. His subsequent hits include Sundarapandian, Rajini Murugan, and Seema Raja, although some of his performances faced criticism.Soori's recent lead role in Viduthalai Part 1 has been well-received by critics and audiences alike."
    },
    {
      id: 3,
      title: "Garudan Box Office Collection: Soori-starrer Mints Rs 14.6 Crore In 1st Weekend",
      date: "July 29, 2023",
      comments: "No Comments",
      desc: "Garudan, a rural Tamil action drama starring Soori, Sasikumar, and Unni Mukundan, was released on May 31 and has received positive reviews. Directed by R.S. Senthilkumar, the film explores themes of friendship and betrayal, emerging as the second biggest hit in Kollywood this year.On its opening day, Garudan earned approximately ₹3 crore, with collections rising to ₹4.85 crore on the second day and ₹6.25 crore on the third, totaling ₹14.6 crore in its first week in India and ₹16.8 crore worldwide.Produced by Vetrimaaran’s Grass Root Film Company and K. Kumar’s Lark Studios, the film features music by Yuvan Shankar Raja. Senthilkumar highlighted that the screenplay is non-hero centric, with various characters driving the story forward.Unni Mukundan promoted the film on Instagram, encouraging audiences to experience the blockbuster in theaters. Soori is also known for his roles in Viduthalai Part 1, Kottukkaali, and other notable films."
    },
    {
      id: 4,
      title: "Soori Joins Cast of Director Ram’s Untitled Project Starring Nivin Pauly",
      date: "June 04, 2024",
      comments: "No Comments",
      desc: "Famous Malayalam actor Nivin Pauly has teamed up with director Ram for an upcoming bilingual film. The film is being made in Tamil and Malayalam. The second schedule of shooting began in January. Now, the latest reports say that actor Soori has also joined the film’s star cast. Anjali will be the leading lady opposite Nivin.Critically acclaimed writer and director Mysskin, famous for projects like Pisasu and Psycho, recently visited the sets of the film. His pictures with the team were shared on Instagram by Nivin.The team behind director Ram’s untitled project was happy to see Mysskin on their sets. Nivin posted this photo with the caption, Look who dropped into Ram Sir’s set! Pleasure meeting you #Mysskin sir.”Nivin also posted some snaps from the sets of the film. He wrote that the second schedule of Ram sir’s untitled project has commenced. Nivin also wrote that it had been a great learning experience working with Ram."
    },
    {
      id:5,
      title:"Kamal Haasan appreciates Soori's Kottukkaali",
      date:"August 21, 2024",
      desc:"Director PS Vinothraj's upcoming film 'Kottukkaali' starring Soori and Anna Ben is all set to hit the screens on August 23, 2024. The film produced by Sivakarthikeyan Productions and has won several accolades an international film festivals, and now, Ulaganayagan Kamal Haasan has appreciated the team after watching the film."
    },
    {
      id:6,
      title:"Kamal Haasan appreciates Soori's Kottukkaali",
      date:"August 21, 2024",
      desc:"Director PS Vinothraj's upcoming film 'Kottukkaali' starring Soori and Anna Ben is all set to hit the screens on August 23, 2024. The film produced by Sivakarthikeyan Productions and has won several accolades an international film festivals, and now, Ulaganayagan Kamal Haasan has appreciated the team after watching the film."
    }
  ]
  return (
    <>
      <div className='bg-colors'>
        <Theme3navbar />
        <div className='abt-page-t3 text-center'>
          <h1>News</h1>
        </div>
      </div>
      <div className='our_news-crd mt-5'>
        <Container>
        <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
          {crdDatas && crdDatas.map((item, i) => (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='card-our-news'>
                <CardContent>
                  <h4 className='news-cr-con mt-2'>{item.title}</h4>
                  <span className='mt-3'>{item.date}</span>
                  <p className='mt-3'>{item.desc}</p>
                </CardContent>
                {/* <CardActions >
                  <button className="sch-act-btn-rm-theme3 ">Read More</button>
                </CardActions> */}
              </div>
            </Grid>
          ))

          }
        </Grid>
        </Container>
      </div>
      {/* <div className='mt-5'>
        <Theme3logobanner/>
      </div> */}
    </>
  )
}

export default Theme3news