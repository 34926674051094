import React, { useState, useContext, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Search, Close } from '@mui/icons-material';
import { Button, Container, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { FiPhoneCall, FiMail } from 'react-icons/fi';

const Theme1navbar = () => {
  const { navbarDetailsApi } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useDidMountEffect(() => {
    // var navbar = sessionStorage.getItem('_pre_info')
    // if (navbar === null) {
    //   navbarDetailsApi();
    // }

  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let urlName = sessionStorage.getItem('urlName')
  const [activeLink, setActiveLink] = useState('/home');

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchOpen(true);
  };

  const handleClose = () => {
    setSearchOpen(false);
    setAnchorEl(null);
  };

  const handleIconClick = (event) => {
    if (searchOpen) {
      handleClose();
    } else {
      handleClickOpen(event);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {/* <div className="topbar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-12 d-lg-block d-none">
              <a href="/" title="TishPolitical" className="navbar-brand">TishPolitical</a>
              <strong className="navbar-brand-description">Political WordPress Theme</strong>
            </div>
            <div className="col-lg-9 col-12">
              <div className="contact-info-wrap">
                <div className="contact-info">
                  <div className="phoneicon">
                    <FiPhoneCall size={24} style={{ color: '#ED6B37' }} />
                  </div>
                  <div className="info-text">
                    <span>Call Us Now:</span>
                    <p><a href="tel:(804) 359-1337">(804) 359-1337</a></p>
                  </div>
                </div>

                <div className="contact-info">
                  <div className="phoneicon">
                    <FiMail size={24} style={{ color: '#ED6B37' }} />
                  </div>
                  <div className="info-text">
                    <span>E-mail Now:</span>
                    <p><a href="mailto:info@example.com">info@example.com</a></p>
                  </div>
                </div>
                <div className="contact-info"><button className="theme-btned">Donate Now</button></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Navbar bg="light" expand="lg" sticky={isFixed ? 'top' : undefined} className={isFixed ? 'fixed-navbar' : ''} >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" style={{ alignContent: 'center', justifyContent: "space-between", padding: '8px' }}>
            <Nav className="me-auto" style={{ fontSize: '18px', color: '#16182F' }}>
              <Nav.Link as={NavLink} to={`/${urlName}/home`} className="nav-item nav-mail" activeClassName="active">HOME</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/blog`} className="nav-item nav-mail" activeClassName="active">BLOG</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/gallery`} className="nav-item nav-mail" activeClassName="active">GALLERY</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/aboutus`} className="nav-item nav-mail" activeClassName="active">BIOGRAPHY</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/contactus`} className="nav-item nav-mail" activeClassName="active">CONTACT</Nav.Link>
            </Nav>
            <div className="d-flex align-items-center">
              {/* <IconButton onClick={handleIconClick}>
                {searchOpen ? <Close style={{ color: 'black' }} /> : <Search style={{ color: 'black' }} />}
              </IconButton> */}
              <div style={{ borderLeft: '1px solid #ccc', height: '30px', margin: '0 10px' }}></div>
              <IconButton component="a"
                href="https://www.facebook.com/UdhayStalin/"
                target="_blank"
                rel="noopener noreferrer">
                <FacebookIcon style={{ color: 'gray' }} />
              </IconButton>
              <IconButton component="a"
                href="https://x.com/Udhaystalin"
                target="_blank"
                rel="noopener noreferrer">
                <TwitterIcon style={{ color: 'gray' }} />
              </IconButton>
              <IconButton component="a"
                href="https://www.instagram.com/udhay_stalin/?hl=en"
                target="_blank"
                rel="noopener noreferrer">
                <InstagramIcon style={{ color: 'gray' }} />
              </IconButton>
              <IconButton component="a"
                href="https://www.youtube.com/@dmk_pages"
                target="_blank"
                rel="noopener noreferrer">
                <YouTubeIcon style={{ color: 'gray' }} />
              </IconButton>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Popover
        id={id}
        open={searchOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            padding: '10px',
            width: '300px',
            position: 'absolute',
            top: '90px',
            left: '863px',
            transition: 'opacity 232ms cubic-bezier(0.4, 0, 0.2, 1), transform 155ms cubic-bezier(0.4, 0, 0.2, 1)',
          },
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="Search"
          type="text"
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClose}>
                <Search />
              </IconButton>
            ),
          }}
        />
      </Popover>
    </>
  );
};

export default Theme1navbar;
