import React from 'react'
import Theme6navbar from '../../layouts/Theme6navbar'
import { Container } from 'react-bootstrap'
import { Grid } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
const Theme6service = () => {
    const services = [
        {
          icon: "fas fa-bullseye",
          title: "Awesome Features",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.",
          },
        {
          icon: "fas fa-tablet-alt",
          title: "100% Responsive",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.",
          },
        {
          icon: "fas fa-camera",
          title: "Media Support",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.",
         },
        {
          icon: "fas fa-cogs",
          title: "Extremely Flexible",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.",
          }
      ];
    return (
        <div>
            <div className='bg-t6-contact'>
                <Theme6navbar />

                <div className='Contact-content'>
                    <h1>Services</h1>
                    <p>{`Home >> services`}</p>
                </div>
            </div>
            <Container>
                <div className="Services">
                    <h2>WELCOME TO FOREAL THEME</h2>
                    <p>WELCOME TO FOREAL THEME
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
                        Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                </div>
            </Container>
            <div className="Services-box">


                {/* <Container className='mt-5'>
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="service-boxs">
                                <div className='service-icon'>

                                    <i class="fas fa-bullseye"></i>
                                </div>
                                <h4>Awesome Features</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.</p>
                                <span>More</span>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="service-boxs">
                                <div className='service-icon'>
                                    <i class="fas fa-tablet-alt"></i>
                                </div>
                                <h4>100% Responsive</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.</p>
                                <span>More</span>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="service-boxs">
                                <div className='service-icon'>
                                    <i class="fas fa-camera"></i>
                                </div>
                                <h4>Media Support</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.</p>
                                <span>More</span>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="service-boxs">
                                <div className='service-icon'>
                                    <i class="fas fa-cogs"></i>
                                </div>
                                <h4>Extremely Flexible</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id augue vitae odio accumsan condimentum id in urna.</p>
                                <span>More</span>
                            </div>

                        </Grid>
                    </Grid>

                </Container> */}
                 <Container className='mt-5'>
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2' key={index}>
            <div className="service-boxs">
              <div className='service-icon'>
                <i className={service.icon}></i>
              </div>
              <h4>{service.title}</h4>
              <p>{service.description}</p>
              <span className='Service_more'>More</span>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
            </div>
            {/* THEME */}
            <div className="theme">

                <Container className='mt-5'>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                            <div className="theme-box">
                                <h3>THE MULTIPURPOSE THEME</h3>
                                <p className='mt-3'>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum
                                    felis eu pede mollis pretium.</p>
                            </div>
                            <div className='theme-side'>
                                <div className='theme-side-icon'>
                                    <i class="fas fa-inbox"></i>
                                </div>
                                <div className='ml-4'>
                                    <h5>Full of Options and Features</h5>
                                    <p>Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales.Sed consequat, leo eget</p>
                                </div>
                            </div>
                            <div className='theme-side mt-4'>
                                <div className='theme-side-icon'>
                                    <i class="fas fa-tablet-alt"></i>
                                </div>
                                <div className='ml-4'>
                                    <h5>Highly Responsive</h5>
                                    <p>Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales.Sed consequat, leo eget</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                            <img src="/images/Theme6/service-mockup.png" alt="" className='img-fluid' />
                        </Grid>
                    </Grid>

                </Container>
            </div>
            {/* service-design */}
            <div className="service-Design">
                <FacebookIcon />
                <TwitterIcon />
                <InstagramIcon />
                <div className='service-Design-content'>
                    <h2>FULLY FEATURED DESIGN</h2>
                </div>
                <div className='feacture-btn'>
                    <button className='fea-btn1'>View All Demos</button>
                    <button className='fea-btn2'>View All Demos</button>
                </div>
            </div>
            <Container className='mt-5'>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='px-2'>
                        <div className='business'>
                            <h2>Everything you need
                                to kickstart your business</h2>
                            <p>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                                Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                                consequat vitae, eleifend ac, enim.</p>
                            <img src="/images/Theme6/about-image.png" alt="" className='img-fluid'/>
                        </div>
                    </Grid>
                </Grid>

            </Container>
            {/* work with us */}
            <div className="work-with-us">
                <Container >
                    <div className='work-flex'>
                    <div className='work-with'>
                        <div className='work-icon'>
                            <i class="far fa-comments"></i>
                        </div>
                        <div className='work-content ml-4 mt-2'>
                            <h4>WANT TO WORK WITH US</h4>
                            <p>Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.</p>
                        </div>
                    </div>
                    <div className='work-btn'>
                        <button>Purchase Now</button>
                    </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Theme6service
