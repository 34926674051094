import React, { useEffect } from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import { Grid, Container } from '@mui/material'
import Theme3beliveyou from './Theme3beliveyou'
import Theme3ourprofile from './Theme3ourprofile'
import Theme3contactme from './Theme3contactme'
import Theme3ourschedule from './Theme3ourschedule'
import Theme3ournews from './Theme3ournews'
import Theme3onpress from './Theme3onpress'
import Theme3Swiper from './Theme3Swiper'
import { FaPlayCircle } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Theme3home = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <div className='bg-colors'>
                <Theme3navbar />
                <Container>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='main-title'>
                                <h2 >Soori <br /> Muthuchamy </h2>
                                <p>Actor | Comedian | Entrepreneur</p>
                                {/* <div className='play-btn-t3 mt-5'>
                      <span className='ply-t3-text'><FaPlayCircle className='plsy-btn-t3'/> Latest Video</span>
                    </div> */}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='man-images'>
                                <img src={'/images/theme3/soori/remove_bg.png'} alt="portfolio" className='banner-imags' />
                                <img src="/images/theme3/soori/Vignesh Sep-Nov (1).png" alt="tri" className='tri-1' />
                                <img src="/images/theme3/Tri-home-2.png" alt="tri" className='tri-2' />
                                <img src="/images/theme3/soori/Vignesh Sep-Nov.png" alt="tri" className='tri-3' />
                                <img src="/images/theme3/tri-1.png" alt="tri" className='tri-4' />
                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </div>
            <Theme3beliveyou />
            {/* <Theme3Swiper /> */}
            <Theme3ourprofile />
            {/* <Theme3contactme /> */}
            <Theme3ourschedule />
            <Theme3onpress />
            <Theme3ournews />
        </>
    )
}

export default Theme3home