import React, { useEffect } from 'react'
import { useState } from 'react';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Theme2navbar = () => {
  document.getElementById('theme').setAttribute('href', '/css/theme2.css');
  const [isFixed, setIsFixed] = useState(false);

  // useDidMountEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > window.innerHeight) {
  //       setIsFixed(true);
  //     } else {
  //       setIsFixed(false);
  //     }
  //   };
  // useDidMountEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {  
  //       setIsFixed(true);
  //     } else {
  //        setIsFixed(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

 
  return (
    <>
      <section className='container'>
        <nav className={`navbar navbar-expand-lg navbar-light ${isFixed ? 'navbar-fixed' : 'navbar-flexible'}`} >
          {/* <nav className="navbar navbar-expand-lg navbar-light px-0" style={{ marginBottom: '50px' }}> */}
          <div>
            <a className="navbar-brand" href="/"><img src='' className='theme2-logo-nav img-fluid' alt=''/></a>
            {/* <span className='nav-mail ml-3'><b>Sivarajah Ramanathan</b></span> */}
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Works</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Resume</a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Skills</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Testimonials</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{ color: 'white' }}>Contact</a>
              </li>
            </ul>
            <button className="form-btn ml-5 mb-3" type="submit">Hire me!</button>
          </div>
        </nav>
      </section>

    </>
  )
}

export default Theme2navbar