import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay ,Pagination} from 'swiper/modules';
import { Link } from 'react-router-dom';

const Theme5discoverproduct = () => {
    const [screenSize, setScreenSize] = useState(5);
    useEffect(() => {
        size();
    }, []);

    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(4);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(4);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(2);
        } else {
            setScreenSize(1);
        }
    };
    const crdImage = [
        {
            name: "Charger Dock",
            img: "/images/theme5/products/caro-prod1.png"
        },
        {
            name: "Mobile Phone",
            img: "/images/theme5/products/caro-prod2.png"
        },
        {
            name: "Wireless Charger",
            img: "/images/theme5/products/caro-prod3.png"
        },
        {
            name: "TWS Headphones",
            img: "/images/theme5/products/caro-prod4.png"
        },
        {
            name: "Pencil pro",
            img: "/images/theme5/products/caro-prod5.png"
        },
       

    ]
    return (
        <div>
            <div className='disc-t3-prod mt-5'>
                <div className='text-center'>
                    <span className='chip-abt'>Discover Products</span>
                    <h1 className='mt-3'>What’s Inside The Box?</h1>
                </div>
                <div className='mt-5'>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={screenSize}
                        loop
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        modules={[Autoplay,Pagination]}
                        style={{height:'460px'}}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                              return `<span class="${className}" style="background-color:blue;"></span>`;
                            },
                          }}
                    >
                        {crdImage && crdImage.map((a, i) => (
                            <SwiperSlide key={i}>
                                <div className="swiper-items-prod-t3"  data-aos="fade-right"  data-aos-duration="3000">
                                    <img src={a.img} alt="Products" className='t5-prods-caros mb-2'/>
                                   <div className='text-center mt-4'>
                                    <h5 className='h5-insi-t3'>{a.name}</h5>
                                    <Link className='t5-featue-links'>View Specs</Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Theme5discoverproduct