import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme6layout from './layouts/Theme6layout'
import Theme6home from './components/home-page/Theme6home'
import Theme6contactus from './components/home-page/Contact-page/Theme6contactus'
import Theme6aboutus from './components/home-page/Aboutus-Page/Theme6aboutus'
import Theme6service from './components/Service-page/Theme6service'
import Theme6blog from './components/blog-pages/Theme6blog'
import Theme6portfolio from './components/portfolio-page/Theme6portfolio'
import Theme6portfoliodetails from './components/portfolio-page/Theme6portfoliodetails'

const Theme6route = () => {
    document.getElementById('theme').setAttribute('href', '/css/theme6.css');
  return (
    <div>
         <Routes>
                <Route path='/:name' element={<Theme6layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme6home />} />
                    <Route path='/:name/aboutus' element={<Theme6aboutus />} />
                    <Route path='/:name/service' element={<Theme6service />} />
                    <Route path='/:name/contactus' element={<Theme6contactus />} />
                    <Route path='/:name/blog' element={<Theme6blog />} />
                    <Route path='/:name/portfolio' element={<Theme6portfolio />} />
                    <Route path='/:name/portfolio/details' element={<Theme6portfoliodetails />} />
                   
                    </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
    </div>
  )
}

export default Theme6route