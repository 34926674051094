import React, { useContext, useState } from 'react'
import Theme6navbar from '../../layouts/Theme6navbar'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Container, Grid, Tab, Tabs } from '@mui/material';
import DataContext from '../../../../context/DataContext';

const Theme6portfolio = () => {
  const { navigator } = useContext(DataContext);
  let urlName = sessionStorage.getItem('urlName')
  const [value, setValue] = useState("1");
  const [tabName, setTabName] = useState("All");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabNames = {
      1:"All",
      2:"Blue",
      3:"Creativity",
      4:"Indoor",
      5:"Lighting",
      6:"News",
      7:"Photography",
      8:"Photoshop"
    };
    if (tabNames[newValue]) {
      setTabName(tabNames[newValue]);
    }
  };
  const handlePortfolioGet = () =>{
    navigator(`/${urlName}/portfolio/details`);
  }
  
  return (
    <div>
         <div className='bg-t6-contact'>
                <Theme6navbar />

                <div className='Contact-content'>
                    <h1>Portfolio</h1>
                    <p>{`Home >> Portfolio`}</p>
                </div>
            </div>
            <section>
        <div className=' mt-5 text-center'>
          <TabContext value={value} className="tab-div">
            <div className="name-tab">
              <Box className="tabs-boxs" >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{
                    borderRadius: '50px',
                    borderBottom: 'none',
                    padding: '10px',
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                  }}
                  className="custom-tabs"
                >
                  <Tab
                    label="ALL"
                    value="1"
                    style={{
                      background: value === "1" ? '#555' : '',
                      color: value === "1" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Blue"
                    value="2"
                    style={{
                      background: value === "2" ? '#555' : '',
                      color: value === "2" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Creativity"
                    value="3"
                    style={{
                      background: value === "3" ? '#555' : '',
                      color: value === "3" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Indoor"
                    value="4"
                    style={{
                      background: value === "4" ? '#555' : '',
                      color: value === "4" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="News"
                    value="5"
                    style={{
                      background: value === "5" ? '#555' : '',
                      color: value === "5" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Photography"
                    value="6"
                    style={{
                      background: value === "6" ? '#555' : '',
                      color: value === "6" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Photoshop"
                    value="7"
                    style={{
                      background: value === "7" ? '#555' : '',
                      color: value === "7" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                </Tabs>
              </Box>
            </div>
            <TabPanel value="1">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                    <div className='portfolio-hover'>
                      <h4 onClick={handlePortfolioGet}>Man And Women</h4>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                    <div className='portfolio-hover'>
                      <h4>Man And Women</h4>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-3.jpg' className='work-img img-fluid' alt='' />
                    <div className='portfolio-hover'>
                      <h4>Man And Women</h4>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-4.jpg' className='work-img img-fluid' alt='' />
                    <div className='portfolio-hover'>
                      <h4>Man And Women</h4>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-5.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-3.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                    
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="5">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-3.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="6">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="7">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-3.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </div>
      </section>
    </div>
  )
}

export default Theme6portfolio
