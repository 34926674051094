import { Autocomplete, Button, FormHelperText, Grid, Table, TableBody, TableCell, Paper, TableContainer, TableHead, TableRow, TextField, Divider, Tab, Tabs, Box, Container } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { TabContext, TabPanel } from '@mui/lab';
import Aos from 'aos';
import 'aos/dist/aos.css'
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Theme2navbar from '../layouts/Theme2navbar';
import { useEffect } from 'react';


const services = [
  { id: 1, title: 'Branding Design', description: 'Brolly show off show off pick your nose and blow off well A bit of how’s your father tomfoolery blimey, me old...' },
  { id: 2, title: 'UI/UX Design', description: 'Brolly show off show off pick your nose and blow off well A bit of how’s your father tomfoolery blimey, me old...' },
  { id: 3, title: 'Web Design', description: 'Brolly show off show off pick your nose and blow off well A bit of how’s your father tomfoolery blimey, me old...' },
  { id: 4, title: 'App Design', description: 'Brolly show off show off pick your nose and blow off well A bit of how’s your father tomfoolery blimey, me old...' }
];


const Theme2home = () => {
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, reset, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

  const [screenSize, setScreenSize] = useState(3)
  useEffect(() => {
    size()
  }, [])

  const size = () => {
    const width = window.innerWidth;
    if (width > 1440) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(2)
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2)
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(1)
    } else if (window.innerWidth <= 575 && window.innerWidth > 320) {
      setScreenSize(1)
    }
  }

  const handleOption = (value) => {
    setValue("department", value);
    clearErrors("department");
  }

  useDidMountEffect(() => {
    Aos.init();
  }, [])

  const onSubmit = (data) => { }
  const onError = (event) => { }

  const skills = [
    "Branding Design",
    "Web Design",
    "UI/UX Design",
    "App Design"
  ]

  const [value, setValue] = useState("1");
  const [tabName, setTabName] = useState("All");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabNames = {
      1: "All",
      2: "Apps",
      3: "Branding",
      4: "UX?UI"
    };
    if (tabNames[newValue]) {
      setTabName(tabNames[newValue]);
    }
  };
  return (
    <>
      <section>
        <div className='theme2-banner'>

          <Theme2navbar />

          <div className='banner-hi'>
            <span className='letter'>S</span>
            <span className='letter'>I</span>
            <span className='letter'>V</span>
            <span className='letter'>A</span>
            <span className='letter'>R</span>
            <span className='letter'>A</span>
            <span className='letter'>J</span>
            <span className='letter'>A</span>
            <span className='letter'>H</span>
          </div>

          <div className='container'>
            <Grid container direction="row" alignItems="flex-start" spacing={5}>
              <Grid item sm={12} md={6} lg={6}>
                <h1 className='banner-font-name white-clr'><b>Sivarajah Ramanathan
                </b></h1>
                <h1 className='banner-font work-tog-head m-0'><b>Maaberum Putholil Kanavu!
                </b></h1>
                <p className='banner-font-con white-clr mt-4'>A Social Entrepreneur with over 25 years of experience, currently serves as the CEO of the Tamil Nadu Startup and Innovation Mission (TANSIM), also known as StartupTN. Steering the mission of fostering a dynamic startup ecosystem in Tamil Nadu and to support entrepreneurs and drive economic growth through innovation.</p>
                {/* <div className='banner-btn'>
                  <span>
                    <button className='banner-button'>
                      Download CV <FileDownloadOutlinedIcon />
                    </button>
                  </span>
                  <span className='icon-round'>
                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" className='icon-og' />
                  </span>
                </div> */}
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <div>
                  {/* <img src='/images/theme2/sivaraja-1.jpg' alt="" className='banner-man img-fluid' /> */}
                  <img src='/images/theme2/sivaraj-ds-1.jpeg' alt="" className='banner-man img-fluid' />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* <div className='container-fluid mt-5 mx-5'>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={5}>
              <Grid item sm={12} md={3} lg={3}>
                <div className='d-flex'>
                  <span className='banner-count-font'><b>14</b></span>
                  <span className='white-clr m-3'>Years of <br /> Experience</span>
                </div>
              </Grid>
              <Grid item sm={12} md={3} lg={3}>
                <div className='d-flex'>
                  <span className='banner-count-font'><b>50+</b></span>
                  <span className='white-clr m-3'>Project <br /> Completed</span>
                </div>
              </Grid>
              <Grid item sm={12} md={3} lg={3}>
                <div className='d-flex'>
                  <span className='banner-count-font'><b>1.5K</b></span>
                  <span className='white-clr m-3'>Happy <br />Clients</span>
                </div>
              </Grid>
              <Grid item sm={12} md={3} lg={3}>
                <div className='d-flex'>
                  <span className='banner-count-font'><b>14</b></span>
                  <span className='white-clr m-3'>Years of <br /> Experience</span>
                </div>
              </Grid>
            </Grid>
          </div> */}
        </div>
      </section>
      <section>
        <div className='recent-wrk'>
          <h1 className='work-tog-head text-center mb-3'>Gallery</h1>
          {/* <TabContext value={value} className="tab-div">
            <div className="name-tab">
              <Box className="tabs-boxs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{
                    borderRadius: '50px',
                    backgroundColor: 'black',
                    borderBottom: 'none',
                    padding: '10px',
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                  }}
                  className="custom-tabs"
                >
                  <Tab
                    label="All"
                    value="1"
                    style={{
                      background: value === "1"
                        ? 'linear-gradient(90deg, rgba(130, 77, 238, 1) 0%, rgba(83, 46, 156, 1) 60%, rgba(53, 27, 102, 1) 100%)'
                        : '',
                      color: 'white',
                      borderRadius: '50px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Apps"
                    value="2"
                    style={{
                      background: value === "2"
                        ? 'linear-gradient(90deg, rgba(130, 77, 238, 1) 0%, rgba(83, 46, 156, 1) 60%, rgba(53, 27, 102, 1) 100%)'
                        : '',
                      color: 'white',
                      borderRadius: '50px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="Branding"
                    value="3"
                    style={{
                      background: value === "3"
                        ? 'linear-gradient(90deg, rgba(130, 77, 238, 1) 0%, rgba(83, 46, 156, 1) 60%, rgba(53, 27, 102, 1) 100%)'
                        : '',
                      color: 'white',
                      borderRadius: '50px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="UX/UI"
                    value="4"
                    style={{
                      background: value === "4"
                        ? 'linear-gradient(90deg, rgba(130, 77, 238, 1) 0%, rgba(83, 46, 156, 1) 60%, rgba(53, 27, 102, 1) 100%)'
                        : '',
                      color: 'white',
                      borderRadius: '50px',
                    }}
                    className="setFont"
                  />
                </Tabs>
              </Box>
            </div>
            <TabPanel value="1">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={5}>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio1.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>

                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio3.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio2.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>

                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio4.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={5}>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio3.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>

                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={5}>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work' >
                    <img src='/images/theme2/theme2-portfolio1.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio2.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={5}>
                <Grid item sm={12} md={6} lg={6}>
                  <div className='work'>
                    <img src='/images/theme2/theme2-portfolio2.png' className='work-img img-fluid' alt="Portfolio" />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>

                </Grid>
              </Grid>
            </TabPanel>
          </ TabContext> */}
          <Container>
            <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={5}>
              <Grid item sm={12} md={6} lg={6}>
                <div className='work'>
                  <img src={"/images/theme2/inaugrated.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
                <div className='work mt-5'>
                  <img src={"/images/theme2/tv-reality.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
                <div className='work'>
                  <img src={"/images/theme2/singapore-img1.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
                {/* <div className='bold-white'>
                  <h2 className='text-glow'>
                  <span> Sivarajah Ramanathan</span>
                  </h2>
                </div> */}
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <div className='work'>
                  <img src={"/images/theme2/kilsa-images.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
                <div className='work'>
                  <img src={"/images/theme2/velumani-img.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
                <div className='work'>
                  <img src={"/images/theme2/singapore-img2.jpeg"} className='work-img img-fluid' alt="Portfolio" />
                </div>
              </Grid>
              
            </Grid>
          </Container>
        </div>
      </section>
      <section>
        <div className='theme2-blogs'>
          <h1 className='work-tog-head text-center'>Accomplishments</h1>
          <p className='text-center m-0' style={{ color: 'white' }}>After taking over as CEO of StartupTN, the accomplishments made in the last 2+ years.</p>
          {/* <p className='text-center' style={{ color: 'white' }}>inspires you and you customers.</p> */}

          <div className='container mt-5'>
            <div className='main-skills-box'>
              <div>
                <div className='skill-box'>
                  <img src='/images/theme2/images/181.png' className='skill-img img-fluid py-3' alt="figma" />
                </div>
                <div>
                  <h6 className='purple-clr text-center mt-3'><b>Accelerated registered DPIIT Startups count  From 2300 +  to 9000 + Startups in span of 2+ years.</b></h6>
                </div>
              </div>
              <div>
                <div className='skill-box'>
                  <img src='/images/theme2/images/182.png' className='skill-img img-fluid py-3' alt="figma" />
                </div>
                <div>
                  <h6 className='purple-clr text-center mt-3'><b>Introduced TN SC/ST Startup Seed Fund for inclusive entrepreneurship growth.</b></h6>
                </div>
              </div>
              <div>
                <div className='skill-box'>
                  <img src='/images/theme2/images/183.png' className='skill-img img-fluid py-3' alt="figma" />
                </div>
                <div>
                  <h6 className='purple-clr text-center mt-3'><b>Propelled From being ranked as a leader to best performer (TOP) category in StartupIndia Ranking.</b></h6>
                </div>
              </div>
              <div>
                <div className='skill-box'>
                  <img src='/images/theme2/images/184.png' className='skill-img img-fluid py-3' alt="figma" />
                </div>
                <div>
                  <h6 className='purple-clr text-center mt-3'><b>Introduced initiatives to support differently-abled and Transgender founders in startup growth journey.</b></h6>
                </div>
              </div>
              <div>
                <div className='skill-box'>
                  <img src='/images/theme2/images/185.png' className='skill-img img-fluid py-3' alt="figma" />
                </div>
                <div>
                  <h6 className='purple-clr text-center mt-3'><b>Introducing special package for Startups in Sector of Rutal Impact, GreenTech & Women led Startups.</b></h6>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* <section>
        <div className='testimonial'>
          <div className='container-fluid px-5'>
            <h1 className='work-tog-head text-center mb-3'>My Quality Services</h1>
            <p className='white-clr text-center m-0'>We put your ideas and thus your wishes in the form of a unique web project that</p>
            <p className='white-clr text-center mb-5'>inspires you and you customers.</p>
          </div>

          <div className='ser-tab active'>
            <div className='ser-tab-flex'>
              <span className='ser-tab-no mr-3'><h4><b>01</b></h4></span>
              <span className='white-clr' ><h2><b>Brand Design</b></h2></span>
            </div>
            <p className='white-clr'>Brolly show off show off pick your nose and blow off well A bit of <br /> how’s your father tomfoolery blimey, me old...</p>
            <div className='down-arrow'><SouthEastIcon /></div>
            <div className='up-arrow'><CallMadeIcon /></div>
          </div>

          <div className='ser-tab'>
            <div className='ser-tab-flex'>
              <span className='ser-tab-no mr-3'><h4><b>02</b></h4></span>
              <span className='white-clr' ><h2><b>UI/UX Design</b></h2></span>
            </div>
            <p className='white-clr'>Brolly show off show off pick your nose and blow off well A bit of <br /> how’s your father tomfoolery blimey, me old...</p>
            <div className='down-arrow'><SouthEastIcon /></div>
            <div className='up-arrow'><CallMadeIcon /></div>
          </div>

          <div className='ser-tab'>
            <div className='ser-tab-flex'>
              <span className='ser-tab-no mr-3'><h4><b>03</b></h4></span>
              <span className='white-clr' ><h2><b>Web Design</b></h2></span>
            </div>
            <p className='white-clr ml-3'>Brolly show off show off pick your nose and blow off well A bit of <br /> how’s your father tomfoolery blimey, me old...</p>
            <div className='down-arrow'><SouthEastIcon /></div>
            <div className='up-arrow'><CallMadeIcon /></div>
          </div>

          <div className='ser-tab'>
            <div className='ser-tab-flex'>
              <span className='ser-tab-no mr-3'><h4><b>04</b></h4></span>
              <span className='white-clr' ><h2><b>App Design</b></h2></span>
            </div>
            <p className='white-clr ml-3'>Brolly show off show off pick your nose and blow off well A bit of <br /> how’s your father tomfoolery blimey, me old...</p>
            <div className='down-arrow'><SouthEastIcon /></div>
            <div className='up-arrow'><CallMadeIcon /></div>
          </div>

         

        </div>
      </section> */}



      <section>
        <div className='testimonial'>
          <div className='container-fluid px-5'>
            <Grid container direction="row" alignItems="center" spacing={5}>
              <Grid item sm={12} md={6} lg={6}>
                <div className='d-flex'>
                  <h1 className='work-tog-head'><span><img src='/images/theme2/badge.png' className='img-fluid mr-3' alt="Badge" />My Experience</span></h1>
                </div>

                <div className='exp-box mt-5'>
                  <h4 className='exp-box-text' ><b>2022 - Present</b></h4>
                  <h3><b>Startup TN - Mission Director</b></h3>
                  <h6>Tamil Nadu, India</h6>
                </div>

                <div className='exp-box mt-5'>
                  <h4 className='exp-box-text' ><b>2015 - 2022</b></h4>
                  <h3><b>Native Angels Network</b></h3>
                  <h6>Tamil Nadu, India</h6>
                </div>

                <div className='exp-box mt-5'>
                  <h4 className='exp-box-text' ><b>2012 - 2022</b></h4>
                  <h3><b>Founder & CEO - Native Lead</b></h3>
                  <h6>Madurai</h6>
                </div>

                <div className='exp-box mt-5'>
                  <h4 className='exp-box-text' ><b>2010 - 2011</b></h4>
                  <h3><b>Member - Academic Council</b></h3>
                  <h6>Anna University</h6>
                </div>

              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={5} lg={5}>
                    <img src={'/images/theme2/sivaraja-2.jpg'} alt="1" className='sivaj1' />
                    <img src={'/images/theme2/sivaraj_3.jpeg'} alt="1" className='sivaj1 mt-3' />
                  </Grid>
                  <Grid item sm={12} md={7} lg={7}>
                    <img src={'/images/theme2/sivaraja-1.jpg'} alt="2" className='sivaj1' />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>



      <section>
        <div className='testimonial'>
          <div className='container-fluid px-5'>
            {/* <Grid container direction="row" alignItems="flex-start"> */}
            {/* <Grid item sm={12} md={6} lg={6}> */}
            <h1 className='work-tog-head text-center'>Accolades</h1>
            {/* <p className='text-center mb-5' style={{ color: 'white', fontSize: '17px' }}>Empowering people in new a digital journey with my super services</p> */}
            {/* </Grid> */}
            {/* <Grid item sm={12} md={6} lg={6}> */}
            <swiper-container className="mySwiper" pagination={true} style={{
              "--swiper-pagination-color": "#8750f7",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "6px",
              "height": "500px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }} pagination-clickable="true" space-between="30" slides-per-view={screenSize} autoplay="true">
              <swiper-slide >
                <div className='swiper-testi'>
                  {/* <div className='d-flex'>
                    <span><img src='/images/theme2/the2-test1.png' className='img-fluid' alt="test-2" /></span>
                    <span><img src='/images/theme2/the2-testi1.png' className='testi-img img-fluid' alt="test-2" /></span>
                  </div> */}
                  <div className='names-founder'>
                    <div>
                      <img src="/images/theme2/naren_sundaram.jpeg" alt='naren' className='imag-testi' />
                    </div>
                    <div>
                      <h4 className='white-clr'><b>Naren Sundram</b></h4>
                      <h6 className='white-clr'>SVP & Country Head</h6>
                      <h6 className='white-clr'> Sagent India</h6>
                    </div>
                  </div>
                  <div className='testi-para'>
                    <h5 className='white-clr my-5'>“Man with clarity in thoughts to derive the outcomes by enabling and empowering the Start-up ecosystem. He has created a deep cultural mind shift about StartUps in Tamil
                      Nadu. Sivarajah's Sir efforts will have a positive impact for Tamil Nadu StartUp ecosystem. </h5>
                  </div>

                </div>
              </swiper-slide>
              <swiper-slide>
                <div className='swiper-testi'>
                  <div className='names-founder'>
                    <div>
                      <img src="/images/theme2/Aj_balasubramanium.jpeg" alt='naren' className='imag-testi' />
                    </div>
                    <div>
                      <h4 className='white-clr'><b>A J Balasubramanian</b></h4>
                      <h6 className='white-clr'>Director and Head of Indian Operations</h6>
                      <h6 className='white-clr'>Aigilx Health Technologies Private Ltd</h6>
                    </div>
                  </div>
                  <div className='testi-para'>
                    <h5 className='white-clr my-5'>“Sivarajah is a rare individual whose contributions to the startup ecosystem in Tamil Nadu will be etched in the state's history. His vision and leadership, both in thought and action, are likely to have a lasting impact on society. Executing large-scale ecosystem projects within public systems is never easy, yet he has managed to achieve this by being genuinely accessible and empathetic to all stakeholders. Through his selfless actions, he has inspired many to volunteer for these social causes.</h5>
                  </div>


                </div>
              </swiper-slide>
              <swiper-slide>
                <div className='swiper-testi'>
                  <div className='names-founder'>
                    <div>
                      <img src="/images/theme2/And_giri.jpeg" alt='naren' className='imag-testi' />
                    </div>
                    <div>
                      <h4 className='white-clr'><b>Andi Giri</b></h4>
                      <h6 className='white-clr'>General Partner</h6>
                      <h6 className='white-clr'>Tamil Ventures LP</h6>
                    </div>
                  </div>
                  <div className='testi-para'>
                    <h5 className='white-clr my-5'>“Over the past 3 years, Sivarajah has aggressively scaled Startup TN’s agenda. His tenure has seen a multiplicative growth of 2300 startups in Mar 2021 to 8220 startups by Mar 2024. Starting with the SC/ST Startup seed fund, Sivarajah’s leadership has introduced schemes for the following under-served segments of founders: Rural Impact, Green Tech, Women-owned, Differently abled, and Transgender. I wish him all the best towards making Tamil Nadu the #1 Startup-friendly state in India. Startup TN and Guidance Tamil Nadu are key components of the $1 Triliion dream proposed by the CM.</h5>
                  </div>


                </div>
              </swiper-slide>
              <swiper-slide>
                <div className='swiper-testi'>
                  <div className='names-founder'>
                    <div>
                      <img src="/images/theme2/Murugesan_r.jpeg" alt='naren' className='imag-testi' />
                    </div>
                    <div>
                      <h4 className='white-clr'><b>Murugesan Ramasamy</b></h4>
                      <h6 className='white-clr'>CEO</h6>
                      <h6 className='white-clr'>ARAM Realty PL</h6>
                    </div>
                  </div>
                  <div className='testi-para'>
                    <h5 className='white-clr my-5'>“Fabulous Human! A CEO with vision for social entrepreneurial wisdom. Angels to many rural start ups! Humble person!.</h5>
                  </div>


                </div>
              </swiper-slide>
            </swiper-container>
            {/* </Grid> */}
            {/* </Grid> */}
          </div>
        </div>
      </section>

      {/* <section>
        <div className='theme2-blogs'>
          <div className='container-fluid px-5'>
            <h1 className='text-center work-tog-head m-0'>Recent Blogs</h1>
            <p className='text-center pt-3 m-0' style={{ color: 'white' }}>We put your ideas and thus your wishes in the form of a unique web project that</p>
            <p className='text-center pb-5 m-0' style={{ color: 'white' }}>inspires you and you customers.</p>

            <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={3}>
              <Grid item sm={12} md={4} lg={4}>
                <div className='blog1'>
                  <div className='blog-title-bg1'>SAAS</div>
                  <div className='blog-saas'>
                    <div className='d-flex align-items-center'>
                      <span className='blog-saas-icon mr-3'><CalendarMonthOutlinedIcon />May 10,2024</span>
                      <span className='blog-saas-icon'><ForumOutlinedIcon />No Comments</span>
                    </div>
                    <h4 className='mt-3' style={{ color: 'white' }}><b>The Role of Technology in Modern...</b></h4>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <div className='blog2'>
                  <div className='blog-title-bg2'>DESIGN</div>
                  <div className='blog-saas1'>
                    <div className='d-flex align-items-center'>
                      <span className='blog-saas-icon1 mr-3'><CalendarMonthOutlinedIcon />May 10,2024</span>
                      <span className='blog-saas-icon1'><ForumOutlinedIcon />No Comments</span>
                    </div>
                    <h4 className='mt-3' style={{ color: 'white' }}><b>The services provide for design</b></h4>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <div className='blog3'>
                  <div className='blog-title-bg3'>TECHNOLOGY</div>
                  <div className='blog-saas2'>
                    <div className='d-flex align-items-center'>
                      <span className='blog-saas-icon2 mr-3'><CalendarMonthOutlinedIcon />May 10,2024</span>
                      <span className='blog-saas-icon2'><ForumOutlinedIcon />No Comments</span>
                    </div>
                    <h4 className='mt-3' style={{ color: 'white' }}><b>Digital Marketo to Their New Office.</b></h4>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section> */}

      <section>
        <div className='work-tog px-3'>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item sm={12} md={12} lg={12}>
              <div className='purple-box'>
                <div className='text-center'>
                <h1 className='work-tog-head pb-2'>Let’s work together!</h1>
                <p style={{ color: 'white', fontSize: '15px' }}>I design and code beautifully simple things and i love what i do. Just simple <br /> like that!</p>
                </div>
                  <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3} className='mt-3'>
                      <Grid item sm={12} md={6} lg={6}>
                        <div className='mb-3'>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{ required: "First name is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="First name"
                                fullWidth
                                type="text"
                                {...field}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px', // Adjust the border-radius as needed
                                    '& fieldset': {
                                      borderColor: 'grey !important', // Default grey border color
                                      borderRadius: '10px', // Apply border-radius to the fieldset
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'grey', // Grey border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                    },
                                    backgroundColor: 'black', // Black background
                                    color: 'white', // White text color
                                    '& input': {
                                      color: 'white', // White text color inside the input field
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: 'white', // White label color
                                  },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.firstName && errors.firstName.message}
                          </FormHelperText>
                        </div>

                        <div className='mb-3'>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Email is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Email address"
                                fullWidth
                                type="text"
                                {...field}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px', // Adjust the border-radius as needed
                                    '& fieldset': {
                                      borderColor: 'grey !important', // Default grey border color
                                      borderRadius: '10px', // Apply border-radius to the fieldset
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'grey', // Grey border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                    },
                                    backgroundColor: 'black', // Black background
                                    color: 'white', // White text color
                                    '& input': {
                                      color: 'white', // White text color inside the input field
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: 'white', // White label color
                                  },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </div>
                      </Grid>

                      <Grid item sm={12} md={6} lg={6}>
                        <div className='mb-3'>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Last name is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Last name"
                                fullWidth
                                type="text"
                                {...field}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px', // Adjust the border-radius as needed
                                    '& fieldset': {
                                      borderColor: 'grey !important', // Default grey border color
                                      borderRadius: '10px', // Apply border-radius to the fieldset
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'grey', // Grey border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                    },
                                    backgroundColor: 'black', // Black background
                                    color: 'white', // White text color
                                    '& input': {
                                      color: 'white', // White text color inside the input field
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: 'white', // White label color
                                  },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.lastName && errors.lastName.message}
                          </FormHelperText>
                        </div>

                        <div className='mb-3'>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Phone number is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Phone number"
                                fullWidth
                                type="number"
                                {...field}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px', // Adjust the border-radius as needed
                                    '& fieldset': {
                                      borderColor: 'grey !important', // Default grey border color
                                      borderRadius: '10px', // Apply border-radius to the fieldset
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'grey', // Grey border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                    },
                                    backgroundColor: 'black', // Black background
                                    color: 'white', // White text color
                                    '& input': {
                                      color: 'white', // White text color inside the input field
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: 'white', // White label color
                                  },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.phone && errors.phone.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Controller
                        sx={{ width: '600' }}
                        name="department"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={skills}
                            onChange={(e, selectedOptions) =>
                              handleOption(selectedOptions)
                            }
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '10px', // Adjust the border-radius as needed
                                '& fieldset': {
                                  borderColor: 'grey !important', // Default grey border color
                                  borderRadius: '10px', // Apply border-radius to the fieldset
                                },
                                '&:hover fieldset': {
                                  borderColor: 'grey', // Grey border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                },
                                backgroundColor: 'black', // Black background
                                color: 'white', // White text color
                                '& input': {
                                  color: 'white', // White text color inside the input field
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white', // White label color
                              },
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Please choose an option " />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.department && errors.department.message}
                      </FormHelperText>
                    </div>
                    <div className='mt-3'>
                      <Controller
                        name="message"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Message is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            placeholder="Message"
                            fullWidth
                            type="text"
                            multiline
                            rows={6}
                            {...field}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '10px', // Adjust the border-radius as needed
                                '& fieldset': {
                                  borderColor: 'grey !important', // Default grey border color
                                  borderRadius: '10px', // Apply border-radius to the fieldset
                                },
                                '&:hover fieldset': {
                                  borderColor: 'grey', // Grey border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#7041ce !important', // Change border color to #3c2073 when focused
                                },
                                backgroundColor: 'black', // Black background
                                color: 'white', // White text color
                                '& input': {
                                  color: 'white', // White text color inside the input field
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white', // White label color
                              },
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.message && errors.message.message}
                      </FormHelperText>
                    </div>
                    <button type="submit" className="form-btn">
                      <b>Send Message</b>
                    </button>
                  </form>
                
              </div>
            </Grid>
            {/* <Grid item sm={12} md={5} lg={5} >
              <div className='d-flex mb-4'>
                <div className='icon-bg-pur'><PhoneInTalkIcon /></div>
                <div className='ml-3'>
                  <span className='white-clr'>Phone<br /></span>
                  <span className='white-clr1'>+01 123 654 8096</span>
                </div>
              </div>

              <div className='d-flex mb-4'>
                <div className='icon-bg-pur'><MailOutlineIcon /></div>
                <div className='ml-3'>
                  <span className='white-clr'>Email<br /></span>
                  <span className='white-clr1'>gerolddesign@mail.com</span>
                </div>
              </div>

              <div className='d-flex mb-4'>
                <div className='icon-bg-pur'><LocationOnOutlinedIcon /></div>
                <div className='ml-3'>
                  <span className='white-clr'>Address<br /></span>
                  <span className='white-clr1'>Warne Park Street Pine,<br />FL 33157, New York</span>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </section>
    </>
  )
}

export default Theme2home