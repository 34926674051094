import React, { useContext, useState } from 'react'
import Theme5navbar from '../layouts/Theme5navbar'
import { Grid, Radio, Rating } from '@mui/material'
import { Link } from 'react-router-dom';
import DataContext from '../../../context/DataContext';

const Theme5shoppage = () => {
    const {navigator}=useContext(DataContext)
    const [selectedCategory, setSelectedCategory] = useState(null);
    let urlName = sessionStorage.getItem('urlName')
    const catelists = [
        {
            id: 1,
            categ: "Appliances"
        },
        {
            id: 2,
            categ: "Camera"
        },
        {
            id: 3,
            categ: "Gadgets"
        },
        {
            id: 4,
            categ: "Gaming Accessories"
        },
        {
            id: 5,
            categ: "Home Audio"
        },
        {
            id: 6,
            categ: "Smart Watches"
        },
    ]
    const tags = [
        {
            id: 1,
            tag: "APPLIANCES"
        },
        {
            id: 2,
            tag: "ESSENTIALS"
        },
        {
            id: 1,
            tag: "TECH-PRODUCTS"
        },
    ]
    const crdImage = [
        {
            id:1,
            cate: "Appliances",
            name: "12S Slim Pro Laptop",
            img: "/images/theme5/shop-page/Product-Image-.png",
            stock: "Trending",
            price: "₹80.70 – ₹252.40"
        },
        {
            id:2,
            cate: "Gadgets",
            name: "5G Smartphone",
            img: "/images/theme5/shop-page/prod-2.png",
            stock: "Trending",
            price: "₹80.70 – ₹252.40"
        },
        {
            id:3,
            cate: "Smart Watches",
            name: "Black Smart Watch",
            img: "/images/theme5/shop-page/prod-3.png",
            stock: "New",
            price: "₹80.70 – ₹252.40"
        },
        {
            id:4,
            cate: "Home Audio",
            name: "Bluetooth Speaker",
            img: "/images/theme5/shop-page/prod-4.png ",
            stock: "New",
            price: "₹80.70 – ₹252.40"
        },
        {
            id:5,
            cate: "Camera",
            name: "Digital Camera",
            img: "/images/theme5/shop-page/prod5.png",
            stock: "Trending",
            price: "₹80.70 – ₹252.40"
        },
        {
            id:6,
            cate: "Gaming Accessories",
            name: "Gaming CPU",
            img: "/images/theme5/shop-page/prod-6.png",
            stock: "Trending",
            price: "₹80.70 – ₹252.40"
        },

    ]
    const handleRadioClick = (categ) => {
        setSelectedCategory(categ);
    };
    const  handleCarddetails = (val)=>{
        navigator(`/${urlName}/productdetails/${btoa(val)}`)
    }
    return (
        <div className='banner-t5-back'>
            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Shop</h1>
                        <p>Home &nbsp;/ &nbsp;Blog</p>
                    </div>
                </div>
            </div>
            <div className='t3-shoppage-card mt-5' >
                <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={4}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='shop-left-cate' data-aos="fade-left"  data-aos-duration="1000">
                            <div className='left-head-t5'>
                                <h3>Category</h3>
                            </div>
                            <div className='lists-categ-t5'>
                                <ul className='lists-t5-shop'>
                                    {catelists.map((cate, i) => (
                                        <li key={i} onClick={() => handleRadioClick(cate.categ)}>
                                            <Radio checked={selectedCategory === cate.categ} />
                                            {cate.categ}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='shop-left-cate mt-5' data-aos="fade-left"  data-aos-duration="1000">
                            <div className='left-head-t5'>
                                <h3>Tags</h3>
                            </div>
                            <div className='lists-categ-t5'>
                                <ul className='lists-t5-shop'>
                                    {tags && tags.map((a, i) => (
                                        <span className='chip-abt-tags' key={i}>{a.tag}</span>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} data-aos="fade-right"  data-aos-duration="1000">
                        <div className='pages-t5-shop mb-4'>
                            <span>Showing 1-12 of 18 results</span>
                        </div>
                        <Grid container spacing={2}>
                            {crdImage && crdImage.map((a, i) => (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="swiper-items-prod-t3" onClick={()=>handleCarddetails(a.id)} key={i} >
                                        <div>
                                            <p className='p-cate-t5'>{a.cate}</p>
                                        </div>
                                        <img src={a.img} alt="Products" className='t5-prods-caros mb-2' />
                                        <div className='names-t5-shop mt-2'>
                                            <div className='stocks-t5-shp'>
                                                <p>{a.stock}</p>
                                            </div>
                                            <h6 className='name-shop-name'>{a.name}</h6>
                                            <Rating name="size-medium" defaultValue={2} size='small'/>
                                            <p className='price_t5-shop mt-1'>{a.price}</p>
                                        </div>
                                    </div>
                                </Grid>
                            ))}

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Theme5shoppage