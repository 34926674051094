import { FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import SendIcon from '@mui/icons-material/Send';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import Aos from "aos";
import 'aos/dist/aos.css'
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { useEffect } from 'react';
import { useContext } from 'react';
import DataContext from '../../../context/DataContext';

const Theme8navbar = () => {
    const { navigator } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );

    useEffect(() => {
        Aos.init();
    }, [])

    return (
        <>
            <section className='top-nav'>
                <div className='container px-5'>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className='top-nav-content'>
                                <h6 className='mt-2'>Get Electrician Services Pro WordPress Theme</h6>
                                <button className='top-nav-btn'>Buy now</button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className='top-nav-content'>
                                <h6 className='mt-2'>Or Try Our WordPress Bundle - For $89 </h6>
                                <button className='top-nav-btn'>Get It Now</button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className='sec-nav'>
                <div className='px-3'>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className='sec-nav-cont'>
                                        <QueryBuilderIcon className='sec-nav-icon-clr' />
                                        <div className='sec-nav-cont-2'>
                                            <p className='m-0' style={{ color: '#ff3a47', fontSize: '12px' }}><b>Working Time</b></p>
                                            <p className='m-0' style={{ fontSize: '12px' }} >Mon-sat <span style={{ color: '#ff3a47', fontSize: '12px' }}><b>8:00am to 6pm</b></span></p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className='sec-nav-cont'>
                                        <LocationOnIcon className='sec-nav-icon-clr' />
                                        <div className='sec-nav-cont-2'>
                                            <p className='m-0' style={{ color: '#ff3a47', fontSize: '12px' }}><b>Our Location</b></p>
                                            <p className='m-0' style={{ fontSize: '12px' }} >247/B nelesbio squre,Califonia</p>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <img src='/images/theme8/electrician-logo.png' className='img-fluid mt-2' alt='' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Grid container >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className='sec-nav-cont'>
                                        <WifiCalling3Icon className='sec-nav-icon-clr' />
                                        <div className='sec-nav-cont-2'>
                                            <p className='m-0' style={{ color: '#ff3a47', fontSize: '12px' }}><b>Call Us Anytime</b></p>
                                            <p className='m-0' style={{ fontSize: '12px' }} >(+91) 1800-214-122</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className='sec-nav-cont'>
                                        <SendIcon className='sec-nav-icon-clr' />
                                        <div className='sec-nav-cont-2'>
                                            <p className='m-0' style={{ color: '#ff3a47', fontSize: '12px' }}><b>Email Us Anytime</b></p>
                                            <p className='m-0' style={{ fontSize: '12px' }} >electrician@example.com</p>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </section>

            
        </>
    )
}

export default Theme8navbar