import { Grid, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { TfiMap } from "react-icons/tfi";
import { TfiMobile } from "react-icons/tfi";
import { TfiEmail } from "react-icons/tfi";



const Theme7contactus = () => {

    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Contact</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Contact</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="contact-section">
                <div className="contact-item">
                    <TfiMap className='icon-location' />
                    <h4>Our Location</h4>
                    <p className='mt-4'>3481 Melrose Place, Beverly Hills<br />CA 90210</p>
                </div>
                <div className="contact-item">
                    <TfiMobile className='icon-location' />
                    <h4>Telephone</h4>
                    <p className='mt-4'>(+1) 517 397 7100<br />(+1) 517 397 7500</p>
                </div>
                <div className="contact-item">
                    <TfiEmail className='icon-location' />
                    <h4>Send Email</h4>
                    <p className='mt-4'>Info@example.com<br />admin@example.com</p>
                </div>
            </div>
            <div className='contact-into-sect'>
                <div className="text-center">
                    <span className='comp'>Get in touch</span>
                    <h2 className='blogsed'>
                        Contact us for any kind<br></br>of inquire
                    </h2>
                </div>
                <div className='container mt-5'>
                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            className='contact-input'
                                            {...field}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.name}
                                            helperText={errors.name ? errors.name.message : ""}
                                            InputProps={{
                                                style: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                            message: "Invalid email address"
                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='contact-input'
                                            label="Your Email"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.email}
                                            helperText={errors.email ? errors.email.message : ""}
                                            InputProps={{
                                                style: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="subject"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Subject is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='contact-input'
                                            label="Your Subject"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.subject}
                                            helperText={errors.subject ? errors.subject.message : ""}
                                            InputProps={{
                                                style: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="message"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Message is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='msg-textarea'
                                            label="Your Message"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            error={!!errors.message}
                                            helperText={errors.message ? errors.message.message : ""}
                                            InputProps={{
                                                style: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#fff',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} className="text-center">
                                <button className="btn_one mb-5">
                                    Send Message
                                </button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Theme7contactus