import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Grid } from '@mui/material';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa'

const Theme1footer = () => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className='donor-sect'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="footer-column">
              <div className="widget">
                <h2 className="wp-block-heading">Udhayanidhi Stalin</h2>
                <p>DMK Youth Wing Secretary , <br />Minister for Youth Welfare and Sports Development, Government of Tamil Nadu , MLA, Chepauk - Thiruvallikeni Constituency</p>
                <div className="social">
                  <ul className='copied'>
                    <li className='ico'><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                    <li className='ico'><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                    <li className='ico'><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                    <li className='ico'><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                  </ul>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="footer-column">
              <div className="widget">
                <h2 className="wp-block-heading">Recent Posts</h2>
                <ul className="wp-block-latest-posts">
                  <li>
                    <div className="wp-block-latest-posts-image d-flex">
                      <img
                        width="150"
                        height="100"
                        src="/images/theme1/dmkloksabha_4.jpg"
                        alt=""
                      />
                      <div className='ml-2'>
                        <a className="wp-block-latest-posts__post-title" href="/:name/blog">elevate Udhayanidhi now ?</a>
                        <p className='wp-block-latest-posts__post-date'>August 2023</p>
                      </div>
                    </div>

                  </li>
                  <li>
                    <div className="wp-block-latest-posts-image d-flex mt-3">
                      <img
                        width="150"
                        height="100"
                        src="/images/theme1/blog-back3.png"
                        alt=""
                      />
                      <div className='ml-2'>
                        <a className="wp-block-latest-posts__post-title" href="/:name/blog">Cinema to Politics</a>
                        <p className='wp-block-latest-posts__post-date'>June 2024</p>
                      </div>
                    </div>

                  </li>
                </ul>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="footer-column">
              <div className="widget">
                <h2 className="wp-block-heading">Address</h2>
                <div className="office-info-box">
                  <p>
                    <span>
                      <FaMapMarkerAlt /> Anna Arivalayam,
                      No 367/369, Anna Salai, Teynampet,
                      Chennai - 600018.
                    </span>
                  </p>
                  <p>
                    <span>
                      <FaPhone /> + (91) - 044- 24348258
                    </span>
                  </p>
                  {/* <p>
                    <a href="mailto:office@example.com">
                      <FaEnvelope /> office@example.com
                    </a>
                  </p> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="wpo-lower-footer">
        <div className='donor-sect'>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={6} xl={6}>
              <ul className='copied'><li>Powered By : <a href='https://amizhthdev.in/' target='_blank' rel='noopener noreferrer'>
                <img src='/images/welcome/logo-e 1.png' alt='' className='pli-emuga' />
              </a></li></ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
}

export default Theme1footer;
