import { Grid } from '@mui/material'
import React from 'react'

const Theme5Productinfo = () => {
    return (
        <div>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <div className='desc-t5-prod'>
                        <p>Cras aliquam ultricies ante, eu sollicitudin nulla mattis et. Proin non sapien commodo, maximus libero eu, dictum massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris posuere sem a tellus posuere, non aliquet lacus faucibus. Aliquam sodales vestibulum sollicitudin. Proin ullamcorper gravida mi, sit amet pharetra justo rhoncus vitae. Suspendisse volutpat tempor massa id efficitur. Ut fermentum rhoncus hendrerit. Vestibulum maximus tempus turpis, vel aliquet odio euismod at. Sed sed justo non mauris cursus varius in a leo. Proin iaculi placerat placerat.icitudin. Proin ullamcorper gravida mi, sit amet pharetra justo rhoncus vitae.</p>
                   
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                  
                   <img src={'/images/theme5/Shop-Description-Image.jpg'} alt="imag" className='des-img img-fluid'/>
                </Grid>
            </Grid>
        </div>
    )
}

export default Theme5Productinfo