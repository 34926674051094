import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme5layout from './layouts/Theme5layout'
import Theme5home from './components/home-page/Theme5home'
import Theme5Aboutpage from './About-page/Theme5Aboutpage'
import Theme5shippinginfo from './Shippinginfo-page/Theme5shippinginfo'
import Theme5blog from './blog-page/Theme5blog'
import Theme5blogdetails from './blog-page/Theme5blogdetails'
import Theme5shoppage from './Shop-page/Theme5shoppage'
import Theme5shopdetails from './Shop-page/Theme5shopdetails'
import Theme5contactus from './contactus-page/Theme5contactus'
import Theme5Termsandcondition from './Termsandcondition-page/Theme5Termsandcondition'



const Theme5route = () => {

    document.getElementById('theme').setAttribute('href', '/css/theme5.css');
    return (
        <div>
            <Routes>
                <Route path='/:name' element={<Theme5layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme5home />} />
                    <Route path='/:name/about' element={<Theme5Aboutpage />} />
                    <Route path='/:name/blog' element={<Theme5blog />} />
                    <Route path='/:name/blogdetails/:id' element={<Theme5blogdetails />} />
                    <Route path='/:name/shop' element={<Theme5shoppage />} />
                    <Route path='/:name/productdetails/:id' element={<Theme5shopdetails/>} />
                    <Route path='/:name/contactus' element={<Theme5contactus/>} />
                    <Route path='/:name/shippinginfo' element={<Theme5shippinginfo />} />
                    <Route path='/:name/termsandconditions' element={<Theme5Termsandcondition/>} />
                </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
        </div>
    )
}

export default Theme5route