import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { Container } from 'react-bootstrap'
import $ from 'jquery';
import DataContext from '../../../context/DataContext';
import { FaXTwitter } from "react-icons/fa6";
const Theme6footer = () => {
  const { footerData } = useContext(DataContext);
  $(document).ready(function () {
    $('#backToTop').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 'slow'); // 'slow' can be replaced with a duration in milliseconds (e.g., 800)
    });
});
var logo = sessionStorage.getItem('logo')
const handleReDirectClick = (value) => {
  if (value === 'linkedIn') {
      const linkeInUrl = footerData.linkedIn
      window.open(linkeInUrl, '_blank');
  }
  else if (value === 'twitter') {
      const twitterUrl = footerData.twitter
      window.open(twitterUrl, '_blank');
  }
  else if (value === 'facebook') {
      const facebookUrl = footerData.facebook
      window.open(facebookUrl, '_blank');
  }
  else if (value === 'instagram') {
      const instagramUrl = footerData.instagram
      window.open(instagramUrl, '_blank');
  }
  else if (value === 'youtube') {
      const youtubeUrl = footerData.youtube
      window.open(youtubeUrl, '_blank');
  }
};

  return (
    <div className='footer-bg'>
      <Container className=''>
        <div className="footer-details">
        <Grid container direction="row" >
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
            <div className='footer-content'>
              <p> {footerData.address}</p>
              <p> +91 {footerData.phone}</p>
              <p>{footerData.email}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
            <div className='footer-img'>
            <img src={logo} alt="" />
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2'>
          <div className='footer-icon'>
          {(footerData.facebook) && (
          <i class="fab fa-facebook"  onClick={() => handleReDirectClick('facebook')}></i>
        )}
        {(footerData.twitter) && (
          <FaXTwitter style={{ color: "#fff", cursor: 'pointer', width: '28px',height:'23px',marginTop:'-7px' }} onClick={() => handleReDirectClick('twitter')}/>
        )}
            {(footerData.instagram) && (
          <i class="fab fa-instagram" onClick={() => handleReDirectClick('instagram')}></i>
        )}
           {(footerData.linkedIn) && (
          <i class="fab fa-linkedin" onClick={() => handleReDirectClick('linkedIn')}></i>
        )}
           {(footerData.youtube) && (
          <i class="fab fa-youtube" onClick={() => handleReDirectClick('youtube')}></i>
        )}
          </div>
        </Grid>
        </Grid>
        </div>
        <div className='copy-rights'>
          <p>Copyright © 2024 All Rights Reserved By E-Mugavari</p>
        </div>
      </Container>

    </div>
  )
}

export default Theme6footer