import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MdArrowOutward } from "react-icons/md";
import { TfiMedall } from "react-icons/tfi";
import { TfiServer } from "react-icons/tfi";
import { TfiCup } from "react-icons/tfi";
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const statsData = [
    { number: 34, label: 'Years Experience' },
    { number: 99, label: 'Customer Satisfaction' },
    { number: 184, label: 'Team Members' },
    { number: 541, label: 'Project Complete' }
];

const blogPosts = [
    {
        title: "How can you improvement to your business policy for the better product.",
        date: "July 17, 2024",
        category: "Operation",
        image: "/images/theme7/blog-1.jpg",
        description: "How can you improvement to your business policy for the better product.",
        link: "#"
    },
    {
        title: "People population change anything what your need for your next generation.",
        date: "July 17, 2024",
        category: "Operation",
        image: "/images/theme7/blog-5.jpg",
        description: "People population change anything what your need for your next generation.",
        link: "#"
    },
    {
        title: "Improve your business so that you can stay in your local business in next month.",
        date: "July 17, 2024",
        category: "Security",
        image: "/images/theme7/blog-6.jpg",
        description: "Improve your business so that you can stay in your local business in next month.",
        link: "#"
    }
];

const Theme7aboutus = () => {

    const [screenSize, setScreenSize] = useState(2);

    useEffect(() => {
        const handleResize = () => {
            size();
        };

        window.addEventListener('resize', handleResize);
        size();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(2);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(2);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
            setScreenSize(2);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(1);
        } else {
            setScreenSize(1);
        }
    };

    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">About Us</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>About Us</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mt-5">
                <div className='cyber-timed'>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <div>
                                <img src='/images/theme7/ab-1.jpg' alt='' className='abt-sect img-fluid' />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <div className='compy'>
                                <span className='comp'>Trust your cyber company</span>
                                <p className='opera mt-3'>This is 24/7 cyber security operation center for your problem solution.</p>
                            </div>
                            <div className='check1 mt-4'>
                                <div className='checked'>
                                    <div>
                                        <img src='/images/theme7/check.jpg' alt='' className='img-fluid' />
                                    </div>
                                    <div>
                                        <h4 className='thre'>
                                            Security Services</h4>
                                    </div>
                                </div>
                                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
                            </div>
                            <div className='check1 mt-4'>
                                <div className='checked'>
                                    <div>
                                        <img src='/images/theme7/check.jpg' alt='' />
                                    </div>
                                    <div>
                                        <h4 className='thre'>
                                            Industry Certified</h4>
                                    </div>
                                </div>
                                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='cyber-timed mt-5'>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className='compy'>
                            <span className='comp'>
                                Our team member
                            </span>
                            <h2 className='expec mt-4'>
                                Our Expert's Team Member <br />
                                For Your Solution
                            </h2>
                            <p className='expec-sub mt-4'>
                                Lorem ipsum dolor sit amet consectetuer adipiscing elitenean commo doligula eget
                                dolorenean massa.In enim justo, rhoncus ut, imperdiet avene natis vitae justo nullam
                                dictum felis eu pede mollis pretium Inte ger tincid unt cras dapibus.
                            </p>
                            <div>
                                <button className='btn_one mt-4'>
                                    View All Members
                                    <MdArrowOutward />
                                </button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className='team-mem'>
                            <div>
                                <img
                                    src="/images/theme7/team1.jpg"
                                    alt=""
                                    className='teamed img-fluid'
                                    sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                                />
                                <div>
                                    <h4 className='team-name'>
                                        William Smith
                                    </h4>
                                    <p style={{ color: '#fff' }}>
                                        Brand designer
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img
                                    src="/images/theme7/team2.jpg"
                                    alt=""
                                    className='teamed img-fluid'
                                    sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                                />
                                <div>
                                    <h4 className='team-name'>
                                        David Bell
                                    </h4>
                                    <p style={{ color: '#fff' }}>
                                        Marketer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='team-mem'>
                            <div>
                                <img
                                    src="/images/theme7/team3.jpg"
                                    alt=""
                                    className='teamed img-fluid'
                                    sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                                />
                                <div>
                                    <h4 className='team-name'>
                                        Kevin Woods
                                    </h4>
                                    <p style={{ color: '#fff' }}>
                                        Product designer
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img
                                    src="/images/theme7/team4.jpg"
                                    alt=""
                                    className='teamed img-fluid'
                                    sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                                />
                                <div>
                                    <h4 className='team-name'>
                                        Georgia James
                                    </h4>
                                    <p style={{ color: '#fff' }}>
                                        Developer
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='cyber-timing mt-5'>
                <Grid container spacing={3} className="stats-container" alignItems="center">
                    {statsData.map((stat, index) => (
                        <Grid
                            item
                            xs={12} sm={6} md={3} lg={3} xl={3}
                            className='staicss'
                            key={index}
                        >
                            <div className="stat-box">
                                <h2 className="stat-number">{stat.number}</h2>
                                <p className="stat-label">{stat.label}</p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className="mt-5">
                <div className="text-center">
                    <span className='comp'>Pricing plan</span>
                    <h2 className='blogsed'>
                        Our best pricing plan for<br></br>your solution
                    </h2>
                </div>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <div className='pricingTable'>
                            <div className="pricingTable-header">
                                <h3 className="pricetitle">Personal</h3>
                            </div>
                            <div className="pricing-icon">
                                <TfiMedall />
                            </div>
                            <div className='pricingcontent'>
                                <p>5 website</p>
                                <p>50GB Disk Space</p>
                                <p>50 Email Accounts</p>
                                <p>50GB Monthly Bandwidth</p>
                                <p>10 Subdomains</p>
                            </div>
                            <div className="price-value">
                                <span className="priceamount">$99</span>
                                <span className="priceduration">/mo</span>
                            </div>
                            <div className='text-center'>
                                <button className='purchase_one'>PURCHASE NOW</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <div className='pricingTable'>
                            <div className="pricingTable-header">
                                <h3 className="pricetitle">Business</h3>
                            </div>
                            <div className="pricing-icon">
                                <TfiServer />
                            </div>
                            <div className='pricingcontent'>
                                <p>5 website</p>
                                <p>50GB Disk Space</p>
                                <p>50 Email Accounts</p>
                                <p>50GB Monthly Bandwidth</p>
                                <p>10 Subdomains</p>
                            </div>
                            <div className="price-value">
                                <span className="priceamount">$99</span>
                                <span className="priceduration">/mo</span>
                            </div>
                            <div className='text-center'>
                                <button className='purchase_one'>PURCHASE NOW</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <div className='pricingTable'>
                            <div className="pricingTable-header">
                                <h3 className="pricetitle">Premium</h3>
                            </div>
                            <div className="pricing-icon">
                                <TfiCup />
                            </div>
                            <div className='pricingcontent'>
                                <p>5 website</p>
                                <p>50GB Disk Space</p>
                                <p>50 Email Accounts</p>
                                <p>50GB Monthly Bandwidth</p>
                                <p>10 Subdomains</p>
                            </div>
                            <div className="price-value">
                                <span className="priceamount">$99</span>
                                <span className="priceduration">/mo</span>
                            </div>
                            <div className='text-center'>
                                <button className='purchase_one'>PURCHASE NOW</button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="testimonial-slider-section mt-5">
                <div className="cyber-timed">
                    <div className="text-center">
                        <span className='comp'>Top reviews</span>
                        <h2 className='blogsed'>
                            Take a look our top <br></br>Customer feedback
                        </h2>
                    </div>
                    <div className='mt-5'>
                        <Swiper
                            slidesPerView={screenSize}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                        >
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>James Clayton</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>James Simmons</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>Alex Feroundo</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>Mack Hokins</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>Larav Peter</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>

                                <div className="testimonial">
                                    <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                                    <div className="testimonial_content">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                                    </div>
                                    <div className="testi_pic_title">
                                        <h4>Larav Peter</h4>
                                    </div>
                                </div>

                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className='cyber-timed'>
                    <div className="text-center">
                        <span className='comp'>Blogs & News</span>
                        <h2 className='blogsed'>
                            See Our Latest Blog And <br></br>News From Us
                        </h2>
                    </div>
                    <Grid container spacing={3}>
                        {blogPosts.map((post, index) => (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                                <div className="service-card">
                                    <img
                                        className='blog-img img-fluid'
                                        alt={post.title}
                                        src={post.image}
                                    />
                                    <div className="service-content mt-3">
                                        <span className='blog-cat'>
                                            {post.category}
                                        </span>
                                        <span style={{ color: 'lightgrey' }}>
                                            {post.date}
                                        </span>
                                        <h3 className='blog-ti'>
                                            {post.title}
                                        </h3>
                                        <div className="icon-box">
                                            <span className="icon-box-title">Read More</span>
                                            <span >
                                                <MdArrowOutward className="box-title" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Theme7aboutus