import { Container, Grid } from '@mui/material'
import React from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'

const Theme3Gallery = () => {
  const Galleryimages = [
    { img: "/images/theme3/soori/movies/images.jpeg" },
    { img: "/images/theme3/soori/movies/soori_5.jpeg" },
    { img: "/images/theme3/soori/movies/soori_2.jpg" },
    { img: "/images/theme3/soori/movies/soori_4.jpg" },
    { img: "/images/theme3/soori/movies/soori_3.webp" },
    { img: "/images/theme3/soori/movies/images (1).jpeg" },
    { img: "/images/theme3/soori/movies/451017241_18350180845112754_2273967497628148885_n.jpg" },
    { img: "/images/theme3/soori/movies/456625223_1686002428800710_6594398001483249078_n.jpg" },

  ]
  return (
    <div>
      <div className='bg-colors'>
        <Theme3navbar />
        <div className='abt-page-t3 text-center'>
          <h1>Gallery</h1>
          <h5> Explore Soori's photos, showcasing his roles and charm!</h5>
        </div>
        <img src="/images/theme3/tri-1.png" alt="tri" className='tri-7' />
      </div>
      <div className='abt-t3-gallery mt-5'>
        <div className='our-sched-titl text-center mb-5'>
          <h2>Film Gallery</h2>
        </div>
        <Container>
          <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
            {Galleryimages && Galleryimages.map((img, i) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={i}>
                <img src={img.img} alt="gallery-img" className='gal-img-t3' />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default Theme3Gallery