import React from 'react'
import Theme6navbar from './Theme6navbar'
import { Outlet } from 'react-router-dom'
import Theme6footer from './Theme6footer'

const Theme6layout = () => {
  return (
    <div>
        <Outlet></Outlet>
        <Theme6footer/>
    </div>
  )
}

export default Theme6layout