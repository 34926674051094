import { Box, Container, Dialog, Grid, Slide } from '@mui/material';
import React, { useState } from 'react';
import Modalbox from './Modalbox'; // Assuming you have this component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Theme1gallery = () => {
  const images = [
    { url: '/images/theme1/stalin-gall1.png' },
    { url: '/images/theme1/stalin-gall2.png' },
    { url: '/images/theme1/stalin-gall3.png' },
    { url: '/images/theme1/stalin-gall4.png' },
    { url: '/images/theme1/stalin-gall5.png' },
    { url: '/images/theme1/stalin-gall6.png' },
    { url: '/images/theme1/stalin-gall-19.png' },
    { url: '/images/theme1/stalin-gall8.png' },
    { url: '/images/theme1/stalin-gall9.png' },
    { url: '/images/theme1/stalin-gall10.png' },
    { url: '/images/theme1/stalin-gall11.png' },
    { url: '/images/theme1/stalin-gall12.png' },
    { url: '/images/theme1/stalin-gall13.png' },
    { url: '/images/theme1/stalin-gall14.png' },
    { url: '/images/theme1/stalin-gall-15.png' },
    { url: '/images/theme1/stalin-gall-16.png' },
    { url: '/images/theme1/stalin-gall-17.png' },
    { url: '/images/theme1/stalin-gall-18.png' },
    { url: '/images/theme1/stalin-gall15.png' },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <section className="contact-page-title">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="contact-breadcumb-wrap">
                <h2 className='contec'>Gallery</h2>
                <ol className="contact-breadcumb-wrap">
                  <li>
                    <a href="/" title="Home" className='contact-breadcumb-wrap'>Home</a>
                  </li>
                  <li className="contact-breadcumb-wrap">Gallery</li>
                </ol>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Container>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={index === images.length - 1 ? 12 : 4}
                lg={index === images.length - 1 ? 12 : 4}
              >
                <div className="item">
                  <img
                    src={image.url}
                    alt={`img-${index}`}
                    className='img-fluid'
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                      height: 'auto',
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
      >
        <Modalbox
          images={images}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={setSelectedImageIndex}
          handleCloseModal={handleCloseModal}
        />
      </Dialog>
    </div>
  );
};

export default Theme1gallery;
