import { FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import { useState } from 'react';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DataContext from '../../../../context/DataContext';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Theme4footer from '../../layouts/Theme4footer';

const Theme4contact = () => {
    const { pageLoading, navigator } = useContext(DataContext)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [formData, setFormData] = useState({});
    const { handleSubmit, control, reset, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleWork = () => {
        navigator('/:name/home/work')
    }

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlehome = () => {
        navigator('/:name/home')
    }

    const handleBlog = () => {
        navigator('/:name/home/blogs')
    }

    const handleContact = () => {
        navigator('/:name/home/contact')
    }

    return (
        <>
            <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }}>
                <Grid item sm={12} md={5} lg={5} className="fixed-grid">
                    <section className=''>
                        <section className='nav-position'>
                            <nav className="navbar navbar-expand-lg navbar-light p-0">
                                <div className='t4-logo-bg'>
                                    <a className="navbar-brand text-center" href="#"><img src='/images/theme4/theme-4-logo.png' className='theme4-logo-nav img-fluid' /></a>
                                </div>
                                <div className=" black-nav" >
                                    <div className=" ml-auto">
                                        <div className="form-btn" onClick={toggleDrawer}><MenuIcon /></div>
                                    </div>
                                </div>
                            </nav>

                            <img src='/images/theme4/fig3.png' alt='' className='theme4-image' />

                            <div
                                className={`drawer ${isDrawerOpen ? "open" : ""}`}
                                onClick={toggleDrawer}
                            >
                                <div className="drawer-content">
                                    <div className="close-icon" onClick={toggleDrawer}>
                                        <CloseIcon />
                                    </div>
                                    <ul>
                                        <li><a href="#" onClick={handlehome} >Home</a></li>
                                        <li><a href="#" onClick={handleWork} >Work</a></li>
                                        <li><a href="#" onClick={handleBlog} >Blog</a></li>
                                        <li><a href="#" onClick={handleContact} >Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}

                        </section>
                    </section>
                </Grid>
                <Grid item sm={12} md={7} lg={7}>
                    <section className='blogs-sec'>
                        <div className="speech-bubble">
                            <span>GET IN TOUCH</span>
                        </div>

                        <h1 className='portfolio'>CONTACT</h1>

                        <div className='yel-line'></div>
                        <div className='yel-line-1'></div>

                        <h2 className='mt-4 ml-2'>Let's Keep In Touch</h2>

                        <p className='mt-4 mr-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p>

                        <div className='mt-5'>
                            <span><PhoneEnabledIcon style={{ color: '#ffc815' }} /><b>+7(111)123456789</b></span>
                            <span className='ml-3'><LocationOnIcon style={{ color: '#ffc815' }} /><b>27th Brooklyn New York, NY 10065</b></span>
                            <span className='ml-3'><b>yourmail@domain.com</b></span>
                        </div>

                        <form>
                            <div className='mt-5 mb-3' style={{ width: '80%' }}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            placeholder="Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.name && errors.name.message}
                                </FormHelperText>
                            </div>

                            <div className='mb-3' style={{ width: '80%' }}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Email is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            placeholder="Email"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.email && errors.email.message}
                                </FormHelperText>
                            </div>

                            <div className='mb-3' style={{ width: '80%' }}>
                                <Controller
                                    name="subject"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Subject is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            placeholder="Subject"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.subject && errors.subject.message}
                                </FormHelperText>
                            </div>

                            <div className='mb-3' style={{ width: '80%' }}>
                                <Controller
                                    name="message"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Message is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            placeholder="Message"
                                            fullWidth
                                            type="text"
                                            rows={4}
                                            multiline
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.message && errors.message.message}
                                </FormHelperText>
                            </div>

                            <button className='con-sub' type='submit'>
                                <b>SEND MESSAGE</b>
                            </button>
                        </form>
                    </section>
                    <Theme4footer />
                </Grid>
            </Grid>
        </>
    )
}

export default Theme4contact