import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { MdArrowOutward } from "react-icons/md";
import DataContext from '../../../../context/DataContext';

const Theme7services = () => {
    const { navigator } = useContext(DataContext)
    const services = [
        {
            id: 1,
            title: 'Malware Protection',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service1.jpg',
        },
        {
            id: 2,
            title: 'Server Protection',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service2.jpg',
        },
        {
            id: 3,
            title: 'Computer Security',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service3.jpg',
        },
        {
            id: 4,
            title: 'Strong Authentication',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service8.jpg',
        },
        {
            id: 5,
            title: 'Firewall Implementation',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service9.jpg',
        },
        {
            id: 6,
            title: 'Security Monitoring',
            description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
            image: '/images/theme7/service10.jpg',
        },
    ];

    const handleServicemore = (id) => {
        console.log(id, "ids")
        navigator(`/:name/readmore/${btoa(id)}`)
    }
    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Services</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Services</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='mt-5'>
                <div className='cyber-timed'>
                    <Grid container spacing={3}>
                        {services.map((service, index) => (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                                <div className="service-card">
                                    <img
                                        className='service-img'
                                        src={service.image}
                                        alt={service.title}
                                    />
                                    <div className="service-content">
                                        <h5 className='serv-ti'>{service.title}</h5>
                                        <p className='ser-des'>{service.description}</p>
                                        <div className="icon-box">
                                            <span className="icon-box-title" onClick={() => handleServicemore(service.id)}>Read More</span>
                                            <span >
                                                <MdArrowOutward className="box-title" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Theme7services