import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Theme2layout from './layouts/Theme2layout'
import Theme2home from './components/Theme2home'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
const Theme2route = () => {

    document.getElementById('theme').setAttribute('href', '/css/theme2.css');
    return (
        <div>
            <Routes>
                <Route path='/:name' element={<Theme2layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme2home />} />
                </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
        </div>
    )
}

export default Theme2route