import React from 'react'
import Theme8layout from './layouts/Theme8layout';
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance';
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound';
import { Navigate, Route, Routes } from 'react-router-dom';
import Theme8home from './components/home-page/Theme8home';
import Theme8aboutus from './components/About-page/Theme8aboutus';
import Theme8blog from './components/Blog-page/Theme8blog';
import Theme8contact from './components/Contactus-page/Theme8contact';
import Theme8page from './components/Pages-page/Theme8page';

const Theme8route = () => {
    document.getElementById('theme').setAttribute('href', '/css/theme8.css');
    return (
      <div>
        <Routes>
          <Route path='/:name' element={<Theme8layout />}>
            <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
            <Route path='/:name/home' element={<Theme8home />} />
            <Route path='/:name/home/about' element={<Theme8aboutus />} />
            <Route path='/:name/home/blog' element={<Theme8blog />} />
            <Route path='/:name/home/contact' element={<Theme8contact />} />
            <Route path='/:name/home/pages' element={<Theme8page />} />
          </Route>
          <Route path='/under-maintenance' element={<Undermaintenance />} />
          <Route path='*' element={<Pagenotfound />} />
        </Routes>
      </div>
    )
}

export default Theme8route