import React from 'react'
import Theme7navbar from './Theme7navbar'
import { Outlet } from 'react-router-dom'
import Theme7footer from './Theme7footer'

const Theme7layout = () => {
  return (
    <div>
        <Theme7navbar/>
        <Outlet></Outlet>
        <Theme7footer/>
    </div>
  )
}

export default Theme7layout