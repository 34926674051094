import React from 'react'
import { Outlet } from 'react-router-dom'
import Theme5footer from './Theme5footer'

const Theme5layout = () => {
  return (
    <div>
        <Outlet></Outlet>
        <div className='prod-div-t5 pb-3'>
       <Theme5footer/>
       </div>
    </div>
  )
}

export default Theme5layout