import React, { useContext, useEffect, useState } from 'react'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'
import { Container, Grid } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const cardItems = [
  {
    cardimg: "/images/theme1/sports.png",
    cardTitle: "Kalaignar Sports Kit Scheme",
    cardDetails:
      "This initiative aims to enhance grassroots sports by distributing kits to 12,000 village panchayats in the state, encouraging youth participation in rural areas.",
  },
  {
    cardimg: "/images/theme1/woman.png",
    cardTitle: "Pudhumai Penn (Modern Woman) Scheme",
    cardDetails:
      "Supports girl students by providing financial aid for their higher education, helping boost female literacy and education rates in the state.",
  },
  {
    cardimg: "/images/theme1/stethoscope.png",
    cardTitle: "Makkalai Thedi Maruthuvam",
    cardDetails:
      "Focuses on doorstep healthcare services, offering medical checkups, medicines, and treatment directly to people's homes.",
  },
  {
    cardimg: "/images/theme1/english-breakfast.png",
    cardTitle: "Chief Minister's Breakfast",
    cardDetails:
      "Free breakfast provided to government primary school students to address malnutrition and promote education.",
  }
  ,
  {
    cardimg: "/images/theme1/school-bus.png",
    cardTitle: "Free Bus Pass for Women",
    cardDetails:
      "Women can travel for free on government buses, reducing the financial burden of transportation costs.",
  }
  ,
  {
    cardimg: "/images/theme1/winner-on-the-podium.png",
    cardTitle: "Naan Mudhalvan (I am the First)",
    cardDetails:
      "Training in technology, soft skills, and career guidance to improve employment opportunities for Tamil Nadu’s youth.",
  }
  ,
  {
    cardimg: "/images/theme1/welfare.png",
    cardTitle: "Dravidian Model Welfare Schemes",
    cardDetails:
      "Udhayanidhi Stalin is implementing various welfare schemes, including support for girl students, financial aid for women, and free bus travel.",
  }
];

const Theme1aboutus = () => {
  let urlName = sessionStorage.getItem('urlName')
  const { setPageLoading } = useContext(DataContext)
  useDidMountEffect(() => {

  }, [])
  const handleLoader = () => {
    setPageLoading(true)
    setTimeout(() => {
      setPageLoading(false)
    }, 5000);
  }

  const [screenSize, setScreenSize] = useState(4);
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  return (
    <div>
      <section className="contact-page-title">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="contact-breadcumb-wrap">
                <h2 className='contec'>Biography</h2>
                <ol className="contact-breadcumb-wrap">
                  <li>
                    <a href="/" title="Home" className='contact-breadcumb-wrap'>Home</a>
                  </li>
                  <li className="contact-breadcumb-wrap">Biography</li>
                </ol>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="wpo-about-section-s3 mt-5">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-about-text">
                <div className="section-title">
                  {/* <span className="abo-pol">About Politian</span> */}
                  <h2 className="can-wo">Biography</h2>
                </div>
                <p className="lead">
                  Udhayanidhi Stalin, born on November 27, 1977, in Chennai, Tamil Nadu, is an Indian film producer, actor, and politician. He is the son of M. K. Stalin, the current Chief Minister of Tamil Nadu, and the grandson of M. Karunanidhi, a former Chief Minister of Tamil Nadu
                </p>
                <p className="lead">
                  Udhayanidhi attended Don Bosco Matriculation Higher Secondary School in Egmore, Chennai, and later graduated with a degree in commerce from Loyola College, Chennai
                </p>
                {/* <p className="lead">
                  Udhayanidhi Stalin contested and won in the Chepauk – Thiruvallikeni Assembly Constituency in the 2021 Tamil Nadu Legislative Assembly election.
                </p> */}
                {/* <div className="quote">
                  <p className="world">“We can start by taking small steps and making small changes that can have a big impact on the world.”</p>
                </div> */}
                {/* <div className="wpo-about-left-info">
                  <div className="wpo-about-left-inner">
                    <div className="wpo-about-left-text">
                      <h5 className="name-abt">Robert Willum</h5>
                      <span className="name-sur">CEO &amp; Founder</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-abouted-img">
                <img src="/images/theme1/stalin-bios.png" alt="About Politician" className="abted" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="wpo-about-section-s3 mt-5">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-abouted-img">
                <img src="/images/theme1/processed_766068.jpg" alt="About Politician" className="abted" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-about-text">
                <div className="section-title">
                  {/* <span className="abo-pol">About Politian</span> */}
                  <h2 className="can-wo">Entry into Politics</h2>
                </div>
                <p className="lead">
                  Udhayanidhi’s entry into politics was almost inevitable, given his family's legacy. His political career began in earnest after M.K. Stalin became the DMK’s president following Karunanidhi's passing in 2018.
                </p>
                <p className="lead">
                  <span style={{ fontWeight: 'bold' }}>2019 Lok Sabha Elections:</span> Udhayanidhi played a crucial role in the DMK's campaign, especially in connecting with the youth, and helped secure a resounding victory for the DMK and its alliance in Tamil Nadu.
                </p>
                <p className="lead">
                  <span style={{ fontWeight: 'bold' }}>Rising Role in the Party:</span> In 2020, Udhayanidhi was appointed as the DMK's Secretary of the Youth Wing, a significant position once held by his father, M.K. Stalin. Under his leadership, the youth wing saw increased activity, and he positioned himself as a dynamic leader for the next generation.
                </p>
                {/* <div className="quote">
                  <p className="world">“We can start by taking small steps and making small changes that can have a big impact on the world.”</p>
                </div> */}
                {/* <div className="wpo-about-left-info">
                  <div className="wpo-about-left-inner">
                    <div className="wpo-about-left-text">
                      <h5 className="name-abt">Robert Willum</h5>
                      <span className="name-sur">CEO &amp; Founder</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="wpo-about-section-s3 mt-5">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-about-text">
                <div className="section-title">
                  {/* <span className="abo-pol">About Politian</span> */}
                  <h2 className="can-wo">2021 Tamil Nadu Assembly Elections</h2>
                </div>
                <p className="lead">
                  In the 2021 Tamil Nadu Legislative Assembly elections, Udhayanidhi contested from the Chepauk-Thiruvallikeni constituency, a DMK stronghold that his grandfather M. Karunanidhi once represented. He won with a large margin, securing his place in the Tamil Nadu Legislative Assembly. His victory signified his growing influence within the DMK and the state's political landscape.
                </p>
                <p className="lead">
                  Udhayanidhi Stalin's fierce campaign across Tamil Nadu has helped the DMK party secure a major win in the assembly elections. His AIIMS Brick remark changed the course of the Tamil Nadu election campaign.
                </p>
                {/* <p className="lead">
                  Udhayanidhi Stalin contested and won in the Chepauk – Thiruvallikeni Assembly Constituency in the 2021 Tamil Nadu Legislative Assembly election.
                </p> */}
                {/* <div className="quote">
                  <p className="world">“We can start by taking small steps and making small changes that can have a big impact on the world.”</p>
                </div> */}
                {/* <div className="wpo-about-left-info">
                  <div className="wpo-about-left-inner">
                    <div className="wpo-about-left-text">
                      <h5 className="name-abt">Robert Willum</h5>
                      <span className="name-sur">CEO &amp; Founder</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-abouted-img">
                <img src="/images/theme1/stalin-spech.png" alt="About Politician" className="abted" />
              </div>

            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="wpo-about-section-s3 mt-5">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-abouted-img">
                <img src="/images/theme1/Untitled-stalin.png" alt="About Politician" className="abted" />
              </div>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="wpo-about-text">
                <div className="section-title">
                  {/* <span className="abo-pol">About Politian</span> */}
                  <h2 className="can-wo">Ministerial Role</h2>
                </div>
                <p className="lead">
                  In December 2022, Udhayanidhi Stalin was appointed as the Minister for Youth Welfare and Sports Development in the Tamil Nadu government led by his father. His appointment came with both praise and criticism, as some viewed it as dynastic politics, while others saw it as a natural progression given his active role in the party.
                </p>
                {/* <p className="lead">
                  2019 Lok Sabha Elections: Udhayanidhi played a crucial role in the DMK's campaign, especially in connecting with the youth, and helped secure a resounding victory for the DMK and its alliance in Tamil Nadu.
                </p>
                <p className="lead">
                  Rising Role in the Party: In 2020, Udhayanidhi was appointed as the DMK's Secretary of the Youth Wing, a significant position once held by his father, M.K. Stalin. Under his leadership, the youth wing saw increased activity, and he positioned himself as a dynamic leader for the next generation.
                </p> */}
                {/* <div className="quote">
                  <p className="world">“We can start by taking small steps and making small changes that can have a big impact on the world.”</p>
                </div> */}
                {/* <div className="wpo-about-left-info">
                  <div className="wpo-about-left-inner">
                    <div className="wpo-about-left-text">
                      <h5 className="name-abt">Robert Willum</h5>
                      <span className="name-sur">CEO &amp; Founder</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="wpo-team-section mt-5">
        <div className='donor-sect'>
          <Grid container>
            <Grid item xs={12}>
              <div className="wpo-section-title">
                {/* <span className='ot'>TEAM MEMBERS</span> */}
                <h2 className='met'>Achievements</h2>
              </div>
            </Grid>
          </Grid>
          <div className="wpo-team-wrap">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/stalin-serv.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    {/* <h2>
                      Youth Welfare and Empowerment
                    </h2> */}
                    <span>Political Rise: From Youth Wing to Minister of Youth Welfare and Sports Development</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/ac6.png"
                        alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    {/* <h2>

                      Education and Awareness

                    </h2> */}
                    <span>Focused on improving rural infrastructure and essential services to boost local economies</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/ac7.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    {/* <h2>

                      Sports Development

                    </h2> */}
                    <span>Electoral Success: Winning Tamil Nadu Assembly Seat in 2021</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/ac5.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    {/* <h2>

                      Social Justice and Equality

                    </h2> */}
                    <span>Sports Initiatives: Enhancing Tamil Nadu’s Youth Welfare Programs</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <div className="wpo-section-title">
        {/* <span className='ot'>TEAM MEMBERS</span> */}
        <h2 className='met'>Schemes</h2>
      </div>
      <div className="donor-sect mt-5">
        <Swiper
          slidesPerView={screenSize}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="my-Swiper"
          breakpoints={{
            320: { slidesPerView: 1 },
            576: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
        >
          {cardItems &&
            cardItems.map((a, i) => (
              <SwiperSlide key={i}>



                <div className="funding_details1">
                  <img
                    src={a.cardimg}
                    alt={a.cardTitle}
                    className="card-image mt-4"
                  />
                  <h2 className="cardtitle mt-4">{a.cardTitle}</h2>
                  <p className="carddetail mt-4">{a.cardDetails}</p>
                </div>


              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div >
  )
}

export default Theme1aboutus
