import React from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import { Container, Grid } from '@mui/material'
import { FaRegUser } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md"
import { FaRegCommentAlt } from "react-icons/fa";

const Theme3readmore = () => {
    return (
        <>
            <div className='bg-colors'>
                <Theme3navbar />
            </div>
            <div>
                <Container maxWidth="md">
                    <Grid container>
                        <Grid item sm={12} md={12} lg={12}>
                            <div className='rm-main-tit-t3 mt-5'>
                                <h1>Accusamus et iusto odio dignissimos</h1>
                            </div>
                            <div className='author-t3-readm d-flex mt-4'>
                                <div >
                                    <span className='mr-3'><FaRegUser className='rm-t3-icn' /></span>
                                    <span>Admin</span>
                                </div>
                                <div className='mx-3'>
                                    <span className='mr-3'><MdOutlineCalendarMonth className='rm-t3-icn' /></span>
                                    <span>July 10, 2020</span>
                                </div>
                                <div>
                                    <span className='mr-3'><FaRegCommentAlt className='rm-t3-icn' /></span>
                                    <span>No Comments</span>
                                </div>
                            </div>
                            <div className='rm-more-pars mt-5'>
                                <p>Rutenim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</p>
                                <p>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.</p>
                                <p>Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.</p>
                                <p>Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien.</p>
                            </div>
                            <div className='rm-main-tit-t3 mt-5'>
                                <h1>Ordered List</h1>
                                <ol>
                                    <li>qui officia deserunt mollit anim id est laborum.</li>
                                    <li>Proident sunt in culpa qui officia.</li>
                                    <li>Officia deserunt mollit anim.</li>
                                </ol>
                            </div>
                            <div className='rm-main-tit-t3 mt-5'>
                                <h1>Unordered List</h1>
                                <ul>
                                    <li>qui officia deserunt mollit anim id est laborum.</li>
                                    <li>Proident sunt in culpa qui officia.</li>
                                    <li>Officia deserunt mollit anim.</li>
                                </ul>
                            </div>
                            <div className='rm-more-pars mt-5'>
                                <p> Tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.</p>
                                <p>Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Theme3readmore