import React, { createContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import apiService from '../api/apiService';

const DataContext = createContext({})
export const DataProvider = ({ children }) => {
    const navigator = useNavigate()
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    // password icon code
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    //  MODAL CSS Code
    const modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };
    // pagination code
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // email validation
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    // phone number validaion
    const validatePhoneNumber = (value) => {
        if (value.length !== 10) {
            return 'Phone number must be 10 digits';
        }
        const matches = value.match(
            /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
        );
        if (matches === null) {
            return "Only numbers allowed";
        }
    };
    // number validation 
    const validateNumberonly = (value) => {
        if (value > 0) {
            var data = value.toString()
            const matches = data.match(
                /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
            );
            if (matches === null) {
                return "Only numbers allowed";
            }
        } else {
            return "Min Value 1 is required";
        }

    };


    // this project common code 
    // navbar details code
    const [navbarDetails, setNavbarDetails] = useState({})
    const navbarDetailsApi = () => {
        var navDet = JSON.parse(sessionStorage.getItem('_pre_info'))
        if (navDet) {
            setNavbarDetails(navDet)
            if (navDet.imageName) {
                var spl = navDet.imageName
                const favicon = document.getElementById("favicon");
                favicon.setAttribute("href", spl);
            } else {
                const favicon = document.getElementById("favicon");
                favicon.setAttribute("href", 'assets/images/favicon.png');
            }
            console.log(navbarDetails)

        }
    }
    // homepage details code
    const homePageURL = process.env.REACT_APP_HOME_GET_URL
    const [homeSection, setHomeSection] = useState([])
    const [bannerData, setBannerData] = useState([])
    const [aboutData, setAboutData] = useState({})
    const [testimonialData, setTestimonialData] = useState({})
    const [videoLinkData, setVideoLinkData] = useState({})
    const [footerData, setFooterData] = useState({})
    const [serviceData, setServiceData] = useState({})
    const [sectionData, setSectionData] = useState([])
    const homePageGetApi = () => {
        var siteId = sessionStorage.getItem('siteId')
        apiService(`${homePageURL}?siteid=${siteId}`, '', 'unauthget').then((result) => {
            if (result && result.data) {
                if (result && result.data && result.data.json && result.data.json.sections) {
                    setHomeSection(result.data.json.sections)
                    debugger
                    var footer = result.data.json.sections.filter((list) => list.id === 5)
                    sessionStorage.setItem('_footer_info',JSON.stringify(footer[0]))
                }
                if (result.data.json.sections) {
                    var data = result.data.json.sections
                    if (data.length > 0) {
                        setSectionData(data)
                        data.forEach(element => {
                            if (element.id === 1) {
                                element.images.forEach(value => {
                                    if (value.imageType === 'Banner' && value.imageUrl) {
                                        element.bannerImageURL = value.imageUrl
                                        element.bannerImageName = value.imageName
                                        // element.bannerImage = value.imageName
    
                                    } if (value.imageType === 'Logo' && value.imageUrl) {
                                        element.logoURL = value.imageUrl
                                        // element.logo = value.imageName
                                        element.logoName = value.imageName
    
                                    }
                                });
                                setBannerData(element)
                                
                            } if (element.id === 2) {
                                if(element.images){
                                    element.images.forEach(value => {
                                        if (value.imageType === 'AboutBanner' && value.imageUrl) {
                                            element.aboutImageURL = value.imageUrl
                                            element.aboutImageName = value.imageName
                                        }
                                    });
                                   
                                }
                                setAboutData(element)
                                debugger
                            } if (element.id === 3) {
                                element.images.forEach((value, index) => {
                                    if (value.imageType === 'testimoialImage' && value.imageUrl) {
                                        element.testimonials[index].imagesURL = value.imageUrl
                                        element.testimonials[index].imagesName = value.imageName
                                    }
                                });
                                setTestimonialData(element)
                                debugger
                            } if (element.id === 4) {
                                setVideoLinkData(element)
                            }
                            if (element.id === 5) {
                                setFooterData(element)
                            }
                            if (element.id === 6) {
                                element.images.forEach((value, index) => {
                                    if (value.imageType === 'serviceImage' && value.imageUrl) {
                                        element.services[index].imagesURL = value.imageUrl
                                        element.services[index].imagesName = value.imageName
                                    }
                                });
                                setServiceData(element)
                            }
                        });
                    }
    
    
    
                }
            }
        })
    }
    // banner details Code
    const bannerListURL = process.env.REACT_APP_BANNER_LIST
    const [bannerList, setBannerList] = useState([])
    const bannerListApi = () => {
        var req = {
            "showbannerimage": "YES"
        }
        apiService(bannerListURL, req, 'unauthpost')
            .then((result) => {
                if (result.data.bannerModelList) {
                    sessionStorage.setItem('banner_list', JSON.stringify(result.data.bannerModelList))
                    setBannerList(result.data.bannerModelList)
                }

            }).catch((err) => {

            });
    }
    // Product details Code
    let urlName = sessionStorage.getItem('urlName')
    const productListURL = process.env.REACT_APP_PRODUCT_LIST
    const productGetURL = process.env.REACT_APP_PRODUCT_GET
    const [productListData, setProductListData] = useState([])
    const productListApi = () => {
        var req = {
            "listSize": 10,
            "pageNumber": 1,
            "showProductImage": 1,
            "searchString": "",
            "show": "SALEAVAILABLE"
        }
        apiService(productListURL, req, 'unauthpost')
            .then((result) => {
                console.log(result.data)
                setProductListData(result.data.responseModelList)
            }).catch((err) => {

            });
    }
    const handleProdDetailsPage = (id) => {
        navigator(`/${urlName}/shop/${btoa(id)}`)
    }
    const [productDetails, setProductDetails] = useState({})
    const [productImage, setProductImage] = useState([])
    const [variantImage, setVariantImage] = useState("");
    const [ratingValue, setRatingValue] = useState()
    const productGetApi = (id) => {
        apiService(productGetURL + id, '', 'unauthget')
            .then((result) => {
                setProductDetails(result.data)
                setRatingValue(result.data.avgRating)
                // 
                if (result.data.variantImages) {
                    setProductImage(result.data.variantImages)
                    result.data.variantImages.forEach(element => {
                        if (element.defaultImage === 1) {
                            setVariantImage(element.imageURL)
                        }
                    });
                }
            }).catch((err) => {

            });
    }
    const getErrorStatus = (value) => {
        if (value === 1000) {
            return {
                type: 'info',
                message: 'Invalid UserName or Password'
            }
        } else if (value === 1001) {
            return {
                type: 'info',
                message: 'Security Breach'
            }
        } else if (value === 1002) {
            return {
                type: 'info',
                message: 'Your Account is Locked, Please user forgot password to update password'
            }
        } else if (value === 1003) {
            return {
                type: 'info',
                message: 'Data Not Found'
            }
        } else if (value === 1004) {
            return {
                type: 'info',
                message: 'Invalid Email Template'
            }
        } else if (value === 1005) {
            return {
                type: 'info',
                message: 'Invalid Email SMTP Credentials'
            }
        } else if (value === 1006) {
            return {
                type: 'info',
                message: 'Invalid SMS Template'
            }
        } else if (value === 1007) {
            return {
                type: 'info',
                message: 'Invalid SMS Credentials'
            }
        } else if (value === 1008) {
            return {
                type: 'info',
                message: 'Invalid OTP'
            }
        } else if (value === 1009) {
            return {
                type: 'info',
                message: 'OTP Expired'
            }
        } else if (value === 1010) {
            return {
                type: 'info',
                message: 'Oops ! The Mobile Number is not registered with us.'
            }
        } else if (value === 1011) {
            return {
                type: 'info',
                message: 'Sorry ! Seems there is more traffic on the site, please try again in some time.'
            }
        } else if (value === 1012) {
            return {
                type: 'info',
                message: 'Seems the OTP is already used for login, please start the journey again'
            }
        } else if (value === 1013) {
            return {
                type: 'info',
                message: 'Template Not Found'
            }
        } else if (value === 1014) {
            return {
                type: 'info',
                message: 'Unable to Identify the type of login'
            }
        } else if (value === 1015) {
            return {
                type: 'info',
                message: 'Sorry ! Unable to reach google server at the moment, please login using OTP'
            }
        } else if (value === 1016) {
            return {
                type: 'info',
                message: 'Mobile Number not present in the google account'
            }
        } else if (value === 1017) {
            return {
                type: 'info',
                message: 'An Account with this mobile number already exists, please login using OTP'
            }
        } else if (value === 1018) {
            return {
                type: 'info',
                message: 'Invalid User ID'
            }
        } else if (value === 1019) {
            return {
                type: 'info',
                message: 'Invalid URL'
            }
        } else if (value === 1020) {
            return {
                type: 'info',
                message: 'Invalid JSON'
            }
        } else if (value === 1021) {
            return {
                type: 'info',
                message: 'Page Not Found'
            }
        } else if (value === 1022) {
            return {
                type: 'info',
                message: 'Invalid SiteID'
            }
        } else if (value === 1023) {
            return {
                type: 'info',
                message: 'Unable to upload the image, please try again later'
            }
        } else if (value === 1024) {
            return {
                type: 'info',
                message: 'Invalid Mode'
            }
        } else if (value === 1025) {
            return {
                type: 'info',
                message: 'Invalid Type'
            }
        } else if (value === 1026) {
            return {
                type: 'info',
                message: 'Invalid Recipient'
            }
        } else if (value === 1027) {
            return {
                type: 'info',
                message: 'Notification Template Empty'
            }
        } else if (value === 1028) {
            return {
                type: 'info',
                message: 'Business Name Already Exisits'
            }
        } else {
            return {
                type: 'danger',
                message: 'Please try again later'
            }
        }
    }
    return (
        <>
            <DataContext.Provider value={{
                validateEmail, validatePhoneNumber, validateNumberonly, pageLoading, setPageLoading, readyOnlyValue, setReadyOnlyValue, handleChangePage,
                handleChangeRowsPerPage, showPassword, handleClickShowPassword, navigator, modalstyle, page, rowsPerPage,
                // this product code 
                urlName, productListApi, productListData, handleProdDetailsPage, productGetApi, productDetails, ratingValue, productImage, variantImage, setVariantImage,
                bannerList, setBannerList, bannerListApi,
                navbarDetailsApi, navbarDetails, getErrorStatus,
                // homepage code 
                homePageGetApi, homeSection,bannerData,aboutData,serviceData,footerData,testimonialData,videoLinkData
            }}>
                {children}

            </DataContext.Provider>

        </>
    )
}
export default DataContext;