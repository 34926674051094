import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { MdKeyboardArrowDown } from "react-icons/md";

const Theme5navbar = () => {
  let urlName = sessionStorage.getItem('urlName')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // let urlName = sessionStorage.getItem('urlName')
  const [activeLink, setActiveLink] = useState('/home'); // Default to home

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  return (
    <div>
      {/* <nav class="navbar navbar-expand-lg navbar-light bg-transparent mt-4" style={{ position: 'relative', zIndex: 2 }}>
        <Link className="navbar-brand nav-log-res1 mt-0" to="/">
          <img src="/images/theme5/logo_theme5.png" alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav-t5 ml-5">
            <li className="nav-item">
              <NavLink className="nav-link-t5" to={`/${urlName}/home`} activeClassName="active">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link-t5" to={`/${urlName}/about`} activeClassName="active">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link-t5" to={`/${urlName}/shop`} activeClassName="active">Shop</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link-t5" to={`/${urlName}/blog`} activeClassName="active">Blog</NavLink>
            </li>
            <li className="nav-item">
              <button
                aria-controls="nav-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="nav-link-t5"
                style={{ background: 'none', border: 'none', color: '#fff' }}
              >
                Help Center <MdKeyboardArrowDown />
              </button>
              <Menu
                id="nav-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <NavLink to={`/${urlName}/contactus`}>Contact Us</NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to={`/${urlName}/shippinginfo`}>Shipping Information</NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to={`/${urlName}/termsandconditions`}>Terms & Conditions</NavLink>
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </div>
      </nav> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent pt-5">
        <Link className="navbar-brand nav-log-res1 mt-0 ml-3" to="/">
          <img src="/images/theme5/logo_theme5.png" alt="Logo" style={{
            border: '1px solid lightgray',
            padding: '10px',
            borderRadius: '36px'
          }} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <img src={'/images/theme5/app.png'} alt="menu"/>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: 'center' }}>
          <div className='border-t5-des'>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link-t3" to={`/${urlName}/home`} activeClassName="active">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link-t3" to={`/${urlName}/about`} activeClassName="active">About</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link-t3" to={`/${urlName}/shop`} activeClassName="active">Shop</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link-t3" to={`/${urlName}/blog`} activeClassName="active">Blog</NavLink>
              </li>
              <li className="nav-item">
                <button
                  aria-controls="nav-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className="nav-link-t5"
                  style={{ background: 'none', border: 'none', color: '#fff' }}
                >
                  Help Center <MdKeyboardArrowDown />
                </button>
                <Menu
                  id="nav-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <NavLink to={`/${urlName}/contactus`}>Contact Us</NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink to={`/${urlName}/shippinginfo`}>Shipping Information</NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink to={`/${urlName}/termsandconditions`}>Terms & Conditions</NavLink>
                  </MenuItem>
                </Menu>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Theme5navbar