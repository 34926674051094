import React from 'react'

const YouTubeEmbed = ({ videoUrl }) => {
 // Extract the video ID from the URL
 const videoId = videoUrl.split('v=')[1];
 const embedUrl = `https://www.youtube.com/embed/${videoId}`;

 return (
   <div>
     <iframe
      
       src={embedUrl}
       title="YouTube video player"
       frameBorder="0"
       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
       allowFullScreen
    className='set-iframe'
     ></iframe>
   </div>
 );
};

export default YouTubeEmbed