import React, { useState } from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import { Box, Container, Grid, Tab, Tabs } from '@mui/material'
import Theme3logobanner from '../logos-banner/Theme3logobanner';
import { TabContext, TabPanel } from '@mui/lab';

const Theme3press = () => {
    const videoData = [
        {
            src: "https://www.youtube.com/embed/c5a-XExLeEs?si=cKuFW3oVJsf5Kq73",
            description: "Viduthalai Part 1 Audio Launch - Soori Speech | Vetri Maaran | Ilaiyaraaja | Vijay Sethupathi.",

        },
        {
            src: "https://www.youtube.com/embed/v2qCclP92tc?si=--uTA5Wa8OW6k2VY",
            description: "நடிகர் சூரி செய்தியாளர் சந்திப்பு | Actor Soori | Press Meet.",

        },
        {
            src: "https://www.youtube.com/embed/v2qCclP92tc?si=pl8rCQ_W3yTH7xoF",
            description: "நடிகர் சூரி செய்தியாளர் சந்திப்பு | Soori | Madurai | Press Meet.",
        },
        {
            src: "https://www.youtube.com/embed/wr3OtWUvr-w?si=JQIPPKlAOAdb5ly6",
            description: "அவன் Hero தான்டா-னு சண்ட போடுவாரு - Garudan Soori Fans Meet | Sivakarthikeyan , Keerthisuresh.",
        },
    ];
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className='bg-colors'>
                <Theme3navbar />
                <div className='abt-page-t3 text-center'>
                    <h1>Media</h1>
                </div>
            </div>
            <div className="tab-t3-medias">
                {/* <TabContext value={value} className="tab-div">
                    <div className="name-tab-t3 text-center">
                        <Box className="tabs-boxs">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                style={{
                                    borderRadius: '50px',
                                    borderBottom: 'none',
                                    padding: '10px',
                                    '& .MuiTabs-indicator': {
                                        display: 'none',
                                    },
                                }}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#D97D54"
                                    }
                                }}
                                className="custom-tabs"
                            >
                                <Tab
                                    label="Shorts"
                                    value="1"
                                    style={{
                                        background: value === "1" ? '#555' : '',
                                        color: value === "1" ? 'white' : 'black',
                                        borderRadius: '7px',
                                    }}
                                    className="setFont"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                />
                                <Tab
                                    label="Interviews"
                                    value="2"
                                    style={{
                                        background: value === "2" ? '#555' : '',
                                        color: value === "2" ? 'white' : 'black',
                                        borderRadius: '7px',
                                    }}
                                    className="setFont"
                                />
                            </Tabs>
                        </Box>
                    </div>
                    <TabPanel value="1">
                    </TabPanel>
                    <TabPanel value="2">
                        <Container>
                            <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                                {videoData.map((video, index) => (
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                                        <div>
                                            <iframe
                                                width="200"
                                                height="200"
                                                frameborder="0"
                                                src={video.src}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                            <div>
                                                <p className='mb-0'>{video.description}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </TabPanel>
                </TabContext> */}
            </div>
            <Container className='mt-5'>
                <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                            <iframe width="300" height="600" src="https://www.youtube.com/embed/M3gd1uGn3TY?si=16Hq93kc6cni9Uie" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </Grid> */}
                       <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                                {videoData.map((video, index) => (
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                                        <div>
                                            <iframe
                                                width="200"
                                                height="200"
                                                frameborder="0"
                                                src={video.src}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                            <div>
                                                <p className='mb-0'>{video.description}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    
                                ))}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <div>
                                     <iframe width="400" height="600" src="https://www.youtube.com/embed/M3gd1uGn3TY?si=16Hq93kc6cni9Uie" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                 </div>
                                    </Grid>
                            </Grid>
                </Grid>
                <div className='mt-5'>

                </div>
            </Container>
            {/* <div className='mt-5'>
                <Theme3logobanner />
            </div> */}
        </>
    )
}

export default Theme3press