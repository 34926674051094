/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Autoplay } from 'swiper/modules';

const Theme5gallery = () => {
    const [screenSize, setScreenSize] = useState(5);
    useEffect(() => {
        size();
    }, []);

    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(4);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(4);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(2);
        } else {
            setScreenSize(1);
        }
    };
    return (

        <div className='products-t5-back-prod-ppp '>
            <div className='text-center'>
                <span className='chip-abt'>Gadget Gallery</span>
                <h1 className='mt-3'>Unveiling Our Mobile Marvels!</h1>
            </div>
            <div className="swiper-container-t5 mt-5">
                <Swiper
                    slidesPerView={screenSize}
                    spaceBetween={30}
                    freeMode={true}
                    navigation
                    loop
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Navigation, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={'/images/theme5/swiper-img1.jpg'} alt="swipe-img" className='swip-imag ' />
                        <h5 className='text-center mt-2'>5X Telephoto</h5>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={'/images/theme5/swiper-img1.jpg'} alt="swipe-img" className='swip-imag ' />
                        <h5 className='text-center mt-2'>5X Telephoto</h5>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={'/images/theme5/swiper-img1.jpg'} alt="swipe-img" className='swip-imag ' />
                        <h5 className='text-center mt-2'>5X Telephoto</h5>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={'/images/theme5/swiper-img1.jpg'} alt="swipe-img" className='swip-imag ' />
                        <h5 className='text-center mt-2'>5X Telephoto</h5>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={'/images/theme5/swiper-img1.jpg'} alt="swipe-img" className='swip-imag ' />
                        <h5 className='text-center mt-2'>5X Telephoto</h5>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Theme5gallery