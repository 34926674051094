import React from 'react';
import { Grid, Typography, Box, Link, IconButton } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Theme7footer = () => {
  return (
    <div className='footer-cyber'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <div>
            <img src="/images/theme7/logo.jpg" alt="Logo" style={{ marginBottom: '20px' }} />
            <p className='foot-title'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae risus nec dui venenatis dignissim.
            </p>
            <div sx={{ display: 'flex', gap: '10px', marginTop: '16px' }}>
            <div>
                <IconButton href="#" color="primary"><Facebook /></IconButton>
                <IconButton href="#" color="primary"><Twitter /></IconButton>
                <IconButton href="#" color="primary"><Instagram /></IconButton>
                <IconButton href="#" color="primary"><LinkedIn /></IconButton>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <div>
            <h4 className='single_footer'>About Company</h4>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li className='foot-lidt'><Link href="/about-2/" sx={{ color: '#fff' }} className='foot-2'>About Us</Link></li>
              <li className='foot-lidt'><Link href="/cybal/blog/" sx={{ color: '#fff' }} className='foot-2'>Blog & News</Link></li>
              <li className='foot-lidt'><Link href="/cybal/projects/" sx={{ color: '#fff' }} className='foot-2'>Our Projects</Link></li>
              <li className='foot-lidt'><Link href="/pricing-plan/" sx={{ color: '#fff' }} className='foot-2'>Pricing Plan</Link></li>
              <li className='foot-lidt'><Link href="/cybal/faq/" sx={{ color: '#fff' }} className='foot-2'>Asked Questions</Link></li>
              <li className='foot-lidt'><Link href="/cybal/contact/" sx={{ color: '#fff' }} className='foot-2'>Contact Us</Link></li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <div>
            <h4 className='single_footer'>Our Services</h4>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Malware Protection</Link></li>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Computer Security</Link></li>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Server Protection</Link></li>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Strong Authentication</Link></li>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Firewall Implementation</Link></li>
              <li className='foot-lidt'><Link href="#" sx={{ color: '#fff' }} className='foot-2'>Security Monitoring</Link></li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <div>
            <h4 className='single_footer'>Download App</h4>
            <p className='foot-title'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae risus nec dui venenatis.
            </p>
            <div>
              <Link href="#"><img src="/images/theme7/play.jpg" alt="Google Play" style={{ marginRight: '10px', border: '1px solid #373e51', marginBottom: '20px' }} /></Link>
              <Link href="#"><img src="/images/theme7/app.jpg" alt="App Store" style={{ marginRight: '10px', border: '1px solid #373e51' }} /></Link>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className='foot-last'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div>
              <p >
                Copyright © 2024 cybal All Rights Reserved.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
              <Link href="#" sx={{ color: '#fff', marginRight: '16px', textDecoration: 'none' }} className='last-fo'>Terms of use</Link>
              <Link href="#" sx={{ color: '#fff', marginRight: '16px', textDecoration: 'none' }} className='last-fo'>Privacy Policy</Link>
              <Link href="#" sx={{ color: '#fff', textDecoration: 'none' }} className='last-fo'>Cookie Policy</Link>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Theme7footer;
