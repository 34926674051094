import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md";
import DataContext from '../../../../context/DataContext';

const Theme3onpress = () => {
  const {navigator} = useContext(DataContext)
  const redirect=()=>{
    navigator('/:name/onpress')
  }
  return (
    <div className='onpress-t3 mt-5'>
      <div className='whole-video-div-t3'>
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <div className='our-sched-titl '>
              <h2>Our Press</h2>
              <p className='text-left'>Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <div className='video-sec-t3'>
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    <div className="videos-t3-onp">
                      <div className='imag-posi-t3'>
                        <iframe width="300" height="150" src="https://www.youtube.com/embed/c5a-XExLeEs?si=cKuFW3oVJsf5Kq73" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </div>
                      {/* <div className='ml-4 text-left'>
                        <p>Laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <p style={{ color: "#b6b6b6" }}>24 August 2020</p>
                      </div> */}
                    </div>
                  </li>
                  <hr />
                  <li>
                    <div className="videos-t3-onp">
                      <div>
                        <iframe width="300" height="150" src="https://www.youtube.com/embed/v2qCclP92tc?si=--uTA5Wa8OW6k2VY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </div>
                      {/* <div className='ml-4 text-left'>
                        <p>Laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <p style={{ color: "#b6b6b6" }}>24 August 2020</p>
                      </div> */}
                    </div>
                  </li>
                  <hr />
                  <li>
                    <div className="videos-t3-onp">
                      <div>
                        <iframe width="300" height="150" src="https://www.youtube.com/embed/wr3OtWUvr-w?si=JQIPPKlAOAdb5ly6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </div>
                      {/* <div className='ml-4 text-left'>
                        <p>Laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <p style={{ color: "#b6b6b6" }}>24 August 2020</p>
                      </div> */}
                    </div>
                  </li>
                </ul>
                <div className='text-center mt-5 '>
          <p className='c-pointer'style={{color:'#cb995a'}} onClick={()=>redirect()}>Explore More<MdKeyboardArrowRight style={{ fontSize: '24px', fontWeight: '600' }} /></p>
        </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <div className='our-press-pos-r'>
            <div className='mt-2'>
              <img src="/images/theme3/Tri-home-1.png" alt="tri" className='tri-bel-press' />
            </div>
            <div>
              <iframe width="400" height="400" src="https://www.youtube.com/embed/M3gd1uGn3TY?si=16Hq93kc6cni9Uie" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <img src="/images/theme3/Tri-home-2.png" alt="tri" className='tri-2-press'/>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Theme3onpress