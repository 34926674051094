import React, { useContext } from 'react'
import Theme5navbar from '../layouts/Theme5navbar'
import { Grid, Pagination } from '@mui/material'
import { Link } from 'react-router-dom'
import DataContext from '../../../context/DataContext'

const Theme5blog = () => {
    const { navigator } = useContext(DataContext)
    let urlName = sessionStorage.getItem('urlName')
    const blogimg = [
        {
            id: 1,
            img: "/images/theme5/blognew-1.jpg",
            title: "Exploring The Latest Innovations In Video Games",
            paras: "Rutrum quisque non tellus orci ac auctor augue. In nulla posuere sollicitudin aliquam ultrices sagittis. Vehicula ipsum a arcu...",
            date: "June 1, 2024"
        },
        {
            id: 2,
            img: "/images/theme5/blognew-2.jpg",
            title: "Smart Appliances That Will Revolutionize Your Kitchen",
            paras: "Facilisi etiam dignissim diam quis enim lobortis scelerisque. Nisi lacus sed viverra tellus in hac habitasse platea dictumst. Vitae...",
            date: "June 1, 2024"
        },
        {
            id: 3,
            img: "/images/theme5/blognew-3.jpg",
            title: "Exploring The Latest Innovations In Video Games",
            paras: "Rutrum quisque non tellus orci ac auctor augue. In nulla posuere sollicitudin aliquam ultrices sagittis. Vehicula ipsum a arcu...",
            date: "June 1, 2024"
        },
        {
            id: 4,
            img: "/images/theme5/blognew-4.jpg",
            title: "How To Extend The Battery Life Of Laptop",
            paras: "Dui faucibus in ornare quam viverra orci. Nulla pellentesque dignissim enim sit amet venenatis. Aliquam eleifend mi in nulla....",
            date: "June 1, 2024"
        },
    ]
    const handleblogClick = (val) => {
        navigator(`/${urlName}/blogdetails/${(val)}`)
        debugger
    }
    return (
        <div className='banner-t5-back'>
            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Blog</h1>
                        <p>Home &nbsp;/ &nbsp;Blog</p>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Grid container direction="row" alignItems="flex -start" justifyContent="flex-start" spacing={2}>
                    {blogimg && blogimg.map((a, i) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                            <div className="blog-items-testi-t5" data-aos="fade-right"  data-aos-duration="1000">
                                <div className='blog-imag-big'>
                                    <img src={a.img} alt="Blog" className='blog-t5-img' />
                                    <div className='blog-date-t5'>
                                        <span>{a.date}</span>
                                    </div>
                                </div>

                                <div className='blog-crd-contt3 mt-3 pb-3'>
                                    <h5 className='blog-crd-contt-tr'>{a.title}</h5>
                                    <span className='blog-crd-contt-pars mt-2 '>{a.paras}</span>
                                    <div className='abt-secur-right-sde mt-2'>
                                        <p className='t5-featue-links-blog' onClick={() => handleblogClick(a.id)}>Read More</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))}
                    
                </Grid>
                <div className='d-flex justify-content-center mt-5'>
                     <Pagination count={2} variant="outlined" shape="rounded" />
                     </div>
            </div>
        </div>
    )
}

export default Theme5blog