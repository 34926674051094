import { FormHelperText, TextField } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const Theme8footer = () => {
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
    { values: formData }
  );
  return (
    <>
      <footer class="text-lg-start bg-body-tertiary text-muted">
        <section class="footer-css">
          <div class="container text-md-start mt-5">
            <div class="row mt-3">
              <div class="col-md-3 col-lg-4 col-xl-3  mb-4">
                <h6 class=" fw-bold mx-auto mb-4">
                  <b style={{ color: 'white' }}>About Us</b>
                </h6>
                <p style={{ fontSize: '13px' }}>
                  Cnsectetur adipisicing elithesde esesd sed dsdio eiusmod tempor incididunt ut labore et
                  dolore magnauet ensdsim aedi minim veniamquis nostrud.
                </p>


                <div>
                  <a href="" className="icon-hover mr-4 text-reset">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="" className="icon-hover mr-4 text-reset">
                    <i className="fa-brands fa-pinterest-p"></i>
                  </a>
                  <a href="" className="icon-hover mr-4 text-reset">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                  <a href="" className="icon-hover mr-4 text-reset">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>


              </div>

              <div class="col-md-2 col-lg-2 col-xl-2  mb-4">
                <h6 class=" fw-bold mx-auto mb-4">
                  <b style={{ color: 'white' }}>Useful Links</b>
                </h6>
                <p>
                  <a href="#!" class="text-reset">Home</a>
                </p>
                <p>
                  <a href="#!" class="text-reset">About Us</a>
                </p>
                <p>
                  <a href="#!" class="text-reset">Request Call Back</a>
                </p>
                <p>
                  <a href="#!" class="text-reset">Testimonial</a>
                </p>
                <p>
                  <a href="#!" class="text-reset">Contact Us</a>
                </p>
              </div>

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class=" fw-bold mb-4">
                  <b style={{ color: 'white' }}>Latest Twitter</b>
                </h6>
                <div>
                  <p style={{ fontSize: '13px' }}>Electrical Tower & Wire Installation</p>
                  <p style={{ color: '#dd353c', fontSize: '13px' }}>May 31, 2024</p>
                </div>
                <div>
                  <p style={{ fontSize: '13px' }}>Electrical Tower & Wire Installation</p>
                  <p style={{ color: '#dd353c', fontSize: '13px' }}>May 31, 2024</p>
                </div>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 class=" fw-bold mb-4" style={{ color: 'white' }}>Subscribe Now</h6>
                <div className=' mb-3' style={{ width: '100%' }}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Email is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        placeholder="Enter Email Here"
                        fullWidth
                        type="text"
                        {...field}
                        InputProps={{
                          style: { backgroundColor: '#4d4d4d' },
                        }}
                        InputLabelProps={{
                          style: { color: '#adb7be' },
                        }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </div>
                <button className='sub-btn'>Subscribe Now</button>
                <p><i class="fas fa-location-dot mr-3" style={{ color: '#dd353c' }}></i>
                  247/B neles squre uearmerica Califonia</p>
              </div>
            </div>
          </div>
        </section>

        <div class="text-center p-3" style={{ backgroundColor: '#33333e', color: '#adb7be' }}>
          Copyright © 2021 Electrician. All Rights Reserved
        </div>
      </footer>
    </>
  )
}

export default Theme8footer