import { Checkbox, FormControlLabel, FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { SlCalender } from "react-icons/sl";


const Theme7blogmore = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };
    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Blog</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Blog</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='project-subpage'>
                <Grid container spacing={3} className='mt-4'>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="card-content">
                            <div>
                                <img src="/images/theme7/blog-10.jpg" alt='Programming & AI' className='img-fluid  client-proj ' />
                                <ul className="post-meta">

                                    <li>
                                        <i className="fa-regular fa-user"></i>
                                        <span>Written by:</span>
                                        <span className="user"> Admin</span>
                                    </li>

                                    <li>
                                        <i className="fa-solid fa-calendar-days"></i>
                                        <span className="date-left-dot">July 17, 2024</span>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-folder-open"></i>
                                        <span>Categories:</span>
                                        <span className="user">Cyber, Operation</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className='projecttite'>Digital solution for your business problem so that you can improve in business</h2>
                                <p className='analys-title'>The difference between short-form and long-form videos is simple: short-form videos are short, and long-form videos are long. To be more specific, short-form videos are typically under 10 minutes long, while long-form videos exceed that 10-minute mark. You’ll see a lot of short-form videos on social media. Target, for example, uses this video format on Instagram to advertise its products.</p>
                            </div>
                            <div>
                                <p className='analys-title'>You’ll typically see longer videos on a business’s website or YouTube. Video and podcast hosting provider, Wistia, uses long-form video to educate its audience about the cost of video production.</p>
                            </div>
                            <div className='project-images'>
                                <div>
                                    <img src='/images/theme7/blog_details2.jpg' alt='' className='moreim img-fluid' />
                                </div>
                            </div>
                            <div>
                                <h2 className='projecttite'>It includes brainstorming</h2>
                                <p className='analys-title'>Content is king in the digital world. Agencies produce high-quality content, including blog posts, videos, infographics, and more, to engage and educate the target audience. Content marketing builds trust and authority for the brand. Agencies manage and grow a brand’s presence on social media platforms such as Facebook, Twitter, LinkedIn, and Instagram.</p>
                            </div>
                            <div className="comment-respond">
                                <h3 className="comment-reply-title">Leave A Comment</h3>
                                <form className="comment-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                            <Controller
                                                name="name"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Name is required'
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        label="Name *"
                                                        placeholder="Enter Your Name"
                                                        fullWidth
                                                        type="text"
                                                        {...field}
                                                        InputLabelProps={{
                                                            style: { color: '#fff' },
                                                        }}
                                                        InputProps={{
                                                            style: { borderRadius: '28px', border: '1px solid #fff', color: '#fff' },
                                                        }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#fff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#fff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#fff',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.name && errors.name.message}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Email is required'
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Email *"
                                                    placeholder="E-mail"
                                                    fullWidth
                                                    type="email"
                                                    {...field}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' },
                                                    }}
                                                    InputProps={{
                                                        style: { borderRadius: '28px', border: '1px solid #fff', color: '#fff' },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.email && errors.email.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="subject"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Subject is required'
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Subject *"
                                                    placeholder="Enter Subject"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' },
                                                    }}
                                                    InputProps={{
                                                        style: { borderRadius: '28px', border: '1px solid #fff', color: '#fff' },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.subject && errors.subject.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="message"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Message is required'
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Message"
                                                    fullWidth
                                                    multiline
                                                    rows={10}
                                                    {...field}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' },
                                                    }}
                                                    InputProps={{
                                                        style: { borderRadius: '28px', border: '1px solid #fff', color: '#fff' },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#fff',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.message && errors.message.message}
                                        </FormHelperText>
                                    </Grid>
                                    <p className="comment-form-cookies-consent">
                                        <FormControlLabel
                                            control={<Checkbox
                                                sx={{
                                                    color: "#fff",
                                                    '&.Mui-checked': {
                                                        color: "#fff",
                                                    },
                                                }} />}
                                            label="Save my name, email, and website in this browser for the next time I comment."
                                            className='verify'
                                        />
                                        {errors.consent && <FormHelperText error>{errors.consent.message}</FormHelperText>}
                                    </p>
                                    <div className="comented">
                                        <button className="btn_one"
                                        >
                                            Submit Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="widget-search">
                            <h4 className="widget-headline">Search</h4>
                            <form className="search-form">
                                <div className="post-inside-wrapper">
                                    <div className="form-group">
                                        <input className="search-control" type="text" name="s" placeholder="Search here" />
                                        <input type="hidden" name="post_type" value="post" />
                                    </div>
                                    <button type="submit" className="search-btn">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="category">
                            <h4 className="widget-headline">Category</h4>
                            <ul>
                                <li>
                                    <a className="service-item-list" href="/">Cyber<span className="arrow-icon">(2)</span></a>
                                </li>
                                <li>
                                    <a className="service-item-list" href="/">Team<span className="arrow-icon">(4)</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="service-item-list" href="/">Solution<span className="arrow-icon">(3)</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="service-item-list" href="/">Operation<span className="arrow-icon">(3)</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="service-item-list" href="/">Security<span className="arrow-icon">(4)</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="popular-posts">
                            <h4 className="widget-headline">Recent Posts</h4>
                            <div className="theme-recent-post-wrap">
                                <div className="theme-recent-post-item">
                                    <div className="">
                                        <div>
                                            <img src="/images/theme7/blog-2.jpg" alt="" className='thumb img-fluid' />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <span><SlCalender className='clander' /></span>
                                        <span className="time">
                                            July 17, 2024</span>
                                        <h6 className='recent-post'>How to Improve your business so that you can stay in your local business.</h6>
                                    </div>
                                </div>
                                <div className="theme-recent-post-item">
                                    <div className="">
                                        <div >
                                            <img src="/images/theme7/blog-3.jpg" alt="" className='thumb img-fluid' />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <span><SlCalender className='clander' /></span>
                                        <span className="time">July 17, 2024</span>
                                        <h6 className='recent-post'>Population change anything what your need for your next generation.</h6>
                                    </div>
                                </div>
                                <div className="theme-recent-post-item">
                                    <div className="">
                                        <div >
                                            <img src="/images/theme7/blog-4.jpg" alt="" className='thumb img-fluid' />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <span><SlCalender className='clander' /></span>
                                        <span className="time">July 17, 2024</span>
                                        <h6 className='recent-post'>A significant shift in mindset is required to support either type of side project.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cybal-tags">
                            <h4 className="widget-headline">Tags</h4>
                            <div className="tag-cloud">
                                <a href="/">Security</a>
                                <a href="/">Operation</a>
                                <a href="/">Team</a>
                                <a href="/">Solution</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Theme7blogmore