import React from 'react';
import { Grid, Typography, Box, Link, Container } from '@mui/material';

const Theme3footer = () => {
  return (
    <div className='footer-theme3' >
      <div className='text-right mt-2'>
        <img src="/images/theme3/bel-2.png " alt="tri" className='tri-bel-12' />
      </div>
      <div className='whole-foot-theme3'>
        <Grid container direction="row" justifyContent="space-between" spacing={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <h4 className='foot-t3-titls'>Address</h4>
            <p>99 Lorem Ipsum<br />
            Lorem Ipsum<br />
            Lorem Ipsum<br /></p>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <h4 className='foot-t3-titls'>Links</h4>
            <ul>
              <li className='custom-list-t3 pb-3'>
                <Link to='/:name/home' className='a-t3-foot'>Home</Link>
              </li>
              <li className='custom-list-t3 pb-3'>
                <Link to='/:name/aboutus' className='a-t3-foot'>Biography</Link>
              </li>
              <li className='custom-list-t3 pb-3'>
                <Link to='' className='a-t3-foot'>Filmography</Link>
              </li>
              <li className='custom-list-t3 pb-3'>
                <Link to='' className='a-t3-foot'>Gallery</Link>
              </li>
              <li className='custom-list-t3 pb-3'>
                <Link to='' className='a-t3-foot'>Media</Link>
              </li>
              <li className='custom-list-t3 pb-3'>
                <Link to='' className='a-t3-foot'>News</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 className='foot-t3-titls'>Phone</h4>
            <h2 className='ph-th-3'><span className='mr-4'><img src={'/images/theme3/phn-t3.png'} alt="phn" /></span>+62 458 7976 2473</h2>
            <Typography>If you have a question, please contact at <Link href="mailto:info@comed.com" underline="hover">info@comed.com</Link></Typography>
          </Grid>
        </Grid>
      </div>

    </div>
  );
}

export default Theme3footer;
