import React from 'react'
import { Outlet } from 'react-router-dom'
import Theme1navbar from './Theme1navbar'
import Theme1footer from './Theme1footer'
const Theme1layout = () => {
    return (
        <div className='welcome'>
            <Theme1navbar />
            <Outlet></Outlet>
            <Theme1footer />           
        </div>
    )
}

export default Theme1layout