import React from 'react'
import Theme4navbar from './Theme4navbar'
import { Outlet } from 'react-router-dom'
import Theme4footer from './Theme4footer'

const Theme4layout = () => {
  return (
    <div>
      {/* <Theme4navbar /> */}
      <Outlet></Outlet>
      {/* <Theme4footer /> */}
    </div>
  )
}

export default Theme4layout
