import React from 'react'
import Theme6navbar from '../../../layouts/Theme6navbar'
import { Container } from 'react-bootstrap'
import { Grid } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
const Theme6aboutus = () => {
    const teamMembers = [
        {
            imgSrc: "/images/Theme6/team1.jpg",
            name: "Jenny Caprice",
            position: "Analyst",
            socialLinks: {
                facebook: "/",
                twitter: "/",
                instagram: "/"
            }
        },
        {
            imgSrc: "/images/Theme6/team2.jpg",
            name: "Tomhawak Rene",
            position: "Programmer",
            socialLinks: {
                facebook: "/",
                twitter: "/",
                instagram: "/"
            }
        },
        {
            imgSrc: "/images/Theme6/team3.jpg",
            name: "Tomhawak Rene",
            position: "Programmer",
            socialLinks: {
                facebook: "/",
                twitter: "/",
                instagram: "/"
            }
        },
        {
            imgSrc: "/images/Theme6/team4.jpg",
            name: "Jenny Caprice",
            position: "Analyst",
            socialLinks: {
                facebook: "/",
                twitter: "/",
                instagram: "/"
            }
        }
    ];
    return (
        <div>
            <div className='bg-t6-contact'>
                <Theme6navbar />

                <div className='Contact-content'>
                    <h1>About</h1>
                    <p>{`Home >> About`}</p>
                </div>
            </div>
            <Container className='mt-5'>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className='aboutus-img'>
                            <img src="/images/Theme6/about-person.png" alt="" />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className='about-content'>
                            <h4> QUALITY & SAFETY IS OUR CULTURE</h4>
                            <p className='py-4'>Occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas id est sed lacus volutpat lobortis. Lorem ipsum dolor sit amet.</p>
                            <h6><b>Danny Boyle</b> | Flim Maker</h6>
                            <div className='about-icon'>
                                <i class="fab fa-facebook"></i>
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-pinterest-square"></i>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className='about-signature'>
                <h3>IT'S IN FOREAL SIGNATURE</h3>
                <div className='text-center'>
                    <button className='btn-1'>Know More</button>
                    <button className='btn-1 ml-4'>View All Demo</button>
                </div>
            </div>
            {/* OUR TEAM */}
            <Container>
                <div className='our-team'>
                    <h2>our team</h2>
                </div>
                <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <p>Occaecati cupiditate non provident, similique sunt in culpa qui
                            officia deserunt mollitia animi, id est laborum et dolorum fuga.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <p>Occaecati cupiditate non provident, similique sunt in culpa qui
                            officia deserunt mollitia animi, id est laborum et dolorum fuga.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <p className='mt-3'>Qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Harum quidem rerum facilis est et expedita distinctio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Grid>
                </Grid>
            </Container>
            <div className='about-box'>
                <div className="wpo-team-wrap">
                    {/* <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <div className="wpo-team-img-box">
                                            <img src="/images/Theme6/team1.jpg" alt="William Taylor" className="img-responsive" />
                                            <ul className="social-icons">
                                                <li><a href="/" title="Follow William Taylor on Facebook"><FacebookIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Twitter"><TwitterIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Instagram"><InstagramIcon /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wpo-team-text">
                                        <h6>
                                            Jenny Caprice
                                        </h6>
                                        <span>Analyst</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <div className="wpo-team-img-box">
                                            <img src="/images/Theme6/team2.jpg"
                                                alt="Robert Williams" className="img-responsive" />
                                            <ul className="social-icons">
                                                <li><a href="/" title="Follow William Taylor on Facebook"><FacebookIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Twitter"><TwitterIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Instagram"><InstagramIcon /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wpo-team-text">
                                        <h6>
                                            Tomhawak Rene
                                        </h6>
                                        <span>Programmer</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <div className="wpo-team-img-box">
                                            <img src="/images/Theme6/team3.jpg" alt="William Taylor" className="img-responsive" />
                                            <ul className="social-icons">
                                                <li><a href="/" title="Follow William Taylor on Facebook"><FacebookIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Twitter"><TwitterIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Instagram"><InstagramIcon /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wpo-team-text">
                                        <h6>
                                            Tomhawak Rene
                                        </h6>
                                        <span>Programmer</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <div className="wpo-team-img-box">
                                            <img src="/images/Theme6/team4.jpg" alt="William Taylor" className="img-responsive" />
                                            <ul className="social-icons">
                                                <li><a href="/" title="Follow William Taylor on Facebook"><FacebookIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Twitter"><TwitterIcon /></a></li>
                                                <li><a href="/" title="Follow William Taylor on Instagram"><InstagramIcon /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wpo-team-text">
                                        <h6>
                                            Jenny Caprice
                                        </h6>
                                        <span>Analyst</span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container> */}
                    <Container>
                        <Grid container spacing={2}>
                            {teamMembers.map((member, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                                    <div className="wpo-team-item">
                                        <div className="wpo-team-img">
                                            <div className="wpo-team-img-box">
                                                <img
                                                    src={member.imgSrc}
                                                    alt={member.name}
                                                    className="img-responsive"
                                                />
                                                <ul className="social-icons">
                                                    <li>
                                                        <a href={member.socialLinks.facebook} title={`Follow ${member.name} on Facebook`}>
                                                            <FacebookIcon />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={member.socialLinks.twitter} title={`Follow ${member.name} on Twitter`}>
                                                            <TwitterIcon />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={member.socialLinks.instagram} title={`Follow ${member.name} on Instagram`}>
                                                            <InstagramIcon />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="wpo-team-text">
                                            <h6>{member.name}</h6>
                                            <span>{member.position}</span>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </div>
            </div>
            {/* CREATIVE  */}
            <div className="creative">
                <Container>
                    <div className='creative-content'>
                        <h2>WE ARE A TEAM WITH CREATIVE PEOPLE</h2>
                        <p>Occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Et harum quidem rerum facilis est et expedita distinctio. Maecenas id est sed lacus volutpat lobortis.</p>
                        <button>Ask us Anything</button>

                    </div>
                </Container>
            </div>
            <div className="percentage-img">
                <img src="/images/Theme6/presentation.png" alt="" className='img-fluid' />
            </div>

            <Container>

                <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className="percentage-left">
                            <h2>MADE FOR ALL DEVICES</h2>
                            <p className='mt-4'>Occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                mollitia animi, id est laborum et dolorum fuga. Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit.</p>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className='px-2'></Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className='px-2'>
                        <div className="percentage-right">
                            <h2>AWESOME FEATURES</h2>
                            <ul>
                                <li>Maecenas tincidunt ligula at condimentum gravida.</li>
                                <li>Cras ut ex euismod, placerat dui a, condimentum dolor.</li>
                                <li>Curabitur ac lorem ut orci finibus iaculis sed mollis arcu.</li>
                                <li>Vivamus accumsan eros nec massa sodales mattis.</li>
                                <li>In dapibus tortor id dolor pulvinar pretium.</li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Theme6aboutus
