import { Button, Container, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Box, styled } from '@mui/system';
import { Phone, Email, LocationOn } from '@mui/icons-material';

const Theme1contactus = () => {

    return (
        <div>
            <section className="contact-page-title">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="contact-breadcumb-wrap">
                                <h2 className='contec'>Contact</h2>
                                <ol className="contact-breadcumb-wrap">
                                    <li>
                                        <a href="/" title="Home" className='contact-breadcumb-wrap'>Home</a>
                                    </li>
                                    <li className="contact-breadcumb-wrap">Contact</li>
                                </ol>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Container>
                <Grid container spacing={4} justifyContent="center" sx={{ padding: '2rem' }}>
                    <Grid item xs={12} md={6}>
                        <div className=''>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Name" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Email" variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Subject" variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Message" variant="outlined" multiline rows={4} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            background: 'linear-gradient(90deg, #F57335, #EF3A3A)',
                                            color: '#fff',
                                            padding: '0.8rem',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Send Message
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className='contact-box'>
                            <h3 className='con-info'>
                                Address
                            </h3>
                            <p className='contact-log'>
                                <LocationOn sx={{ color: '#EF3A3A', marginRight: '0.5rem' }} />
                                Anna Arivalayam, No 367/369, Anna Salai, Teynampet, Chennai - 600018.
                            </p>
                            <p className='contact-log'>
                                <Phone sx={{ color: '#EF3A3A', marginRight: '0.5rem' }} />
                                + (91) - 044- 24348258
                            </p>
                            {/* <p className='contact-log'>
                                <Email sx={{ color: '#EF3A3A', marginRight: '0.5rem' }} />
                                info@example.com
                            </p> */}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Theme1contactus