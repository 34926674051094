/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Theme5navbar from '../../layouts/Theme5navbar'
import { Container, Grid } from '@mui/material'
import { Link } from 'react-router-dom';
import Theme5products from './Theme5products';
import Theme5gallery from './Theme5gallery';
import Theme5discoverproduct from './Theme5discoverproduct';
import Theme5testimonial from './Theme5testimonial';
import AOS from 'aos';
import 'aos/dist/aos.css';



const Theme5home = () => {
    const [screenSize, setScreenSize] = useState(5);
    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(4);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(4);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(4);
        } else {
            setScreenSize(1);
        }
    };
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        size();
    }, []);
    const [isPlaying, setIsPlaying] = useState(false);
    const handlePlay = () => {
        setIsPlaying(true);
        const video = document.getElementById('video-t5-div');
        if (!isPlaying) {
            video.play();
        } else {
            video.pause();
        }
    };
    const [email, setEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isChecked) {
            alert(`Form submitted with email: ${email}`);
        } else {
            alert("Please agree with the terms and conditions");
        }
    };

    return (
        <>
            <div className='banner-t5-back'>
                <div className='background-t5'>
                    <Theme5navbar />
                    <Container maxWidth="lg">
                    <div className='banner-content'>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                             <h1>4K HD Video Resistant</h1>
                             <h4>Wireless Charging | Water-Resistant</h4>
                             <button>Read More</button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                            <div className='bat-t5-rig-cont'>
                                            <img src={'/images/theme5/battery.png'} alt="Battery" className='batte-img-t5' />
                                        </div>
                            </Grid>
                        </Grid>
                    </div>
                    </Container>
                </div>
                <div className='mt-5'>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className='t5-abt-sec-left-cont ' data-aos="fade-left" data-aos-duration="1000" >
                                <div>
                                    <span className='chip-abt'>About the device</span>
                                    <h1 className='abt-head-t5 mt-4'>Our Most Recent Smartphone For the Future.Combining Stylish<span><img src={'/images/theme5/about-text-img-01.png'} alt="img" className='abt-t5-img' /></span> Mobile device. With The Most <span><img src={'/images/theme5/about-text-img-02.png'} alt="img" className='abt-t5-img' /></span>Powerful  Performance Possible.</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                       
                        </Grid>
                    </Grid>
                </div>
                <Container maxWidth="lg">
                    <div className='video-t5-div mt-5'>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div data-aos="zoom-in"  data-aos-duration="1000">
                                    <video id="video-t5-div" className='video_css banner-image' autoPlay loop onClick={handlePlay} style={{ width: '100%', borderRadius: '30px' }}>
                                        <source src='/images/theme5/video/Electrix+Video+Final+Style.mp4' type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
                <div className='t5-security-user mt-5'>
                    <Grid container direction="row" alignItems="flex-end" justifyContent="center" spacing={5}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='t5-abt-sec-left-cont'  data-aos="fade-right"  data-aos-duration="1000">
                                <img src={'/images/theme5/Bg-Stripes-Bottom.png'} alt="bg-img" className='img-btm' />
                                <div className='left-cont-paras-t3'>
                                    <span className='chip-abt'>tech Treasures</span>
                                    <h1 className='abt-secur-t5 mt-4'>Security Features</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='abt-secur-right-sde'>
                                <p>Non nisi est sit amet facilisis magna etiam tempor. Aliquam sem et tortor consequat.Dolor morbi neque aliquam non.</p>
                                <Link className='t5-featue-links'>View All Features</Link>
                            </div>
                        </Grid>
                    </Grid>
                    <div className='mt-5' data-aos="zoom-in"  data-aos-duration="1000">
                        <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className='features-t5-box'>
                                    <h5>Processor</h5>
                                    <h1>Brand-New k15</h1>
                                    <p>Quis risus sed vulputate odio ut enim blandit. Neque laoreet suspendisse interdum nisl.</p>
                                    <div className='sec-imag-t5'>
                                        <img src={'/images/theme5/Ai-sec-sec.png'} alt="Ai" className='img-fluid' />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className='features-right-t5-box'>
                                    <div className='bat-t5-whl-div'>
                                        <div className='bat-t5-lef-cont'>
                                            <h5>Battery Life </h5>
                                            <h1>Upto 31 hrs </h1>
                                            <p>Nunc pulvinar sapien et ligula ullamcorper malesuada proin libero. Arcu cursus vitae congue mauris rhoncus.</p>
                                        </div>
                                        <div className='bat-t5-rig-cont'>
                                            <img src={'/images/theme5/battery.png'} alt="Battery" className='batte-img-t5' />
                                        </div>
                                    </div>
                                </div>
                                <div className='features-right-t5-box1 mt-4'>
                                    <div className='bat-t5-whl-div'>
                                        <div className='bat-t5-rig-cont'>
                                            <img src={'/images/theme5/storage.png'} alt="Battery" className='batte-img-t5' />
                                        </div>
                                        <div className='bat-t5-lef-cont'>
                                            <h5>Data Storage</h5>
                                            <h1>Presently 512GB+</h1>
                                            <p>Mollis aliquam ut porttitor leo a. Vulputate odio ut enim blandit volutpat maecenas. Pellentesque nulla facilisi.</p>
                                        </div>

                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </div>
            <div className='prod-div-t5'>
                <Theme5products />
                <Theme5gallery />
                <Theme5discoverproduct />
                <div className='features-right-t5-box1-mail-t5-roc' data-aos="fade-up"  data-aos-duration="1000">
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} lg={4}>
                            <div className='stay-wired-div text-left' >
                                <span className='chip-abt'>STAY WIRED IN</span>
                                <h1 className='mt-3'>Get our most recent updates straight to your.</h1>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>

                            <div className='paper-rock-img-div text-center'>
                                <img src="/images/theme5/rocket-paper.png" alt="rocket" className='paper-rock-img' />
                                <div className='rocket-img-strip'>
                                    <img src="/images/theme5/blue_strip.png" alt="Back" className='rocket-img-strip-imag' />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <div className='email-t5-div text-center'>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-with-button">
                                        <input
                                            type="email"
                                            placeholder="Enter Your Mail id"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="input-field"
                                        />
                                        <button type="submit" className="submit-button">Submit</button>
                                    </div>
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            checked={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                        />
                                        <label htmlFor="terms" className='t5-terms'>I agree with the terms & conditions</label>
                                    </div>
                                </form>

                            </div>
                        </Grid>
                    </Grid>

                </div>
                <Theme5testimonial />
            </div>
        </>
    )
}

export default Theme5home