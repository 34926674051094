import { FormHelperText, Grid, TextField } from '@mui/material'
import Aos from "aos";
import 'aos/dist/aos.css'
import React from 'react'
import { useEffect } from 'react';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import SendIcon from '@mui/icons-material/Send';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';

const Theme8home = () => {
  const { navigator } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
    { values: formData }
  );

  const handlehome = () => {
    navigator('/:name/home')
  }

  const handleabout = () => {
    navigator('/:name/home/about')
  }

  const handleblog = () => {
    navigator('/:name/home/blog')
  }

  const handlepage = () => {
    navigator('/:name/home/pages')
  }

  const handlecontact = () => {
    navigator('/:name/home/contact')
  }

  useEffect(() => {
    Aos.init();
  }, []);

  const [screenSize, setScreenSize] = useState(3)

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(3)
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2)
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2)
    } else {
      setScreenSize(1)
    }
  }

  return (
    <>
      <section className='nav-bar'>
        <nav className="navbar">
          <ul className="nav-links">
            <li><a href="#" onClick={handlehome} >Home</a></li>
            <li><a href="#" onClick={handleabout} >About Us</a></li>
            <li><a href="#" onClick={handleblog} >Blog</a></li>
            <li><a href="#" onClick={handlepage} >Pages</a></li>
            <li><a href="#" onClick={handlecontact} >Contact Us</a></li>
          </ul>
          <div className="social-icons">
            <FacebookOutlinedIcon className='social-icon-clr' />
            <TwitterIcon className='social-icon-clr' />
            <YouTubeIcon className='social-icon-clr' />
            <InstagramIcon className='social-icon-clr' />
          </div>
        </nav>
        <div className='bg-img'>
          {/* <img src='/images/theme8/slider1.png' className='img-fluid' /> */}
          <div class="text-content">
            <h3 className='mt-3' style={{ color: '#ff3a47' }}>EXPERT IN ELECTRICAL</h3>
            <h1 className='cont-size mt-3'>We Are fast electrical <br /> services provider</h1>
            <button className='gra-btn'>Read More</button>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='red-box' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
          <h3 className='text-center mb-5'>Request Services Now</h3>
          <div className='red-box-cont'>
            <div>
              <p className='padd-left'>Full Name</p>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    placeholder="Nacika Marina"
                    fullWidth
                    {...field}
                    type="text"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        '&.MuiInputBase-input': {
                          padding: '0px',
                        },
                        '& input::placeholder': {
                          fontWeight: 'bold',
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
              />
              <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
            </div>
            <div>
              <p className='padd-left'>Email Address</p>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    placeholder="nacika@gmail.com"
                    fullWidth
                    {...field}
                    type="text"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        '&.MuiInputBase-input': {
                          padding: '0px',
                        },
                        '& input::placeholder': {
                          fontWeight: 'bold',
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
              />
              <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
            </div>
            <div>
              <p className='padd-left'>Contact Number</p>
              <Controller
                name="number"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    placeholder="+91 1800-124-12"
                    fullWidth
                    {...field}
                    type="text"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        '&.MuiInputBase-input': {
                          padding: '0px',
                        },
                        '& input::placeholder': {
                          fontWeight: 'bold',
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
              />
              <FormHelperText className='text-danger'>{errors.number && errors.number.message}</FormHelperText>
            </div>
            <div>
              <p className='padd-left'>Service Date</p>
              <Controller
                name="date"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    placeholder="18-09-24"
                    fullWidth
                    {...field}
                    type="text"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        '&.MuiInputBase-input': {
                          padding: '0px',
                        },
                        '& input::placeholder': {
                          fontWeight: 'bold',
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
              />
              <FormHelperText className='text-danger'>{errors.date && errors.date.message}</FormHelperText>
            </div>
            <div>
              <p className='padd-left'>Service Time</p>
              <Controller
                name="date"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    placeholder="8:50pm"
                    fullWidth
                    {...field}
                    type="text"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        '&.MuiInputBase-input': {
                          padding: '0px',
                        },
                        '& input::placeholder': {
                          fontWeight: 'bold',
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='about-us' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <div className='container'>
          <Grid container >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div class="home-content">
                <h3 className='mt-3' style={{ color: '#ff3a47' }}>ABOUT US</h3>
                <h1 className='cont-size1 mt-3'>Our expert electricians are <br /> trustworthy and reliable</h1>
              </div>

              <img src='/images/theme8/about-img.png' className='about-img img-fluid' />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className='about-rig-con'>
                <h1 className='about-num' style={{ color: '#ff3a47' }}>25+</h1>
                <div className='about-rig-con2'>
                  <h5 className='m-0'>YEAR OF</h5>
                  <h5>EXPERIENCE</h5>
                </div>
              </div>

              <div className='rig-content'>
                <div>
                  <h4>We get the lights on fast and for a good price Lorem ipsum dolor sit amet consectetur .</h4>
                  <p style={{ color: '#4d4d4d' }}>Lorem ipsum dolor sit amet, consectetur adipieisicing elit, seeded esde eeiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nissdsfei ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in andevoluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sisdesdnt occaessicat cupidat non proident,
                    sunt in culpa qui officia deserunt miollit aneimd essddeiit laborume ed ut perspiciatis unde omnis iste
                    natus error sitesde and the voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                    quae ab illo inventore.</p>
                  <button className='gra-btn'>Read More</button>
                </div>
                <img src='/images/theme8/tower.png' className='' />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className='black-bg'>
        <div className='container' data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
          <Grid container justifyContent='center' alignItems='center' spacing={5} >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <h4 style={{ color: '#ff3a46' }}>OUR SERVICES</h4>
              <h1 style={{ color: 'white' }}>See What we can do for you</h1>
              <button className='gra-btn'>View All Services</button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='card1'>
                <img src='/images/theme8/card1.png' className='img-fluid' />
                <h3 className='my-2'>Air Conditioning</h3>
                <p className='my-2' style={{ color: '#707070' }}>Lorem ipsum dolor sit ametand thee consectetur adipiscing elit, sed dseio eiusmod tempor incididunt ut labore egehsd estore magna aliqua.</p>
                <button className='gra-btn1 my-2'>Read More</button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='card1'>
                <img src='/images/theme8/card2.png' className='img-fluid' />
                <h3 className='my-2'>Heating Service</h3>
                <p className='my-2' style={{ color: '#707070' }}>Lorem ipsum dolor sit ametand thee consectetur adipiscing elit, sed dseio eiusmod tempor incididunt ut labore egehsd estore magna aliqua.</p>
                <button className='gra-btn1 my-2'>Read More</button>
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' alignItems='center' spacing={5} className='mt-5' >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='card1'>
                <img src='/images/theme8/card3.png' className='img-fluid' />
                <h3 className='my-2'>Residential</h3>
                <p className='my-2' style={{ color: '#707070' }}>Lorem ipsum dolor sit ametand thee consectetur adipiscing elit, sed dseio eiusmod tempor incididunt ut labore egehsd estore magna aliqua.</p>
                <button className='gra-btn1 my-2'>Read More</button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='card1'>
                <img src='/images/theme8/card4.png' className='img-fluid' />
                <h3 className='my-2'>Industrial</h3>
                <p className='my-2' style={{ color: '#707070' }}>Lorem ipsum dolor sit ametand thee consectetur adipiscing elit, sed dseio eiusmod tempor incididunt ut labore egehsd estore magna aliqua.</p>
                <button className='gra-btn1 my-2'>Read More</button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='card1'>
                <img src='/images/theme8/card5.png' className='img-fluid' />
                <h3 className='my-2'>Commercial</h3>
                <p className='my-2' style={{ color: '#707070' }}>Lorem ipsum dolor sit ametand thee consectetur adipiscing elit, sed dseio eiusmod tempor incididunt ut labore egehsd estore magna aliqua.</p>
                <button className='gra-btn1 my-2'>Read More</button>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className='electrician-service'>
        <div className='container'>
          <Grid container justifyContent='center' alignItems='center' spacing={5}  >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h2 className='mb-3'>Looking for a quality and affordable electrician service?</h2>
              <button className='gra-btn3 mb-5'>Get A Quote</button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className='big-btn-dflex'>
                <button className='big-btn' >
                  <span className='icon-bg'><WifiCalling3Icon /></span>
                  <div>
                    <p className='mb-0' style={{ fontSize: '12px' }}><b>Call Us Anytime</b></p>
                    <p className='mb-0' style={{ fontSize: '15px' }}>(+91) 1800-214-122</p>
                  </div>
                </button>
                <button className='big-btn ml-3' >
                  <span className='icon-bg'><SendIcon /></span>
                  <div>
                    <p className='mb-0' style={{ fontSize: '12px' }}><b>Mail us</b></p>
                    <p className='mb-0' style={{ fontSize: '15px' }}>electrician@example.com</p>
                  </div>
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className='testimonial mt-5'>
        <div className='container-fluid p-5' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
          <Grid container justifyContent='center' alignItems='flex-start' spacing={5}  >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src='/images/theme8/tower.png' className='tower' />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h4 className='text-center' style={{ color: '#ff3a47' }}>OUR TESTIMONIALS</h4>
              <h1 className='text-center'>What our client say about us</h1>
              <swiper-container className="mySwiper" style={{ padding: '30px' }} space-between="30" slides-per-view={2} autoplay="true">
                <swiper-slide>
                  <div className='testimonial-card'>
                    <h4>I’m 100% Satisfied with your services</h4>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      uest labore efdgrser dolore magna aliquauis ipsum suspe ses ndisse ultrices gracommodo.</p>
                    <div className='testicard-dflex'>
                      <div>
                        <h6>Hannah Kimberly</h6>
                        <p style={{ color: '#ff3a47' }}>Project Manager</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                      </div>
                      <div className='red-cir'>
                        <i class="fa-solid fa-quote-right" style={{ color: '#ffffff' }}></i>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='testimonial-card'>
                    <h4>I’m 100% Satisfied with your services</h4>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      uest labore efdgrser dolore magna aliquauis ipsum suspe ses ndisse ultrices gracommodo.</p>
                    <div className='testicard-dflex'>
                      <div>
                        <h6>Caroline Dorothy</h6>
                        <p style={{ color: '#ff3a47' }}>Coder</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                      </div>
                      <div className='red-cir'>
                        <i class="fa-solid fa-quote-right" style={{ color: '#ffffff' }}></i>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='testimonial-card'>
                    <h4>I’m 100% Satisfied with your services</h4>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      uest labore efdgrser dolore magna aliquauis ipsum suspe ses ndisse ultrices gracommodo.</p>
                    <div className='testicard-dflex'>
                      <div>
                        <h6>Anderson Campbell</h6>
                        <p style={{ color: '#ff3a47' }}>Project Manager</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                      </div>
                      <div className='red-cir'>
                        <i class="fa-solid fa-quote-right" style={{ color: '#ffffff' }}></i>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='testimonial-card'>
                    <h4>I’m 100% Satisfied with your services</h4>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      uest labore efdgrser dolore magna aliquauis ipsum suspe ses ndisse ultrices gracommodo.</p>
                    <div className='testicard-dflex'>
                      <div>
                        <h6>Kevin</h6>
                        <p style={{ color: '#ff3a47' }}>Coder</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                        <i class="fa-solid fa-star fa-flip-horizontal"  style={{ color: '#2a2a34' }}></i>
                      </div>
                      <div className='red-cir'>
                        <i class="fa-solid fa-quote-right" style={{ color: '#ffffff' }}></i>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper-container>
            </Grid>
          </Grid>
        </div>
      </section>

      <section>
        <div className='Achievement mt-5'>
          <div className='container' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='pt-5' >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h3 style={{ color: '#ff343b' }}>OUR ACHIEVEMENT</h3>
                <h1 style={{ color: 'white' }}>Our status show that’s we have happy customer’s</h1>
                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt uest
                  labore efdgrser dolore magna aliquauis ipsum suspe ses ndisse ultrices gracommodo.</p>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <section>
                  <div className="award-card">
                    <center>
                      <div className='red-circle'>
                        <i class="fa-solid fa-award" style={{ color: 'white' }}></i>
                      </div>
                    </center>
                    <h1 className="award-count">423+</h1>
                    <div className="award-text">Awards Winner</div>
                  </div>
                  <center><div className='award-red-card'></div></center>
                </section>

                <section>
                  <div className="award-card mt-5">
                    <center>
                      <div className='red-circle1'>
                        <i class="fa-solid fa-user-group" style={{ color: 'white' }}></i>
                      </div>
                    </center>
                    <h1 className="award-count">2k+</h1>
                    <div className="award-text">Satisfied Clients</div>
                  </div>
                  <center><div className='award-red-card mb-5'></div></center>
                </section>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <section>
                  <div className="award-card">
                    <center>
                      <div className='red-circle'>
                        <i class="fa-solid fa-file-zipper" style={{ color: 'white' }}></i>
                      </div>
                    </center>
                    <h1 className="award-count">957+</h1>
                    <div className="award-text">Active projects</div>
                  </div>
                  <center><div className='award-red-card'></div></center>
                </section>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      <section>
        <div className='Team my-5'>
          <div className='container' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <Grid container justifyContent='center' alignItems='flex-start' spacing={3}  >
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <center><h4 style={{ color: '#ff343b' }}>OUR TEAM</h4></center>
                <center><h1>Meet Our Expert Team</h1></center>
                <div className='mt-5'>
                  <swiper-container className="mySwiper" style={{ padding: '30px 0px' }} space-between="30" slides-per-view={3} autoplay="true">
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Bernadet orothy</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team1.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Felicity Alexander</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team2.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Harry Nicholas</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team3.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Bernadet orothy</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team1.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Felicity Alexander</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team2.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div className='team-card'>
                        <center><h5>Harry Nicholas</h5></center>
                        <center><p style={{ color: '#ff3a47' }} >Electrician</p></center>
                        <center><img src='/images/theme8/team3.png' className='Team-img img-fluid' /></center>
                        <button className='gra-btn'><i class="fa-solid fa-phone-volume" style={{ color: 'white' }}></i><span className='ml-2'>+91 1800-214-122</span></button>
                      </div>
                    </swiper-slide>
                  </swiper-container>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src='/images/theme8/tower.png' className='tower img-fluid' />
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      <section>
        <div className='Pricing mt-5'>
          <div className='container' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
            <center><h3 style={{ color: '#ff343b' }}>OUR PRICING</h3></center>
            <center><h1 style={{ color: 'white' }}>See Our Pricing Rate</h1></center>
          </div>
          <div className='container-fluid px-5' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <Grid container justifyContent='center' alignItems='flex-start'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className='pricing-card'>
                  <center><div className='pricing-table'><h5>Pricing Table</h5></div></center>
                  <center><h2>Price</h2></center>
                  <div className='price-dot'>
                    <ul className='pb-4'>
                      <li className='py-3'>Annual A/C inspection</li>
                      <li className='py-3'>Annual electrical inspection</li>
                      <li className='py-3'>Supply and install Sensor light</li>
                      <li className='py-3'>Lighting Fixtures</li>
                      <li className='py-3'>Switchboard Upgrade</li>
                      <li className='py-3'>Invetor Services</li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className='pricing-card'>
                  <center><div className='pricing-table'><h5>BASIC</h5></div></center>
                  <center><h2>$45</h2></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                </div>
                <center><button className='gra-btn2'>Choose Plan</button></center>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className='pricing-card'>
                  <center><div className='pricing-table'><h5>STANDARD</h5></div></center>
                  <center><h2>$67</h2></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                </div>
                <center><button className='gra-btn2'>Choose Plan</button></center>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className='pricing-card'>
                  <center><div className='pricing-table'><h5>PREMIUM</h5></div></center>
                  <center><h2>$148</h2></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#21d709' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                  <center><div className='price-tick'><i class="fa-solid fa-circle-check" style={{ color: '#a3a3a3' }}></i></div></center>
                </div>
                <center><button className='gra-btn2'>Choose Plan</button></center>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      <section>
        <div className='project my-5'>
          <div className='container' data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <Grid container justifyContent='center' alignItems='flex-start'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src='/images/theme8/tower.png' className='tower-project img-fluid' />
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <h4 style={{ color: '#ff3a47' }}>OUR PROJECTS</h4>
                    <h3>See Our Awesome Recent Projects</h3>
                    <button className='gra-btn'>View All Projects</button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <img src='/images/theme8/pro-1.png' className='tilt-image img-fluid' />
                    <h5 className='mt-2'>A/C Electric Power Repair</h5>
                    <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amsectetur adipiscing elitsed do eimod tempor</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <img src='/images/theme8/pro-2.png' className='tilt-image img-fluid' />
                    <h5 className='mt-2'>A/C Electric Power Repair</h5>
                    <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amsectetur adipiscing elitsed do eimod tempor</p>
                  </Grid>
                </Grid>
                <Grid container className='mt-3' justifyContent='center' alignItems='flex-start' spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <img src='/images/theme8/pro-3.png' className='tilt-image img-fluid' />
                    <h5 className='mt-2'>A/C Electric Power Repair</h5>
                    <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amsectetur adipiscing elitsed do eimod tempor</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <img src='/images/theme8/pro-4.png' className='tilt-image img-fluid' />
                    <h5 className='mt-2'>A/C Electric Power Repair</h5>
                    <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amsectetur adipiscing elitsed do eimod tempor</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <img src='/images/theme8/pro-5.png' className='tilt-image img-fluid' />
                    <h5 className='mt-2'>A/C Electric Power Repair</h5>
                    <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amsectetur adipiscing elitsed do eimod tempor</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      <section>
        <div className='Pricing mt-5'>
          <div className='container' data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
            <center><h3 style={{ color: '#ff343b' }}>OUR GALLERY</h3></center>
            <center><h1 style={{ color: 'white' }}>Our Work Gallery</h1></center>
            <Grid className='mt-5' container justifyContent='center' alignItems='flex-start' spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <img src='/images/theme8/gallery1.png' className='gallery-img img-fluid' />
                <img src='/images/theme8/gallery4.png' className='gallery-img mt-5 img-fluid' />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <img src='/images/theme8/gallery2.png' className='gallery-img img-fluid' />
                <img src='/images/theme8/gallery5.png' className='gallery-img mt-5 img-fluid' />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <img src='/images/theme8/gallery3.png' className='gallery-img img-fluid' />
                <img src='/images/theme8/gallery6.png' className='gallery-img mt-5 img-fluid' />
              </Grid>
            </Grid>
            <center><button className='gra-btn my-5'>View All Projects</button></center>
          </div>
        </div>
      </section>

      <section>
        <div className='blogs my-5'>
          <div className='container' data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <center><h3 style={{ color: '#ff343b' }}>OUR BLOGS</h3></center>
            <center><h1 style={{ color: 'white' }}>Read Recent Blog & News</h1></center>
            <Grid container justifyContent='center' alignItems='flex-start'>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Grid container justifyContent='center' alignItems='flex-start' spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img src='/images/theme8/h-blog1.png' className='img-fluid' />

                    <div className='blog-main1 mt-5'>
                      <div className='blog-icons-dflex'>
                        <span className='blog-icons'>
                          <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>31 May 2024</p>
                        </span>
                        <span className='blog-icons1'>
                          <PersonIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>By - Demo_admin</p>
                        </span>
                        <span className='blog-icons1'>
                          <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>0 Comments</p>
                        </span>
                      </div>
                      <h3 className='my-3' style={{ color: '#2a2a34', fontSize: '20px' }} >Electrical Tower & Wire Installation</h3>
                      <p style={{ color: '#707070', fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                      <button className='gra-btn'>Read More</button>
                    </div>

                    <img src='/images/theme8/h-blog3.png' className='mt-5 img-fluid' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className='blog-main1'>
                      <div className='blog-icons-dflex'>
                        <span className='blog-icons'>
                          <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>31 May 2024</p>
                        </span>
                        <span className='blog-icons1'>
                          <PersonIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>By - Demo_admin</p>
                        </span>
                        <span className='blog-icons1'>
                          <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>0 Comments</p>
                        </span>
                      </div>
                      <h3 className='my-3' style={{ color: '#2a2a34', fontSize: '20px' }}>Electrical Tower & Wire Installation</h3>
                      <p style={{ color: '#707070', fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                      <button className='gra-btn'>Read More</button>
                    </div>

                    <img src='/images/theme8/h-blog2.png' className='mt-5 img-fluid' />

                    <div className='blog-main1 mt-5'>
                      <div className='blog-icons-dflex'>
                        <span className='blog-icons'>
                          <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>31 May 2024</p>
                        </span>
                        <span className='blog-icons1'>
                          <PersonIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>By - Demo_admin</p>
                        </span>
                        <span className='blog-icons1'>
                          <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                          <p className='ml-1' style={{ color: '#2a2a34', fontSize: '12px' }}>0 Comments</p>
                        </span>
                      </div>
                      <h3 className='my-3' style={{ color: '#2a2a34', fontSize: '20px' }}>Electrical Tower & Wire Installation</h3>
                      <p style={{ color: '#707070', fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                      <button className='gra-btn'>Read More</button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <img src='/images/theme8/tower.png' className='tower-project1 img-fluid' />
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </>
  )
}

export default Theme8home