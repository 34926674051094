import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { MdArrowOutward } from "react-icons/md";
import DataContext from '../../../../context/DataContext';

const Theme7projects = () => {

    const { navigator } = useContext(DataContext)

    // const handleServicemore = (id) => {
    //     console.log(id, "ids")
    //     navigator(`/:name/readmore/${btoa(id)}`)
    // }

    const handleServicemore = () => {
        navigator('/:name/projectsreadmore')
    }

    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Projects</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Projects</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='mt-5'>
                <div className='client-full'>
                    <Grid container direction='row' spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className="card-content">
                                <div>
                                    <img src="/images/theme7/client-1.jpg" alt='Creative Solution' className='cliented img-fluid' />
                                </div>
                                <div className="single_port mb-0">
                                    <span>Creative</span>
                                </div>
                                <h3 className='product-tit'>Creative Solution</h3>
                                <div className="icon-box">
                                    <span className="icon-box-title"  onClick={() => handleServicemore()}>Read More</span>
                                    <span><MdArrowOutward className="box-title" /></span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="card-content">
                                <div>
                                    <img src="/images/theme7/client-2.jpg" alt='Programming & AI' className='cliented img-fluid' />
                                </div>
                                <div className="single_port mb-0">
                                    <span>Programming</span>
                                </div>
                                <h3 className='product-tit'>Programming & AI</h3>
                                <div className="icon-box">
                                    <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                    <span><MdArrowOutward className="box-title" /></span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className="card-content">
                                <div>
                                    <img src="/images/theme7/client-3.jpg" alt='Computer Security' className='cliented img-fluid' />
                                </div>
                                <div className="single_port mb-0">
                                    <span>Cyber</span>
                                </div>
                                <h3 className='product-tit'>Computer Security</h3>
                                <div className="icon-box">
                                    <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                    <span><MdArrowOutward className="box-title" /></span>
                                </div>
                            </div>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className='mt-4'>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/client-4.jpg" alt='On Board Project' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Solution</span>
                                    </div>
                                    <h3 className='product-tit'>On Board Project</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/client-5.jpg" alt='Computer Security' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Cyber</span>
                                    </div>
                                    <h3 className='product-tit'>Computer Security</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/client-6.jpg" alt='Family Security' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Protection</span>
                                    </div>
                                    <h3 className='product-tit'>Family Security</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className='mt-4 mb-4'>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/project1.jpg" alt='On Board Project' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Protection</span>
                                    </div>
                                    <h3 className='product-tit'>Creative Solution</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/project2.jpg" alt='Computer Security' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Cyber</span>
                                    </div>
                                    <h3 className='product-tit'>Programming & AI</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="card-content">
                                    <div>
                                        <img src="/images/theme7/project3.jpg" alt='Family Security' className='cliented img-fluid' />
                                    </div>
                                    <div className="single_port mb-0">
                                        <span>Solution</span>
                                    </div>
                                    <h3 className='product-tit'>Computer Security</h3>
                                    <div className="icon-box">
                                        <span className="icon-box-title" onClick={() => handleServicemore()}>Read More</span>
                                        <span><MdArrowOutward className="box-title" /></span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Theme7projects