import React, { useEffect, useState } from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import Theme3ourprofile from '../home-page/Theme3ourprofile'
import Theme3beliveyou from '../home-page/Theme3beliveyou'
import { Container, Grid } from '@mui/material'
import Theme3logobanner from '../logos-banner/Theme3logobanner'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const Theme3aboutus = () => {
  let urlName = sessionStorage.getItem('urlName')
  const [screenSize, setScreenSize] = useState(3);
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(3);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(3);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(3);
    } else {
      setScreenSize(1);
    }
  };
  const swipeImages = [
    "/images/theme3/soori/soori-tamil-actor_165345502700.webp",
    " /images/theme3/soori/sor_1.webp",
    "/images/theme3/soori/coat_soori.jpg"
  ];
  return (
    <>
      <div className='bg-colors-2'>
        <Theme3navbar />
        <div className='abt-page-t3 text-center'>
          <h1>Biography</h1>
        </div>
        <img src="/images/theme3/soori/Vignesh Sep-Nov (1).png" alt="tri" className='tri-7' />
      </div>
      {/* <Theme3ourprofile /> */}
      <Container>
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className='whole_soori-content mt-5 '>
              <div className='child_soori'>
                <h2>Early Life and Childhood</h2>
                <p>Ramalakshmanan Muthuchamy, popularly known as Soori, was born on August 24, 1977 in a small village near Madurai, Tamil Nadu. Growing up in a modest family, he developed a strong sense of determination and work ethic, traits that would later shape his career. His early life revolved around village activities, and despite limited exposure to the outside world, Soori nurtured a passion for acting from a young age.</p>
              </div>
              <div className='child_soori'>
                <h2>Move to Chennai and Struggles</h2>
                <p>In 1996, Soori moved to Chennai with dreams of entering the Tamil film industry. However, breaking into the industry proved challenging. To support himself and pursue acting opportunities, Soori worked as a cleaner while auditioning for roles. His persistence during this difficult time kept his dreams alive, despite many setbacks.</p>
              </div>
              <div className='child_soori'>
                <h2>Breakthrough with Vennila Kabadi Kuzhu</h2>
                <p>After several uncredited appearances, Soori gained recognition in 2009 with the film Vennila Kabadi Kuzhu. The iconic "parotta" scene made him a household name, earning him the nickname "Parotta Soori." This role marked the beginning of his successful career as a comedian in Tamil cinema.</p>
              </div>
              <div className='child_soori'>
                <h2>Success as a Comedic Actor</h2>
                <p>Following Vennila Kabadi Kuzhu, Soori became known for his comedic timing and relatable performances. Films like Sundarapandian (2012), Varuthapadatha Valibar Sangam (2013), and Rajini Murugan (2016) showcased his versatility, making him a staple in Tamil comedy. His ability to make audiences laugh while portraying down-to-earth characters set him apart in the industry.</p>
              </div>
              <div className='child_soori'>
                <h2>Transition to Leading Roles</h2>
                <p>In 2023, Soori transitioned from comedic roles to leading actor status with Vetrimaaran's Viduthalai Part 1, a critically acclaimed period crime thriller. His portrayal of a serious, dramatic character was well-received, establishing Soori as a multifaceted actor. This role marked a significant shift in his career, opening doors for future lead roles.</p>
              </div>
              <div className='child_soori'>
                <h2>Entrepreneurial Ventures</h2>
                <p>Beyond acting, Soori is also an entrepreneur. He is the CEO of Amman Hotel in Madurai, showing his business acumen alongside his artistic talents. This venture has further solidified his presence beyond the film industry.</p>
              </div>
              <div className='child_soori'>
                <h2>Family and Personal Life</h2>
                <p>Soori values his family deeply, often crediting them for their support throughout his journey. Despite the fame and success, he remains connected to his roots and is known for his humility and grounded nature. His story is one of perseverance, from a humble childhood to becoming one of Tamil cinema's beloved actors.</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}></Grid>
        </Grid>
      </Container>
      {/* <div className='mt-5'>
        <Theme3beliveyou />
      </div> */}
      
      <div className='swipe-t3 mt-5' >
        <Swiper
          slidesPerView={screenSize}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          className="my-Swiper"
          navigation={true}
        >

          {swipeImages.map((image, index) => (
            <SwiperSlide key={index}>
              <div>
                <img src={image} alt={`Slide ${index + 1}`} className='foreground-image' />
              </div>
            </SwiperSlide>
          ))}

        </Swiper>
        <div className='mt-2'>
          <img src="/images/theme3/bel-2.png " alt="tri" className='tri-bel-3' />
        </div>
      </div>
     
      {/* <div className='abou-t3-counts mt-5'>
        <Container>
          <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className='t3-count-content'>
                <h2>126 +</h2>
                <p>Number of Comedian Roles</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className='t3-count-content'>
                <h2>4 + </h2>
                <p>Number of Hero Roles</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className='t3-count-content'>
                <h2>4 +</h2>
                <p>Number of Awards</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div> */}

      {/* <div className='mt-5'>
        <Theme3logobanner />
      </div> */}
    </>
  )
}

export default Theme3aboutus