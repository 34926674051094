import React from 'react'
import { useContext } from 'react'
import DataContext from '../../../../context/DataContext'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const Theme8page = () => {
  const { navigator } = useContext(DataContext)

  const handlehome = () => {
    navigator('/:name/home')
  }

  const handleabout = () => {
    navigator('/:name/home/about')
  }

  const handleblog = () => {
    navigator('/:name/home/blog')
  }

  const handlepage = () => {
    navigator('/:name/home/pages')
  }

  const handlecontact = () => {
    navigator('/:name/home/contact')
  }
  return (
    <>
      <section className='nav-bar'>
        <nav className="navbar">
          <ul className="nav-links">
            <li><a href="#" onClick={handlehome} >Home</a></li>
            <li><a href="#" onClick={handleabout} >About Us</a></li>
            <li><a href="#" onClick={handleblog} >Blog</a></li>
            <li><a href="#" onClick={handlepage} >Pages</a></li>
            <li><a href="#" onClick={handlecontact} >Contact Us</a></li>
          </ul>
          <div className="social-icons">
            <FacebookOutlinedIcon className='social-icon-clr' />
            <TwitterIcon className='social-icon-clr' />
            <YouTubeIcon className='social-icon-clr' />
            <InstagramIcon className='social-icon-clr' />
          </div>
        </nav>
      </section>

      <section>
        <div className='about-page-blue-bg'>
          <h1 className='text-center' style={{ color: '##2a2a34' }} >Pages</h1>
        </div>

        <div className='container my-5'>
          <p className='my-3' style={{color: '#707070'}}>Te obtinuit ut adepto satis somno. Aliisque institoribus iter deliciae vivet vita. Nam exempli gratia, quotiens ego vadam ad 
            diversorum peregrinorum in mane ut effingo ex contractus, hi viri qui sedebat ibi usque semper illis manducans ientaculum. 
            Solum cum bulla ut debui; EGO youd adepto a macula proiciendi. Sed quis scit si forte quod esset optima res pro me. sicut ea 
            quae sentio. Qui vellem cadunt off ius desk ejus! Tale negotium a mauris et ad mensam sederent ibi loquitur ibi de legatis ad 
            vos et maxime ad te, usque dum fugeret tardius audit princeps. Bene tamen fiduciam Ego got off semelTe obtinuit ut adepto satis 
            somno. Aliisque institoribus iter deliciae vivet vita. Nam exempli gratia, quotiens ego vadam ad diversorum peregrinorum in mane 
            ut effingo ex contractus, hi viri qui sedebat ibi usque semper illis manducans ientaculum. Solum cum bulla ut debui; 
            EGO youd adepto a macula proiciendi. Sed quis scit si forte quod esset optima res pro me. sicut ea quae sentio. Qui vellem 
            cadunt off ius desk ejus! Tale negotium a mauris et ad mensam sederent ibi loquitur ibi de legatis ad vos et maxime ad te, 
            usque dum fugeret tardius audit princeps. Bene tamen fiduciam Ego got off semel</p>
        </div>
      </section>
    </>
  )
}

export default Theme8page