import axios from 'axios';
import notifyService from './notifySerivce';
const apiService = async (URL, body, method) => {
    // DEV
    // const apiURL = 
    // const URL = `${process.env.REACT_APP_API_URL}${url}`;    
    // QA
    // const URL = `http://ec2-13-232-21-132.ap-south-1.compute.amazonaws.com/tanfund/${url}`
    // LIVE
    // const URL =`https://api.tanfund.in/tanfund/${url}`
    const token = sessionStorage.getItem("jwttoken")
    const tenantId = sessionStorage.getItem("tenantID")
    if (method === 'unauthpost') {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-TenantID': tenantId,
                "Origin": "https://amizhthdev.in",
                "Referer": "https://amizhthdev.in/"
            },
        };
        return axios.post(URL, body, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'unauthget') {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-TenantID': tenantId,
                "Origin": "https://amizhthdev.in",
                "Referer": "https://amizhthdev.in/"
            },
        };
        return axios.get(URL, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'post') {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'X-TenantID': tenantId,
            },
        };
        return axios.post(URL, body, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'get') {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'X-TenantID': tenantId,
            },
        };
        return axios.get(URL, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'download') {
        const config = {
            headers:
            {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': token,
                'X-TenantID': tenantId,
            },
            responseType: 'arraybuffer',
        };
        return axios.get(URL, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'fileupload') {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
                'X-TenantID': tenantId,
            },
        };
        return axios.post(URL, body, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'downloadpost') {
        const config = {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token,
                'X-TenantID': tenantId,
            },
            responseType: 'arraybuffer',
        };
        return axios.post(URL, body, config)
            .catch(function (error) {
                handleError(error)
            });
    }
    if (method === 'multipartpublic') {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-TenantID': 'public',
            },
        };
        return axios.post(URL, body, config)
            .catch(function (error) {
                handleError(error)
            });
    }
}

const handleError = (error) => {
    console.log(error)
    if (error.response) {
        if (error.response.status === 403) {
            // window.location.assign('/sesstion-timeout')
            // return notifyService('danger', 'Internal server error !!', 'Please try again later')
        } else if (error.response.status === 400) {
            return notifyService('danger', 'Internal server error !!', 'Please try again later')
        } else if (error.response.data.errorResponseDTO && error.response.data.errorResponseDTO.errorMessage) {
            return notifyService('danger', 'Error', error.response.data.errorResponseDTO.errorMessage)
        } else {
            return notifyService('danger', 'Internal server error !!', 'Please try again later')
        }
    } else if (error.message === 'Network Error') {
        console.log(error.message);
        // window.location.assign('/under-maintenance')
    } else {
        notifyService('danger', 'Internal server error !!', 'Please try again later')
    }

}
const logout = () => {
    var userId = sessionStorage.getItem('userId')
    apiService(`user/logout?userid=${userId}`, '', 'get').then((res) => {
        if (res) {
            if (res.data && res.data.responseStatus === 'Success') {
                sessionStorage.clear()
                window.location.assign('/sign-in')
            }
        }
    })
}


export default apiService