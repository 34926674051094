import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay ,Pagination} from 'swiper/modules';
import { Link } from 'react-router-dom';

const Theme5professionalteam = () => {
    const [screenSize, setScreenSize] = useState(5);
    useEffect(() => {
        size();
    }, []);

    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(4);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(4);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(4);
        } else {
            setScreenSize(1);
        }
    };
    const crdImage = [
        {
            name: "Farah Shahrzad",
            designation:"E-commerce Specialist",
            img: "/images/theme5/team1.jpg"
        },
        {
            name: "Alireza Behzad",
            designation:"Electrical Engineer",
           img: "/images/theme5/team2.jpg"
        },
        {
            name: "Irena Shereen",
            designation:"Warehouse Manager",
          img: "/images/theme5/team3.jpg"
        },
        {
            name: "Behruz Habib",
            designation:"Operations Manager",
            img: "/images/theme5/team4.jpg"
        }

    ]
    return (
        <div>
            <div className='disc-t3-prod mt-5'>
                <div className='text-center'>
                    <span className='chip-abt'>Our TechGurus</span>
                    <h1 className='mt-3'>Elite Professional Team</h1>
                </div>
                <div className='mt-5'>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={screenSize}
                        loop
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        modules={[Autoplay,Pagination]}
                        style={{height:'460px'}}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                              return `<span class="${className}" style="background-color:blue;"></span>`;
                            },
                          }}
                    >
                        {crdImage && crdImage.map((a, i) => (
                            <SwiperSlide key={i}>
                                <div className="swiper-items-abtus-t3" data-aos="fade-right"  data-aos-duration="1000">
                                    <div>
                                    <img src={a.img} alt="Products" className='t5-prods-caros-abt mb-2'/>
                                    </div>
                                   <div className='swiper-items-abtus-t5 text-center mt-4'>
                                    <h5>{a.name}</h5>
                                   <p>{a.designation}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Theme5professionalteam