import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Theme5testimonial from '../components/home-page/Theme5testimonial'
import Theme5professionalteam from './Theme5professionalteam'
import { TbTargetArrow } from "react-icons/tb";
import { BsRocketTakeoff } from "react-icons/bs";
import { HiOutlineLightBulb } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { SwiperSlide, Swiper } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper/modules';
import Theme5navbar from '../layouts/Theme5navbar';

const Theme5Aboutpage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
    const video = document.getElementById('video-t5-div');
    if (!isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  };
  const [screenSize, setScreenSize] = useState(5);
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(5);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(5);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(5);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(5);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(5);
    } else {
      setScreenSize(1);
    }
  };
  return (
    <>
      <div className='banner-t5-back'>
       
        <div className='background-abt-t5'>
        <Theme5navbar/>
        <div className='background-abt-d-t5'>
          <div className='content-aboutpage'>
            <h1>About Us</h1>
            <p>Home &nbsp;/ &nbsp;About Us</p>
          </div>
          </div>
        </div>
        <div className='mt-5'  data-aos="fade-right"  data-aos-duration="1000">
          <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className='t5-abt-sec-left-cont'>
                <div>
                  <span className='chip-abt'>About Us</span>
                  <h1 className='abt-head-t5 mt-4'>The One-stop shop for the newest and best technology available.<span><img src={'/images/theme5/about-text-img-01.png'} alt="img" className='abt-t5-img' /></span> We think that technology<span><img src={'/images/theme5/about-text-img-02.png'} alt="img" className='abt-t5-img' /></span>ought to be dependable, and most of all, fun.</h1>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className='t3-abtus-sec'>

              </div>
            </Grid>
          </Grid>
          <div className='mt-5' data-aos="fade-down"  data-aos-duration="1000">
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="swiper-items-testi-t5">
                  <div className='icn-back-abts'>
                    < TbTargetArrow className='icons-abt-page' />
                  </div>
                  <div className='cust-testi-name mt-3'>
                    <div className='test-h5-news'>
                      <h5 >Our Focus</h5>
                      <p>Consectetur adipiscing elit duis tristique sollicitudin nibh. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Urna et pharetra.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="swiper-items-testi-t5">
                  <div className='icn-back-abts'>
                    < BsRocketTakeoff className='icons-abt-page' />
                  </div>
                  <div className='cust-testi-name mt-3'>
                    <div className='test-h5-news'>
                      <h5 >Our Objective</h5>
                      <p>Consectetur adipiscing elit duis tristique sollicitudin nibh. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Urna et pharetra.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="swiper-items-testi-t5">
                  <div className='icn-back-abts'>
                    < HiOutlineLightBulb className='icons-abt-page' />
                  </div>
                  <div className='cust-testi-name mt-3'>
                    <div className='test-h5-news'>
                      <h5 >Our Philosophy</h5>
                      <p>Consectetur adipiscing elit duis tristique sollicitudin nibh. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Urna et pharetra.</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div>

          <div className='video-t5-div mt-5'>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div data-aos="zoom-in">
                  <video id="video-t5-div" className='video_css banner-image' autoPlay loop onClick={handlePlay} style={{ width: '100%', borderRadius: '30px' }}>
                    <source src='/images/theme5/video/abt-vdeo.mp4' type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Grid>
            </Grid>
          </div>

        </div>
        <div className='mt-5'>
          <Theme5professionalteam />
        </div>
        <div>
          <div className='t5-security-user-abt' >
            <div className="abt-logo-banners" data-aos="fade-up"  data-aos-duration="1000">
              <Grid container direction="row" alignItems="flex-end" justifyContent="center" spacing={5}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className='t5-abt-sec-left-cont'>
                    <div className='left-cont-para-abt-t3'>
                      <span className='chip-abt'>Welcome To Elektrix</span>
                      <h1 className='abt-secur-t5 mt-4'>Our Premium Brands</h1>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className='abt-secur-right-sde'>
                    <p>A scelerisque purus semper eget duis at. Integer malesuada nunc vel risus commodo viverra.Nam aliquam sem et tortor consequat id.</p>
                    <Link className='t5-featue-links'>View All Brands</Link>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                freeMode={true}

                loop

                modules={[FreeMode, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='text-center'>
                    <img src={'/images/theme5/textLogo.png'} alt="swipe-img" className='swip-imag-abts ' />
                    <h5 className='text-center mt-2'>44 Items</h5>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <Theme5testimonial />
        </div>
      </div>
    </>
  )
}

export default Theme5Aboutpage