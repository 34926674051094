import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext';
import Theme6navbar from '../../layouts/Theme6navbar';
import { Container } from 'react-bootstrap';
import { BiCategory } from "react-icons/bi";
import { FormHelperText, Grid, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import PersonIcon from '@mui/icons-material/Person';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DescriptionIcon from '@mui/icons-material/Description';
const Theme6portfoliodetails = () => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    const onSubmit = (data) => {
        console.log(data);

    };
    const variImg = [
        {
            variImg: '/images/Theme6/img-1.jpg',
        },
        {
            variImg: '/images/Theme6/img-2.jpg',
        },
        {
            variImg: '/images/Theme6/img-3.jpg',
        },
    ];
    const [VariantImage, setVariantImage] = useState('');
    return (
        <div>
            <div className='bg-t6-contact'>
                <Theme6navbar />

                <div className='Contact-content'>
                    <h1>Portfolio</h1>
                    <p>{`Home >> Portfolio >> Details`}</p>
                </div>
            </div>
            <Container className='mt-5 res-details'>
                <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className="portfolio-content">
                            <h4>Sample text here looks good</h4>
                            <p className='mt-3'>Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                        <div className='Details'>
                            <h6>Details</h6>
                            <div className='mt-3'>
                                <i class="fas fa-calendar-week"></i>
                                <span className='ml-3'>Date: 20 Apr 2015</span>
                            </div>
                            <div className='mt-3'>
                                <i class="fas fa-globe"></i>
                                <span className='ml-3'>Author: ram</span>
                            </div>
                            <div className='mt-3'>
                                <BiCategory />
                                <span className='ml-3'>Creativity, Indoor, News</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                    <div className='product-images'>
                       
                        <div>
                            <div className="main-crd">
                                {VariantImage ? (
                                    <img
                                        src={VariantImage}
                                        alt="VariantImage"
                                        
                                        className='veriant-img img-fluid'
                                    />
                                ) : (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        src={'/images/Theme6/img-1.jpg'}
                                        alt="Product Image"
                                        className='veriant-img img-fluid'
                                      
                                    />
                                )}
                            </div>
                        </div>
                        <div className='portfolio-swiper'>
                            {variImg &&
                                variImg.map((a, i) => (

                                    <div key={i}>
                                        <div
                                            className=' variant-cards-t5 c-pointer'
                                            onClick={() => setVariantImage(a.variImg)}
                                        >
                                            <img

                                                src={a.variImg}
                                                alt={`Variant ${i + 1}`}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '90px',
                                                    margin: '0px auto',
                                                    borderRadius: '18px',
                                                    border: ' 1px solid lightgray'
                                                }}
                                            />
                                        </div>
                                    </div>

                                ))}
                        </div>
                    </div>
                    </Grid>
                </Grid>
            </Container>
            <div>
                <Container className='mt-5'>
                    <h3>Leave a Comment</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className=''>
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: " Name is required",

                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            // label="Enter Name *"
                                            label={
                                                <span>
                                                    <PersonIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                    Enter Name *
                                                </span>
                                            }
                                            placeholder="Enter Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.name && errors.name.message}
                                </FormHelperText>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Mail ID is required",
                                        validate: validateEmail,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            // label="Mail ID *"
                                            label={
                                                <span>
                                                    <MarkunreadIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                    Enter Email *
                                                </span>
                                            }
                                            placeholder="Mail ID"
                                            fullWidth
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.email && errors.email.message}
                                </FormHelperText>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                <div>
                                    <Controller
                                        name="about"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: `Message is required` }}
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                rows={5}
                                                fullWidth
                                                // label="Enter Message *"
                                                label={
                                                    <span>
                                                        <DescriptionIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                        Enter Message *
                                                    </span>
                                                }
                                                placeholder="Enter Message *"
                                                type="text"
                                                {...field}
                                                className="invest-area"
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.about && errors.about.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='text-center'>
                            <button className="contact-btn mb-3" type="submit">
                                Post Comment
                            </button>
                        </div>
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default Theme6portfoliodetails
