import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

const Theme6navbar = () => {
  let urlName = sessionStorage.getItem('urlName')
  const [activeLink, setActiveLink] = useState('/home');

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  var logo = sessionStorage.getItem('logo')
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <div className='navcont-t6 '>

        <nav
          className={`navbar navbar-expand-lg navbar-light ${isScrolled ? 'bg-white' : 'bg-transparent'}`}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
            transition: 'background-color 0.3s ease',
          }}>
          <span className="navbar-brand nav-log-res1 mt-0" to="/" >
            <img src={logo} alt="Logo" class='img-fluid nav-logo' />
          </span>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6 " to={`/${urlName}/home`} activeClassName="active">HOME </NavLink>
              </li>
              <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6" to={`/${urlName}/aboutus`} activeClassName="active">ABOUT</NavLink>
              </li>
              <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6" to={`/${urlName}/service`} activeClassName="active">SERVICE</NavLink>

              </li>
              <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6" to={`/${urlName}/blog`} activeClassName="active">BLOG</NavLink>

              </li>
             
              <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6" to={`/${urlName}/portfolio`} activeClassName="active">PORTFOLIO</NavLink>
              </li>
              <li className="nav-item nav-t6">
                <NavLink className="nav-link-t6" to={`/${urlName}/contactus`} activeClassName="active">CONTACT</NavLink>
              </li> */}
              <li className="nav-item nav-t6">
                <a className="nav-link-t6" href="#Home" onClick={() => handleScrollTo('Home')}>HOME</a>
              </li>
              <li className="nav-item nav-t6">
                <a className="nav-link-t6" href="#About" onClick={() => handleScrollTo('About')}>ABOUT</a>
              </li>
              <li className="nav-item nav-t6">
                <a className="nav-link-t6" href="#Service" onClick={() => handleScrollTo('Service')}>SERVICE</a>
              </li>
              <li className="nav-item nav-t6">
                <a className="nav-link-t6" href="#Videos" onClick={() => handleScrollTo('Videos')}>OUR VIDEOS</a>
              </li>
              <li className="nav-item nav-t6">
                <a className="nav-link-t6" href="#Testimonials" onClick={() => handleScrollTo('Testimonials')}>TESTIMONIAL</a>
              </li>
            </ul>

          </div>
        </nav>
      </div>
    </div>

  )
}

export default Theme6navbar