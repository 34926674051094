import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Modalbox = ({ images, selectedImageIndex, handleCloseModal }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Carousel
        showThumbs={false}
        selectedItem={selectedImageIndex}
        infiniteLoop={false}
        showStatus={false}
        autoPlay={false}
        showArrows={true}
        onClickItem={handleCloseModal}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.url} alt={`img-${index}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Modalbox;
