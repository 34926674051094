import React from 'react'
import Theme8navbar from './Theme8navbar'
import Theme8footer from './Theme8footer'
import { Outlet } from 'react-router-dom'

const Theme8layout = () => {
  return (
    <div>
        <Theme8navbar/>
        <Outlet></Outlet>
        <Theme8footer/>
    </div>
  )
}

export default Theme8layout