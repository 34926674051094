import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Theme7navbar = () => {
  let urlName = sessionStorage.getItem('urlName')
  const [activeLink, setActiveLink] = useState('/home'); // Default to home

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <Navbar expand="md" className="site-header navigation">
      <Container fluid>
        <Navbar.Brand href="/" className="site-logo">
          <img src="/images/theme7/cybal.jpg" alt="Logo" style={{ width: '150px' }} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="cybal_mobile_menu" />

        <Navbar.Collapse id="cybal_mobile_menu">
          <Nav className="mx-auto cybal_main_menu">
            <Nav.Link as={NavLink} to={`/${urlName}/home`}className='nav-link' activeClassName="active">Home</Nav.Link>
            <Nav.Link as={NavLink} to={`/${urlName}/services`}className='nav-link' activeClassName="active">Services</Nav.Link>
            <Nav.Link as={NavLink} to={`/${urlName}/aboutus`} className='nav-link' activeClassName="active">About Us</Nav.Link>
            <Nav.Link as={NavLink} to={`/${urlName}/projects`} className='nav-link' activeClassName="active">Projects</Nav.Link>
            <Nav.Link as={NavLink} to={`/${urlName}/blog`} className='nav-link' activeClassName="active">Blog</Nav.Link>
            <Nav.Link as={NavLink} to={`/${urlName}/contactus`} className='nav-link' activeClassName="active">Contact</Nav.Link>
          </Nav>

          <Button variant="primary" className="btn_one d-none d-md-inline-block">
            Get A Quote
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Theme7navbar;
