import { Grid } from '@mui/material'
import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";

const Theme7projectsmore = () => {

    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Projects</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Projects</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='project-subpage'>
                <Grid container spacing={3} className='mt-4'>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="card-content">
                            <div>
                                <img src="/images/theme7/client-2.jpg" alt='Programming & AI' className='img-fluid  client-proj ' />
                            </div>
                            <div>
                                <h2 className='projecttite'>Enabling Medical Staff To Prescribe</h2>
                                <p className='analys-title'>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit.</p>
                            </div>
                            <div className='projectlisted'>
                                <div className="projectmore">
                                    <ul>
                                        <li className="project-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Market Competitor Analysis</span>
                                        </li>
                                        <li className="project-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Make the Right Choice for Your Future.</span>
                                        </li>
                                        <li className="project-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Leadership Training program</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="projectmore">
                                    <ul>
                                        <li className="elementor-icon-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Market Competitor Analysis</span>
                                        </li>
                                        <li className="project-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Make the Right Choice for Your Future.</span>
                                        </li>
                                        <li className="project-list-item">
                                            <span className="project-icon-list-icon">
                                                <FaRegCheckCircle className='circel' />

                                            </span>
                                            <span className='projects-titles'>Leadership Training program</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <p className='analys-title'>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit.</p>
                            </div>
                            <div>
                                <p className='analys-title'>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit.</p>
                            </div>
                            <div className='project-images'>
                                <div>
                                    <img src='/images/theme7/port_details1.jpg' alt='' className='moreim img-fluid' />
                                </div>
                            </div>
                            <div>
                                <p className='analys-title'>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit.</p>
                            </div>
                            <div>
                                <p className='analys-title'>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="about_project_details">
                            <ul>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Customer: </b>William Smell</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Category: </b>Business</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Start Date: </b>25 May, 2024</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>End Date: </b>25 June, 2024</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Website: </b>www.google.com</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Tags: </b>trend</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Location: </b>USA</span>
                                </li>
                                <li className="project-items">
                                    <span className="project-icon">
                                        <FaCircleDot className='dot-circle' />
                                    </span>
                                    <span className="elementor-icon-list-text"><b className='project-custom'>Budget: </b>$69</span>
                                </li>
                            </ul>
                        </div>
                        <div className='mt-4'>
                            <img src='/images/theme7/home-img2.jpg' alt='' className='cyber-thief img-fluid' />
                        </div>
                        <div className="project-info-container">
                            <div className="pro-det">
                                <div className="pro-det-icon">
                                    <FaPhoneAlt className='call-icon'/>
                                </div>
                                <div className="pro-det-content">
                                    <h3 className="pro-det-title">
                                        Call For Any Help
                                    </h3>
                                    <p className="pro-det-description">
                                        (01)-234-567-891
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Theme7projectsmore