import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import DataContext from '../../../context/DataContext';
import { useContext } from 'react';

const Theme4navbar = () => {
  const { pageLoading, navigator } = useContext(DataContext)
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  document.getElementById('theme').setAttribute('href', '/css/theme4.css');

  const handleWork = () => {
    navigator('/:name/home/work')
  }
  const handleBlog = () => {
    navigator('/:name/home/blogs')
  }
  const handleContact = () => {
    navigator('/:name/home/contact')
  }

  return (
    <>
      {/* <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }}>
        <Grid item sm={12} md={5} lg={5}>
          <section className='nav-position'>
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <div className='t4-logo-bg'>
                <a className="navbar-brand text-center" href="#"><img src='/images/theme4/theme-4-logo.png' className='theme4-logo-nav img-fluid' /></a>
              </div>

              <div className="collapse navbar-collapse black-nav" id="navbarSupportedContent">
                <div className="navbar-nav ml-auto">
                  <div className="form-btn"><MenuIcon /></div>
                </div>

              </div>
            </nav>
          </section>
        </Grid>
        <Grid item sm={12} md={7} lg={7}>

        </Grid>
      </Grid> */}
      <section className='nav-bg'>
        <section className='nav-position'>
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className='t4-logo-bg'>
              <a className="navbar-brand text-center" href="#"><img src='/images/theme4/theme-4-logo.png' className='theme4-logo-nav img-fluid' /></a>
            </div>
            <div className=" black-nav" >
              <div className=" ml-auto">
                <div className="form-btn" onClick={toggleDrawer}><MenuIcon /></div>
              </div>
            </div>
          </nav>

          <div
            className={`drawer ${isDrawerOpen ? "open" : ""}`}
            onClick={toggleDrawer}
          >
            <div className="drawer-content">
              <div className="close-icon" onClick={toggleDrawer}>
                <CloseIcon />
              </div>
              <ul>
                <li><a href="#" >Home</a></li>
                <li><a href="#" onClick={handleWork} >Work</a></li>
                <li><a href="#" onClick={handleBlog}>Blog</a></li>
                <li><a href="#" onClick={handleContact}>Contact</a></li>
              </ul>
            </div>
          </div>

          {/* Backdrop */}
          {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}

        </section>
        <div className="content-wrapper">
          <h1 className="content-text">HI I AM GWENN STERN</h1>
          <h4 className="content-text-h4">LET' START</h4>
        </div>
      </section>
    </>
  )
}

export default Theme4navbar