import React from 'react'
import './undermaintenance.css'
// import { useNavigate } from "react-router-dom";
// import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Undermaintenance = () => {
    // useDidMountEffect(()=>{
    //     debugger
    // },[])
    // const navigate = useNavigate();
    // const upApi = () => {        
    //     // apiService(`actuator/health`, '', 'get').then((res) => {
    //     //     navigate(-1)
    //     // })
    // }
    return (
        <div class="under_maintaine">
            <div class="boxunder">
                <div class="animation">
                    <div class="one spin-one"></div>
                    <div class="two spin-two"></div>
                    <div class="three spin-one"></div>
                </div>
                <h1>Under maintenance</h1>
                <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance. We'll be back online as soon as possible.</p>
                <p>If you need immediate assistance, please contact us at tanfund@startuptn.in</p>
                <p>Thank you for your patience.</p>
                <p className='refbtn'>Refresh</p>
            </div>
        </div>
    )
}

export default Undermaintenance