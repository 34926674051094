import { Container, Grid } from '@mui/material'
import React from 'react'


const Theme3beliveyou = () => {

    return (
        <div className='mt-5' >
            <Container>
                <div className='bel-pos-rel-t3'>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='believe-title-1' data-aos="fade-up">
                                <h2 >What we believe in</h2>
                                <p className='belive-paras-heads'>Actor Soori, fondly known for his comedic timing, rose to fame with his iconic "parotta" scene in Vennila Kabadi Kuzhu. He has since become a household name in Tamil cinema, delivering memorable performances in numerous comedy roles.</p>
                                <p className='believe-title-cate'>Comedian <br />
                                    <span> 01.</span></p>
                            </div>
                            <div className='imag-posi-t3'>
                                <img src={'/images/theme3/soori/soor-img1.jpg'} alt="beleive1" className='bel-1' />
                            </div>
                            <div className='mt-2'>
                                <img src="/images/theme3/Tri-home-1.png" alt="tri" className='tri-bel' />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='imag-posi-t3'>
                                <img src={'/images/theme3/soori/2.jpg'} alt="beleive1" className='bel-1' />
                            </div>
                            <div className='believe-title-2'>
                                <p className='believe-title-cate-2'>
                                    <span> 02.</span><br />
                                    Actor
                                </p>
                                <p className='belive-paras-heads' >Actor Soori, renowned for his versatility, transitioned from a comedic icon to a critically acclaimed lead actor with his powerful performance in Viduthalai Part 1. His journey reflects dedication and a remarkable range in Tamil cinema.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='believe-title-3'>
                                <div className='grid-3-color'></div>
                                <div className='imag-posi-t3'>
                                    <img src={'/images/theme3/soori/coat_soori.jpg'} alt="beleive1" className='bel-1' />
                                </div>
                                <p className='believe-title-cate-3'>
                                    <span> 03.</span><br />
                                    Entrepreneur </p>
                                <p className='belive-paras-heads'>Actor Soori, beyond his cinematic success, is also an entrepreneur, serving as the CEO of Amman Hotel in Madurai. His business ventures reflect his versatility and ambition beyond the entertainment industry.</p>
                            </div>
                            <div className='text-right mt-2'>
                                <img src="/images/theme3/bel-2.png " alt="tri" className='tri-bel-1' />
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </Container>
           
        </div>
    )
}

export default Theme3beliveyou