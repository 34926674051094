import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Theme1home from './components/home-page/Theme1home'
import Theme1layout from './layouts/Theme1layout'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme1contactus from './components/contactus-page/Theme1contactus'
import Theme1blog from './components/blog-page/Theme1blog'
import Theme1gallery from './components/gallery-page/Theme1gallery'
import Theme1aboutus from './components/aboutus-page/Theme1aboutus'
import Theme1blodmore from './components/blog-page/Theme1blodmore'

const Theme1route = () => {

    document.getElementById('theme').setAttribute('href', '/css/theme1.css');
    return (
        <div>
            <Routes>
                <Route path='/:name' element={<Theme1layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme1home />} />
                    <Route path='/:name/aboutus' element={< Theme1aboutus />} />
                    <Route path='/:name/contactus' element={<Theme1contactus />} />
                    <Route path='/:name/blog' element={<Theme1blog />} />
                    <Route path='/:name/gallery' element={<Theme1gallery />} />
                    <Route path='/:name/readmore/:id' element={<Theme1blodmore />} />
                </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
        </div>
    )
}

export default Theme1route