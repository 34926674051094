import React, { useContext, useState } from 'react'
import Theme1route from '../modules/theme1-module/Theme1route'
import Welcome from './welcome-page/Welcome'
import Theme2route from '../modules/theme2-module/Theme2route'
import DataContext from '../context/DataContext'
import Loader from './common-page/loader-page/Loader'
import useDidMountEffect from '../hooks/useDidMountEffect'
import Theme3route from '../modules/theme3-module/Theme3route'
import Theme4route from '../modules/theme4-module/Theme4route'
import Theme5route from '../modules/theme5-module/Theme5route'
import Theme6route from '../modules/theme6-module/Theme6route'
import Theme7route from '../modules/theme7-module/Theme7route'
import axios from 'axios'
import Theme8route from '../modules/theme8-module/Theme8route'
import notifyService from '../api/notifySerivce'
import apiService from '../api/apiService'

const BaseRoute = () => {
    const lookup_URL = process.env.REACT_APP_LOOKUP_API_URL
    const { pageLoading, navigator, setPageLoading, getErrorStatus } = useContext(DataContext)

    const [themeId, setThemeId] = useState()

    // useDidMountEffect(() => {
    //     navigator(`/${'cybersecurity'}/home`)
    // }, [])

    let domainName
    let urlName
    // const dominAPI = process.env.REACT_APP_DOMIN
    // const themeApi = process.env.REACT_APP_THEME_GET
    // const organizationAPI = process.env.REACT_APP_ORGANIZATION_GET


    useDidMountEffect(() => {
        var tenantID = sessionStorage.getItem('tenantID')
        if (tenantID === null) {
            webDEVCode()
        } else {
            setThemeId(Number(sessionStorage.getItem('theme')))
        }
        // react-hooks/exhaustive-deps
    }, [themeId])
    const webDEVCode = () => {
        // var nameOfDomin = "https://www.amizhthdev.in/subashevent"
        // var nameOfDomin = "https://www.amizhthdev.in/subashk"
        // var nameOfDomin = "https://www.amizhthdev.in/subashes"
        var nameOfDomin = window.location.href
        var name = nameOfDomin.split('/')
        if (name[3] === "") {
            getTheme('', '')
        } else {
            domainName = name[2] + '/' + name[3]
            urlName = name[3]
            sessionStorage.setItem('urlName', urlName)
            getTheme(urlName, nameOfDomin)
        }
    }

    const getTheme = (urlName, domainName) => {
        if (urlName === '') {
            sessionStorage.setItem('theme', 0)
            setThemeId(0)
            navigator(`/`)
        } else {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-TenantID': 'public',
                },
            };
            setPageLoading(true)
            axios.get(`${lookup_URL}?url=${domainName}`, config).then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    if (result.data.response && result.data.response.responseStatus === 'Success') {
                        var data = result.data
                        sessionStorage.setItem('tenantID', data.tenantId)
                        sessionStorage.setItem('theme', data.themeId)
                        sessionStorage.setItem('siteId', data.siteId)
                        setThemeId(data.themeId)
                        // setThemeId(data.themeId)
                        preferencesGetAPI()
                        navigator(`/${urlName}/home`)
                    } else {
                        var error = getErrorStatus(result.data)
                        notifyService(error.type, '', error.message)
                        navigator(`/404`)
                    }
                }
            }).catch((err) => {

            });
            // if (urlName === 'fullblack') {
            //     setThemeId(1)
            //     navigator(`/${urlName}/home`)
            // } else if (urlName === 'medicalblue') {
            //     setThemeId(2)
            //     sessionStorage.setItem('theme', 2)
            //     navigator(`/${urlName}/home`)
            // } else if (urlName === 'meshgradient') {
            //     setThemeId(3)
            //     sessionStorage.setItem('theme', 3)
            //     navigator(`/${urlName}/home`)
            // } else if (urlName === 'bestbag') {
            //     setThemeId(4)
            //     sessionStorage.setItem('theme', 4)
            //     navigator(`/${urlName}/home`)
            // } else if (urlName === 'cybersecurity') {
            //     setThemeId(7)
            //     sessionStorage.setItem('theme', 7)
            //     navigator(`/${urlName}/home`)
            // }
        }

    }
    // const themeGetAPI = () => {
    //     apiService(themeApi, '', 'unauthget')
    //         .then((result) => {
    //             sessionStorage.setItem('theme', result.data.id)
    //             storeAPI()
    //             var id = result.data.id
    //             setThemeId(id)
    //             navigator(`/${urlName}/home`)
    //         }).catch((err) => {

    //         });
    // }
    const preferencesAPI = process.env.REACT_APP_PREFERENCE_GET_URL
    const preferencesGetAPI = () => {
        var id = sessionStorage.getItem('siteId')
        apiService(`${preferencesAPI}?siteid=${id}`, '', 'unauthget')
            .then((result) => {
                if (result && result.data) {
                    sessionStorage.setItem('logo',result.data.logo)
                    sessionStorage.setItem('_pre_info', JSON.stringify(result.data))
                    if (result.data.logo) {
                        var spl = result.data.logo
                        const favicon = document.getElementById("favicon");
                        favicon.setAttribute("href", spl);
                    } else {
                        const favicon = document.getElementById("favicon");
                        favicon.setAttribute("href", 'assets/images/favicon.png');
                    }
                }
            }).catch((err) => {

            });
    }
    // const storeAPI = () => {
    //     apiService(organizationAPI, '', 'unauthget')
    //         .then((result) => {
    //             sessionStorage.setItem('_sto_info', JSON.stringify(result.data))
    //         }).catch((err) => {

    //         });
    // }
    return (
        <div>
            {
                pageLoading &&
                (
                    <Loader />
                )
            }
            {
                themeId === 0 && (
                    <Welcome />
                )
            }
            {
                themeId === 1 && (
                    <Theme1route />
                )
            }
            {
                themeId === 2 && (
                    <Theme2route />
                )
            }
            {
                themeId === 3 && (
                    <Theme3route />
                )
            }
            {
                themeId === 4 && (
                    <Theme4route />
                )
            }
            {
                themeId === 5 && (
                    <Theme5route />
                )
            }
            {
                themeId === 6 && (
                    <Theme6route />
                )
            }
            {
                themeId === 7 && (
                    <Theme7route />
                )
            }
            {
                themeId === 8 && (
                    <Theme8route />
                )
            }
        </div>
    )
}

export default BaseRoute