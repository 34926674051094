import React, { useContext } from 'react'
import { Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DataContext from '../../../../context/DataContext'


const Theme3ourprofile = () => {
    let urlName = sessionStorage.getItem('urlName')
    const {navigator} = useContext(DataContext)
    const handleAboutsus =()=>{
        navigator(`${urlName}/aboutus`)
    }
    return (
        <div className='our-t3-sec mt-5 ' >
            <Container>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className='two-imgs '>
                        <div className='first-imgs text-center'data-aos="fade-right">
                    <img src={'/images/theme3/soori/hand_soori.jpg'} alt="back-img" className='our-prof-1'/>
                    </div>
                    {/* <div  className='first-imgs2'>
                    <img src={'/images/theme3/carousel/caro-3.jpg'} alt="fromt-img" className='our-prof-2'/> 
                    </div> */}
                    <div className='mt-2'>
                                <img src="/images/theme3/Tri-home-1.png" alt="tri" className='tri-bel-out-1' />
                            </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className='right-cont-ourprof'>
                        <h2 className='mt-2'>Biography</h2>
                        <p style={{color:'#7a7a7a',fontSize: '18px',lineheight: '40px'}} className='mt-3'> <span className='soori-bigTit'>Ramalakshmanan Muthuchamy, popularly known as Soori,</span> was born on August 24, 1977 in a small village near Madurai, Tamil Nadu. Growing up in a modest family, he developed a strong sense of determination and work ethic, traits that would later shape his career. His early life revolved around village activities, and despite limited exposure to the outside world, Soori nurtured a passion for acting from a young age.</p>
                        <button className="contact-me-btn-theme3-bp-3 mt-3" onClick={()=>handleAboutsus()}>See More</button>
                    </div>
                </Grid>
            </Grid>
            </Container>
        </div>
    )
}

export default Theme3ourprofile