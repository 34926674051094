import { Grid } from '@mui/material'
import { MdArrowOutward } from "react-icons/md";
import React, { useContext } from 'react'
import DataContext from '../../../../context/DataContext';

const Theme7blog = () => {
  const { navigator } = useContext(DataContext)

  const blogPosts = [
    {
      id: 1,
      title: "How can you improvement to your business policy for the better product.",
      date: "July 17, 2024",
      category: "Operation",
      image: "/images/theme7/blog-1.jpg",
      description: "How can you improvement to your business policy for the better product.",
      link: "#"
    },
    {
      id: 2,
      title: "People population change anything what your need for your next generation.",
      date: "July 17, 2024",
      category: "Operation",
      image: "/images/theme7/blog-5.jpg",
      description: "People population change anything what your need for your next generation.",
    },
    {
      id: 3,
      title: "Improve your business so that you can stay in your local business in next month.",
      date: "July 17, 2024",
      category: "Security",
      image: "/images/theme7/blog-6.jpg",
      description: "Improve your business so that you can stay in your local business in next month.",
    },
    {
      id: 4,
      title: "How can you improvement to your business policy for the better product.",
      date: "July 17, 2024",
      category: "Operation",
      image: "/images/theme7/blog-4.jpg",
      description: "A significant shift in mindset is required to support either type of side project.",
    },
    {
      id: 5,
      title: "People population change anything what your need for your next generation.",
      date: "July 17, 2024",
      category: "Solution",
      image: "/images/theme7/blog-3.jpg",
      description: "Population change anything what your need for your next generation.",
    },
    {
      id: 6,
      title: "Improve your business so that you can stay in your local business in next month.",
      date: "July 17, 2024",
      category: "Operation",
      image: "/images/theme7/blog-2.jpg",
      description: "How to Improve your business so that you can stay in your local business.",
    }
  ];

  const handleBlogmore = (id) => {
    navigator(`/:name/blogmore`)
  }
  return (
    <div>
      <div className='about-section'>
        <Grid container justifyContent="center" alignItems="center" >
          <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
            <div className="section-top-title">
              <h1 className="fadeinup">Blog</h1>
              <ul className="page-list">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>/</li>
                <li>
                  <span>Blog</span>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="mt-5">
        <div className='cyber-timed'>
          <div className="text-center">
            <span className='comp'>Blogs & News</span>
            <h2 className='blogsed'>
              See Our Latest Blog And <br></br>News From Us
            </h2>
          </div>
          <Grid container spacing={3}>
            {blogPosts.map((post, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index} className='mb-4'>
                <div className="service-card">
                  <img
                    className='blog-img img-fluid'
                    alt={post.title}
                    src={post.image}
                  />
                  <div className="service-content mt-3">
                    <span className='blog-cat'>
                      {post.category}
                    </span>
                    <span style={{ color: 'lightgrey' }}>
                      {post.date}
                    </span>
                    <h3 className='blog-ti'>
                      {post.title}
                    </h3>
                    <div className="icon-box">
                      <span className="icon-box-title" onClick={() => handleBlogmore(post.id)}>Read More</span>
                      <span >
                        <MdArrowOutward className="box-title" />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Theme7blog