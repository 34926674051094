import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme7layout from './layouts/Theme7layout'
import Theme7home from './components/home-page/Theme7home'
import Theme7aboutus from './components/aboutuspage/Theme7aboutus'
import Theme7services from './components/servicespage/Theme7services'
import Theme7servicesmore from './components/servicespage/Theme7servicesmore'
import Theme7blog from './components/blogpage/Theme7blog'
import Theme7contactus from './components/contactpage/Theme7contactus'
import Theme7projects from './components/projectspage/Theme7projects'
import Theme7projectsmore from './components/projectspage/Theme7projectsmore'
import Theme7blogmore from './components/blogpage/Theme7blogmore'

const Theme7route = () => {
  document.getElementById('theme').setAttribute('href', '/css/theme7.css');
  return (
    <div>
      <Routes>
        <Route path='/:name' element={<Theme7layout />}>
          <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
          <Route path='/:name/home' element={<Theme7home />} />
          <Route path='/:name/aboutus' element={<Theme7aboutus />} />
          <Route path='/:name/services' element={<Theme7services />} />
          <Route path='/:name/readmore/:id' element={<Theme7servicesmore />} />
          <Route path='/:name/blog' element={<Theme7blog />} />
          <Route path='/:name/blogmore' element={<Theme7blogmore />} />
          <Route path='/:name/projects' element={<Theme7projects />} />
          <Route path='/:name/projectsreadmore' element={<Theme7projectsmore />} />
          <Route path='/:name/contactus' element={<Theme7contactus />} />
        </Route>
        <Route path='/under-maintenance' element={<Undermaintenance />} />
        <Route path='*' element={<Pagenotfound />} />
      </Routes>
    </div>
  )
}

export default Theme7route