import { FormHelperText, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Theme5navbar from '../layouts/Theme5navbar';

const Theme5contactus = () => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    const validatePhoneNumber = (value) => {
        // const isValid = value.length === 10;
        if (value.length !== 10) {
            return 'Phone number must be 10 digits';
        }
        const matches = value.match(
            /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
        );
        if (matches === null) {
            return "Only numbers allowed";
        }
    };
    const validateNumberonly = (e) => {
        const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
            e.preventDefault();
        }
    };
    const onSubmit = (data) => {
        console.log(data);

    };
    return (
        <div className='banner-t5-back'>

            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage'>
                        <h1>Contact Us</h1>
                        <p>Home &nbsp;/ &nbsp;Contact Us</p>
                    </div>
                </div>
            </div>
            <div className='mt-5' data-aos="zoom-up"  data-aos-duration="1000">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <iframe
                            style={{ borderRadius: '15px', border: 0, width: '100%' }}
                            title="google-map"
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3730.9500954897703!2d78.13120084502243!3d9.930841232529497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwNTUnNTEuNSJOIDc4wrAwOCcwMS4xIkU!5e0!3m2!1sen!2sin!4v1722334469432!5m2!1sen!2sin"
                            width="500"
                            height="350"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Grid>
                </Grid>
  </div>
            <div className='mt-5 px-3' >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} data-aos="fade-left"  data-aos-duration="1000">
                        <div className='react-us-btn'>
                            <button>Reach Us</button>
                        </div>
                        <div className='contact-infos'>
                            <h2>We're here to help at the click of a button!</h2>
                            <p className='mt-2'>Leo duis ut diam quam nulla porttitor massa id. Dictum at tempor commodo ullamcorper. Sit amet cursus sit amet sit amet commodo dictum.</p>
                        </div>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} data-aos="fade-right"  data-aos-duration="1000">
                                <div className='cont-dic ml-3'>
                                    <div className='con-icons'>
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className='ml-3 con-details'>
                                        <h5>Regional Office</h5>
                                        <p>SR, Seestrasse St, Zurich, CH</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className='cont-dic ml-3'>
                                    <div className='con-icons'>
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                    <div className='ml-3 con-details'>
                                        <h5>Mail Us</h5>
                                        <p>support@example.com</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className='cont-dic ml-3'>
                                    <div className='con-icons'>
                                        <i class="far fa-comment"></i>
                                    </div>
                                    <div className='ml-3 con-details'>
                                        <h5>Chat With Us</h5>
                                        <p>Get live chat support</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className='cont-dic ml-3'>
                                    <div className='con-icons'>
                                        <i class="fas fa-phone-volume"></i>
                                    </div>
                                    <div className='ml-3 con-details'>
                                        <h5>Call Us</h5>
                                        <p>+00 123 456 789</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="form-boxs">
                            <form onSubmit={handleSubmit(onSubmit)} className='form-paddings' data-aos="fade-right"  data-aos-duration="1000">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="name"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: " Name is required",

                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    // label="Enter Name *"
                                                    label="Your Name"
                                                    placeholder="Enter Name"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    InputProps={{
                                                        style: { borderRadius: '28px' }, // Customize the border radius here
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.name && errors.name.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Mail ID is required",
                                                validate: validateEmail,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    // label="Mail ID *"
                                                    label="Email Address"
                                                    placeholder="Enter Email Address"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    InputProps={{
                                                        style: { borderRadius: '28px' }, // Customize the border radius here
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.email && errors.email.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Phone Number is required",
                                                validate: validatePhoneNumber,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Phone Number *"
                                                    placeholder="Enter Phone Number"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    InputProps={{
                                                        style: { borderRadius: '28px' },
                                                    }}
                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                    inputProps={{ maxLength: 10 }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.phone && errors.phone.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <Controller
                                                name="about"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: `Message is required` }}
                                                render={({ field }) => (
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        fullWidth
                                                        InputProps={{
                                                            style: { borderRadius: '28px' },
                                                        }}
                                                        label="Additional Message"
                                                        placeholder="Enter Additional Message *"
                                                        type="text"
                                                        {...field}
                                                        className="invest-area"
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.about && errors.about.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className='text-center'>
                                    <button className="contact-btns" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </div></div>
    )
}

export default Theme5contactus