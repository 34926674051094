import React, { useEffect, useState } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import Rating from '@mui/material/Rating';

const Theme5testimonial = () => {
    const [screenSize, setScreenSize] = useState(3);
    useEffect(() => {
        size();
    }, []);

    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(3);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(3);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(3);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(3);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(1);
        } else {
            setScreenSize(1);
        }
    };
    const crdImage = [

        {
            title: "I Love the outstanding quality",
            msg: "Lacus vel facilisis volutpat est velit. Consequat id porta nibh venenatis cras sed. Odio ut enim blandit volutpat maecenas nunc vel risus. Enim sed faucibus turpis in.",
            name: "Graeme Mclaughlin",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-1.jpg"
        },
        {
            title: "Reliable and durable products",
            msg: "Ridiculus mus mauris vitae ultricies leo integer. Et malesuada fames ac turpis egestas. Convallis aenean et tortor at rutrum quisque non risus viverra consecteturm.",
            name: "Graeme Mclaughlin",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-2.jpg"
        },
        {
            title: "Best user-friendly site",
            msg: "Lacus vel facilisis volutpat est velit. Consequat id porta nibh venenatis cras sed. Odio ut enim blandit volutpat maecenas nunc vel risus. Enim sed faucibus turpis in.",
            name: "Ellis Marshall",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-3.jpg"
        },
        {
            title: "Superb after-sales support",
            msg: "Lacus vel facilisis volutpat est velit. Consequat id porta nibh venenatis cras sed. Odio ut enim blandit volutpat maecenas nunc vel risus. Enim sed faucibus turpis in.",
            name: "Graeme Mclaughlin",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-1.jpg"
        },
        {
            title: "Expert guidance service",
            msg: "Lacus vel facilisis volutpat est velit. Consequat id porta nibh venenatis cras sed. Odio ut enim blandit volutpat maecenas nunc vel risus. Enim sed faucibus turpis in.",
            name: "Graeme Mclaughlin",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-3.jpg"
        },
        {
            title: "Value for money",
            msg: "Lacus vel facilisis volutpat est velit. Consequat id porta nibh venenatis cras sed. Odio ut enim blandit volutpat maecenas nunc vel risus. Enim sed faucibus turpis in.",
            name: "Graeme Mclaughlin",
            city: "Los Angles, CA",
            img: "/images/theme5/testi-3.jpg"
        },

    ]
    return (
        <div className='mt-5'>
            <div className='text-center' >
                <span className='chip-abt'>TESTIMONIALS</span>
                <h1 className='mt-3'>Our Customer Feedback</h1>
            </div>
            <div className='mt-5'>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={screenSize}
                    navigation
                    loop
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    modules={[Autoplay, Navigation]}
                   
                    // pagination={{
                    //     clickable: true,
                    //     renderBullet: function (index, className) {
                    //         return `<span class="${className}" style="background-color:blue;"></span>`;
                    //     },
                    // }}
                >
                    {crdImage && crdImage.map((a, i) => (
                        <SwiperSlide key={i}>
                            <div className="swiper-items-testi-t5" data-aos="fade-down">
                                <Rating name="size-medium" defaultValue={2} size='small'/>
                                <h5 className='testi-new-css-tt mt-3'>{a.title}</h5>
                                <p className='testi-new-css-p mt-3 '>{a.msg}</p>
                                <div className='msg-border-t5 mt-5'></div>
                                <div className='cust-testi-name mt-3'>
                                    <div>
                                        <img src={a.img} alt="Products" className='t5-testi-caros mb-2' />
                                    </div>
                                    <div>
                                        <p className='use-nam-t5'>{a.name}</p>
                                        <p className='use-nam-t5-city'>{a.city}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}

                </Swiper>
            </div>
        </div>
    )
}

export default Theme5testimonial