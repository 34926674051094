import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import Theme5navbar from '../layouts/Theme5navbar'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Theme5Productinfo from './Theme5Productinfo';

const Theme5shopdetails = () => {
    const [VariantImage, setVariantImage] = useState('');
    const [selectedColor, setSelectedColor] = useState(null);
    const [count, setCount] = useState(0);
    const [activeChip, setActiveChip] = useState("");
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const colors = [
        { name: "Beige", hex: "#EED0B5" },
        { name: "Green", hex: "#A8E6A1" },
        { name: "Blue", hex: "#BCE2ED" },
        { name: "Purple", hex: "#C1B3E6" },
        { name: "Pink", hex: "#E6A1A8" },
    ];
    const variImg = [
        {
            variImg: '/images/theme5/products/Prod-1.png',
        },
        {
            variImg: '/images/theme5/products/Prod2.png',
        },
        {
            variImg: '/images/theme5/products/Prod-1.png',
        },
    ];

    const handleChipClick = (chip) => {
        setActiveChip(chip);
        debugger
    };
    const addItem = () => {
        if (count >= 0) {
            setCount(count + 1);
        }
    };
    const subItem = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };
    const crdImage = [
        {
            name: "Charger Dock",
            img: "/images/theme5/products/caro-prod1.png"
        },
        {
            name: "Warranty Cards",
            img: "/images/theme5/inside-box.png"
        },
        {
            name: "Wireless Charger",
            img: "/images/theme5/products/caro-prod3.png"
        }
    ]
    return (
        <div className='banner-t5-back'>
            <div className='background-abt-t5 mb-5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Shop</h1>
                        <p>Home &nbsp;/ &nbsp;Smart Watches &nbsp;/ &nbsp;5G Smartphone</p>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                   <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <div className=''>

                        <div>
                            <div className="main-crd">
                                {VariantImage ? (
                                    <img
                                        src={VariantImage}
                                        alt="VariantImage"
                                        className='variant-images-prod-th3'
                                        style={{
                                            
                                        }}
                                    />
                                ) : (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        src={'/images/theme5/products/Prod-1.png'}
                                        alt="Product Image"
                                        className='variant-images-prod-th3'
                                       
                                    />
                                )}
                            </div>
                        </div>
                        <div className='variant-images-t3'>
                            {variImg &&
                                variImg.map((a, i) => (

                                    <div key={i}>
                                        <div
                                            className=' variant-cards-t5 c-pointer'
                                            onClick={() => setVariantImage(a.variImg)}
                                        >
                                            <img

                                                src={a.variImg}
                                                alt={`Variant ${i + 1}`}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '90px',
                                                    margin: '0px auto',
                                                    borderRadius: '18px',
                                                    border: ' 1px solid lightgray'
                                                }}
                                            />
                                        </div>
                                    </div>

                                ))}
                        </div>
                    </div>
                </Grid>
                    <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
                    <div className='content-right-prod' data-aos="fade-right"  data-aos-duration="1000">
                        <h3>5G Smartphone</h3>
                        <p>Etiam quis lobortis odio, at sodales mauris. Morbi fermentum pretium ligula, id efficitur quam mattis in. Fusce nec pretium ante. Vivamus vitae bibendum lacus, ac varius ligula.</p>
                        <h4>₹80.70 – ₹130.85</h4>
                        <div className="color-picker mt-3">
                            <p>Color</p>
                            <div className="color-options">
                                {colors.map((color, index) => (
                                    <div
                                        key={index}
                                        className={`color-circle ${selectedColor === color.hex ? "selected" : ""}`}
                                        style={{ backgroundColor: color.hex }}
                                        onClick={() => handleColorClick(color.hex)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <p>Storage</p>
                            <div className="chips">
                                <Button
                                    variant={activeChip === "128 GB" ? "contained" : "outlined"}
                                    className={activeChip === "128 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("128 GB")}
                                >
                                    128 GB
                                </Button>
                                <Button
                                    variant={activeChip === " 1 TB" ? "contained" : "outlined"}
                                    className={activeChip === " 1 TB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick(" 1 TB")}
                                >
                                    1 TB
                                </Button>
                                <Button
                                    variant={activeChip === " 256 GB" ? "contained" : "outlined"}
                                    className={activeChip === " 256 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick(" 256 GB")}
                                >
                                    256 GB
                                </Button>
                                <Button
                                    variant={activeChip === "512 GB" ? "contained" : "outlined"}
                                    className={activeChip === "512 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("512 GB")}
                                >
                                    512 GB
                                </Button>
                                <Button
                                    variant={activeChip === "64 GB" ? "contained" : "outlined"}
                                    className={activeChip === "64 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("64 GB")}
                                >
                                    64 GB
                                </Button>
                            </div>
                        </div>
                        {/* <div className='t5-prod-actions'>
                            <div className='add-cart-t5'>
                                <div className="checkout-count">
                                    <h6 onClick={() => subItem()} style={{ fontweight: '600' }}>-</h6>
                                    <span style={{ fontweight: '600' }}>{count}</span>
                                    <h6 onClick={() => addItem()} style={{ fontweight: '600' }}>+</h6>
                                </div>

                                <div>
                                    <button className='t5-cart-btn'>Add To Cart</button>
                                </div>
                            </div>
                            <div>
                                <button className='t5-buy-btn'>Buy Now</button>
                            </div>
                        </div> */}
                    </div>
                    </Grid>
                </Grid>
            </div>
            <div className='products-t5-back-prod-new'>
                <div className='info-t5-shopdet'>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Description" value="1" sx={{fontWeight:'600',fontSize:'18px',textTransform:'none'}}/>
                                    <Tab label="Additional Information" value="2" sx={{fontWeight:'600',fontSize:'18px',textTransform:'none'}}/>
                                </TabList>
                            </Box>
                            <TabPanel value="1"><Theme5Productinfo /></TabPanel>
                            <TabPanel value="2">
                                <table class="table table-bordered mt-5" style={{background:'#f8f8f8'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{fontWeight:'600',textAlign:'center'}}>Weight</td>
                                            <td>1.5 kg</td>

                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'600',textAlign:'center'}}>Dimensions</td>
                                            <td>10 × 8 × 3 cm</td>

                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'600',textAlign:'center'}}>Color</td>
                                            <td>Desert Sand, Green, Powder Blue, Purple, Rose</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'600',textAlign:'center'}}>Storage</td>
                                            <td>128GB, 1TB, 256GB, 512GB, 64GB</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel value="3">Item Three</TabPanel>
                        </TabContext>
                    </Box>
                </div>
                <div className='disc-t3-prod mt-5'>
                    <div className='text-center'>
                        <span className='chip-abt'>Exchange Bonus</span>
                        <h1 className='mt-3'>What’s Inside The Box?</h1>
                    </div>
                    <div className='mt-5' data-aos="fade-up"  data-aos-duration="1000">
                        <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                            {crdImage && crdImage.map((a, i) => (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="swiper-items-prod-t3-shop-det">
                                        <img src={a.img} alt="Products" className='t5-prods-caros-caros-blog mb-2' />
                                        <div className='text-center mt-4'>
                                            <h3 className='prod-t5-gad-'>{a.name}</h3>
                                            <Link className='t5-featue-links'>View Specs</Link>
                                        </div>
                                    </div>
                                </Grid>
                            ))}

                        </Grid>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Theme5shopdetails