import React, { useContext, useEffect, useState } from 'react'
import Theme6navbar from '../../layouts/Theme6navbar'
import { Container } from 'react-bootstrap'
import { Grid, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper/modules";
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import YouTubeEmbed from '../../../../context/YouTubeEmbed';
const Theme6home = () => {
    const [screenSize, setScreenSize] = useState(3);
    const { homePageGetApi, homeSection, bannerData, aboutData, serviceData, testimonialData,videoLinkData } = useContext(DataContext);
    debugger
    useDidMountEffect(() => {
        homePageGetApi()
        console.log(homeSection);
        // if (result && result.data) {

        // setOverAll(result.data)
        // console.log(result.data);
        // console.log(imageType);
        // if (result.data.json) {
        //     setJsonData(result.data.json)
        // }

        // }
        size();
    }, []);
    useEffect(() => {
        size();
    }, []);
    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(3);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(3);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(3);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(3);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(3);
        } else {
            setScreenSize(1);
        }
    };
    const services = [
        {
            imgSrc: "/images/Theme6/pets.png",
            title: "Creative Design",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        },
        {
            imgSrc: "/images/Theme6/light-blub.png",
            title: "Innovative Ideas",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        },
        {
            imgSrc: "/images/Theme6/flask.png",
            title: "Well Analysed",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        },
        {
            imgSrc: "/images/Theme6/monitor.png",
            title: "Completely Responsive",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        },
        {
            imgSrc: "/images/Theme6/plus.png",
            title: "Lots of Features",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        },
        {
            imgSrc: "/images/Theme6/magic-wand.png",
            title: "Creative Special Settings",
            description:
                "There are many variations of passages of Lorem Ipsum available, but the joy have suffered alteration in some format, by injected humour."
        }
    ];
    return (
        <>
            <div className='bg-t6' style={{ backgroundImage: `url(${bannerData.bannerImageURL})` }} id='Home'>
                <Theme6navbar />
                <div className='banner-content'>
                    {/* <h1>{bannerData.bannerTitle}</h1> */}
                    <Typography variant="h1" component="h1" sx={{ color: bannerData.fontColor, fontFamily: bannerData.fontStyle }}>{bannerData.bannerTitle}</Typography>
                    {/* <p>{bannerData.bannerDescription}</p> */}
                    <Typography variant="p" component="p" sx={{ color: bannerData.fontColor, fontFamily: bannerData.fontStyle }}>{bannerData.bannerDescription}</Typography>
                </div>
                {bannerData.backgroundColor && (
                    <Typography variant="div" component="div" sx={{ background: bannerData.backgroundColor }} className='background-layour'></Typography>
                )}
            </div>
            {/* session Who we are */}
            <div className='who-we' id='About'>
                <Typography variant="h2" component="h2" sx={{ color: aboutData.fontColor, fontFamily: aboutData.fontStyle }}>{aboutData.aboutTitle}</Typography>
                <Typography variant="p" component="p" sx={{ color: aboutData.fontColor, fontFamily: aboutData.fontStyle }}>{aboutData.aboutDescription}</Typography>

            </div>
            <Container className='mt-5'>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2 mb-2'>
                        <Typography variant="p" component="p" sx={{ color: aboutData.fontColor, fontFamily: aboutData.fontStyle }}>{aboutData.firstParagraph}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2 mb-2'>
                        <Typography variant="p" component="p" sx={{ color: aboutData.fontColor, fontFamily: aboutData.fontStyle }}>{aboutData.secondParagraph}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2 mb-2'>
                        <Typography variant="p" component="p" sx={{ color: aboutData.fontColor, fontFamily: aboutData.fontStyle }}>{aboutData.thirdParagraph}</Typography>
                    </Grid>
                </Grid>
            </Container>
            {/* <div className='who-we'>
                <h2>{aboutData.aboutTitle}</h2>
            </div>
            <Container className='mt-5'>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <p>{aboutData.aboutDescription}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                       <img src={aboutData.aboutImageURL} alt="" />
                    </Grid>
                    
                </Grid>
            </Container> */}
            {/* feacture-img */}
            {/* <div className='features'>
                <Container >
                    <img src="/images/Theme6/feature-img.png" alt="" className='img-fluid' />
                    <h5>Foreal is a multipurpose theme with rich features and unique layout that stands out majestically…
                    </h5>
                    <button>Purchase Now</button>
                </Container>
            </div> */}
            {/* OUR SERVICES */}
            <div className="services" id='Service'>
                <h2>{serviceData.serviceTitle}</h2>
                <p>{serviceData.serviceDescription}</p>
            </div>
            <div>
                <Container className='mt-5'>
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>

                        {serviceData?.services?.map((service, index) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='px-2 mt-3' key={index}>
                                <div className="service-box">
                                    <div className='service-box-icon'>
                                        <img src={service.imagesURL} alt={service.title} className='img-fluid print' />
                                        <h4>{service.title}</h4>
                                    </div>
                                    <p className='mt-2'>{service.description}</p>
                                    <div className='service-box-big-icon'>
                                        <img src={service.imagesURL} alt={service.title} className='img-fluid print' />
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
            {/* <Swiper
                    direction={'horizontal'}
                    slidesPerView={screenSize}
                    spaceBetween={30}
                    mousewheel={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Mousewheel]}
                    className="mySwiper"
                > */}

            {/* NEW WORKS */}
            <div className="New-Works" id='Videos'>
                <h2>OUR VIDEOS</h2>
                {/* <p>The only way to get rid of a temptation is to yield to it. Go for it…Foreal!</p> */}
            </div>
            <div>
            <Container >
                <Swiper
                    // direction={'horizontal'}
                    slidesPerView={screenSize}
                    spaceBetween={30}
                    mousewheel={true}
                    pagination={{
                        clickable: true,
                    }}
                      autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                    modules={[Autoplay,Mousewheel,Navigation]}
                    className="mySwiper"
                >
                    {/* <img src="/images/Theme6/img-1.jpg" alt="" className='img-fluid' /> */}
                    {
                        videoLinkData?.videoLists?.map((value, index) => (
                            <SwiperSlide>
                                <div className='about-img-div'>
                                    <YouTubeEmbed videoUrl={value.videoURL} />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </Container>
            </div>
            {/* MASTER PIECE */}
            {/* <div>
                <div className="master-piece">
                    <h2>MASTER PIECE</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
                </div>
                <Container className='mt-5'>
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="piece-box">
                                <img src="/images/Theme6/trophy.png" alt="" />
                                <h3>125</h3>
                                <span>AWARDS</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="piece-box">
                                <img src="/images/Theme6/water.png" alt="" />
                                <h3>1000</h3>
                                <span>PROJECTS</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="piece-box">
                                <img src="/images/Theme6/mouse.png" alt="" />
                                <h3>30</h3>
                                <span>CREATIVITY</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className='px-2'>
                            <div className="piece-box">
                                <img src="/images/Theme6/thumbs-up.png" alt="" />
                                <h3>252</h3>
                                <span>CLIENTS</span>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
            <div id='Testimonials'>
            <Container className='mt-5'>
                <div className="mycarsoual">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                                // Add margin-top to the bullet element
                                return `<span class="${className}" style="background-color: gray;"></span>`;
                            },
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {testimonialData?.testimonials?.map((item, i) => (
                            <SwiperSlide>
                                <div className='myswip'>
                                    <img src="/images/Theme6/quote.png" alt="" />
                                    <p className='mt-4'>{item.description}</p>
                                    <h3>{item.title}</h3>
                                    <h5>{item.position}</h5>
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </Container>
            </div>
        </>
    )
}

export default Theme6home