import React from 'react'
import { Outlet } from 'react-router-dom'
import Theme3footer from './Theme3footer'

const Theme3layout = () => {
  return (
    <div>
      <Outlet></Outlet>
      <Theme3footer />
    </div>
  )
}

export default Theme3layout