import React from 'react'
import Theme3navbar from '../../layouts/Theme3navbar'
import { MdKeyboardArrowRight } from "react-icons/md";
import { Container, Grid } from '@mui/material';
import Theme3logobanner from '../logos-banner/Theme3logobanner';

const Theme3schedule = () => {
  const data = [
    {
      "id": 1,
      "date": "14 Aug 2020",
      "imageSrc": "/images/theme3/sch-1.jpg",
      "title": "Culpaqui officia deserunt mollit anim.",
      "location": "Gelora Pancasila",
      "time": "20:30 - 21:30",
      "description": "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur officia deserunt mollit anim id est laborum."
    },
    {
      "id": 2,
      "date": "15 Sep 2020",
      "imageSrc": "/images/theme3/sch-2.jpg",
      "title": "Lorem ipsum dolor sit amet.",
      "location": "Gelora Pancasila",
      "time": "20:30 - 21:30",
      "description": "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      "id": 3,
      "date": "21 Jul 2020",
      "imageSrc": "/images/theme3/sch-3.jpg",
      "title": "Ut enim ad minim veniam.",
      "location": "Gelora Pancasila",
      "time": "20:30 - 21:30",
      "description": "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
  ];
  // const releasedfilms = [
  //   "/images/theme3/soori/film/Etharkkum_Thunindhavan.jpg",
  //   "/images/theme3/soori/film/Garudan_2024_poster.jpg",
  //   "/images/theme3/soori/film/Gemini_Ganeshanum_Suruli_Raajanum_film_poster.jpg",
  //   "/images/theme3/soori/film/Idhu_Namma_Aalu_(2016_film).jpg",
  //   "/images/theme3/soori/film/Jeeva_(2014).jpg",
  //   "/images/theme3/soori/film/Kadaikutty_Singam_poster.jpg",
  //   "/images/theme3/soori/film/Kalavani_movie_poster.jpg",
  //   "/images/theme3/soori/film/Kaththi_Sandai_poster.jpg",
  //   "/images/theme3/soori/film/Kedi-Billa-Killadi-Ranga1.jpg",
  //   "/images/theme3/soori/film/Kennedy_Club_poster.jpg",
  //   "/images/theme3/soori/film/Kombu_Vatcha_Singamda_poster.jpg",
  //   "/images/theme3/soori/film/Kullanari_Koottam.jpg",
  //   "/images/theme3/soori/film/Maan_Karate.jpg",
  //   "/images/theme3/soori/film/Manam_kothi_paravai.jpg",
  //   "/images/theme3/soori/film/Mapla_Singam_poster.jpg",
  //   "/images/theme3/soori/film/Marudhu_poster.jpg",
  //   "/images/theme3/soori/film/Naan_Mahaan_Alla_2010_poster.jpg",
  //   "/images/theme3/soori/film/Naiyaandi_poster.jpg",
  //   "/images/theme3/soori/film/Namma_Veetu_Pillai.jpg",
  //   "/images/theme3/soori/film/Nimirnthu_nil.jpg",
  //   "/images/theme3/soori/film/Oru_Oorla_Rendu_Raja_film_poster.jpg",
  //   "/images/theme3/soori/film/Paayum_Puli_2015.jpg",
  //   "/images/theme3/soori/film/Poraali_poster.jpg",
  //   "/images/theme3/soori/film/Prince_2022_poster.jpg",
  //   "/images/theme3/soori/film/Pulivaal_Poster.jpg",
  //   "/images/theme3/soori/film/Rajinimurugan_poster.jpg",
  //   "/images/theme3/soori/film/Rummy_Poster.jpg",
  //   "/images/theme3/soori/film/Sakalakala_Vallavan_Appatakkar.jpg",
  //   "/images/theme3/soori/film/Saamy_Square.jpg",
  //   "/images/theme3/soori/film/Sangathamizhan_poster.jpg",
  //   "/images/theme3/soori/film/Sangili_Bungili_Kadhava_Thorae.jpg",
  //   "/images/theme3/soori/film/Saravanan_Irukka_Bayamaen.jpg",
  //   "/images/theme3/soori/film/Seemaraja_poster.jpg",
  //   "/images/theme3/soori/film/Si3_film_poster.jpg",
  //   "/images/theme3/soori/film/Sketch_Release_Poster.jpg",
  //   "/images/theme3/soori/film/Sundarapandian_poster.jpg",
  //   "/images/theme3/soori/film/The_Adamant_Girl_poster.jpg",
  //   "/images/theme3/soori/film/Thondan_poster.jpg",
  //   "/images/theme3/soori/film/Udanpirappe_poster.jpg",
  //   "/images/theme3/soori/film/Varuthapadatha_Valibar_Sangam_Poster.jpg",
  //   "/images/theme3/soori/film/Vedhalam.jpg",
  //   "/images/theme3/soori/film/Velainu_Vandhutta_Vellaikaaran.jpg",
  //   "/images/theme3/soori/film/Velayudham_poster.jpg",
  //   "/images/theme3/soori/film/Velan_2021_film_poster.jpg",
  //   "/images/theme3/soori/film/Vennila_Kabadi_Kuzhu.jpg",
  //   "/images/theme3/soori/film/Vennila_Kabaddi_Kuzhu_2_poster.jpg",
  //   "/images/theme3/soori/film/Viduthalai_Part_1.jpg",
  //   "/images/theme3/soori/film/Viruman.jpeg"

  // ]
  const releasedfilms =[
    "/images/theme3/soori/Garuda_posters.jpg",
    "/images/theme3/soori/kootukalippos.jpg",
    "/images/theme3/soori/viduthalaiposter.jpg"
  ]
  return (
    <>
      <div className='bg-colors'>
        <Theme3navbar />
        <div className='abt-page-t3 text-center'>
          <h1>Filmography</h1>
        </div>
      </div>
      <Container>
        <div className='sch-list mt-5'>
          <Grid container spacing={5}>
            {/* {data && data.map((a, i) => (
              <>
                {i % 2 === 0 ? (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className='sch-left-img-div'>
                        <img src={a.imageSrc} className="images-left-sche-new" alt="img" />
                        <div className='white-dates'>
                          <p>{a.date}</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className='sch-right-cont'>
                        <h2>{a.title}</h2>
                        <p>{a.description}</p>
                        <div className='d-flex '>
                          <p className='mr-2'><span><img src="/images/theme3/location-pin.png" alt="loc" style={{ width: "100%", maxWidth: '17px', marginRight: '5px' }} /></span>{a.location}</p>
                          <p><span><img src="/images/theme3/back-in-time.png" alt="loc" style={{ width: "100%", maxWidth: '17px', marginRight: '5px' }} /></span>{a.time}</p>
                        </div>
                      </div>
                      <div className='our-sch-action'>
                        <button className="sch-act-btn-theme3">Ticket & info</button>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className='sch-right-cont'>
                        <h2>{a.title}</h2>
                        <p>{a.description}</p>
                        <div className='d-flex '>
                          <p className='mr-2'><span><img src="/images/theme3/location-pin.png" alt="loc" style={{ width: "100%", maxWidth: '17px', marginRight: '5px' }} /></span>{a.location}</p>
                          <p><span><img src="/images/theme3/back-in-time.png" alt="loc" style={{ width: "100%", maxWidth: '17px', marginRight: '5px' }} /></span>{a.time}</p>
                        </div>
                      </div>
                      <div className='our-sch-action'>
                        <button className="sch-act-btn-theme3 ">Ticket & info</button>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className='sch-left-img-div'>
                        <img src={a.imageSrc} className="images-left-sche-new" alt="img" />
                        <div className='white-dates'>
                          <p>{a.date}</p>
                        </div>
                      </div>
                    </Grid>
                  </>
                )}
              </>
            ))} */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='right-cont-ourprof'>
                <h2 className='mt-2'>Soori
                  Muthuchamy</h2>
                <p style={{ color: '#7a7a7a', fontSize: '18px', lineheight: '40px' }} className='mt-3'> Actor | Comedian</p>
                <h4>Filmography</h4>
                <p>Soori is a talented actor in the Indian film industry, especially known for his work in Tamil cinema. He made his debut in the 2010 film Easan and quickly gained attention for his humor.
                  Over the years, Soori has showcased his versatility in many films, excelling in both comedic and serious roles. His breakthrough came with the 2013 hit Varuthapadatha Valibar Sangam, which established him as a key figure in the industry.</p>
                <p>With over a decade of experience, Soori has become a household name, known for his relatable characters and engaging performances in both commercial successes and critically acclaimed films. He has also explored web series and short films, further demonstrating his adaptability. Soori's contributions have left a lasting impact on Indian cinema, endearing him to fans across different demographics.</p>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <div className='text-center mt-5 '>
          <p className='c-pointer' style={{ color: '#cb995a' }}>Explore More<MdKeyboardArrowRight style={{ fontSize: '24px', fontWeight: '600' }} /></p>
        </div> */}
        <Container>
        <div className='released_film mt-5'>
          <div>Releaed Films</div>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={5}>
            {releasedfilms && releasedfilms.map((film, i) => (
              <Grid xs={12} sm={12} md={3} lg={3} xl={3} key={i}>
                <img src={film} alt="films" className='img-fluid' />
              </Grid>
            ))}
          </Grid>
        </div>
        </Container>
      </Container>



      <div className='mt-5'>
        {/* <Theme3logobanner/> */}
        <div className='abou-t3-counts mt-5'>
          <Container>
            <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className='t3-count-content'>
                  <h2>126 +</h2>
                  <p>Number of Comedian Roles</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className='t3-count-content'>
                  <h2>4 + </h2>
                  <p>Number of Hero Roles</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className='t3-count-content'>
                  <h2>4 +</h2>
                  <p>Number of Awards</p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Theme3schedule;
