import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { Grid } from '@mui/material';

const Theme8blog = () => {
  const { navigator } = useContext(DataContext)

  const handlehome = () => {
    navigator('/:name/home')
  }

  const handleabout = () => {
    navigator('/:name/home/about')
  }

  const handleblog = () => {
    navigator('/:name/home/blog')
  }

  const handlepage = () => {
    navigator('/:name/home/pages')
  }

  const handlecontact = () => {
    navigator('/:name/home/contact')
  }
  return (
    <>
      <section className='nav-bar'>
        <nav className="navbar">
          <ul className="nav-links">
            <li><a href="#" onClick={handlehome} >Home</a></li>
            <li><a href="#" onClick={handleabout} >About Us</a></li>
            <li><a href="#" onClick={handleblog} >Blog</a></li>
            <li><a href="#" onClick={handlepage} >Pages</a></li>
            <li><a href="#" onClick={handlecontact} >Contact Us</a></li>
          </ul>
          <div className="social-icons">
            <FacebookOutlinedIcon className='social-icon-clr' />
            <TwitterIcon className='social-icon-clr' />
            <YouTubeIcon className='social-icon-clr' />
            <InstagramIcon className='social-icon-clr' />
          </div>
        </nav>
      </section>

      <section className='blog-bg'>
        <div className='about-page-blue-bg'>
          <h1 className='text-center' style={{ color: '##2a2a34' }} >Blog</h1>
        </div>

        <div className='about-page-grid'>
          <div className='container'>
            <Grid container justifyContent='center' alignItems='flex-start' spacing={5} >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className='card-blog1'>
                  <img src='/images/theme8/blog1.png' className='blog1-img' />
                  <div className='blog-main'>
                    <div className='blog-icons-dflex'>
                      <span className='blog-icons'>
                        <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>31 May 2024</p>
                      </span>
                      <span className='blog-icons1'>
                        <PersonIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>By - Demo_admin</p>
                      </span>
                      <span className='blog-icons1'>
                        <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>0 Comments</p>
                      </span>
                    </div>
                    <h3 className='my-3'>Electrical Tower & Wire Installation</h3>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                    <button className='gra-btn'>Read More</button>
                  </div>
                </div>

                <div className='card-blog1 my-5'>
                  <img src='/images/theme8/blog3.png' className='blog1-img' />
                  <div className='blog-main'>
                    <div className='blog-icons-dflex'>
                      <span className='blog-icons'>
                        <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>31 May 2024</p>
                      </span>
                      <span className='blog-icons1'>
                        <PersonIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>By - Demo_admin</p>
                      </span>
                      <span className='blog-icons1'>
                        <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>0 Comments</p>
                      </span>
                    </div>
                    <h3 className='my-3'>Electrical Tower & Wire Installation</h3>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                    <button className='gra-btn'>Read More</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className='card-blog1'>
                  <img src='/images/theme8/blog2.png' className='blog1-img' />
                  <div className='blog-main'>
                    <div className='blog-icons-dflex'>
                      <span className='blog-icons'>
                        <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>31 May 2024</p>
                      </span>
                      <span className='blog-icons1'>
                        <PersonIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>By - Demo_admin</p>
                      </span>
                      <span className='blog-icons1'>
                        <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>0 Comments</p>
                      </span>
                    </div>
                    <h3 className='my-3'>Electrical Tower & Wire Installation</h3>
                    <p style={{ color: '#707070' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dsdio eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ensdsim aedi minim veniam, quis nostrud exercitation. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat…</p>
                    <button className='gra-btn'>Read More</button>
                  </div>
                </div>

                <div className='card-blog2 mt-5'>
                  {/* <img src='/images/theme8/blog2.png' className='blog1-img' /> */}
                  <div className='blog-main'>
                    <div className='blog-icons-dflex'>
                      <span className='blog-icons'>
                        <CalendarMonthIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>31 May 2024</p>
                      </span>
                      <span className='blog-icons1'>
                        <PersonIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>By - Demo_admin</p>
                      </span>
                      <span className='blog-icons1'>
                        <QuestionAnswerRoundedIcon style={{ color: '#ff3a47' }} />
                        <p className='ml-1' style={{ color: '#2a2a34' }}>0 Comments</p>
                      </span>
                    </div>
                    <h3 className='my-3'>Hello world</h3>
                    <p style={{ color: '#707070' }}>Welcome to Ovation Themes Demos Sites. This is your first post. Edit or delete it, then start writing!</p>
                    <button className='gra-btn'>Read More</button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </>
  )
}

export default Theme8blog