import React, { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'


const Theme3navbar = () => {
  let urlName = sessionStorage.getItem('urlName')
  const [activeLink, setActiveLink] = useState('/home'); // Default to home

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <div>
      <div className='navcont-t3'>
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-transparent pt-5">
        <Link className="navbar-brand nav-log-res1 mt-0" to="/" >
          <img src="/images/theme3/logo.png" alt="Logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="anavbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          
             <Nav.Link as={NavLink} to={`/${urlName}/home`} className="nav-item nav-mail active"  >Home</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/aboutus`} className="nav-item nav-mail">About</Nav.Link>
              <Nav.Link as={NavLink} to={`/${urlName}/schedule`} className="nav-item nav-mail">Schedule</Nav.Link>
              <Nav.Link as={NavLink}  to={`/${urlName}/onpress`}  className="nav-item nav-mail">Press</Nav.Link>
              <Nav.Link as={NavLink}  to={`/${urlName}/news`} className="nav-item nav-mail">News</Nav.Link>
              <button className='nav-t3-btns'>Contact</button>
        </div>
      </nav> */}
       <nav className="navbar navbar-expand-lg navbar-light bg-transparent pt-5">
  <Link className="navbar-brand nav-log-res1 mt-0" to="/">
    {/* <img src="/images/theme3/logo.png" alt="Logo" /> */}
    <div class="keyboard">
  <span class="key">S</span>
  <span class="key">O</span>
  <span class="key">O</span>
  <span class="key">R</span>
  <span class="key">I</span>
  <br/>
</div>
  </Link>
  <button
    className="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ml-auto" style={{alignItems:'center'}}>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/home`} activeClassName="active">Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/aboutus`} activeClassName="active">Biography</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/schedule`} activeClassName="active">Filmography</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/gallery`} activeClassName="active">Gallery</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/onpress`} activeClassName="active">Media</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link-t3" to={`/${urlName}/news`} activeClassName="active">News</NavLink>
      </li>
      {/* <li className="nav-item">
        <button className='nav-t3-btns'>Contact</button>
      </li> */}
    </ul>
  </div>
</nav>
      </div>
    </div>

  )
}

export default Theme3navbar