import { Container, Grid, Pagination, Stack } from '@mui/material'
import React, { useContext } from 'react'
import DataContext from '../../../../context/DataContext';

const Theme1blog = () => {
  const { navigator } = useContext(DataContext)

  const blogPosts = [
    {
      id: 1,
      title: 'Why MK Stalin wants to elevate Udhayanidhi now?',
      date: 'August 2023',
      author: 'Admin',
      categories: ['Lorem ipsum', 'aenean, vulputate'],
      image: '/images/theme1/dmkloksabha_4.jpg',
      description: 'This blog explores the strategic moves made by Tamil Nadu Chief Minister MK Stalin to elevate his son, Udhayanidhi Stalin, within the ranks of the DMK. The article examines the timing and political reasons behind this move, including Udhayanidhi growing influence within the party’s youth wing and his role in upcoming elections. It also looks at the broader political landscape in Tamil Nadu and how Udhayanidhi increased responsibilities might shape the future of DMK.',
      readMoreLink: '/#',
    },
    {
      id: 2,
      title: 'Udhayanidhi Stalin: From Cinema to Politics',
      date: 'June 2024',
      author: 'Admin',
      categories: ['Lorem ipsum', 'aenean, vulputate'],
      image: '/images/theme1/blog-back3.png',
      description: 'This blog details Udhayanidhi Stalin’s journey from being a successful film producer and actor in Tamil cinema to becoming a prominent political figure. It covers his early work in the film industry, founding his production company Red Giant Movies, and his acting debut. The article transitions to his political career, highlighting key milestones like his election to the Tamil Nadu Legislative Assembly and his ministerial role.',
      readMoreLink: '/#',
    },
    {
      id: 3,
      title: 'Udhayanidhi Stalin’s Sanatan Dharma Comments and Political Fallout',
      date: 'June 2024',
      author: 'Admin',
      categories: ['Lorem ipsum', 'aenean, vulputate'],
      image: '/images/theme1/blog-back2.png',
      description: 'This article discusses Udhayanidhi Stalin’s controversial comments on Sanatan Dharma during a political event, where he criticized the religious concept. The remarks sparked nationwide political debates, with opposition parties strongly reacting to his statement. The blog delves into the political implications of the controversy, how it affected Udhayanidhi standing both within Tamil Nadu and nationally, and explores his justification for the comments as a call against caste-based discrimination.',
      readMoreLink: '/#',
    },
    // {
    //   id: 4,
    //   title: 'Nullam Dictum Felis',
    //   date: 'March 1, 2024',
    //   author: 'Admin',
    //   categories: ['Lorem ipsum', 'aenean, vulputate'],
    //   image: '/images/theme1/fo-4.jpg',
    //   description: 'Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.',
    //   readMoreLink: '/#',
    // }
  ];

  const handleReadmore = (id) => {
    navigator(`/:name/readmore/${btoa(id)}`);
  };


  return (
    <div>
      <section className="contact-page-title">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="contact-breadcumb-wrap">
                <h2 className='contec'>Blog</h2>
                <ol className="contact-breadcumb-wrap">
                  <li>
                    <a href="/" title="Home" className='contact-breadcumb-wrap'>Home</a>
                  </li>
                  <li className="contact-breadcumb-wrap">Blog</li>
                </ol>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section>
        <Container>
          <Grid container spacing={2}>
            {blogPosts.map((post) => (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={post.id}>
                <div>
                  <h1 className="entry-title">{post.title}</h1>
                  <div className="blog-content">
                    <span>{post.date}</span>
                    {/* <span className="blog-li">{post.author}</span> */}
                    {/* {post.categories.map((category, index) => (
                      <span key={index} className="blog-li">{category}</span>
                    ))} */}
                  </div>
                  <div>
                    <img
                      src={post.image}
                      alt=""
                      className="blog-image"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                  <p className='blog-descrp'>{post.description}</p>
                  {/* <p className="readed c-pointer" onClick={() => handleReadmore(post.id)}>Read More</p> */}
                </div>
              </Grid>
            ))}
          </Grid>
          {/* <Stack spacing={2}>
            <Pagination count={2} variant="outlined" shape="rounded" />
          </Stack> */}
        </Container>
      </section>
    </div>
  )
}

export default Theme1blog