import React from 'react'
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tabs } from 'react-bootstrap';
import DataContext from '../../../../context/DataContext';
import { useContext } from 'react';
import Theme4footer from '../../layouts/Theme4footer';

const Theme4portfolio = () => {
    const { pageLoading, navigator } = useContext(DataContext)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handlehome = () => {
        navigator('/:name/home')
    }

    const handleWork = () => {
        navigator('/:name/home/work')
    }

    const handleBlog = () => {
        navigator('/:name/home/blogs')
    }

    const handleContact = () => {
        navigator('/:name/home/contact')
    }

    // const [value, setValue] = useState("1");
    // const [tabName, setTabName] = useState("All");
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     const tabNames = {
    //         1: "ALL",
    //         2: "BRANDING",
    //         3: "PHOTOGRAPHY",
    //         4: "WEB DESIGN"
    //     };
    //     if (tabNames[newValue]) {
    //         setTabName(tabNames[newValue]);
    //     }
    // };

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }}>
                <Grid item sm={12} md={5} lg={5} className="fixed-grid">
                    <section className=''>
                        <section className='nav-position'>
                            <nav className="navbar navbar-expand-lg navbar-light p-0">
                                <div className='t4-logo-bg'>
                                    <a className="navbar-brand text-center" href="#"><img src='/images/theme4/theme-4-logo.png' className='theme4-logo-nav img-fluid' /></a>
                                </div>
                                <div className=" black-nav" >
                                    <div className=" ml-auto">
                                        <div className="form-btn" onClick={toggleDrawer}><MenuIcon /></div>
                                    </div>
                                </div>
                            </nav>

                            <img src='/images/theme4/fig2.png' alt='' className='theme4-image' />

                            <div
                                className={`drawer ${isDrawerOpen ? "open" : ""}`}
                                onClick={toggleDrawer}
                            >
                                <div className="drawer-content">
                                    <div className="close-icon" onClick={toggleDrawer}>
                                        <CloseIcon />
                                    </div>
                                    <ul>
                                        <li><a href="#" onClick={handlehome}>Home</a></li>
                                        <li><a href="#" onClick={handleWork} >Work</a></li>
                                        <li><a href="#" onClick={handleBlog} >Blog</a></li>
                                        <li><a href="#" onClick={handleContact} >Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}

                        </section>
                    </section>
                </Grid>
                <Grid item sm={12} md={7} lg={7}>
                    <section>
                        {/* <div className='small-yellow-box'>
                            <span>MY WORK</span>
                        </div> */}
                        <div className="speech-bubble">
                            <span>MY WORK</span>
                        </div>

                        <h1 className='portfolio'>PORTFOLIO</h1>

                        <div className='yel-line'></div>
                        <div className='yel-line-1'></div>
                    </section>

                    <section className='ml-2 mt-5'>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example"
                                        TabIndicatorProps={{ sx: { backgroundColor: '#FFFFFF' } }} // White indicator for selected tab
                                    >
                                        <Tab
                                            label="ALL"
                                            value="1"
                                            sx={{
                                                backgroundColor: value === "1" ? '#292929' : '#FFC815',
                                                color: value === "1" ? '#FFFFFF' : '#191919',
                                                '&.Mui-selected': {
                                                    color: '#FFFFFF', // Make selected tab's color white
                                                },
                                            }}
                                        />
                                        <Tab
                                            label="BRANDING"
                                            value="2"
                                            sx={{
                                                backgroundColor: value === "2" ? '#292929' : '#FFC815',
                                                color: value === "2" ? '#FFFFFF' : '#191919',
                                                '&.Mui-selected': {
                                                    color: '#FFFFFF', // Make selected tab's color white
                                                },
                                            }}
                                        />
                                        <Tab
                                            label="PHOTOGRAPHY"
                                            value="3"
                                            sx={{
                                                backgroundColor: value === "3" ? '#292929' : '#FFC815',
                                                color: value === "3" ? '#FFFFFF' : '#191919',
                                                '&.Mui-selected': {
                                                    color: '#FFFFFF', // Make selected tab's color white
                                                },
                                            }}
                                        />
                                        <Tab
                                            label="WEB DESIGN"
                                            value="4"
                                            sx={{
                                                backgroundColor: value === "4" ? '#292929' : '#FFC815',
                                                color: value === "4" ? '#FFFFFF' : '#191919',
                                                '&.Mui-selected': {
                                                    color: '#FFFFFF', // Make selected tab's color white
                                                },
                                            }}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='py-5 pl-0 pr-3'>
                                    <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start" }} spacing={3} >
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port1.jpg' className='img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port2.jpg' className='img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port3.jpg' className='img-fluid' />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3} >
                                        <Grid item sm={12} md={8} lg={8}>
                                            <img src='/images/theme4/port4.jpg' className='mt-3  img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port5.jpg' className='mt-3  img-fluid' />
                                            <img src='/images/theme4/port6.jpg' className='mt-3  img-fluid' />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start" }} spacing={3} >
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port7.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port8.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port9.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3} >
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port10.jpg' className='mt-3  img-fluid' />
                                            <img src='/images/theme4/port11.jpg' className='mt-3  img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={8} lg={8}>
                                            <img src='/images/theme4/port12.jpg' className='mt-3  img-fluid' />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2" className='py-5 pl-0 pr-3'>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3}>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port1.jpg' className='mt-3 img-fluid' />
                                            <img src='/images/theme4/port7.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port3.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port6.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="3" className='py-5 pl-0 pr-3'>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3}>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port1.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port5.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port10.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="4" className='py-5 pl-0 pr-3'>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3}>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port1.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port2.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port8.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "flex-start", }} spacing={3}>
                                        <Grid item sm={12} md={8} lg={8}>
                                            <img src='/images/theme4/port6.jpg' className='mt-3 img-fluid' />
                                            <img src='/images/theme4/port12.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4}>
                                            <img src='/images/theme4/port9.jpg' className='mt-3 img-fluid' />
                                            <img src='/images/theme4/port11.jpg' className='mt-3 img-fluid' />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </section>
                    <Theme4footer />
                </Grid>
            </Grid>
        </>
    )
}

export default Theme4portfolio