import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import { Grid } from '@mui/material';

const Theme8aboutus = () => {
    const { navigator } = useContext(DataContext)

    const handlehome = () => {
        navigator('/:name/home')
    }

    const handleabout = () => {
        navigator('/:name/home/about')
    }

    const handleblog = () => {
        navigator('/:name/home/blog')
    }

    const handlepage = () => {
        navigator('/:name/home/pages')
    }

    const handlecontact = () => {
        navigator('/:name/home/contact')
    }
    return (
        <>
            <section className='nav-bar'>
                <nav className="navbar">
                    <ul className="nav-links">
                        <li><a href="#" onClick={handlehome} >Home</a></li>
                        <li><a href="#" onClick={handleabout} >About Us</a></li>
                        <li><a href="#" onClick={handleblog} >Blog</a></li>
                        <li><a href="#" onClick={handlepage} >Pages</a></li>
                        <li><a href="#" onClick={handlecontact} >Contact Us</a></li>
                    </ul>
                    <div className="social-icons">
                        <FacebookOutlinedIcon className='social-icon-clr' />
                        <TwitterIcon className='social-icon-clr' />
                        <YouTubeIcon className='social-icon-clr' />
                        <InstagramIcon className='social-icon-clr' />
                    </div>
                </nav>
            </section>

            <section className='mb-5'>
                <div className='about-page-blue-bg'>
                    <h1 className='text-center' style={{ color: '##2a2a34' }} >About</h1>
                </div>

                <div className='about-page-grid'>
                    <div className='container'>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <img src='/images/theme8/about-img.png' className='img-fluid' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <h4 className='' style={{color: '#fe3a47'}} >ABOUT US</h4>
                                <h1 className='my-3'>Our expert electricians are <br /> trustworthy and reliable</h1>
                                <h5 className='my-3' style={{color: '#2a2a34', fontWeight: '100' }} >We get the lights on fast and for a good price Lorem ipsum dolor sit amet consectetur .</h5>
                                <p className='' style={{color: '#707070'}}>Lorem ipsum dolor sit amet, consectetur adipieisicing elit, seeded esde eeiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nissdsfei ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in andevoluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sisdesdnt occaessicat cupidat non proident, sunt in culpa qui officia deserunt miollit aneimd essddeiit laborume ed ut perspiciatis unde omnis iste natus error sitesde and the voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
                                <button className='gra-btn'>Read More</button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Theme8aboutus