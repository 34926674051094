import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';

const Theme5products = () => {
    const [VariantImage, setVariantImage] = useState('');
    const [selectedColor, setSelectedColor] = useState(null);
    const [count, setCount] = useState(0);
    const [activeChip, setActiveChip] = useState("");

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const colors = [
        { name: "Beige", hex: "#EED0B5" },
        { name: "Green", hex: "#A8E6A1" },
        { name: "Blue", hex: "#BCE2ED" },
        { name: "Purple", hex: "#C1B3E6" },
        { name: "Pink", hex: "#E6A1A8" },
    ];
    const variImg = [
        {
            variImg: '/images/theme5/products/Prod-1.png',
        },
        {
            variImg: '/images/theme5/products/Prod2.png',
        },
        {
            variImg: '/images/theme5/products/Prod-1.png',
        },
    ];

    const handleChipClick = (chip) => {
        setActiveChip(chip);
        debugger
    };
    const addItem = () => {
        if (count >= 0) {
            setCount(count + 1);
        }
    };
    const subItem = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };
    return (
        <div className='products-t5-back-prod' >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
          
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <div className=''>

                        <div>
                            <div className="main-crd">
                                {VariantImage ? (
                                    <img
                                        src={VariantImage}
                                        alt="VariantImage"
                                        className='variant-images-prod-th3'
                                        style={{
                                            
                                        }}
                                    />
                                ) : (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        src={'/images/theme5/products/Prod-1.png'}
                                        alt="Product Image"
                                        className='variant-images-prod-th3'
                                       
                                    />
                                )}
                            </div>
                        </div>
                        <div className='variant-images-t3'>
                            {variImg &&
                                variImg.map((a, i) => (

                                    <div key={i}>
                                        <div
                                            className=' variant-cards-t5 c-pointer'
                                            onClick={() => setVariantImage(a.variImg)}
                                        >
                                            <img

                                                src={a.variImg}
                                                alt={`Variant ${i + 1}`}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '90px',
                                                    margin: '0px auto',
                                                    borderRadius: '18px',
                                                    border: ' 1px solid lightgray'
                                                }}
                                            />
                                        </div>
                                    </div>

                                ))}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className='content-right-prod'>
                        <h3>5G Smartphone</h3>
                        <p>Etiam quis lobortis odio, at sodales mauris. Morbi fermentum pretium ligula, id efficitur quam mattis in. Fusce nec pretium ante. Vivamus vitae bibendum lacus, ac varius ligula.</p>
                        <h4>₹80.70 – ₹130.85</h4>
                        <div className="color-picker mt-3">
                            <p>Color</p>
                            <div className="color-options">
                                {colors.map((color, index) => (
                                    <div
                                        key={index}
                                        className={`color-circle ${selectedColor === color.hex ? "selected" : ""}`}
                                        style={{ backgroundColor: color.hex }}
                                        onClick={() => handleColorClick(color.hex)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <p>Storage</p>
                            <div className="chips">
                                <Button
                                    variant={activeChip === "128 GB" ? "contained" : "outlined"}
                                    className={activeChip === "128 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("128 GB")}
                                >
                                    128 GB
                                </Button>
                                <Button
                                    variant={activeChip === " 1 TB" ? "contained" : "outlined"}
                                    className={activeChip === " 1 TB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick(" 1 TB")}
                                >
                                    1 TB
                                </Button>
                                <Button
                                    variant={activeChip === " 256 GB" ? "contained" : "outlined"}
                                    className={activeChip === " 256 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick(" 256 GB")}
                                >
                                    256 GB
                                </Button>
                                <Button
                                    variant={activeChip === "512 GB" ? "contained" : "outlined"}
                                    className={activeChip === "512 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("512 GB")}
                                >
                                    512 GB
                                </Button>
                                <Button
                                    variant={activeChip === "64 GB" ? "contained" : "outlined"}
                                    className={activeChip === "64 GB" ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick("64 GB")}
                                >
                                    64 GB
                                </Button>
                            </div>
                        </div>
                        {/* <div className='t5-prod-actions'>
                            <div className='add-cart-t5'>
                                <div className="checkout-count">
                                    <h6 onClick={() => subItem()} style={{ fontweight: '600' }}>-</h6>
                                    <span style={{ fontweight: '600' }}>{count}</span>
                                    <h6 onClick={() => addItem()} style={{ fontweight: '600' }}>+</h6>
                                </div>

                                <div>
                                    <button className='t5-cart-btn'>Add To Cart</button>
                                </div>
                            </div>
                            <div>
                                <button className='t5-buy-btn'>Buy Now</button>
                            </div>
                        </div> */}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Theme5products;
