
import { Grid } from '@mui/material';
import React from 'react';
import { FaInstagram, FaTwitter, FaDiscord, FaFacebook, FaArrowUp } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

function Theme5footer() {
  let urlName = sessionStorage.getItem('urlName')
  const footerimg = [
    "/images/theme5/footer-img-1.jpg",
    "/images/theme5/footer-img-2.jpg",
    "/images/theme5/footer-img-3.jpg",
    "/images/theme5/footer-img-4.jpg",
  ]
  return (
    <div className="footer-t5 mt-5">
      <div className='mt-5'>
        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className='text-left' >
              <span className='chip-abt'>STAY CONNECTED</span>
              <h2 className='mt-3'>Never Miss Out On The Hottest Deals And Newest Arrivals</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className='text-right'>
              <h5>#Captured Through Elektrix</h5>
              <div className='t5-ft-images mt-3'>
                {footerimg && footerimg.map((a, i) => (
                  <div className='footer-images-t5' key={i}>
                    <img src={a} alt="foot-img" className='t5-img-ft' />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          <nav className="nav-bar mt-5">
            <ul>
              {/* <li className="nav-item-foot active">Home</li>
          <li className="nav-item-foot">About Us</li>
          <li className="nav-item-foot">Shop</li>
          <li className="nav-item-foot">Blog</li>
          <li className="nav-item-foot">Contact Us</li> */}
              <li >
                <NavLink className="nav-item-foot" to={`/${urlName}/home`} activeClassName="active">Home</NavLink>
              </li>
              <li >
                <NavLink className="nav-item-foot" to={`/${urlName}/aboutus`} activeClassName="active">About</NavLink>
              </li>
              <li >
                <NavLink className="nav-item-foot" to={`/${urlName}/schedule`} activeClassName="active">Shop</NavLink>
              </li>
              <li >
                <NavLink className="nav-item-foot" to={`/${urlName}/onpress`} activeClassName="active">Blog</NavLink>
              </li>
              <li >
                <NavLink className="nav-item-foot" to={`/${urlName}/news`} activeClassName="active">Contact Us</NavLink>
              </li>
            </ul>
          </nav>
        </Grid>

      </Grid>
      <div className="footer-t5-soc mt-5">
        <p></p>
        <div className="social-icons">
          <FaInstagram />
          <FaTwitter />
          <FaDiscord />
          <FaFacebook />
          <FaArrowUp />
        </div>
      </div>
    </div>
  );
}

export default Theme5footer;
