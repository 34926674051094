import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme3layout from './layouts/Theme3layout'
import Theme3home from './components/home-page/Theme3home'
import Theme3aboutus from './components/Aboutus-page/Theme3aboutus'
import Theme3schedule from './components/schedule-page/Theme3schedule'
import Theme3onpress from './components/home-page/Theme3onpress'
import Theme3press from './components/press-page/Theme3press'
import Theme3news from './components/news-page/Theme3news'
import Theme3readmore from './components/readmore-page/Theme3readmore'
import Theme3Gallery from './components/Gallery-page/Theme3Gallery'

const Theme3route = () => {

    document.getElementById('theme').setAttribute('href', '/css/theme3.css');
    return (
        <div>
            <Routes>
                <Route path='/:name' element={<Theme3layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme3home />} />
                    <Route path='/:name/aboutus' element={<Theme3aboutus />} />
                    <Route path='/:name/schedule' element={<Theme3schedule />} />
                    <Route path='/:name/onpress' element={<Theme3press />} />
                    <Route path='/:name/news' element={<Theme3news />} />
                    <Route path='/:name/gallery' element={<Theme3Gallery />} />
                    <Route path='/:name/readmore/:id' element={<Theme3readmore />} />
                </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
        </div>
    )
}

export default Theme3route