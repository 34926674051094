import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import MarkunreadRoundedIcon from '@mui/icons-material/MarkunreadRounded';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Button, FormHelperText, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';

const Theme8contact = () => {
  const { navigator } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
    { values: formData }
  );

  const handlehome = () => {
    navigator('/:name/home')
  }

  const handleabout = () => {
    navigator('/:name/home/about')
  }

  const handleblog = () => {
    navigator('/:name/home/blog')
  }

  const handlepage = () => {
    navigator('/:name/home/pages')
  }

  const handlecontact = () => {
    navigator('/:name/home/contact')
  }

  const onSubmit = (data) => {
    console.log(data);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <>
      <section className='nav-bar'>
        <nav className="navbar">
          <ul className="nav-links">
            <li><a href="#" onClick={handlehome} >Home</a></li>
            <li><a href="#" onClick={handleabout} >About Us</a></li>
            <li><a href="#" onClick={handleblog} >Blog</a></li>
            <li><a href="#" onClick={handlepage} >Pages</a></li>
            <li><a href="#" onClick={handlecontact} >Contact Us</a></li>
          </ul>
          <div className="social-icons">
            <FacebookOutlinedIcon className='social-icon-clr' />
            <TwitterIcon className='social-icon-clr' />
            <YouTubeIcon className='social-icon-clr' />
            <InstagramIcon className='social-icon-clr' />
          </div>
        </nav>
      </section>

      <section>
        <div className='about-page-blue-bg'>
          <h2 className='text-center' style={{ color: '##2a2a34' }} >CONTACT</h2>
        </div>

        <div className='container'>
          <div className='contact-mar'>
            <Grid container justifyContent='center' alignItems='center' spacing={5} >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h2>Contact Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis erat metus, posuere eget pulvinar gravida, pulvinar vitae velit. Quisque vel</p>
                <div>
                  <div className='contact-icons-dflex'>
                    <PhoneEnabledRoundedIcon style={{ color: '#ff3a47' }} />
                    <p className='ml-2'><b>(+91) 1800-1234-1234</b></p>
                  </div>
                  <div className='contact-icons-dflex'>
                    <MarkunreadRoundedIcon style={{ color: '#ff3a47' }} />
                    <p className='ml-2'><b>spa&beauty@demomail.com</b></p>
                  </div>
                  <div className='contact-icons-dflex'>
                    <PlaceIcon style={{ color: '#ff3a47' }} />
                    <p className='ml-2'><b>7750 East Nicholesds Street Waxhaw, St. Arlington</b></p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className='red-box-form'>
                  <form>
                    <Grid container justifyContent='center' alignItems='flex-start' spacing={5} >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className=' mb-3' style={{ width: '100%' }}>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Name is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Name"
                                fullWidth
                                type="text"
                                {...field}
                                InputProps={{
                                  style: { backgroundColor: 'white' },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </div>

                        <div className='mb-3' style={{ width: '100%' }}>
                          <Controller
                            name="subject"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Subject is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Subject"
                                fullWidth
                                type="text"
                                {...field}
                                InputProps={{
                                  style: { backgroundColor: 'white' },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.subject && errors.subject.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='mb-3' style={{ width: '100%' }}>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Email is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                placeholder="Email"
                                fullWidth
                                type="text"
                                {...field}
                                InputProps={{
                                  style: { backgroundColor: 'white' },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Select Date"
                              value={selectedDate}
                              onChange={(newValue) => setSelectedDate(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="dd-mm-yyyy"
                                  InputProps={{
                                    style: { backgroundColor: 'white !important' },
                                  }}
                                  InputLabelProps={{
                                    style: { color: 'black !important' },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <div className='mb-3' style={{ width: '100%' }}>
                      <Controller
                        name="message"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Message is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            placeholder="Message"
                            fullWidth
                            type="text"
                            rows={4}
                            multiline
                            {...field}
                            InputProps={{
                              style: { backgroundColor: 'white' },
                            }}
                            InputLabelProps={{
                              style: { color: 'black' },
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.message && errors.message.message}
                      </FormHelperText>
                    </div>
                    <button className='con-sub' type='submit'>
                      <b>Contact Us</b>
                    </button>
                  </form>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>

        <div className='container'>
          <div className='my-5'>
            <img src='/images/theme8/g-map.png' className='img-fluid' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Theme8contact