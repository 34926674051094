import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { TiImage } from 'react-icons/ti';
import { MdArrowOutward } from "react-icons/md";
import { TfiNewWindow } from "react-icons/tfi";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { TfiMedall } from "react-icons/tfi";
import { TfiServer } from "react-icons/tfi";
import { TfiCup } from "react-icons/tfi";

const statsData = [
  { number: 34, label: 'Years Experience' },
  { number: 99, label: 'Customer Satisfaction' },
  { number: 184, label: 'Team Members' },
  { number: 541, label: 'Project Complete' }
];

const services = [
  {
    title: 'Malware Protection',
    description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
    image: '/images/theme7/service1.jpg',
  },
  {
    title: 'Server Protection',
    description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
    image: '/images/theme7/service2.jpg',
  },
  {
    title: 'Computer Security',
    description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.',
    image: '/images/theme7/service3.jpg',
  },
];

const blogPosts = [
  {
    title: "How can you improvement to your business policy for the better product.",
    date: "July 17, 2024",
    category: "Operation",
    image: "/images/theme7/blog-1.jpg",
    description: "How can you improvement to your business policy for the better product.",
    link: "#"
  },
  {
    title: "People population change anything what your need for your next generation.",
    date: "July 17, 2024",
    category: "Operation",
    image: "/images/theme7/blog-5.jpg",
    description: "People population change anything what your need for your next generation.",
    link: "#"
  },
  {
    title: "Improve your business so that you can stay in your local business in next month.",
    date: "July 17, 2024",
    category: "Security",
    image: "/images/theme7/blog-6.jpg",
    description: "Improve your business so that you can stay in your local business in next month.",
    link: "#"
  }
];


const Theme7home = () => {
  const [screenSize, setScreenSize] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      size();
    };

    window.addEventListener('resize', handleResize);
    size();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(2);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(2);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
      setScreenSize(2);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(1);
    } else {
      setScreenSize(1);
    }
  };
  return (
    <div>
      <div className='cyber-home'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div className='cybered'>
              <h1 className='cyber-head'>
                We're a best cyber <br></br>
                security problem <br></br>
                solution team
              </h1>
              <p className='lorem'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, consectetur adipiscing<br></br>
                elit tempor ut labore
              </p>
              <div className='vid'>
                <button className='btnss_one' variant="contained">Read More</button>
                <div className="video-widget-container">
                  <a className="video-play" href="https://www.youtube.com/watch?v=RXv_uIN6e-Y">
                    <TiImage className='ti-image' />
                    <span className="video-title">Video Tour</span>
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='cyber-time'>
        <Grid container spacing={3} className="stats-container" alignItems="center">
          {statsData.map((stat, index) => (
            <Grid
              item
              xs={12} sm={6} md={3} lg={3} xl={3}
              className='staicss'
              key={index}
            >
              <div className="stat-box">
                <h2 className="stat-number">{stat.number}</h2>
                <p className="stat-label">{stat.label}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="mt-5">
        <div className='cyber-timed'>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <div>
                <img src='/images/theme7/about1.jpg' alt='' className='abt-sect img-fluid' />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <div className='compy'>
                <span className='comp'>About our company</span>
                <p className='opera mt-3'>This is 24/7 cyber security operation center for your problem solution.</p>
              </div>
              <div className='check1 mt-4'>
                <div className='checked'>
                  <div>
                    <img src='/images/theme7/check.jpg' alt='' />
                  </div>
                  <div>
                    <h4 className='thre'>Identifying threats</h4>
                  </div>
                </div>
                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
              </div>
              <div className='check1 mt-4'>
                <div className='checked'>
                  <div>
                    <img src='/images/theme7/check.jpg' alt='' className='img-fluid' />
                  </div>
                  <div>
                    <h4 className='thre'>
                      Security management</h4>
                  </div>
                </div>
                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='mt-5'>
        <div className='cyber-timed'>
          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
              <div className='compy'>
                <span className='comp'>Our Services</span>
                <h4 className='opera mt-3'>What We Do For <br></br>Your Cyber Security Solutions</h4>
              </div>
              <div className='service-bton'>
                <button className='serv-two'>
                  View All Services  <MdArrowOutward />
                </button>
              </div>
            </Grid>
            {services.map((service, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                <div className="service-card">
                  <img
                    className='service-img'
                    src={service.image}
                    alt={service.title}
                  />
                  <div className="service-content">
                    <h5 className='serv-ti'>{service.title}</h5>
                    <p className='ser-des'>{service.description}</p>
                    <div className="icon-box">
                      <span className="icon-box-title">Read More</span>
                      <span >
                        <MdArrowOutward className="box-title" />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div className="text-slider-section">
        <div className="container-fluid">
          <div className="text-slider-box">
            <div className="slide-box">
              <h4>*Feedback &amp; Reporting*</h4>
            </div>
            <div className="slide-box">
              <h4>*Access Control Lists*</h4>
            </div>
            <div className="slide-box">
              <h4>*Access Control Lists*</h4>
            </div>
            <div className="slide-box">
              <h4>*Search Functionality*</h4>
            </div>
            <div className="slide-box">
              <h4>*Legal Disclaimers*</h4>
            </div>
            <div className="slide-box">
              <h4>*Access Control Lists*</h4>
            </div>
            <div className="slide-box">
              <h4>*Network Segmentation*</h4>
            </div>
            <div className="slide-box">
              <h4>*Feedback &amp; Reporting*</h4>
            </div>
            <div className="slide-box">
              <h4>*Network Segmentation*</h4>
            </div>
            <div className="slide-box">
              <h4>*Feedback &amp; Reporting*</h4>
            </div>
            <div className="slide-box">
              <h4>*Search Functionality*</h4>
            </div>
            <div className="slide-box">
              <h4>*Legal Disclaimers*</h4>
            </div>
            <div className="slide-box">
              <h4>*Feedback &amp; Reporting*</h4>
            </div>
            <div className="slide-box">
              <h4>*Network Segmentation*</h4>
            </div>
            <div className="slide-box">
              <h4>*Search Functionality*</h4>
            </div>
            <div className="slide-box">
              <h4>*Legal Disclaimers*</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className='cyber-timed'>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <div>
                <img src='/images/theme7/about2.jpg' alt='' className='abt-sect img-fluid' />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <div className='compy'>
                <span className='comp'>Trust your cyber company</span>
                <p className='opera mt-3'>This is 24/7 cyber security operation center for your problem solution.</p>
              </div>
              <div className='check1 mt-4'>
                <div className='checked'>
                  <div>
                    <img src='/images/theme7/check.jpg' alt='' className='img-fluid' />
                  </div>
                  <div>
                    <h4 className='thre'>
                      Security Services</h4>
                  </div>
                </div>
                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
              </div>
              <div className='check1 mt-4'>
                <div className='checked'>
                  <div>
                    <img src='/images/theme7/check.jpg' alt='' />
                  </div>
                  <div>
                    <h4 className='thre'>
                      Industry Certified</h4>
                  </div>
                </div>
                <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor ut labore.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='mt-5'>
        <div className="text-center">
          <span className='comp'>Latest project</span>
          <h2 className='blogsed'>
            We made the latest product<br />for our client
          </h2>
        </div>
        <div className='client-full'>
          <Grid container direction='row' spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="card-content">
                <div>
                  <img src="/images/theme7/client-1.jpg" alt='Creative Solution' className='cliented img-fluid' />
                </div>
                <div className="single_port mb-0">
                  <span>Creative</span>
                </div>
                <h3 className='product-tit'>Creative Solution</h3>
                <div className="icon-box">
                  <span className="icon-box-title">Read More</span>
                  <span><MdArrowOutward className="box-title" /></span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="card-content">
                <div>
                  <img src="/images/theme7/client-2.jpg" alt='Programming & AI' className='cliented img-fluid' />
                </div>
                <div className="single_port mb-0">
                  <span>Programming</span>
                </div>
                <h3 className='product-tit'>Programming & AI</h3>
                <div className="icon-box">
                  <span className="icon-box-title">Read More</span>
                  <span><MdArrowOutward className="box-title" /></span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="card-content">
                <div>
                  <img src="/images/theme7/client-3.jpg" alt='Computer Security' className='cliented img-fluid' />
                </div>
                <div className="single_port mb-0">
                  <span>Cyber</span>
                </div>
                <h3 className='product-tit'>Computer Security</h3>
                <div className="icon-box">
                  <span className="icon-box-title">Read More</span>
                  <span><MdArrowOutward className="box-title" /></span>
                </div>
              </div>
            </Grid>
            <Grid container spacing={2} alignItems="center" className='mt-4'>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="card-content">
                  <div>
                    <img src="/images/theme7/client-4.jpg" alt='On Board Project' className='cliented img-fluid' />
                  </div>
                  <div className="single_port mb-0">
                    <span>Solution</span>
                  </div>
                  <h3 className='product-tit'>On Board Project</h3>
                  <div className="icon-box">
                    <span className="icon-box-title">Read More</span>
                    <span><MdArrowOutward className="box-title" /></span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="card-content">
                  <div>
                    <img src="/images/theme7/client-5.jpg" alt='Computer Security' className='cliented img-fluid' />
                  </div>
                  <div className="single_port mb-0">
                    <span>Cyber</span>
                  </div>
                  <h3 className='product-tit'>Computer Security</h3>
                  <div className="icon-box">
                    <span className="icon-box-title">Read More</span>
                    <span><MdArrowOutward className="box-title" /></span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="card-content">
                  <div>
                    <img src="/images/theme7/client-6.jpg" alt='Family Security' className='cliented img-fluid' />
                  </div>
                  <div className="single_port mb-0">
                    <span>Protection</span>
                  </div>
                  <h3 className='product-tit'>Family Security</h3>
                  <div className="icon-box">
                    <span className="icon-box-title">Read More</span>
                    <span><MdArrowOutward className="box-title" /></span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='cyber-timed mt-5'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className='compy'>
              <span className='comp'>
                Why choose us
              </span>
              <h2 className='expec mt-4'>
                Cyber Security Skills Gap Widens As Demand For Experts Continues To Soar.
              </h2>
              <p className='expec-sub mt-4'>
                Lorem ipsum dolor sit amet consectetuer adipiscing elitenean commo doligula eget
                dolorenean massa.In enim justo, rhoncus ut, imperdiet avene natis vitae justo nullam
                dictum felis eu pede mollis pretium Inte ger tincid unt cras dapibus.
              </p>
              <div className='quals-mem'>
                <div>
                  <span className='quals-serv'>94%</span>
                  <p className='qual-serv'>Quality services</p>
                </div>
                <div>
                  <span className='quals-serv'>69%</span>
                  <p className='qual-serv'>Skilled staff</p>
                </div>
                <div>
                  <span className='quals-serv'>99%</span>
                  <p className='qual-serv'>Support team</p>
                </div>
              </div>
              <div>
                <button className='btn_one mt-4'>
                  Get Your Services
                  <MdArrowOutward />
                </button>
                <button className='serv-two'>
                  Contact with us  <MdArrowOutward />
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div>
              <img src='/images/theme7/computer.jpg' alt='' className='abt-sect img-fluid' />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="mt-5">
        <div className="text-center">
          <span className='comp'>Pricing plan</span>
          <h2 className='blogsed'>
            Our best pricing plan for<br></br>your solution
          </h2>
        </div>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
            <div className='pricingTable'>
              <div className="pricingTable-header">
                <h3 className="pricetitle">Personal</h3>
              </div>
              <div className="pricing-icon">
                <TfiMedall />
              </div>
              <div className='pricingcontent'>
                <p>5 website</p>
                <p>50GB Disk Space</p>
                <p>50 Email Accounts</p>
                <p>50GB Monthly Bandwidth</p>
                <p>10 Subdomains</p>
              </div>
              <div className="price-value">
                <span className="priceamount">$99</span>
                <span className="priceduration">/mo</span>
              </div>
              <div className='text-center'>
                <button className='purchase_one'>PURCHASE NOW</button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
            <div className='pricingTable'>
              <div className="pricingTable-header">
                <h3 className="pricetitle">Business</h3>
              </div>
              <div className="pricing-icon">
                <TfiServer />
              </div>
              <div className='pricingcontent'>
                <p>5 website</p>
                <p>50GB Disk Space</p>
                <p>50 Email Accounts</p>
                <p>50GB Monthly Bandwidth</p>
                <p>10 Subdomains</p>
              </div>
              <div className="price-value">
                <span className="priceamount">$99</span>
                <span className="priceduration">/mo</span>
              </div>
              <div className='text-center'>
                <button className='purchase_one'>PURCHASE NOW</button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
            <div className='pricingTable'>
              <div className="pricingTable-header">
                <h3 className="pricetitle">Premium</h3>
              </div>
              <div className="pricing-icon">
                <TfiCup />
              </div>
              <div className='pricingcontent'>
                <p>5 website</p>
                <p>50GB Disk Space</p>
                <p>50 Email Accounts</p>
                <p>50GB Monthly Bandwidth</p>
                <p>10 Subdomains</p>
              </div>
              <div className="price-value">
                <span className="priceamount">$99</span>
                <span className="priceduration">/mo</span>
              </div>
              <div className='text-center'>
                <button className='purchase_one'>PURCHASE NOW</button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="testimonial-slider-section mt-5">
        <div className="cyber-timed">
          <div className="text-center">
            <span className='comp'>Top reviews</span>
            <h2 className='blogsed'>
              Take a look our top <br></br>Customer feedback
            </h2>
          </div>
          <div className='mt-5'>
            <Swiper
              slidesPerView={screenSize}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>James Clayton</h4>
                  </div>
                </div>

              </SwiperSlide>
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>James Simmons</h4>
                  </div>
                </div>

              </SwiperSlide>
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>Alex Feroundo</h4>
                  </div>
                </div>

              </SwiperSlide>
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>Mack Hokins</h4>
                  </div>
                </div>

              </SwiperSlide>
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>Larav Peter</h4>
                  </div>
                </div>

              </SwiperSlide>
              <SwiperSlide>

                <div className="testimonial">
                  <img src="/images/theme7/quoteds.jpg" alt="" className="review" />
                  <div className="testimonial_content">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <p>Aqestic optio amet a ququam saepe aliquid voluate dicta fuga dolor saerror sed earum a magni soluta quam minus dolor dolor sed earum a magni soluta autem dolor error error sit quam minus sint rem a rerum dolobus veritatis delectus.</p>
                  </div>
                  <div className="testi_pic_title">
                    <h4>Larav Peter</h4>
                  </div>
                </div>

              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className='cyber-timed mt-5'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className='compy'>
              <span className='comp'>
                Our team member
              </span>
              <h2 className='expec mt-4'>
                Our Expert's Team Member <br />
                For Your Solution
              </h2>
              <p className='expec-sub mt-4'>
                Lorem ipsum dolor sit amet consectetuer adipiscing elitenean commo doligula eget
                dolorenean massa.In enim justo, rhoncus ut, imperdiet avene natis vitae justo nullam
                dictum felis eu pede mollis pretium Inte ger tincid unt cras dapibus.
              </p>
              <div>
                <button className='btn_one mt-4'>
                  View All Members
                  <MdArrowOutward />
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className='team-mem'>
              <div>
                <img
                  src="/images/theme7/team1.jpg"
                  alt=""
                  className='teamed img-fluid'
                  sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                />
                <div>
                  <h4 className='team-name'>
                    William Smith
                  </h4>
                  <p style={{ color: '#fff' }}>
                    Brand designer
                  </p>
                </div>
              </div>
              <div>
                <img
                  src="/images/theme7/team2.jpg"
                  alt=""
                  className='teamed img-fluid'
                  sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                />
                <div>
                  <h4 className='team-name'>
                    David Bell
                  </h4>
                  <p style={{ color: '#fff' }}>
                    Marketer
                  </p>
                </div>
              </div>
            </div>
            <div className='team-mem'>
              <div>
                <img
                  src="/images/theme7/team3.jpg"
                  alt=""
                  className='teamed img-fluid'
                  sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                />
                <div>
                  <h4 className='team-name'>
                    Kevin Woods
                  </h4>
                  <p style={{ color: '#fff' }}>
                    Product designer
                  </p>
                </div>
              </div>
              <div>
                <img
                  src="/images/theme7/team4.jpg"
                  alt=""
                  className='teamed img-fluid'
                  sx={{ width: 150, height: 150, margin: "0 auto 20px auto" }}
                />
                <div>
                  <h4 className='team-name'>
                    Georgia James
                  </h4>
                  <p style={{ color: '#fff' }}>
                    Developer
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='cyber-timed mt-5'>
        <div className='news-list'>
          <h2 className='news-title'>
            Subscribe to our newsletter <br></br>
            We don't make any spam.
          </h2>
          <p className='new-sub mt-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elitsed eiusmod tempor enim minim
          </p>
          <div className="home_subs">
            <span className="wpcf7-form-control-wrap" >
              <input size="40" maxlength="400" className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email subscribe__input" aria-required="true" aria-invalid="false" placeholder="Enter Your Email Address" value="" type="email" name="email-40" />
            </span>
            <button type="submit" className="subscribe__btn">
              <TfiNewWindow style={{ marginBottom: '9px', width: '100%' }} />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className='cyber-timed mb-5'>
          <div className="text-center">
            <span className='comp'>Blogs & News</span>
            <h2 className='blogsed'>
              See Our Latest Blog And <br></br>News From Us
            </h2>
          </div>
          <Grid container spacing={3}>
            {blogPosts.map((post, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                <div className="service-card">
                  <img
                    className='blog-img img-fluid'
                    alt={post.title}
                    src={post.image}
                  />
                  <div className="service-content mt-3">
                    <span className='blog-cat'>
                      {post.category}
                    </span>
                    <span style={{ color: 'lightgrey' }}>
                      {post.date}
                    </span>
                    <h3 className='blog-ti'>
                      {post.title}
                    </h3>
                    <div className="icon-box">
                      <span className="icon-box-title">Read More</span>
                      <span >
                        <MdArrowOutward className="box-title" />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Theme7home