import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Undermaintenance from '../../components/under-maintenance-page/Undermaintenance'
import Pagenotfound from '../../components/common-page/page-not-found/Pagenotfound'
import Theme4homepage from './components/home-page/Theme4homepage'
import Theme4layout from './layouts/Theme4layout'
import Theme4portfolio from './components/work-page/Theme4portfolio'
import Theme4contact from './components/contact-page/Theme4contact'
import Theme4blogs from './components/blogs-page/Theme4blogs'
const Theme4route = () => {

    document.getElementById('theme').setAttribute('href', '/css/theme4.css');
    return (
        <div>
            <Routes>
                <Route path='/:name' element={<Theme4layout />}>
                    <Route path="/:name" element={<Navigate replace to="/:name/home" />} />
                    <Route path='/:name/home' element={<Theme4homepage />} />
                    <Route path='/:name/home/work' element={<Theme4portfolio />} />
                    <Route path='/:name/home/contact' element={<Theme4contact />} />
                    <Route path='/:name/home/blogs' element={<Theme4blogs />} />
                </Route>
                <Route path='/under-maintenance' element={<Undermaintenance />} />
                <Route path='*' element={<Pagenotfound />} />
            </Routes>
        </div>
    )
}

export default Theme4route