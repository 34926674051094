import { Grid } from '@mui/material'
import React from 'react'
import { TiImage } from 'react-icons/ti';

const Theme7servicesmore = () => {
    return (
        <div>
            <div className='about-section'>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} textAlign="center">
                        <div className="section-top-title">
                            <h1 className="fadeinup">Services</h1>
                            <ul className="page-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>/</li>
                                <li>
                                    <span>Services</span>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='container mt-5'>
                <div>
                    <img src='/images/theme7/service_details.jpg' alt='' className='service-image img-fluid' />
                </div>
                <div>
                    <h2 className='analys'>Business Analysis And Solution Program</h2>
                    <p className='analys-title'>The difference between short-form and long-form videos is simple: short-form videos are short, and long-form videos are long. To be more specific, short-form videos are typically under 10 minutes long, while long-form videos exceed that 10-minute mark. You’ll see a lot of short-form videos on social media. Target, for example, uses this video format on Instagram to advertise its products.</p>
                    <p className='analys-title'>You’ll typically see longer videos on a business’s website or YouTube. Video and podcast hosting provider, Wistia, uses long-form video to educate its audience about the cost of video production.</p>
                </div>
                <div className="videoplay-container">
                    <div className="video-area">
                        <a href="https://www.youtube.com/watch?v=RXv_uIN6e-Y" className="video-button">
                            <TiImage className='ti-image' />
                        </a>
                    </div>
                </div>
                <div>
                    <h2 className='analys'>Service Benefits</h2>
                    <p className='analys-title'>Content is king in the digital world. Agencies produce high-quality content, including blog posts, videos, infographics, and more, to engage and educate the target audience. Content marketing builds trust and authority for the brand. Agencies manage and grow a brand’s presence on social media platforms such as Facebook, Twitter, LinkedIn, and Instagram.</p>
                </div>
                <div>
                    <h2 className='analys'>Low Cost Our Fee</h2>
                    <p className='analys-title'>Content is king in the digital world. Agencies produce high-quality content, including blog posts, videos, infographics, and more, to engage and educate the target audience. Content marketing builds trust and authority for the brand. Agencies manage and grow a brand’s presence on social media platforms such as Facebook, Twitter, LinkedIn, and Instagram. </p>
                </div>
                <div>
                    <h2 className='analys'>Tips & tricks</h2>
                    <p className='analys-title'>Content is king in the digital world. Agencies produce high-quality content, including blog posts, videos, infographics, and more, to engage and educate the target audience. Content marketing builds trust and authority for the brand. Agencies manage and grow a brand’s presence on social media platforms such as Facebook, Twitter, LinkedIn, and Instagram.</p>
                </div>
            </div>
        </div>
    )
}

export default Theme7servicesmore