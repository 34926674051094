import React from 'react'
import Theme2navbar from './Theme2navbar'
import Theme2footer from './Theme2footer'
import { Outlet } from 'react-router-dom'

const Theme2layout = () => {
  document.getElementById('theme').setAttribute('href', '/css/theme2.css');
  return (
    <div>
      {/* <Theme2navbar /> */}
      <Outlet></Outlet>
      <Theme2footer />
    </div>
  )
}

export default Theme2layout