import { Divider, FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import { useState } from 'react';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../../context/DataContext';
import Theme4footer from '../../layouts/Theme4footer';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const Theme4blogs = () => {
    const { pageLoading, navigator } = useContext(DataContext)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [formData, setFormData] = useState({});
    const { handleSubmit, control, reset, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleWork = () => {
        navigator('/:name/home/work')
    }

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlehome = () => {
        navigator('/:name/home')
    }

    const handleBlog = () => {
        navigator('/:name/home/blogs')
    }

    const handleContact = () => {
        navigator('/:name/home/contact')
    }
    return (
        <>
            <Grid container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }}>
                <Grid item sm={12} md={5} lg={5} className="fixed-grid">
                    <section className=''>
                        <section className='nav-position'>
                            <nav className="navbar navbar-expand-lg navbar-light p-0">
                                <div className='t4-logo-bg'>
                                    <a className="navbar-brand text-center" href="#"><img src='/images/theme4/theme-4-logo.png' className='theme4-logo-nav img-fluid' /></a>
                                </div>
                                <div className=" black-nav" >
                                    <div className=" ml-auto">
                                        <div className="form-btn" onClick={toggleDrawer}><MenuIcon /></div>
                                    </div>
                                </div>
                            </nav>

                            <img src='/images/theme4/fig1.png' alt='' className='theme4-image' />

                            <div
                                className={`drawer ${isDrawerOpen ? "open" : ""}`}
                                onClick={toggleDrawer}
                            >
                                <div className="drawer-content">
                                    <div className="close-icon" onClick={toggleDrawer}>
                                        <CloseIcon />
                                    </div>
                                    <ul>
                                        <li><a href="#" onClick={handlehome}>Home</a></li>
                                        <li><a href="#" onClick={handleWork} >Work</a></li>
                                        <li><a href="#" onClick={handleBlog} >Blog</a></li>
                                        <li><a href="#" onClick={handleContact} >Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}

                        </section>
                    </section>
                </Grid>
                <Grid item sm={12} md={7} lg={7}>
                    <section className='blogs-sec'>
                        <div className="speech-bubble">
                            <span>TIMELINE</span>
                        </div>

                        <h1 className='portfolio'>BLOG</h1>

                        <div className='yel-line'></div>
                        <div className='yel-line-1'></div>


                        <div>
                            <h3 className='mt-4 ml-2' style={{ color: 'black' }}>Sample Slider Post</h3>


                            <div className='mt-4' >
                                <span className='' ><WatchLaterIcon style={{ color: '#ffc815' }} /> July 11, 2015</span>
                                <span className='ml-3' ><ForumRoundedIcon style={{ color: '#ffc815' }} /> 0 Comment</span>
                                <span className='ml-3' ><InsertDriveFileRoundedIcon style={{ color: '#ffc815' }} /> Development</span>
                                <span className='ml-3' ><PersonRoundedIcon style={{ color: '#ffc815' }} /> Posted by Admin</span>
                            </div>

                            <div className='mr-3 my-4'>
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="/images/theme4/port11.jpg" class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="/images/theme4/port4.jpg" class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="/images/theme4/port2.jpg" class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="/images/theme4/port8.jpg" class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </button>
                                </div>

                                <p className='mt-3' style={{ color: 'black' }}>Etiam in nulla arcu, ut vehicula velit. Vivamus dapibus rutrum mi ut aliquam. In hac habitasse platea dictumst. Integer sagittis neque a tortor tempor in porta sem vulputate. Donec varius felis fermentum nisl imperdiet at molestie purus porta... Lorem ipsum dolor sit amet, consectetur adipisicing...</p>
                                <p className='mt-3' style={{ color: '#ffc815' }}><b>CONTINUE READING...</b></p>

                                <div>
                                    <div class='chip1'><b>GALLERY</b></div>
                                    <div class='chip2'><b>MUSIC</b></div>
                                    <div class='chip3'><b>SLIDESHOW</b></div>
                                    <div class='chip4'><b>TONE</b></div>
                                </div>

                                <div className='end-line'>
                                    <Divider />
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className='mt-4 ml-2' style={{ color: 'black' }}>Sample Image Post</h3>


                            <div className='mt-4' >
                                <span className='' ><WatchLaterIcon style={{ color: '#ffc815' }} /> July 11, 2015</span>
                                <span className='ml-3' ><ForumRoundedIcon style={{ color: '#ffc815' }} /> 0 Comment</span>
                                <span className='ml-3' ><InsertDriveFileRoundedIcon style={{ color: '#ffc815' }} /> Development</span>
                                <span className='ml-3' ><PersonRoundedIcon style={{ color: '#ffc815' }} /> Posted by Admin</span>
                            </div>

                            <div className='mr-3 my-4'>

                                <img src='/images/theme4/blog1.jpg' className='img-fluid' />
                                <p className='mt-3' style={{ color: 'black' }}>Etiam in nulla arcu, ut vehicula velit. Vivamus dapibus rutrum mi ut aliquam. In hac habitasse platea dictumst. Integer sagittis neque a tortor tempor in porta sem vulputate. Donec varius felis fermentum nisl imperdiet at molestie purus porta... Lorem ipsum dolor sit amet, consectetur adipisicing...</p>
                                <p className='mt-3' style={{ color: '#ffc815' }}><b>CONTINUE READING...</b></p>

                                <div>
                                    <div class='chip1'><b>DEVELOPMENT</b></div>
                                    <div class='chip2'><b>FILM</b></div>
                                    <div class='chip3'><b>PHOTOSHOP</b></div>
                                    <div class='chip4'><b>VIDEO</b></div>
                                </div>

                                <div className='end-line'>
                                    <Divider />
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className='mt-4 ml-2' style={{ color: 'black' }}>Sample Text Post</h3>

                            <div className='mt-4' >
                                <span className='' ><WatchLaterIcon style={{ color: '#ffc815' }} /> July 11, 2015</span>
                                <span className='ml-3' ><ForumRoundedIcon style={{ color: '#ffc815' }} /> 0 Comment</span>
                                <span className='ml-3' ><InsertDriveFileRoundedIcon style={{ color: '#ffc815' }} /> Web Design</span>
                                <span className='ml-3' ><PersonRoundedIcon style={{ color: '#ffc815' }} /> Posted by Admin</span>
                            </div>

                            <div className='mr-3 my-4'>

                                <img src='/images/theme4/blog1.jpg' className='img-fluid' />
                                <p className='mt-3' style={{ color: 'black' }}>Etiam in nulla arcu, ut vehicula velit. Vivamus dapibus rutrum mi ut aliquam. In hac habitasse platea dictumst. Integer sagittis neque a tortor tempor in porta sem vulputate. Donec varius felis fermentum nisl imperdiet at molestie purus porta... Lorem ipsum dolor sit amet, consectetur adipisicing...</p>
                                <p className='mt-3' style={{ color: '#ffc815' }}><b>CONTINUE READING...</b></p>

                                <div>
                                    <div class='chip1'><b>HTML</b></div>
                                    <div class='chip2'><b>PHP</b></div>
                                    <div class='chip3'><b>SMM</b></div>
                                    <div class='chip4'><b>VIDEO</b></div>
                                </div>

                                <div className='end-line'>
                                    <Divider />
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className='mt-4 ml-2' style={{ color: 'black' }}>Sample Text Post</h3>

                            <div className='mt-4' >
                                <span className='' ><WatchLaterIcon style={{ color: '#ffc815' }} /> July 11, 2015</span>
                                <span className='ml-3' ><ForumRoundedIcon style={{ color: '#ffc815' }} /> 0 Comment</span>
                                <span className='ml-3' ><InsertDriveFileRoundedIcon style={{ color: '#ffc815' }} /> Web Design</span>
                                <span className='ml-3' ><PersonRoundedIcon style={{ color: '#ffc815' }} /> Posted by Admin</span>
                            </div>

                            <div className='mr-3 my-4'>

                                <img src='/images/theme4/port7.jpg' className='img-fluid' />
                                <p className='mt-3' style={{ color: 'black' }}>Etiam in nulla arcu, ut vehicula velit. Vivamus dapibus rutrum mi ut aliquam. In hac habitasse platea dictumst. Integer sagittis neque a tortor tempor in porta sem vulputate. Donec varius felis fermentum nisl imperdiet at molestie purus porta... Lorem ipsum dolor sit amet, consectetur adipisicing...</p>
                                <p className='mt-3' style={{ color: '#ffc815' }}><b>CONTINUE READING...</b></p>

                                <div className='end-line'>
                                    <Divider />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Theme4footer />
                </Grid>
            </Grid>
        </>
    )
}

export default Theme4blogs