import { Container, Grid } from '@mui/material'
import React from 'react'

const Theme1blodmore = () => {
    return (
        <div>
            <section className="contact-page-title">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="contact-breadcumb-wrap">
                                <h2 className='contec'>Blog</h2>
                                <ol className="contact-breadcumb-wrap">
                                    <li>
                                        <a href="/" title="Home" className='contact-breadcumb-wrap'>Home</a>
                                    </li>
                                    <li className="contact-breadcumb-wrap">Blog</li>
                                </ol>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="blog-content">
                                <span>March 1, 2024</span>
                                <span className="blog-li">Admin</span>
                                <span className="blog-li">Lorem ipsum', 'aenean, vulputate</span>
                            </div>
                            <div>
                                <img
                                    src="/images/theme1/fo-1.jpg"
                                    alt=""
                                    className="blog-image"
                                    style={{ width: '85%', height: 'auto' }}
                                />
                            </div>
                            <p >
                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
                            </p>
                            <p >
                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
                            </p>
                            <p>
                                Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus.
                            </p>
                            <p>
                                Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus.
                            </p>
                            <p>
                                Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className='contact-box'>
                                <h3 className='con-info'>
                                    Archives
                                </h3>
                                <p className='contact-log'>
                                    March 2024 (1)
                                </p>
                                <p className='contact-log'>
                                    February 2024 (3)
                                </p>
                                <p className='contact-log'>
                                    January 2024 (4)
                                </p>
                                <h3 className='con-info mt-4'>
                                    Categories
                                </h3>
                                <p className='contact-log'>
                                    Aliquam (2)
                                </p>
                                <p className='contact-log'>
                                    Cicero (2)
                                </p>
                                <p className='contact-log'>
                                    Lorem ipsum (4)
                                </p>
                                <p className='contact-log'>
                                    Uncategorized (3)
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>

        </div>
    )
}

export default Theme1blodmore