import { Grid } from '@mui/material'
import React from 'react'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import SquareIcon from '@mui/icons-material/Square';

const Theme4footer = () => {
  return (
    <>
      <section>
        <div className='footer'>
          <Grid className='footer-pad' container direction="row" sx={{ justifyContent: "", alignItems: "flex-start", }} >
            <Grid className='my-5' item xs={12} sm={12} md={4} lg={4}>
              <h5 className='footer-con'>FIND ME</h5>
              <div className='yellow-line'></div>
              <div className='yel-icon-bg'>
                <div className='yel-smal-box'><img src='/images/theme4/facebook-app-symbol.png' className='img-fluid' /></div>
                <div className='yel-smal-box'><img src='/images/theme4/twitter.png' className='img-fluid' /></div>
                <div className='yel-smal-box'><img src='/images/theme4/linkedin-logo.png' className='img-fluid' /></div>
                <div className='yel-smal-box'><img src='/images/theme4/instagram.png' className='img-fluid' /></div>
                <div className='yel-smal-box'><img src='/images/theme4/tik-tok.png' className='img-fluid' /></div>
              </div>
            </Grid>
            <Grid className='my-5' item xs={12} sm={12} md={4} lg={4}>
              <h5 className='footer-con'>CONTACT INFO</h5>
              <div className='yellow-line'></div>

              <div className='mt-5'>
                <PhoneEnabledIcon style={{ color: '#ffc815', fontSize: '12px' }} /> <span style={{ color: '#676767', fontSize: '12px', marginLeft: '8px' }}> <b> +7(111)123456789 </b></span> <br />
                <LocationOnIcon style={{ color: '#ffc815', fontSize: '12px' }} /> <span style={{ color: '#676767', fontSize: '12px', marginLeft: '8px' }}> <b> 27th Brooklyn New York. </b></span> <br />
                <LocalPostOfficeIcon style={{ color: '#ffc815', fontSize: '12px' }} /> <span style={{ color: '#676767', fontSize: '12px', marginLeft: '8px' }}> <b> yourmail@domain.com </b></span>
              </div>
            </Grid>
            <Grid className='my-5' item xs={12} sm={12} md={4} lg={4}>
              <h5 className='footer-con'>Bionick Demos</h5>
              <div className='yellow-line'></div>

              <div className='mt-5'>
                <div>
                  <span></span><b style={{ color: '#676767' }}><SquareIcon style={{color: '#ffc815', fontSize: '12px'}} className='mr-2' />VIDEO DEMO</b> <br />
                  <span></span><b style={{ color: '#676767' }}><SquareIcon style={{color: '#ffc815', fontSize: '12px'}} className='mr-2' />IMAGE DEMO</b> <br />
                  <span></span><b style={{ color: '#676767' }}><SquareIcon style={{color: '#ffc815', fontSize: '12px'}} className='mr-2' />SLIDESHOW DEMO</b>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='footer-bluebox'>
            <p className='footer-content m-0'>© Bionick 2022 Developed by <span style={{color: '#ffc815'}}>WebRedox</span> | All rights reserved.</p>
        </div>
      </section>
    </>
  )
}

export default Theme4footer