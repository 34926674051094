import React from 'react'
import Theme5navbar from '../layouts/Theme5navbar'



const Theme5Termsandcondition = () => {
    return (
        <div className='banner-t5-back'>
            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Terms & Conditions</h1>
                        <p>Home &nbsp;/ &nbsp;Terms & Conditions</p>
                    </div>
                </div>
            </div>
            <div className='shipping-content-t5 mt-5'>
                <div className='ship-policy-t5' data-aos="fade-right"  data-aos-duration="1000">
                    <h1>Site Usage:</h1>
                    <p className='mt-4'>Facilisi etiam dignissim diam quis enim lobortis. Nulla malesuada pellentesque elit eget gravida cum faucibus. Suspendisse viverra, odio sit amet accumsan mattis, eos libero malesuada enim, quis feugiat lorem lectus eget nulla. Aenean vestibulum sapien ex, quis varius elit auctor id. Proin at metus eget dolor accumsan tempor eu at tellus. Sed pulvinar leo in justo fermentum, a euismod mi vehicula. Curabitur a sollicitudin tellus, at fermentum nisl. Integer at dictum purus. In hac habitasse platea dictumst. Phasellus faucibus ultrices convallis. Praesent blandit nisl at mauris auctor, vulputate tempus mi mollis. Morbi sit amet laoreet odio. Vivamus aliquet magna mauris, nec vestibulum justo aliquam eu.</p>
                </div>
                <div className='ship-policy-t5' data-aos="fade-left"  data-aos-duration="1000">
                    <h1>Liability limitations:</h1>
                    <p className='mt-4'>Suspendisse viverra, odio sit amet accumsan mattis, eos libero malesuada enim, quis feugiat lorem lectus eget nulla. Aenean vestibulum sapien ex, quis varius elit auctor id. Proin at metus eget dolor accumsan tempor eu at tellus. Sed pulvinar leo in justo fermentum, a euismod mi vehicula.</p>
                    <ul className='shipping-lists-1-t5'>
                        <li><p>Cras adipiscing enim eu turpis egestas Proin at metus eget dolor accumsan tempor pretium aenean Fonsectetur adipiscing sit.</p></li>
                        <li><p>Egestas egestas fringilla at fermentum nisl. Integer at dictum purus phasellus faucibus scelerisque Zamper auctor neque.</p></li>
                        <li><p>Morbi enim nunc faucibus a sed iaculis sapien consectetur in pellentesque sit amet porttios Id volutpat lacus Iaculis.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed, aliquet magna nec justo aliquam eu volutpat scelerisque nisl, Aenean posuere nec.</p></li>
                    </ul>
                </div>
                <div className='return-policy-t5 mt-5'data-aos="fade-right"  data-aos-duration="1000">
                    <h1>User Agreement:</h1>
                    <p className='mt-4' >Ut dapibus dui quis elit elementum, vulputate eleifend turpis dignissim. Sed eleifend enim et pulvinar feugiat. In sit amet sapien et turpis convallis scelerisque vel in mi. Donec turpis nibh, posuere ac feugiat sed, ultrices nec mauris. Vestibulum ullamcorper dignissim ex, fringilla mattis libero iaculis ut. Cras at tristique turpis. Pellentesque maximus metus ut sem faucibus auctor. Proin vitae leo tincidunt, placerat ligula egestas, feugiat leo. Mauris at orci id sem scelerisque vehicula. Mauris quam ligula, euismod vel nisi feugiat, suscipit aliquam risus. Cras bibendum lacus a diam eleifend, sed vehicula augue cursus.</p>
                    <ul className='shipping-lists-1-t5'>
                        <li><p>Cras adipiscing enim eu turpis egestas Proin at metus eget dolor accumsan tempor pretium aenean Fonsectetur adipiscing sit.</p></li>
                        <li><p>Egestas egestas fringilla at fermentum nisl. Integer at dictum purus phasellus faucibus scelerisque Zamper auctor neque.</p></li>
                        <li><p>Morbi enim nunc faucibus a sed iaculis sapien consectetur in pellentesque sit amet porttios Id volutpat lacus Iaculis.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed, aliquet magna nec justo aliquam eu volutpat scelerisque nisl, Aenean posuere nec.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed hendrerit vulputate scelerisque nisl, Aenean efficitur posuere nec.</p></li>
                    </ul>
                </div>
                <div className='return-policy-t5 mt-5' data-aos="fade-left"  data-aos-duration="1000">
                    <h1>Reaching Out:</h1>
                    <p className='mt-4'>Nulla malesuada pellentesque elit eget gravida cum faucibus. Suspendisse viverra, odio sit amet accumsan mattis, eos libero malesuada enim, quis feugiat lorem lectus eget nulla. Aenean vestibulum sapien ex, quis varius elit auctor id. Proin</p>
                    <ul className='shipping-lists-1-t5'>
                        <li><p>56 High Street London SW1A 1AA United Kingdom,</p></li>
                        <li><p>+910012345678,</p></li>
                        <li><p>Morbi enim nunc faucibus a sed iaculis sapien consectetur in pellentesque sit amet porttios Id volutpat lacus Iaculis.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed, aliquet magna nec justo aliquam eu volutpat scelerisque nisl, Aenean posuere nec.</p></li>
                        <li><p>support@example.com</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Theme5Termsandcondition