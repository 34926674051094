import React from 'react'
import Theme6navbar from '../../layouts/Theme6navbar'
import { Container } from 'react-bootstrap'
import { Grid } from '@mui/material'

const Theme6blog = () => {
    const blogs = [
        {
          image: "/images/Theme6/blog-4.jpg",
          title: "Winter Dreams – Capturing the snow.",
          categories: "Inspiration, Technology, Web 2.0",
          date: "Apr 16, 2015",
          description:
            "Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...",
          author: "Ram",
          comments: "4",
          buttonText: "Read More"
        },
        {
          image: "/images/Theme6/blog-11.jpg",
          title: "It's Christmas. Special days ahead.",
          categories: "Technology, Trend",
          date: "Apr 28, 2015",
          description:
            "Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...",
          author: "Ram",
          comments: "4",
          buttonText: "Read More"
        },
        {
          image: "/images/Theme6/blog-7.jpg",
          title: "In the dawn of the day",
          categories: "Festival, Learning",
          date: "Apr 28, 2015",
          description:
            "Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...",
          author: "Ram",
          comments: "1",
          buttonText: "Read More"
        }
      ];
    return (
        <div>
            <div className='bg-t6-contact'>
                <Theme6navbar />

                <div className='Contact-content'>
                    <h1>Blog</h1>
                    <p>{`Home >> Blog`}</p>
                </div>
            </div>
            {/* <Container className='mt-5'>
                <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className='blog-img'>
                            <img src="/images/Theme6/blog-4.jpg" alt="" className='img-fluid' />
                            <div className='blog-content'>
                                <h5>Winter Dreams – Capturing the snow.</h5>
                                <span className='mt-3'>Inspiration, Technology, Web 2.0 | <span>Apr 16, 2015</span></span>
                                <p className='mt-5'>Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...</p>
                                <span className='blog-content-footer'><span>BY</span> Ram | <span>Comments4</span> |</span>
                                <button>Read More</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className='blog-img'>
                            <img src="/images/Theme6/blog-11.jpg" alt="" className='img-fluid' />
                            <div className='blog-content'>
                                <h5>Its Christmas. Special days ahead.</h5>
                                <span className='mt-3'>Technology, Trend  | <span>Apr 28, 2015</span></span>
                                <p className='mt-5'>Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...</p>
                                <span className='blog-content-footer'><span>BY</span> Ram | <span>Comments4</span> |</span>
                                <button>Read More</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2'>
                        <div className='blog-img'>
                            <img src="/images/Theme6/blog-7.jpg" alt="" className='img-fluid' />
                            <div className='blog-content'>
                                <h5>In the dawn of the day</h5>
                                <span className='mt-3'>Festival, Learning  | <span>Apr 28, 2015</span></span>
                                <p className='mt-5'>Foreal has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not...</p>
                                <span className='blog-content-footer'><span>BY</span> Ram | <span>Comments1</span> |</span>
                                <button>Read More</button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container> */}

<Container className='mt-5'>
      <Grid container direction="row" spacing={5}>
        {blogs.map((blog, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='px-2' key={index}>
            <div className='blog-img'>
              <img src={blog.image} alt={blog.title} className='img-fluid' />
              <div className='blog-content'>
                <h5>{blog.title}</h5>
                <span className='mt-3'>{blog.categories} | <span>{blog.date}</span></span>
                <p className='mt-5'>{blog.description}</p>
                <span className='blog-content-footer'><span>BY</span> {blog.author} | <span>Comments{blog.comments}</span> |</span>
                <button>{blog.buttonText}</button>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
        </div>
    )
}

export default Theme6blog
