import React from 'react'
import Theme5navbar from '../layouts/Theme5navbar'

const Theme5shippinginfo = () => {
    return (
        <div className='banner-t5-back'>

            <div className='background-abt-t5'>
                <Theme5navbar />
                <div className='background-abt-d-t5'>
                    <div className='content-aboutpage '>
                        <h1>Shipping Information</h1>
                        <p>Home &nbsp;/ &nbsp;Shipping Information</p>
                    </div>
                </div>
            </div>
            <div className='shipping-content-t5 mt-5'>
                <div className='ship-policy-t5' data-aos="fade-right"  data-aos-duration="1000">
                    <h2>Shipping policy:</h2>
                    <p className='mt-4'>Facilisi etiam dignissim diam quis enim lobortis. Nulla malesuada pellentesque elit eget gravida cum faucibus. Suspendisse viverra, odio sit amet accumsan mattis, eos libero malesuada enim, quis feugiat lorem lectus eget nulla. Aenean vestibulum sapien ex, quis varius elit auctor id. Proin at metus eget dolor accumsan tempor eu at tellus. Sed pulvinar leo in justo fermentum, a euismod mi vehicula. Curabitur a sollicitudin tellus, at fermentum nisl. Integer at dictum purus. In hac habitasse platea dictumst. Phasellus faucibus ultrices convallis. Praesent blandit nisl at mauris auctor, vulputate tempus mi mollis. Morbi sit amet laoreet odio. Vivamus aliquet magna mauris, nec vestibulum justo aliquam eu.</p>
                    <ul className='shipping-lists-1-t5'>
                        <li><p>Cras adipiscing enim eu turpis egestas Proin at metus eget dolor accumsan tempor pretium aenean Fonsectetur adipiscing sit.</p></li>
                        <li><p>Egestas egestas fringilla at fermentum nisl. Integer at dictum purus phasellus faucibus scelerisque Zamper auctor neque.</p></li>
                        <li><p>Morbi enim nunc faucibus a sed iaculis sapien consectetur in pellentesque sit amet porttios Id volutpat lacus Iaculis.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed, aliquet magna nec justo aliquam eu volutpat scelerisque nisl, Aenean posuere nec.</p></li>
                    </ul>
                </div>
                <div className='return-policy-t5 mt-5' data-aos="fade-left"  data-aos-duration="1000">
                    <h1>Return policy:</h1>
                    <p className='mt-4' >Ut dapibus dui quis elit elementum, vulputate eleifend turpis dignissim. Sed eleifend enim et pulvinar feugiat. In sit amet sapien et turpis convallis scelerisque vel in mi. Donec turpis nibh, posuere ac feugiat sed, ultrices nec mauris. Vestibulum ullamcorper dignissim ex, fringilla mattis libero iaculis ut. Cras at tristique turpis. Pellentesque maximus metus ut sem faucibus auctor. Proin vitae leo tincidunt, placerat ligula egestas, feugiat leo. Mauris at orci id sem scelerisque vehicula. Mauris quam ligula, euismod vel nisi feugiat, suscipit aliquam risus. Cras bibendum lacus a diam eleifend, sed vehicula augue cursus.</p>
                    <ul className='shipping-lists-1-t5'>
                        <li><p>Cras adipiscing enim eu turpis egestas Proin at metus eget dolor accumsan tempor pretium aenean Fonsectetur adipiscing sit.</p></li>
                        <li><p>Egestas egestas fringilla at fermentum nisl. Integer at dictum purus phasellus faucibus scelerisque Zamper auctor neque.</p></li>
                        <li><p>Morbi enim nunc faucibus a sed iaculis sapien consectetur in pellentesque sit amet porttios Id volutpat lacus Iaculis.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed, aliquet magna nec justo aliquam eu volutpat scelerisque nisl, Aenean posuere nec.</p></li>
                        <li><p>Sed enim ligula, facilisis sed lacinia sed hendrerit vulputate scelerisque nisl, Aenean efficitur posuere nec.</p></li>
                    </ul>
                </div>
                <div className='order-status-t5 mt-5' data-aos="fade-right"  data-aos-duration="1000">
                    <h2>Order status and tracking:</h2>
                    <p className='mt-4'>Dictum sit amet justo donec enim diam vulputate. Convallis tellus id interdum velit laoreet id.In sit amet sapien et turpis convallis scelerisque vel in mi. Donec turpis nibh, posuere ac feugiat sed, ultrices nec mauris. Vestibulum ullamcorper dignissim ex, fringilla mattis libero iaculis ut. Cras at tristique turpis. Pellentesque maximus metus ut sem faucibus auctor. Proin vitae leo tincidunt, placerat ligula egestas, feugiat leo. Mauris at orci id sem scelerisque vehicula. Mauris quam ligula, euismod vel nisi feugiat, suscipit aliquam risus.</p>
                    <div className='t5-ship-table mt-2'>
                        <table class="table table-bordered mt-5">
                            <thead>
                                <tr>

                                    <th scope="col">Shipping method</th>
                                    <th scope="col">Shipping Time</th>
                                    <th scope="col">Shipping fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Standard</td>
                                    <td>2-3 Working Days</td>
                                    <td>@$10 or Free Ship on orders $150+</td>
                                </tr>
                                <tr>
                                    <td>Premium</td>
                                    <td>1 Working Day</td>
                                    <td>Additional $20</td>
                                </tr>
                                <tr>
                                    <td>One-day</td>
                                    <td>12 Hours</td>
                                    <td>Additional $40</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Theme5shippinginfo