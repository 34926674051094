import { Box, Button, Container, Grid, Tab, Tabs } from '@mui/material';
import { FaBullseye, FaHandshake, FaHandHoldingHeart, FaUsers } from 'react-icons/fa';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import React, { useContext, useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Theme1home = () => {
  const { homePageGetApi, navigator } = useContext(DataContext);
  const [screenSize, setScreenSize] = useState(4);
  useDidMountEffect(() => {
    // homePageGetApi() 
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(6);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(6);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const logos = [
    "/images/theme1/swi-1.png",
    "/images/theme1/swi-2.png",
    "/images/theme1/swi-3.png",
    "/images/theme1/swi-4.png",
    "/images/theme1/swi-5.png",
    "/images/theme1/swi-6.png",
    "/images/theme1/swi-1.png",
    "/images/theme1/swi-2.png",
    "/images/theme1/swi-3.png",
    "/images/theme1/swi-4.png",
    "/images/theme1/swi-5.png",
    "/images/theme1/swi-6.png",
  ]

  const [activeSlide, setActiveSlide] = useState(0);

  const testimonials = [
    {
      image: "/images/theme1/quotation.png",
      text: "Udhayanidhi has always shown great passion and dedication towards the people of Tamil Nadu.His commitment to youth development and social welfare is commendable.As a politician, he carries forward the values of service, and I am confident that he will continue to work tirelessly for the betterment of our state.",
      imgSrc: "/images/theme1/mk-stalin-pose.png",
      altText: " M.K. Stalin",
      role: "Chief Minister of Tamil Nadu"
    },
    {
      image: "/images/theme1/quotation.png",
      text: "Udhayanidhi's leadership of the DMK Youth Wing has energized the next generation of party workers. His dedication to both the people and the party, along with his vision for youth welfare, shows his commitment to creating a progressive Tamil Nadu. He embodies the same values that have guided our family for generations",
      imgSrc: "/images/theme1/3.png",
      altText: "Kanimozhi Karunanidhi",
      role: "DMK MP"
    },
    {
      image: "/images/theme1/quotation.png",
      text: "In every step of his political journey, Udhayanidhi has shown maturity beyond his years. His ability to communicate with the masses and his clear understanding of Tamil Nadu’s needs reflect his potential to be a transformative leader for our state.",
      imgSrc: "/images/theme1/4.png",
      altText: "Anbil Mahesh Poyyamozhi",
      role: "Minister for School Education, Tamil Nadu"
    },
  ];

  const [value, setValue] = useState("1");
  const [tabName, setTabName] = useState("All");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabNames = {
      1: "All",
      2: "Apps",
      3: "Branding",
      4: "UX?UI"
    };
    if (tabNames[newValue]) {
      setTabName(tabNames[newValue]);
    }
  };

  const handleabout = () => {
    navigator('/:name/aboutus')
  }

  return (
    <div>
      <section className="static-hero">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="hero-content">
                    {/* <div className="slide-title-sub">
                      <span>We Are Waiting For You</span>
                    </div> */}
                    <div className="slide-title">
                      <h2>Udhayanidhi Stalin</h2>
                    </div>
                    <div className="slide-text">
                      <p>DMK Youth Wing Secretary , Minister for Youth Welfare and Sports Development, Government of Tamil Nadu , MLA, Chepauk - Thiruvallikeni Constituency</p>
                    </div>
                    {/* <div className="slide-btns">
                      <button className='theme-btn'>
                        Join the Campaign
                      </button>
                    </div> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="politian-pic">
                    <img src="/images/theme1/udhayanithi-stalin.png" alt="" className='polit img-fluid' />
                    <div className="politian-shape">
                      <div className="shape-1 wow zoomIn" data-wow-duration="2000ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                      <div className="shape-2 wow zoomIn" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                      <div className="shape-3 wow zoomIn" data-wow-duration="1000ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                      <div className="shape-4 wow zoomIn" data-wow-duration="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="mission">
        <div className="smartcrd-details-div">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <div className="service-partner-overlay">
                <div className="feature-item">
                  <div className="icon">
                    <FaBullseye size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Our Mission</h2>
                  </div>
                </div>
                <div className="feature-item-hidden">
                  <div className="icon">
                    <FaBullseye size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Our Mission</h2>
                    <p className='poweres'>Empowering communities through compassionate action, positive change with charity mission.</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <div className="service-partner-overlay">
                <div className="feature-item">
                  <div className="icon">
                    <FaHandshake size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Campaign Events</h2>
                  </div>
                </div>
                <div className="feature-item-hidden">
                  <div className="icon">
                    <FaHandshake size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Campaign Events</h2>
                    <p className='poweres'>Join us in supporting a worthy cause at our charity event, together we can make a difference.</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <div className="service-partner-overlay">
                <div className="feature-item">
                  <div className="icon">
                    <FaHandHoldingHeart size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Make Donation</h2>
                  </div>
                </div>
                <div className="feature-item-hidden">
                  <div className="icon">
                    <FaHandHoldingHeart size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Make Donation</h2>
                    <p className='poweres'>Donate now to help those in need! Make a difference by taking action with your donation.</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <div className="service-partner-overlay">
                <div className="feature-item">
                  <div className="icon">
                    <FaUsers size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Join Volunteer</h2>
                  </div>
                </div>
                <div className="feature-item-hidden">
                  <div className="icon">
                    <FaUsers size={32} />
                  </div>
                  <div className="feature-text">
                    <h2 className='miss'>Join Volunteer</h2>
                    <p className='poweres'>Join our team of volunteers and help make a positive impact in your community today.</p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div> */}
      <section className="wpo-team-section mt-5">
        <div className='donor-sect'>
          <Grid container>
            <Grid item xs={12}>
              <div className="wpo-section-title">
                {/* <span className='ot'>TEAM MEMBERS</span> */}
                <h2 className='met'>Services</h2>
              </div>
            </Grid>
          </Grid>
          <div className="wpo-team-wrap">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/stalin-serv.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    <h2>
                      Youth Welfare and Empowerment
                    </h2>
                    {/* <span>Youth Welfare and Empowerment</span> */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/stalin-serv2.png"
                        alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    <h2>

                      Education and Awareness

                    </h2>
                    {/* <span>Sports Development</span> */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/stalin-serv3.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    <h2>

                      Sports Development

                    </h2>
                    {/* <span>Social Justice and Equality</span> */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <div className="wpo-team-img-box">
                      <img src="/images/theme1/stalin-serv4.png" alt="" className="img-responsive" />
                      {/* <ul className="social-icons">
                        <li><a href="https://www.facebook.com/UdhayStalin/" title="Follow us on Facebook" target='_blank'><FacebookIcon /></a></li>
                        <li><a href="https://x.com/Udhaystalin" title="Follow us on Twitter" target='_blank'><TwitterIcon /></a></li>
                        <li><a href="https://www.instagram.com/udhay_stalin/?hl=en" title="Follow us on Instagram" target='_blank'><InstagramIcon /></a></li>
                        <li><a href="https://www.youtube.com/@dmk_pages" title="Follow us on YouTube" target='_blank'><YouTubeIcon /></a></li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="wpo-team-text">
                    <h2>

                      Social Justice and Equality

                    </h2>
                    {/* <span>Education and Awareness</span> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="wpo-abouted-section section-padding mt-5">
        <Container>
          <div className="wpo-abouts-wrap">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div className="wpo-abouts-img">
                  <img src="/images/theme1/Udhaya.jpg" alt="" />
                  <div className="wpo-abouts-img-text">
                    <h4>1949</h4>
                    <div className="rotate-text">
                      <span className="wow zoomIn" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>W</span>
                      <span className="wow zoomIn" data-wow-duration="1600ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>e</span>
                      <span className="wow zoomIn" data-wow-duration="1700ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>A</span>
                      <span className="wow zoomIn" data-wow-duration="1800ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>r</span>
                      <span className="wow zoomIn" data-wow-duration="1900ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>e</span>
                      <span className="wow zoomIn" data-wow-duration="2000ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>W</span>
                      <span className="wow zoomIn" data-wow-duration="2100ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>o</span>
                      <span className="wow zoomIn" data-wow-duration="2200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>r</span>
                      <span className="wow zoomIn" data-wow-duration="2300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>k</span>
                      <span className="wow zoomIn" data-wow-duration="2400ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>i</span>
                      <span className="wow zoomIn" data-wow-duration="2500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>n</span>
                      <span className="wow zoomIn" data-wow-duration="2600ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>g</span>
                      <span className="wow zoomIn" data-wow-duration="2700ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>F</span>
                      <span className="wow zoomIn" data-wow-duration="2800ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>o</span>
                      <span className="wow zoomIn" data-wow-duration="2900ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>r</span>
                      <span className="wow zoomIn" data-wow-duration="3000ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>Y</span>
                      <span className="wow zoomIn" data-wow-duration="3100ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>o</span>
                      <span className="wow zoomIn" data-wow-duration="3200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>u</span>
                      <span className="wow zoomIn" data-wow-duration="3300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>S</span>
                      <span className="wow zoomIn" data-wow-duration="3400ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>i</span>
                      <span className="wow zoomIn" data-wow-duration="3500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>n</span>
                      <span className="wow zoomIn" data-wow-duration="3600ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>c</span>
                      <span className="wow zoomIn" data-wow-duration="3700ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}>e</span>
                    </div>
                    <div className="dots">
                      <span className="wow fadeInUp" data-wow-duration="1400ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "fadeInUp" }}></span>
                      <span className="wow fadeInUp" data-wow-duration="1300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "fadeInUp" }}></span>
                      <span className="wow fadeInUp" data-wow-duration="1200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "fadeInUp" }}></span>
                    </div>
                    <div className="border-shape-1 wow zoomIn" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                    <div className="border-shape-2 wow zoomIn" data-wow-duration="1000ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                    <div className="border-shape-3 wow zoomIn" data-wow-duration="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationName: "zoomIn" }}></div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div className="wpo-about-text">
                  <div className="section-title">
                    {/* <span className="abo-pol">About Politian</span> */}
                    <h2 className="can-wo">Entry into Politics</h2>
                  </div>
                  <p className='lead'>Udhayanidhi’s entry into politics was almost inevitable, given his family's legacy. His political career began in earnest after M.K. Stalin became the DMK’s president following Karunanidhi's passing in 2018.</p>
                  <p className='lead'><span style={{ fontWeight: 'bold' }}>2019 Lok Sabha Elections:</span> Udhayanidhi played a crucial role in the DMK's campaign, especially in connecting with the youth, and helped secure a resounding victory for the DMK and its alliance in Tamil Nadu.</p>
                  <p className="lead">
                    <span style={{ fontWeight: 'bold' }}>Rising Role in the Party:</span> In 2020, Udhayanidhi was appointed as the DMK's Secretary of the Youth Wing, a significant position once held by his father, M.K. Stalin. Under his leadership, the youth wing saw increased activity, and he positioned himself as a dynamic leader for the next generation.
                  </p>
                  <div className="slide-btns">
                    <button className='theme-btn' onClick={handleabout}>
                      Read More
                    </button>
                  </div>
                  {/* <div className="quote">
                    <p className="world">“We can start by taking small steps and making small changes that can have a big impact on the world.”</p>
                  </div> */}
                  {/* <div className="wpo-about-left-info">
                    <div className="wpo-about-left-inner">
                      <div className="wpo-about-left-text">
                        <h5 className='name-abt'>Robert Willum</h5>
                        <span className='name-sur'>CEO &amp; Founder</span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <section className=''>
        <div className='recent-wrk mt-5'>
          <h1 className='work-tog-head text-center'>Events</h1>
          <TabContext value={value} className="tab-div">
            <div className="name-tab">
              <Box className="tabs-boxs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{
                    borderRadius: '50px',
                    borderBottom: 'none',
                    padding: '10px',
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                  }}
                  className="custom-tabs"
                >
                  <Tab
                    label="PARTY"
                    value="1"
                    style={{
                      background: value === "1" ? '#555' : '',
                      color: value === "1" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="GOVERNMENT"
                    value="2"
                    style={{
                      background: value === "2" ? '#555' : '',
                      color: value === "2" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  <Tab
                    label="PRESS NEWS"
                    value="3"
                    style={{
                      background: value === "3" ? '#555' : '',
                      color: value === "3" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  />
                  {/* <Tab
                    label="REAL ESTATE"
                    value="4"
                    style={{
                      background: value === "4" ? '#555' : '',
                      color: value === "4" ? 'white' : 'black',
                      borderRadius: '7px',
                    }}
                    className="setFont"
                  /> */}
                </Tabs>
              </Box>
            </div>
            <TabPanel value="1">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">26 Sep 2024</div>
                      <img src='/images/theme1/ev1.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Youth Wing Secretary honored former minister Ko. Si. Mani by laying floral tributes at his statue</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">26 Sep 2024</div>
                      <img src='/images/theme1/ev2.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Youth Wing Secy. receives an overwhelming reception from volunteers during his visit to Thanjavur</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                      <div className="date-overlay">26 Sep 2024</div>
                      <img src='/images/theme1/ev5.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Statue of V. Rathinam unveiled in Mangudi Panchayat honoring his legacy to DMK's grassroots growth</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">26 Sep 2024</div>
                      <img src='/images/theme1/ev4.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Youth Wing Secretary marks DMK's 75th Anniversary by inaugurating Kalaignar Kottam in Kumbakonam</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">25 Sep 2024</div>
                      <img src="/images/theme1/ev3.png" className="work-img img-fluid" alt="" />
                      <span className="work-text">In celebration of the party's 75th anniversary, senior pioneers over 75 were honored with cash prizes</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">18 Sep 2024</div>
                      <img src='/images/theme1/ev6.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Youth Wing Secy: 'We will work tirelessly for Tamil Nadu's progress & upliftment of Tamil people'</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">26 Sep 2024</div>
                      <img src='/images/theme1/gov8.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Prizes were awarded by Tamil Nadu Govt to the winners of Chief Minister's Trophy 2024 in Thanjavur</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">24 Sep 2024</div>
                      <img src='/images/theme1/gov7.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>TN Govt has disbursed the first month's stipend of Rs. 7,500 to 1,000 youth preparing for UPSC exam</span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <div className="work-img-container">
                    <div className="date-overlay">24 Sep 2024</div>
                      <img src='/images/theme1/gov9.png' className='work-img img-fluid' alt='' />
                      <span className='work-text'>Tamil Nadu Government distributed house pattas to 114 families in Egmore Kannappar Thidal</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <a href='https://www.youtube.com/live/Kq6raA9xXDw?si=vrNS2IU7BUqvaV6l' target='_blank' rel='noopener noreferrer'>
                      <div className="work-img-container">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/Kq6raA9xXDw"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <a href='https://www.youtube.com/live/8DpitYb0dkY?si=eNlUSeb6W0ft1Z5R' target='_blank' rel='noopener noreferrer'>
                      <div className="work-img-container">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/8DpitYb0dkY"
                          title="YouTube live stream player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <a href='https://youtu.be/06xuVG7LRBs?si=Yu344XObZyejujGC' target='_blank' rel='noopener noreferrer'>
                      <div className="work-img-container">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/06xuVG7LRBs"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            {/* <TabPanel value="4">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-1.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <div className='work'>
                    <img src='/images/theme1/fo-2.jpg' className='work-img img-fluid' alt='' />
                  </div>
                </Grid>
              </Grid>
            </TabPanel> */}
          </TabContext>
        </div>
      </section>
      {/* <section className="wpo-donors-section mt-5">
        <div className="donor-sect">
          <div className="wpo-donors-wrap">
            <div className="donor-wraps">
              <h2 className='dono'>Would you like to become one of <br></br> our honorable donors?</h2>
              <div className="donors-btn">
                <button className='dura'>$10 Donation</button>
                <button className='oth'>Other</button>
              </div>

            </div>
          </div>
        </div>
      </section> */}
      <section className="wpo-testimonial-section mt-5">
        <div className="container">
          <h2 className='testio'>Accolades</h2>
          <div className="wpo-testimonial-wrap">
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className="testimonial-left">
                  <div className="testimonial-left-inner">
                    <div className="shape-1"></div>
                    <div className="border-s1"></div>
                    <div className="border-s2"></div>
                    <div className="border-s3"></div>
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={1}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                    >
                      {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                          <div className="testimonial-img">
                            <img
                              src={testimonial.imgSrc}
                              alt={testimonial.altText}
                              className="test-spec"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <p>
                    <img src={testimonials[activeSlide].image} alt='' className='quates' />
                  </p>
                  <p className="testing">
                    {testimonials[activeSlide].text}
                  </p>
                  <div className='des'>
                    {/* <div>
                      <img src={testimonials[activeSlide].imgSrc} alt='' className='quated' />
                    </div> */}
                    <div>
                      <p className="testin">
                        {testimonials[activeSlide].altText}
                      </p>
                      <p>
                        {testimonials[activeSlide].role}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="wpo-fun-fact-section-s2">
        <div className='fun-sect'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div >
                <div className="infos" >
                  <h3 className="nums">2 cr+</h3>
                  <p className="nums-ti">Party Members</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div >
                <div className="infos" >
                  <h3 className="nums">23</h3>
                  <p className="nums-ti">Wings</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div >
                <div className="infos" >
                  <h3 className="nums">125</h3>
                  <p className="nums-ti">Legislative Assembly Members</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div >
                <div className="infos" >
                  <h3 className="nums">22</h3>
                  <p className="nums-ti">Loksabha Members</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <div className="donors-sect">
        <img src="/images/theme1/stalin-group.jpeg" alt="" className='funs img-fluid' />
      </div>
      {/* <div className='logos-t3-back'>
        <Swiper
          slidesPerView={screenSize}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="my-Swiper"
          breakpoints={{
            320: { slidesPerView: 1 },
            576: { slidesPerView: 1 },
            768: { slidesPerView: 4 },
            1024: { slidesPerView: 4 },
            1200: { slidesPerView: 6 },
          }}
        >
          {logos.map((image, index) => (
            <SwiperSlide key={index}>
              <div>
                <img src={image} alt={`Slide ${index + 1}`} className='foreground-image' />
              </div>
            </SwiperSlide>
          ))}

        </Swiper>
      </div> */}

    </div>
  );
};

export default Theme1home;
