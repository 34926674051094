import { Container, Grid } from '@mui/material'
import React from 'react'

const Theme3ourschedule = () => {
    const data = [
        {
            "id": 1,
            "imageSrc": "/images/theme3/soori/upcom_films/Viduthalai_Part_2.jpg",
            "title": "Viduthalai Part 2",
            "time":"31 January 2024",
            "description": "Viduthalai Part 2  is a 2024 Indian Tamil-language period crime thriller film directed and co-produced by Vetrimaaran, who co-wrote the screenplay with B. Jeyamohan, under RS Infotainment and Grass Root Film Company. The direct sequel to Viduthalai Part 1 (2023), it is the second of a two-part adaptation of Jeyamohan's short story Thunaivan. It follows a police constable's conflict with the leader of a separatist group. Soori, Vijay Sethupathi, Manju Warrier, Gautham Vasudev Menon, Bhavani Sre, Rajiv Menon, Ilavarasu, Balaji Sakthivel, Saravana Subbiah, Chetan and Munnar Ramesh reprise their role from the first film."
        },
        {
            "id": 2,
            "imageSrc": "/images/theme3/soori/upcom_films/Yezhu_Kadal_Yezhu_Malai.jpg",
            "title": "Yezhu kadal yezhu malai",
            "time": "29 January 2024",
            "description": "Yezhu Kadal Yezhu Malai is a 2024 Indian Tamil-language film written and directed by Ram and produced by Suresh Kamatchi under V House Productions. It stars Nivin Pauly, Anjali and Soori. The film's score and soundtrack album is composed by Yuvan Shankar Raja, with cinematography and editing handled by N.K. Ekambaram and Mathi V.S respectively."
        }
    ];

    return (
        <div className='mt-5'>
            <div className='our-sched-titl text-center mb-5'>
                <h2>Upcoming Movies</h2>
            </div>
            <Container>
                <div className='sch-list'>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                        {data && data.map((a, i) => (
                            <>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='sch-left-img-div text-center' >
                                        <img src={a.imageSrc} className="images-left-sche" alt="img" />
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <div className='sch-right-cont'>
                                        <h2>{a.title}</h2>
                                        <p>{a.description}</p>
                                        <div className='d-flex '>
                                            <p><span><img src="/images/theme3/back-in-time.png" alt="loc" style={{ width: "100%", maxWidth: '17px', marginRight: '5px' }} /></span><b>Expected Release Date:</b> &nbsp;{a.time}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{padding:'0px !important'}}>
                                    <div style={{ borderBottom: '1px solid #ccc', margin: '20px 0' }}></div>
                                </Grid>
                            </>
                        ))
                        }
                    </Grid>
                    
                </div>
            </Container>
        </div>
    )
}

export default Theme3ourschedule